import { connect } from 'react-redux';
import { fetchLeaksIfNeeded, setFilters, setDefFilters, sortLeaks } from '../../actions/AlertsActions';
import { FILTERS_ALERTS } from '../../constants/SettingsKeys';

import AlertsSettings from '../../components/AlertsSettings/AlertsSettings';
import { invalidateProject } from "../../actions/setters";

const mapStateToProps = (state, ownProps) => {
  const {
    selectedProject
  } = state.leaksList;
  return Object.assign({}, {
    selectedProject,
    initialValues: state.leaksList.filters.defFilters,
    optionList: state.leaksList.optionList.options
  }, ownProps);
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values, dispatch, props) => {
    const {
      selectedProject,
      processFilters,
      cleanEmptyFilters
    } = props;
    var filters = processFilters(Object.assign({}, values));
    dispatch(invalidateProject(selectedProject));
    dispatch(setDefFilters(filters.defFilters));
    dispatch(setFilters(cleanEmptyFilters(filters.filters)));
    dispatch(sortLeaks(filters.sort.field, filters.sort.dir));
    dispatch(fetchLeaksIfNeeded(selectedProject, true));

    localStorage.setItem(FILTERS_ALERTS, JSON.stringify(filters.defFilters));
  }
});

const CAlertsSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsSettings);

export default CAlertsSettings;
