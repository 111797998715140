export const getDistanceFromLatLonInMeter = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  const distanceInMeter = 1000 * d;
  return (distanceInMeter);
};

const deg2rad = (deg) => {
  const radians = deg * (Math.PI / 180);
  return (radians);
};

export const sqlGeographyToPointsArray = (sqlValue) => {
  let pointsArray = [];

  if (sqlValue != null) {
    const firstSpace = sqlValue.indexOf(' ');
    const type = sqlValue.substring(0, firstSpace);
    switch (type) {
      case 'LINESTRING': {
        // remove the Parenthesis
        const data = sqlValue.substring(firstSpace + 2, sqlValue.length -1);
        const points = data.split(',');
        points.forEach((point) => {
          const lngLatStr = point.trim().split(' ');
          const lng = parseFloat(lngLatStr[0]);
          const lat = parseFloat(lngLatStr[1]);
          pointsArray.push([lng , lat]);
        });
        break;
      }
      default:
        break;
    }

    return (pointsArray);
  }
};
