import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';

import { fetchMiddleware } from './MiddlewareActions';
import { parsePolygonFromDB } from "./helper";

const requestDMAs = () => {
   return {
      type: types.REQUEST_DMAS
   };
};

const receivetDMAs = (dmas) => {
   return {
      type: types.RECEIVE_DMAS,
      dmas
   };
};

export const fetchDMAs = (customerId) => {
   return (dispatch, getState) => {
      const path = endPoints.DMAS_ENDPOINT + '/' + customerId;
      dispatch(requestDMAs());
      return fetchMiddleware(path, {}, getState).then((json) => {
         if (json.status) {
            const dmas = json.data;
            dmas.forEach((dma) => {
               const polygon = parsePolygonFromDB(dma.Boundary);
               dma.Boundary = polygon;
            });
            dispatch(receivetDMAs(dmas));
         }
      });
   };

};

export const setDisplayDmaData = (isShow) => {
   return {
      type: types.SET_DMA_DATA_DISPLAY,
      isShow
   };
};

export const getDmaData = (dmaId) => {
   return (dispatch, getState) => {
      const path = `${endPoints.DMAS_ENDPOINT}/dma/${dmaId}`;
      dispatch(requestDmaData());
      return fetchMiddleware(path, {}, getState).then((json) => {
         if (json.status) {
            const state = getState();
            const dmasList = state.leaksList.dmas.dmasList;
            const selectedDma = dmasList.find((dma) => dma.DmaID == dmaId);
            dispatch(receiveDmaData(dmaId, selectedDma.Comment, json.data));
         }
      });
   };
};

const requestDmaData = () => {
   return {
      type: types.REQUEST_DMA_DATA
   };
};

const receiveDmaData = (dmaId, customerID, data) => {
   return {
      type: types.RECEIVE_DMA_DATA,
      dmaId,
      customerID,
      data
   };
};
