import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

const CheckBoxCell = (props) => {

  const { rowIndex, data, isChecked } = props;

  const handleClick = (event, checked) => {
    // event.preventDefault();
    // event.stopPropagation();
    if (props.onChange) {
      const value = data[rowIndex];
      props.onChange(value, checked);
    }
  }


  const value = data[rowIndex];
  const checked = isChecked(value);
  // const checked = checkedItems.includes(value.);
  return (
    <Checkbox
      color="primary"
      onChange={(event) => {
        handleClick(event, checked);
      }}
      checked={checked}
    />
  );
}

export default CheckBoxCell;
