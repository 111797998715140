import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { DEVICE_DIGITS, UninstallMode } from '../constants/Misc';
import * as actionsDevice from './DevicesActions';
import * as actionsHistory from './HistoryActions';
import { fetchMiddleware } from './MiddlewareActions';
import * as sensorsActions from './SensorsActions';
import * as sopsActions from './SOPsActions';

import { selectFeature } from "./selectors";
import { setMapDrawMode } from "./setters";

export const changeContext = (context) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;

    dispatch(changeContextAction(context));
    dispatch(setMapDrawMode(false));
    dispatch(sensorsActions.selectSensor(null));

  };
}

const changeContextAction = (context) => {
  return {
    type: types.CHANGE_INSTALL_CONTEXT,
    context
  };
}

export const addNewSOP = () => {
  return {
    type: types.ADD_NEW_INSTALL_ITEM,
  }
}

export const setNewSopPosition = (position) => {
  return (dispatch, getState) => {
    dispatch({
      type: '@@redux-form/CHANGE',
      meta: {
        field: "Coordinate",
        form: "sopForm",
        persistentSubmitErrors: false,
        touch: false,
      },
      payload: position[1].toFixed(6) + ', ' + position[0].toFixed(6),
    });
    return dispatch({
      type: types.SET_NEW_SOP_TEMP_POSITION,
      position,
    });
  }
}

export const setUninstallMode = (isUninstall) => {
  return {
    type: types.SET_SENSORS_UNINSTALL_MODE,
    isUninstall
  }
}

export const fetchConfigs = () => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProjectId = state.leaksList.selectedProject;
    const path = endPoints.PROJECTS_ENDPOINT + "/" + selectedProjectId + "/templates";
    dispatch(setInstallWorkingState(true));
    dispatch(requestConfigs());
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        dispatch(setInstallWorkingState(false));
        if (json.status) {
          dispatch(receiveConfigs(json.data));
        }
      }
      );
  }
}

export const removeSensor = (cb) => {
  return (dispatch, getState) => {
    const state = getState();
    const installState = state.install;
    const sensorDetails = installState.sensorDetails;
    const path = endPoints.INSTALL_ENDPOINT + '/sensor/' + sensorDetails.ID;
    dispatch(setInstallWorkingState(true));
    return fetchMiddleware(path, {
      method: 'DELETE'
    }, getState).then((json) => {
      const projectId = state.leaksList.selectedProject;
      dispatch(setInstallWorkingState(false));
      dispatch(actionDone());

      reloadData(dispatch, projectId);
      returnNotificationData(json.status, 'removeSensor', cb);
    });
  };
};

export const addSensorOrInstallOrUpdate = (values, cb) => {
  return (dispatch, getState) => {
    const state = getState();
    const installState = state.install;
    const sensorDetails = installState.sensorDetails;
    const selectedSopId = sensorDetails.sopID;
    let formInput = null;
    if (state.form.sopOperationsForm != null && state.form.sopOperationsForm.values != null) {
      formInput = state.form.sopOperationsForm.values;
    }

    if (sensorDetails == null || sensorDetails.ID == null) {
      // add sensor or add and install (if device is inserted)
      if (formInput == null || formInput.DeviceID == null || formInput.DeviceID == '') {
        // add sensor:
        dispatch(addSensorOnSop(selectedSopId, cb));
      } else {
        // check if given all values:
        if (isInstallFieldsMissing(formInput)) {
          // Error.
          if (cb != null) {

          }
        } else {
          // add Sensor and install device:
          dispatch(installDeviceOnSensor(selectedSopId, values, cb));
        }
      }
    } else {
      if (isInstallFieldsMissing(formInput)) {
        // Error.
        if (cb != null) {

        }
      } else {
        if (sensorDetails.DeviceID == null || sensorDetails.DeviceID == '') {
          // install:
          dispatch(installDeviceOnSensor(selectedSopId, values, cb));
        } else {
          // update device:
          dispatch(updateDeviceDetails(selectedSopId, sensorDetails, cb));
        }

      }
    }
  };
};

export const uninstallDevice = (cb) => {
  return (dispatch, getState) => {
    const state = getState();
    const uninstallMode = state.install.uninstallMode;
    switch (uninstallMode) {
      default:
      case UninstallMode.NONE:
        // nothing.
        break;

      case UninstallMode.NORMAL:
      case UninstallMode.UNINSTALL_AND_REMOVE_SENSOR:
        const removeSensor = (uninstallMode == UninstallMode.UNINSTALL_AND_REMOVE_SENSOR);
        dispatch(uninstallDeviceAction(removeSensor, cb));
        break;
      case UninstallMode.UNINSTALL_AND_REINSTALL_ON_NEW_LOCATION:
        const sensorIdToUninstall = state.install.uninstallBusyDeviceDetails;
        dispatch(installBusyDevice(sensorIdToUninstall, cb));
        break;
    }
  }
}

const setSensorForUninstallDetails = (details) => {
  return {
    type: types.SET_SENSOR_FOR_UNINSTALL_DETAILS,
    details,
  };
};

const requestConfigs = () => ({
  type: types.REQUEST_CONFIGS
});

const receiveConfigs = (configs) => ({
  type: types.RECEIVE_CONFIGS,
  configs,
});
export const setSensorAsArchived = () => (dispatch, getState) => {
  const state = getState();
  const sensorId = state.install.sensorDetails.ID;
  const projectId = state.leaksList.selectedProject;

  const path = `${endPoints.INSTALL_ENDPOINT}/archive/${sensorId}`;
  return fetchMiddleware(path, { method: 'DELETE' }, getState).then((json) => {
    if (json.status) {
      reloadData(dispatch, projectId);
    }
  });
};
const uninstallDeviceAction = (removeAfterUninstall, cb) => {
  return (dispatch, getState) => {
    const state = getState();
    const sensorId = state.install.sensorDetails.ID;

    let uninstallInput = null;
    if (state.form.uninstallDeviceForm != null) {
      uninstallInput = state.form.uninstallDeviceForm.values;
    }

    const data = Object.assign({}, uninstallInput, {
      removeSensor: removeAfterUninstall
    });
    const postOptions = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    };

    const path = endPoints.INSTALL_ENDPOINT + '/device/' + sensorId;
    dispatch(setInstallWorkingState(true));
    return fetchMiddleware(path, postOptions, getState).then(json => {
      // console.log(json);
      const selectedProject = state.leaksList.selectedProject;
      dispatch(selectFeature(selectedProject, '', ''));
      dispatch(setInstallWorkingState(false));
      if (json.status) {
        const selectedSop = state.install.sensorDetails.sopID;
        reloadData(dispatch, selectedProject);

        dispatch(actionDone());
        dispatch(selectFeature(selectedProject, sensorId, 'sensor'));
        returnNotificationData(json.status, 'uninstallDevice', cb);
      }
    });
  }
}

const installBusyDevice = (sensorToUninstallDetails, cb) => {
  return (dispatch, getState) => {
    const state = getState();

    const selectedProject = state.leaksList.selectedProject;
    const selectedSop = state.leaksList.leaksByProject.selectedFeature;
    const sensorForInstall = state.install.sensorDetails.ID || 0;
    const sensorForUninstall = sensorToUninstallDetails.sensorID;
    const customerID = sensorToUninstallDetails.customerID;
    const selectedProjectId = state.leaksList.selectedProject;

    let formInput = null;
    let uninstallInput = null;
    if (state.form.uninstallDeviceForm != null) {
      uninstallInput = state.form.uninstallDeviceForm.values;
    }
    if (state.form.sopOperationsForm != null && state.form.sopOperationsForm.values != null) {
      formInput = Object.assign({}, state.form.sopOperationsForm.values, uninstallInput, {
        customerID: customerID,
        projectId: selectedProjectId,
      });
    }
    const postOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: formInput })
    };
    const path = endPoints.INSTALL_ENDPOINT + '/busyDevice/' + formInput.DeviceID + '/' + sensorForUninstall + '/' + sensorForInstall;
    dispatch(setInstallWorkingState(true));
    return fetchMiddleware(path, postOptions, getState).then(json => {
      // console.log(json);
      dispatch(setInstallWorkingState(false));
      if (json.data != null && json.data["0"].Status == 'OK') {
        reloadData(dispatch, selectedProject);

        dispatch(actionDone());
        dispatch(actionsHistory.fetchSensorHistoryIfNeeded(selectedProject, sensorForInstall));
        dispatch(actionsDevice.fetchDeviceLog(selectedProject, formInput.DeviceID));

      }
      returnNotificationData(json.status, 'installDevice', cb);
    });
  }
}

const addSensorOnSop = (sopId, cb) => {
  return (dispatch, getState) => {
    // console.log('addSensorOnSop');
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const path = endPoints.END_POINT + '/sop/' + sopId + '/addSensor';
    dispatch(setInstallWorkingState(true));
    return fetchMiddleware(path, {}, getState).then(json => {

      dispatch(setInstallWorkingState(false));

      if (json.status) {

        //dispatch(selectFeature(projectId, '', ''));
        reloadData(dispatch, projectId);
      }

      returnNotificationData(json.status, 'addSensor', cb);
      return ([projectId]);
    }).then((args) => {
      //const sopsData = state.leaksList.leaksByProject[projectId].sops;
      //const sopsItems = sopsData.sops;
      //const sopsIndexMap = sopsData.indexMap;
      //const selectedSop = sopsItems[sopsIndexMap[sopId]];
      //dispatch(sopsActions.selectSOP(selectedSop));
      ////dispatch(selectFeature(args[0], sopId, 'sop'));
    });
  }
}

const installDeviceOnSensor = (sopId, values, cb) => {
  //todo lia 234
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const projectsIndexMap = state.leaksList.projectsList.projectIndexMap;
    const projectData = state.leaksList.projectsList.items[projectsIndexMap[projectId]];
    const sensorData = state.install.sensorDetails;
    const sensorId = (sensorData == null || sensorData.ID == null || sensorData.ID.toString() == '') ? 0 : sensorData.ID;

    const path = endPoints.INSTALL_ENDPOINT + '/device/' + sopId + '/' + sensorId + '/' + projectData.CustomerID;
    const data = state.form.sopOperationsForm.values;
    data.projectId = projectId;

    dispatch(setInstallWorkingState(true));
    // dispatch(selectFeature(projectId, '', ''));

    // console.log("INSTALL SENSOR");

    return fetchMiddleware(path, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    }, getState).then(json => {
      dispatch(setInstallWorkingState(false));
      if (json.status) {
        if (json.data == null) {
          // success:
          reloadData(dispatch, projectId);
          returnNotificationData(true, 'installDevice', cb);
        } else {
          // dispatch(selectFeature(projectId, sensorId, 'sensor'));

          if (json.data[0] != null) {
            const failDetails = json.data[0];
            if (failDetails.Status != 'Failed') {
              // success:
              //dispatch(selectFeature(projectId, '', ''));
              dispatch(actionsHistory.fetchSensorHistoryIfNeeded(projectId, sensorId));

              if (sensorData.DeviceID != null && sensorData.DeviceID != '') {
                dispatch(actionsDevice.fetchDeviceLog(projectId, sensorData.DeviceID));
              }
              reloadData(dispatch, projectId);
              returnNotificationData(true, 'installDevice', cb);
            } else {
              switch (failDetails.ERR_TYPE) {
                case 'BUSY_DEVICE': {
                  const projects = state.leaksList.projectsList;
                  const projectOfDevice = projects.items[projects.projectIndexMap[failDetails.projectID]];
                  if (projectOfDevice != null) {
                    const details = {
                      project: projectOfDevice.Name,
                      sensorID: failDetails.sensorId,
                    }
                    dispatch(setSensorForUninstallDetails(details));

                    // set uninstall mode at least (after you set the updated data befor it):
                    dispatch(setUninstallMode(UninstallMode.UNINSTALL_AND_REINSTALL_ON_NEW_LOCATION));
                  }
                  break;
                }
                case 'WRONG_TYPE': {
                  returnNotificationData(false, 'wrongType', cb);
                  break;
                }
              }
            }
          }
        }
      } else {
        if (cb != null) {
          returnNotificationData(false, 'installDevice', cb);
        }
      }
    });
  }
}

const updateDeviceDetails = (sopId, sensorDetails, cb) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.form.sopOperationsForm == null) {
      cb('Nothing to update');
    } else {
      const values = state.form.sopOperationsForm.values;
      const path = endPoints.INSTALL_ENDPOINT + '/device/' + sopId;

      dispatch(setInstallWorkingState(true));
      return fetchMiddleware(path, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: values })
      }, getState).then(json => {
        dispatch(setInstallWorkingState(false));
        if (json.status) {
          const projectId = state.leaksList.selectedProject;

          //dispatch(selectFeature(projectId, '', ''));
          dispatch(actionsHistory.fetchSensorHistoryIfNeeded(projectId, sensorDetails.ID));
          dispatch(actionsDevice.fetchDeviceLog(projectId, sensorDetails.DeviceID));
          reloadData(dispatch, projectId);
        }
        returnNotificationData(json.status, 'update', cb);
      });
    }
  }
}

const isInstallFieldsMissing = (formInputValues) => {
  let bRc = false;

  if (formInputValues != null) {
    bRc = (formInputValues.DeviceID == null || formInputValues.DeviceID.length != DEVICE_DIGITS) &&
      (formInputValues.SyncMethod == null || formInputValues.SyncMethod.toString() == '') &&
      (formInputValues.SensorType == null || formInputValues.SensorType.toString() == '') &&
      (formInputValues.isUnderground == null || formInputValues.isUnderground.toString() == '') &&
      (formInputValues.Template == null && formInputValues.Template == '')
  }

  return (bRc);
}

const setInstallWorkingState = (isWorking) => {
  return {
    type: types.SET_INSTALL_ACTION_WORKING_STATE,
    isWorking,
  }
}

/**
 * call this to close the Modal
 */
const actionDone = () => {
  return {
    type: types.INSTALL_ACTION_DONE_SUCCESSFULLY,
  }
}

const reloadData = (dispatch, projectId) => {
  dispatch(sensorsActions.fetchSensorsIfNeeded(projectId, true)).then((a) => {
    dispatch(reselectItem());
  });
}

const reselectItem = () => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;
    const selectedFeature = state.leaksList.leaksByProject.selectedFeature;
    const selectedType = state.leaksList.leaksByProject.selectedFeatureType;
    dispatch(selectFeature(selectedProject, '', ''));
    if (selectedType == 'sop') {
      const sops = state.leaksList.leaksByProject[selectedProject].sops;
      const selectedSop = sops.sops[sops.indexMap[selectedFeature]];
      dispatch(sopsActions.selectSOP(selectedSop));
    } else if (selectedType == 'sensor') {
      const sensors = state.leaksList.leaksByProject[selectedProject].sensors;
      const selectedSensor = sensors.sensors[sensors.indexMap[selectedFeature]];
      dispatch(sensorsActions.selectSensor(selectedSensor));
    }
  }
}

const returnNotificationData = (isSuccess, processName, cb) => {
  if (cb != null) {
    const level = (isSuccess) ? 'success' : 'error';
    let message;
    switch (processName) {
      case 'addSensor':
        message = (isSuccess) ? 'Sensor created successfully' : 'Failed to create sensor';
        break;
      case 'installDevice':
        message = (isSuccess) ? 'Device installed successfully' : 'Falied to install the Device';
        break;
      case 'uninstallDevice':
        message = (isSuccess) ? 'Device uninstalled successfully' : 'Uninstall Failed';
        break;
      case 'update':
        message = (isSuccess) ? 'Changes saved' : 'Failed. Changes not saved';
        break;
      case 'removeSensor':
        message = (isSuccess) ? 'Sensor removed successfully' : 'Failed to remove sensor';
        break;
      case 'wrongType':
        message = 'Falied to install the Device. Invalid Device Type';
        break;
      default:
        break;
    }
    // console.log('level: ' + level + ', message: ' + message);
    cb({
      level,
      message
    });
  }
};
