import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, IndexRoute, Redirect } from 'react-router';

import { ENV, ENVIRONMENTS } from '../constants/EndPoints';

import { routes } from '../constants/Misc';
import App from '../app/App';
import Page from '../containers/Page/Page';
import MobilePage from '../containers/MobilePage/MobilePage';
import Reports from '../containers/Reports/Reports';
// import Login from '../containers/Login/Login';
import Login from '../containers/Login/CLogin';
import NotFoundView from '../views/NotFoundView';

import MainPage from '../components/G5DevicesManagement/MainPage';
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions';

import {
  userIsAuthenticated,
  userIsAdmin,
  userIsNotAuthenticated,
} from './auth';
import DashboardPage from '../components/Dashboard/DashboardPage';

const authPage = userIsAuthenticated(Page);
// const authMobilePage = userIsAuthenticated(MobilePage);
const deviceConfigurationPage = userIsAuthenticated(MainPage);
const reportsPage = userIsAuthenticated(userIsAdmin(Reports));
const dashboardPage = userIsAuthenticated(userIsAdmin(DashboardPage));

export default function Routes(props) {
  return (
    <App>
      <Router history={props.history}>
        <Route path="/">
          <IndexRoute component={authPage} />
          <Route path={routes.ALERTS} component={authPage} />
          <Route path={routes.SENSORS} component={authPage} />
          <Route path={routes.COUPLES} component={authPage} />
          <Route path={routes.MOBILE} component={authPage} />
          <Route path={routes.MANAGE} component={authPage} />
          <Route path={routes.INSTALL} component={authPage} />
          <Route path={routes.INPECT} component={authPage} />
          <Route path={routes.REPORTS} component={reportsPage} />
          {JSON.parse(process.env.REACT_APP_DASHBOARD) &&
            <Route path={'dashboard'} component={dashboardPage} />
          }
          <Route
            path={routes.LOGIN}
            component={userIsNotAuthenticated(Login)}
          />
          {/*<Route path={routes.TERMS} component={TermsAndConditions} />*/}
          <Route
            path={routes.G5DevicesManagement}
            component={deviceConfigurationPage}
          />
          <Route path="404" component={NotFoundView} />
          <Redirect from="*" to="alerts" />
        </Route>
      </Router>
    </App>
  );
}

Routes.propTypes = {
  history: PropTypes.object,
};
