import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    margin: '5px 20px'
  },
  fields: {
    padding: '5px 0',
    display: 'grid',
    gridTemplateColumns: '120px 100px',
    gridRowGap: '3px',
  },
  submit: {
    width: '-webkit-fill-availabel',
    fontSize: '1.5rem',
  }
});

export default function ConfSetLTE(props, context) {
  const [open, setOpen] = React.useState(false);
  const [lteValue, setLteValue] = React.useState(0);

  const classes = useStyles();

  function askBeforSubmit(event) {
    event.preventDefault();
    const selected = event.target[0].selectedOptions[0].value;

    setOpen(true);
    setLteValue(Number(selected));
  }

  function handleClose() {
    setOpen(false);
  }

  function submitValue() {
    handleClose();
    props.updateConfig({lteMode: lteValue});
  }

  const modeOptions = [
    { value: 0, label: context.t('cat_m_preferred'), disable: false},
    { value: 1, label: context.t('nb_iot_preferred'), disable: false},
    { value: 2, label: context.t('cat_m_only'), disable: !props.advancedOptions},
    { value: 3, label: context.t('nb_iot_only'), disable: !props.advancedOptions},

  ];

  return (
    <form className={classes.root} onSubmit={askBeforSubmit}>
      <div className={classes.fields}>
        <label htmlFor='lte-mode-select'>{context.t('lte_mode')}</label>
        <select id='lte-mode-select' disabled={!props.enable}>
          {
            modeOptions.map((mode) => <option key={mode.value} value={mode.value} disabled={mode.disable}>{mode.label}</option>)
          }
        </select>
      </div>
      <Button className={classes.submit} variant="contained" color="primary" type='submit' disabled={!props.enable}>{context.t('submit')}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
      >
        {/* <DialogTitle>{context.t('You are about to take a significant action')}</DialogTitle> */}
        <DialogContent>
        <DialogContentText style={{fontSize: 'large', color: 'black', textAlign: 'center'}}>
          {context.t('you_are_about_to_take_a_significant_action_on_num_devices', { num: props.countSelected })}<br/>
          <span style={{fontSize: 'medium', color: '#606060'}}>{context.t('confirm_updating_lte_mode_to', {mode: (modeOptions.find((o) => o.value === lteValue)).label})}</span></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose} autoFocus>{context.t('cancel')}</Button>
          <Button color="primary" onClick={submitValue} autoFocus>{context.t('continue')}</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

ConfSetLTE.propTypes = {
  advancedOptions: PropTypes.bool,
  enable: PropTypes.bool,
  updateConfig: PropTypes.func
};

ConfSetLTE.contextTypes = {
  t: PropTypes.func.isRequired
};
