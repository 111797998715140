import { connect } from 'react-redux';
import InterferencesSettings from '../../components/Interferences/InterferencesSettings';
import {setInterfernecesSettings, fetchInterferences} from '../../actions/InterferencesActions';

const mapStateToProps = (state, ownProps) => {
  const filters = state.leaksList.filters.interferencesFilters;
  const sort = state.leaksList.filters.interferencesSort;
  const interferenceTypes = state.leaksList.optionList.options.InterferenceTypes;

  const initialValues = {
    sortBy: sort.field,
    sortByDir: sort.dir
  };

  return Object.assign({}, {
    filters,
    interferenceTypes,
    initialValues,
  }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      console.table(values);
      console.table(ownProps);
      dispatch((dispatch, getState) => {
        const state = getState();
        const selectedProject = state.leaksList.selectedProject;

        const sortParams = {
          field: values.sortBy,
          dir: values.sortByDir
        };

        const filters = [];

        Object.keys(values).forEach( (key) => {
          if (key != 'sortBy' && key != 'sortByDir'){
            const filterITem = {
              field: key,
              value: values[key]
            };
            filters.push(filterITem);
          }
        });
        // console.log(filters);

        dispatch(setInterfernecesSettings(sortParams, filters));
        dispatch(fetchInterferences(selectedProject));
      });
    }
  };
};

const CInterferencesSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(InterferencesSettings);

export default CInterferencesSettings;
