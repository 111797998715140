import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';
import TotalPipeLengthTab  from '../../components/ProjectsTabs/TotalPipeLengthTab';
import CProjectDetailsTab from '../../containers/CProjectTab/CProjectDetailsTab';
const classNames = require('classnames');

export default class ProjectsTabs extends React.Component {

  constructor(props) {
    super();
    this.closePanel = this.closePanel.bind(this);
    this.getPipeLengthTable = this.getPipeLengthTable.bind(this);
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);
    this.props.clearSelectedFeature();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.manageItem !== null && this.props.manageItem === null) ||
      nextProps.manageItem !== null && this.props.manageItem.id !== nextProps.manageItem.id) {
      this.props.toggleDetailsVisibility(true);
    }
  }

  getPipeLengthTable() {
    this.props.getCalculateTotalPipes();
  }

  render() {
    const { showDetails, manageItem, uiInfo, user, updateProjectPipes, units } = this.props;
    if (manageItem == null || manageItem.item == null) {
      return null;
    }

    const projectName = (manageItem.id == 0) ? this.context.t('new_project') : manageItem.item.ShortName;

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
    );
    const panelStyle = { height: 0.493 * uiInfo.pageHeight };

    return (
      <div className={panelClasses} style={panelStyle}>
        <TabsContainer
          title={this.context.t('project_name_', { name: projectName})}
          handleClose={this.closePanel}
          tabs={[
            {
              label: this.context.t('details'),
              component: (
                <CProjectDetailsTab
                  user={user}
                  notify={this.props.notify}
                  setProjectCenter={this.props.setProjectCenter}
                  updateProjectPipes={updateProjectPipes}
                />
              )
            },
            {
              label: this.context.t('profiler'),
              component: (
                <div>
                  <TotalPipeLengthTab
                    calculatedTotalLengthTable={this.props.calculatedTotalLengthTable}
                    distanceFactor={this.props.distanceFactor}
                    uiInfo={uiInfo}
                    getCalculateTotalPipes={this.props.getCalculateTotalPipes}
                    exportPipesReport={this.props.exportPipesReport}
                    exportAlertInvestigation={this.props.exportAlertInvestigation}
                    units={units}
                  />
                </div>
              )
            },
          ]}
        />
      </div>
    );
  }
}

ProjectsTabs.contextTypes = {
  t: PropTypes.func.isRequired
};

