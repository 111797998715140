import * as types from '../constants/ActionTypes';

const initialAlertsState = {
  isFetching: false,
  data: [],
  valuesSets: {},
  indexMap: {},
  filteredData: {
    field: null,
    dir: null
  }
};
const initialPipesState = {
  isFetching: false,
  data: [],
  valuesSets: {},
};
const initialAnalysisData = {
  fixedLeaks: {},
  infrastructure: {},
  leakByInfrastructure: {},
};

const initialState = {
  alerts: initialAlertsState,
  pipes: initialPipesState,
  projectId: null,
  selectedAlerts: [],
  center: null,
  materialsByPlacementYear: initialAnalysisData,
  diameterByPlacementYear: initialAnalysisData,
  materialAndDiameterByPlacementYear: initialAnalysisData,
  pcaScoreData: {},
  pipeCalcProbParams: {},
  scoreSelected: {
    category: '',
    data: []
  }
};

export const inspect = (state = initialState, action) => {
    switch (action.type) {

      case types.RECEIVE_SUSPICIOUS_ALERTS_IDS: {
        if (action.mode !== 'inspect') {
          return state;
        } else {
          const alerts = [...state.alerts.data];
          const indexMap = state.alerts.indexMap;
          action.alertsIds.forEach((data) => {
            const alert = alerts[indexMap[data.ID]];
            if (alert) {
              // alert.isSuspicious = true;
              alert.Burst = 2;
            }
          });
          return Object.assign({}, state, {
            items: alerts
          });
        }
      }

        case types.REQUEST_ALERTS_INVESTIGATION:
            return Object.assign({}, state, {
              alerts: Object.assign({}, initialAlertsState, {
                isFetching: true,
              })
            });

        case types.RECEIVE_ALERTS_INVESTIGATION:
          return Object.assign({}, state, {
            alerts: {
              isFetching: false,
              data: action.data,
              valuesSets: action.valuesSets,
              indexMap: action.indexMap,
            },
            selectedAlerts: action.data,
            projectId: action.projectId,
          });

        case types.INSPECT_SELECT_FILTERED_ALERTS:
          return Object.assign({}, state, {
            selectedAlerts: action.alerts,
            center: action.center
          });

          case types.SET_ALERTS_DATA_FOR_CATEGORY:
          return Object.assign({}, state, {
            scoreSelected: {
              category: action.category,
              data: action.data
            }
          });

        case types.REQUEST_PIPES_INVESTIGATION:
          return Object.assign({}, state, {
            pipes: Object.assign({}, initialPipesState, {
              isFetching: true,
            })
          });

        case types.RECEIVE_PIPES_INVESTIGATION:
          return Object.assign({}, state, {
            pipeCalcProbParams: action.pipeCalcProbParams,
            pipes: {
              isFetching: false,
              data: action.data,
              valuesSets: action.valuesSets,
            },
          });

        case types.SAVE_INVESTIGATION_ANALYSIS_DATA:
          return Object.assign({}, state, {
            materialsByPlacementYear: action.data.materialsByPlacementYearData,
            diameterByPlacementYear: action.data.diameterByPlacementYearData,
            materialAndDiameterByPlacementYear: action.data.materialsDiameterByPlacementYearData,
            pcaScoreData: action.data.pcaScoreData,
          });

        case types.SORT_INVESTIGATION_ALERTS_LOCALY:
          return {
            ...state,
            alerts: {
              ...state.alerts,
              data: action.alerts,
              indexMap: action.indexMap,
            }
          };

        default:
            return state;
    }
};

export default inspect;
