// CookieConsentComponent.js

import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentComponent = () => {
  const [showConsent, setShowConsent] = useState(true);
  const [userAgreed, setUserAgreed] = useState(false);

  const handleUserAgreement = () => {
    setUserAgreed(true);
    setShowConsent(false);
  };

  return (
    <>
      { false && (
        <CookieConsent
          location="bottom"
          buttonText="I Agree"
          cookieName="myCookieConsent"
          style={{ background: "#2B373B" }}
          // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          buttonClasses="btn btn-primary"
          containerClasses="alert alert-warning col-lg-12"
          expires={150}
          onAccept={handleUserAgreement}
        >
          {this.context.t('this_website_uses_cookies_to_enhance_the_user_expe')}
        </CookieConsent>
      )}
    </>
  );
};

export default CookieConsentComponent;
