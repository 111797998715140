import React from 'react';

import { Link } from 'react-router';

export default class NotFoundView extends React.Component {
  render () {
    return (
      <div className='container text-center'>
        <h1>{this.context.t('this_is_a_demo_404_page')}</h1>
        <hr />
        <Link to='/'>{this.context.t('back_to_home_view')}</Link>
      </div>
    );
  }
}
