import * as types from '../constants/ActionTypes';

export function leak(items = [], action) {
    if (items.length) {
        return [
            ...items.slice(0, action.row),
            Object.assign({}, items[action.row], {
                [action.key]: action.val,
            }),
            ...items.slice(action.row + 1)
        ];
    }
    else {
        return [];
    }
}