// AddMobileAlertForm.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Select from '../Select/Select';
import Input from '../Input/Input';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import AddressField from "../Custom/AddressField";

// Validator for coordinates
const isValidCoordinate = (value) => {

  if (!value) {
    return 'Coordinate is required';
  } else {
    const coords = Array.isArray(value)
      ? value
      : value.split(',').map((coord) => coord.trim());

    if (coords.length !== 2) {
      return 'Invalid coordinates format';
    }

    const [lat, lon] = coords.map((coord) => parseFloat(coord));

    if (
      isNaN(lat) ||
      isNaN(lon) ||
      lat < -90 ||
      lat > 90 ||
      lon < -180 ||
      lon > 180
    ) {
      return 'Invalid coordinates';
    }
  }

  return undefined;
};

const AddMobileAlertForm = (props, context) => {
  const {
    isOpen,
    doClose,
    options,
    user,
    handleSubmit,
    selectedFeature,
    checkedSamples,
    createNewMobileAlert,
    getAddressByLatLong,
    reset, // reset function from redux-form
  } = props;

  // Function to handle form submission
  const onSaveData = (values, dispatch) => {
    const requiredFields = [
      'alertState',
      'alertType',
      'alertClassification',
      'Coordinate',
    ];

    // Validate required fields
    requiredFields.forEach((fieldKey) => {
      if (values[fieldKey] == undefined) {
        throw new SubmissionError({
          [fieldKey]: 'Required',
          _error: `${fieldKey} is required`,
        });
      }
    });

    const [taskId] = selectedFeature;

    createNewMobileAlert(taskId, {
      ...values,
      samplesIds: checkedSamples,
    }
    );
    //
    doClose();

    reset(); // Reset the form after submission
  };

  const formClass = 'form-group auto-center';
  const labelsClass = 'col-xs-3 control-label';
  const fieldsClass = 'col-xs-8 control-input';
  const panelClasses = classNames('add-mobile-alert-form', { show: isOpen });
  const divStyle = { float: 'left', width: '100%' };


  return (
    <div className={panelClasses}>
      <div className="new-alert-header">
        <div className="actions">
          <i className="icon-close" onClick={doClose} />
        </div>
        <h2>{context.t('add_new_mobile_alert')}</h2>
      </div>
      <form
        className="form-horizontal"
        // className="form-horizontal sticky-actions"
        onSubmit={handleSubmit(onSaveData)} // Use onSaveData for form submission
      >
        <div style={{ display: 'flex', marginTop: '2em', width: '100%' }}>
          <div style={divStyle}>

            {/* Alert State Field */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('alert_state')}</label>
              <div className={fieldsClass}>
                <Field
                  name="alertState"
                  component={(fieldProps) => (
                    <Select {...fieldProps} options={options.AlertStateNoise} />
                  )}
                />
              </div>
            </div>
            {/* Alert Type Field */}
            <div className={formClass}>
              <label className={labelsClass}>
                {context.t('alert_type')}
              </label>
              <div className={fieldsClass}>
                <Field
                  name="alertType"
                  component={(fieldProps) => (
                    <Select
                      {...fieldProps}
                      options={options.AlertType}
                    />
                  )}
                />
              </div>
            </div>
            {/* Alert Classification Field */}
            <div className={formClass}>
              <label className={labelsClass}>
                {context.t('alert_classification')}
              </label>
              <div className={fieldsClass}>
                <Field
                  name="alertClassification"
                  component={(fieldProps) => (
                    <Select
                      {...fieldProps}
                      options={options.AudioClassificationEnum}
                    />
                  )}
                />
              </div>
            </div>
            {/* Coordinate Field */}
            <div className={formClass}>
              <label className={labelsClass}>
                {context.t('coordinate')}:
              </label>
              <div className={fieldsClass}>
                <Field
                  name="Coordinate"
                  placeholder={context.t('click_on_map_to_set_coordinate')}
                  component={Input}
                  type="text"
                  className="form-control"
                  validate={[isValidCoordinate]}
                />
              </div>
            </div>
            {/* Address Field */}
            <div style={{ marginLeft: '70px', marginBottom: '20px' }}>
              <AddressField
                className="form-group"
                fieldClassName={fieldsClass}
                user={user}
                fieldName="address"
                onGetAddress={getAddressByLatLong} />
            </div>
            {/* Comment Field */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('comment')}</label>
              <div className={fieldsClass}>
                <Field
                  name="comment"
                  component="textarea"
                  className="form-control"
                  type="text"
                  placeholder={context.t('comment')}
                  maxLength={225}
                />
              </div>
            </div>

          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: '2em 0' }} >
          <Button
            style={{ margin: '0 5px' }}
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            type="submit"
          >
            {context.t('submit')}
          </Button>
          <Button
            style={{ margin: '0 5px' }}
            variant="contained"
            color="secondary"
            endIcon={<CancelIcon />}
            onClick={doClose}
          >
            {context.t('cancel')}
          </Button>
        </div>

      </form>

    </div>
  );
};

// Validators for the form
const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'alertState',
    'alertType',
    'alertClassification',
    'Coordinate',
  ];

  requiredFields.forEach((fieldKey) => {
    if (values[fieldKey] == undefined) {
      errors[fieldKey] = 'Required';
    }
  });

  return errors;
};

AddMobileAlertForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

AddMobileAlertForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  doClose: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedFeature: PropTypes.array.isRequired,
  checkedSamples: PropTypes.array.isRequired,
  createNewMobileAlert: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'add-mobile-alert-form',
  enableReinitialize: true,
  validate,
})(AddMobileAlertForm);
