import * as endPoints from "../constants/EndPoints";
import * as types from "../constants/ActionTypes";
import { PRESSURE_DISPLAY_MODE } from "../constants/Misc";
import { fetchMiddleware } from "../actions/MiddlewareActions";

export const parseTransient = (data, pressureCoefficient, yAxisCoefficient) => {
    let transientData = null;
    const recordData = data.split('-');

    const dateAndTime = recordData[0].split(' ');
    const values = recordData[3].split(',');

    const dateAsDayMonthYear = dateAndTime[0].split(':');
    const timeAsHourMinutsSeconds = dateAndTime[1].split(':');

    const time = new Date(Date.UTC(
      dateAsDayMonthYear[2],          // Year
      (dateAsDayMonthYear[1] - 1),    // Month
      dateAsDayMonthYear[0],          // Day
      timeAsHourMinutsSeconds[0],     // Hour
      timeAsHourMinutsSeconds[1],     // Minute
      timeAsHourMinutsSeconds[2])     // Seconds
    );

    const transientValues = values.map((val) => parseInt(val) * pressureCoefficient + yAxisCoefficient);

    transientData = {
        x: time.getTime(),
        y: transientValues,
    };

    return (transientData);
};

const getSelectedProjectFromState = (state) => {
  const selectedProjectId = state.leaksList.selectedProject;
  const projectsList = state.leaksList.projectsList.items;
  const projectsIndexMap = state.leaksList.projectsList.projectIndexMap;
  const selectedProjectObj = projectsList[[projectsIndexMap[selectedProjectId]]];
  return (selectedProjectObj);
}

const parsePressureData = (data) => {
    let cRc = null;

    const recordData = data.split('-');

    const dateAndTime = recordData[0].split(' ');
    const interval = parseInt(recordData[1]);
    const count = parseInt(recordData[2]);
    const values = recordData[3].split(',');

    const dateAsDayMonthYear = dateAndTime[0].split(':');
    const timeAsHourMinutsSeconds = dateAndTime[1].split(':');

    const startTime = new Date(Date.UTC(
      dateAsDayMonthYear[2],          // Year
      (dateAsDayMonthYear[1] - 1),    // Month
      dateAsDayMonthYear[0],          // Day
      timeAsHourMinutsSeconds[0],     // Hour
      timeAsHourMinutsSeconds[1],     // Minute
      timeAsHourMinutsSeconds[2])     // Seconds
    );

    cRc = {
        startTime,
        interval,
        count,
        values
    };

    return (cRc);
};

export const fetchPressureTransient = (sensor, deviceId, DaysBack) => {
  return (dispatch, getState) => {
    const state = getState();
    const daysStep = (state.leaksList.pressure.mode == PRESSURE_DISPLAY_MODE.DAY) ? 1 : 7;
    const path = endPoints.SENSOR_PRS_ENDPOINT + `/prsTransient/${sensor.id}/${DaysBack + daysStep}/${DaysBack}`;

    dispatch(requestSensorTran(deviceId));

    return fetchMiddleware(path, {}, getState).then((json) => {
      let transientData = [];

      if (!json.status) {
        // Error
      } else {
        if (json.data == null || json.data.length == 0) {

        } else {
          // get Project attr:
          // const selectedProjectObj = getSelectedProjectFromState(state);

          const factorCoeff = sensor.pressure_calib_coeff || 1;
          const yAxisCoeff = sensor.pressure_calib_coeff_y_axis || 0;

          const allData = json.data;

          allData.forEach((rawData) => {
            const records = rawData.Data.split('~');
            records.forEach((record) => {
              // const tranData = parseTransient(record, selectedProjectObj.PressureValueCoefficient || 1);
              const tranData = parseTransient(record, factorCoeff, yAxisCoeff);
              if (tranData != null) {
                transientData.push(tranData);
              }
            });
          });
          transientData = transientData.sort((a, b) => a.x - b.x);
        }
      }
      dispatch(receiveSensorTran(deviceId, transientData));
    });
  };
};

const requestSensorTran = (deviceId) => {
  return {
    type: types.REQUEST_SENSOR_TRAN,
    deviceId,
  };
};

const receiveSensorTran = (deviceId, data) => {
  return {
    type: types.RECEIVE_SENSOR_TRAN,
    deviceId,
    data,
  };
};

export const fetchPressure = (sensor, deviceId, DaysBack) => {
  return (dispatch, getState) => {
    const state = getState();
    const daysStep = (state.leaksList.pressure.mode == PRESSURE_DISPLAY_MODE.DAY) ? 1 : 7;
    const path = endPoints.SENSOR_PRS_ENDPOINT + `/prsTrend/${sensor.id}/${DaysBack}/${DaysBack + daysStep}`;

    dispatch(requestSensorPrs(deviceId, DaysBack));

    return fetchMiddleware(path, {}, getState).then((json) => {
      let readyData = {};
      let avgData = {};

      if (!json.status) {
        // Error
      } else {
        if (json.data == null || json.data.length == 0) {

        } else {
          // get Project attr:
          const selectedProjectObj = getSelectedProjectFromState(state);

          const allData = json.data;

          let readyDataX = [];
          let readyDataY = [];
          let tempDataBuilder = [];
          let tempAvgDataBuilder = [];

          allData.forEach((rawData) => {
            const records = rawData.Data.split('~');
            records.forEach((record) => {
              const pressureData = parsePressureData(record);
              if (pressureData != null) {
                const { values, startTime, interval } = pressureData;
                const avgIntervalMillis = 1000 * 60 * 15; //15 minuts
                let referenceTime = startTime.getTime();
                let mutableValue = 0;
                let mutableCount = 0;

                values.forEach((val, index) => {
                  const factorCoeff = sensor.pressure_calib_coeff || 1;
                  const yAxisCoeff = sensor.pressure_calib_coeff_y_axis || 0;
                  // const value = parseInt(val) * (selectedProjectObj.PressureValueCoefficient || 1);
                  const value = parseInt(val) * factorCoeff + yAxisCoeff;
                  const time = startTime.getTime() + (interval * index) * 1000;

                  if (referenceTime + avgIntervalMillis > time) {
                    // add the values
                    mutableValue += value;
                    mutableCount += 1;
                  } else {
                    const xVal = (time + referenceTime) / 2;
                    const yVal = mutableValue / mutableCount;

                    tempAvgDataBuilder.push({
                      x: xVal,
                      y: yVal
                    });

                    referenceTime = time;
                    mutableCount = 0;
                    mutableValue = 0;
                  }

                  tempDataBuilder.push({
                    x: time,
                    y: value,
                  });
                });
              }
            });
          });

          tempAvgDataBuilder = tempAvgDataBuilder.sort((a, b) => a.x - b.x);
          avgData = {
            x: tempAvgDataBuilder.map(item => item.x),
            y: tempAvgDataBuilder.map(item => item.y),
          }

          tempDataBuilder = tempDataBuilder.sort((a, b) => a.x - b.x);
          tempDataBuilder.forEach((item) => {
            readyDataX.push(item.x);
            readyDataY.push(item.y);
          });

          readyData = {
            x: readyDataX,
            y: readyDataY
          };
        }
      }
      dispatch(receiveSensorPrs(deviceId, readyData, avgData));
    });
  };
};

const requestSensorPrs = (deviceId, daysBack) => {
  return {
    type: types.REQUEST_SENSOR_PRS,
    deviceId,
    daysBack,
  };
};

const receiveSensorPrs = (deviceId, data, avgData) => {
  return {
    type: types.RECEIVE_SENSOR_PRS,
    deviceId,
    data,
    avgData,
  };
};

export const changeDisplayMode = (sensor, deviceId, mode) => {
  const action = {
    type: types.CHANGE_PRESSURE_DISPLAY_MODE,
    mode,
  };
  return (dispatch) => {
    dispatch(action);
    dispatch(fetchPressure(sensor, deviceId, 0));
    dispatch(fetchPressureTransient(sensor, deviceId, 0));
  };
};
