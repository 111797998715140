import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import Plot from 'react-plotly.js';
import ReactSelect from 'react-select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMiddleware } from '../../actions/MiddlewareActions';
import { END_POINT } from '../../constants/EndPoints';
import { Oval } from 'react-loader-spinner';

import './DashboardPage.scss';
import { Button } from '@mui/material';
const nowDate = new Date();
const currentMonth = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);

const DEVICE_TYPE_COLORS = {
  OTHER: 'LightGray',
  G4_UNDER_RADIO: 'MediumSeaGreen',
  G4_OVER_RADIO: 'DodgerBlue',
  G5_UNDER_RADIO: 'Orange',
  G5_OVER_RADIO: 'SlateBlue',
};

const deviceTypeColorByCodeMap = {
  1: DEVICE_TYPE_COLORS.OTHER, // Over Acc GPS
  2: DEVICE_TYPE_COLORS.OTHER, // Over Hydro GPS
  3: DEVICE_TYPE_COLORS.G4_UNDER_RADIO, // Under Acc Radio
  4: DEVICE_TYPE_COLORS.G4_UNDER_RADIO, // Under Hydro Radio
  5: DEVICE_TYPE_COLORS.G4_OVER_RADIO, // Over Hydro Radio
  6: DEVICE_TYPE_COLORS.G4_OVER_RADIO, // Over Acc Radio
  7: DEVICE_TYPE_COLORS.OTHER, // Under Acc GPS
  8: DEVICE_TYPE_COLORS.OTHER, // Under Hydro GPS
  11: DEVICE_TYPE_COLORS.OTHER, // Acc GPS Pressure
  21: DEVICE_TYPE_COLORS.OTHER, // Hydro GPS Pressure
  31: DEVICE_TYPE_COLORS.OTHER, // Acc Radio Pressure
  101: DEVICE_TYPE_COLORS.G5_OVER_RADIO, // G5 Over Acc Radio
  102: DEVICE_TYPE_COLORS.G5_UNDER_RADIO, // G5 Under Acc Radio
  103: DEVICE_TYPE_COLORS.G5_OVER_RADIO, // G5 Over Hydro Radio
  104: DEVICE_TYPE_COLORS.G5_UNDER_RADIO, // G5 Under Hydro Radio
};

const TableCell = ({ rowIndex, keyName, data }) => (
  <Cell>{data[rowIndex][keyName]}</Cell>
);

const GraphCell = ({ rowIndex, keyName, data }) => (
  <Cell>
    <Plot
      config={{
        modeBarButtonsToRemove: [
          'hoverClosestCartesian',
          'hoverCompareCartesian',
          'lasso2d',
          'select2d',
          'autoScale2d',
          'zoom2d',
          'zoomIn2d',
          'zoomOut2d',
          'pan2d',
          'resetScale2d',
          'toImage',
          'sendDataToCloud',
        ],
      }}
      layout={{
        height: 70,
        width: 325,
        margin: { l: 0, r: 0, b: 7, t: 0, pad: 0 },
        barmode: 'stack',
        xaxis: {
          type: 'category',
          categoryarray: data[rowIndex][keyName][0].x.toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
          }),
          categoryorder: 'array',
          autorange: 'reversed',
        },
        showlegend: false,
      }}
      data={data[rowIndex][keyName]}
    />
  </Cell>
);

const dateToInputValue = (date, type) => {
  const month = date.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : month;
  if (type === 'month') {
    return `${date.getFullYear()}-${monthStr}`;
  } else if (type === 'date') {
    const day = date.getDate();
    const dayStr = day < 10 ? `0${day}` : day;
    return `${date.getFullYear()}-${monthStr}-${dayStr}`;
  }
};

function DashboardPage({}, context) {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.leaksList.customers.items);
  const customersOptions = React.useMemo(
    () =>
      customers.map((customer) => ({
        value: customer.ID,
        label: customer.Name,
      })),
    [customers]
  );

  const [columnsWidth, setColumnsWidth] = React.useState({
    customer: 100,
    project: 150,
    totalSensors: 100,
    precent: 100,
    graphGeneral: 330,
  });
  const [pageSize, setPageSize] = React.useState({ w: 1000, h: 800 });
  const [dateSelectionMode, setDateSelectionMode] = React.useState('month');
  const [fromDate, setFromDate] = React.useState(currentMonth);
  const [toDate, setToDate] = React.useState(nowDate);
  const [mainData, setMainData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [filterInput, setFilterInput] = React.useState('');
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [customerFilter, setCustomerFilter] = React.useState([]);

  const setPageUISize = (window, event) => {
    setPageSize({ w: window.innerWidth, h: window.innerHeight });
  };

  React.useEffect(() => {
    // debbug:
    // const data = Object.keys(mockData2).map((projectId) => {
    //   const projectData = mockData2[projectId];
    // });
    // console.log({data});
    const threeMonthAgo = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth() - 3,
      1
    );

    // automatic first load:
    generateReport(threeMonthAgo, nowDate);

    window.addEventListener('resize', setPageUISize);
    return () => window.removeEventListener('resize', setPageUISize);
  }, []);

  React.useEffect(() => {
    let filteredData = mainData;
    if (customerFilter.length > 0 && mainData.length > 0) {
      console.log('customerFilter', customerFilter);

      filteredData = mainData.filter((row) =>
        customerFilter.some((customer) => customer.value === row.customerId)
      );
    }

    if (filterInput.length > 0) {
      filteredData = filteredData.filter((row) => {
        return (
          row.project.toLowerCase().includes(filterInput.toLowerCase()) ||
          row.customer.toLowerCase().includes(filterInput.toLowerCase())
        );
      });
    }

    setTableData(filteredData);
  }, [mainData, customerFilter, filterInput]);

  const generateReport = (valFromDate, valToDate) => {
    dispatch((dispatch, getState) => {
      const state = getState();
      const projectId = state.leaksList.selectedProject;

      setSelectedProject(null);
      setIsLoading(true);

      const from = valFromDate.toISOString().split('T')[0];
      const to = valToDate.toISOString().split('T')[0];
      fetchMiddleware(
        `${END_POINT}/dashboard/sensors/${projectId}?from=${from}&to=${to}`,
        {},
        getState
      )
        .then((json) => {
          setIsLoading(false);
          if (!json.status) {
            console.error(json);
          } else {
            const tableData = [];
            Object.entries(json.data).forEach(
              ([projectId, daysData], index) => {
                const days = Object.keys(daysData);
                const projectName = daysData[days[0]][0].project_name;
                const customerName = daysData[days[0]][0].customer_name;
                const customerId = daysData[days[0]][0].customer_id;
                const totalSensors = daysData[days[0]].reduce(
                  (acc, curr) => acc + curr.count,
                  0
                );
                const totalActive = daysData[days[0]].reduce(
                  (acc, curr) => acc + curr.active,
                  0
                );

                const tableRow = {
                  project: projectName,
                  customer: customerName,
                  customerId,
                  totalSensors,
                  totalActive,
                  precent: `${Math.round((totalActive / totalSensors) * 100)}%`,
                  graphDetaild: [
                    {
                      type: 'scatter',
                      name: 'Total',
                      x: [],
                      y: [0],
                      text: [`0 Total`],
                      line: {
                        color: 'blue',
                      },
                      fill: 'tozeroy',
                      mode: 'lines',
                    },
                    {
                      type: 'scatter',
                      name: 'Total Inactive',
                      x: [],
                      y: [0],
                      text: [`0 Total Inactive`],
                      line: {
                        color: 'darkred',
                      },
                      fill: 'tozeroy',
                      mode: 'lines',
                    },
                  ],
                  graphGeneral: [
                    {
                      type: 'scatter',
                      name: 'inactive',
                      x: [],
                      y: [],
                      text: [],
                      line: {
                        color: '#d62728',
                      },
                      fill: 'tozeroy',
                      mode: 'lines+markers',
                    },
                    {
                      type: 'scatter',
                      name: 'Active',
                      x: [],
                      y: [],
                      text: [],
                      line: {
                        color: '#1f77b4',
                      },
                      fill: 'tozeroy',
                      mode: 'lines+markers',
                    },
                    // {
                    //   type: 'scatter',
                    //   name: 'Inactive Trend',
                    //   x: [],
                    //   y: [],
                    // }
                  ],
                };

                Object.entries(daysData).forEach(([day, dayData]) => {
                  const graphDate = new Date(parseInt(day)).toLocaleString(
                    'en-US',
                    {
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }
                  );
                  // const graphCount = dayData.reduce((acc, curr) => acc + curr.count, 0);
                  const activeCount = dayData.reduce(
                    (acc, curr) => acc + curr.active,
                    0
                  );
                  const inactiveCount = dayData.reduce(
                    (acc, curr) => acc + curr.inactive,
                    0
                  );

                  // insert inactive trace data:
                  tableRow.graphGeneral[0].x.push(graphDate);
                  tableRow.graphGeneral[0].y.push(inactiveCount);
                  tableRow.graphGeneral[0].text.push(inactiveCount);

                  // insert inactive trend trace data:
                  // tableRow.graphGeneral[2].x.push(graphDate);
                  // tableRow.graphGeneral[2].y.push(inactiveCount);

                  // insert active trace data:
                  tableRow.graphGeneral[1].x.push(graphDate);
                  tableRow.graphGeneral[1].y.push(activeCount + inactiveCount);
                  tableRow.graphGeneral[1].text.push(activeCount);

                  dayData.forEach((dataByType) => {
                    const totalTraceName = `Total ${dataByType.device_type_name}`;
                    const inactiveTraceName = `Inactive ${dataByType.device_type_name}`;
                    const totalTraceIndex = tableRow.graphDetaild.findIndex(
                      (trace) => trace.name === totalTraceName
                    );
                    const inactiveTraceIndex = tableRow.graphDetaild.findIndex(
                      (trace) => trace.name === inactiveTraceName
                    );
                    if (totalTraceIndex === -1) {
                      tableRow.graphDetaild.push({
                        type: 'bar',
                        name: totalTraceName,
                        // visible: 'legendonly',
                        x: [graphDate],
                        y: [dataByType.count],
                        text: [
                          `${dataByType.count} ${dataByType.device_type_name}`,
                        ],
                        line: {
                          color:
                            deviceTypeColorByCodeMap[
                              dataByType.device_type_code
                            ],
                        },
                      });
                    } else {
                      tableRow.graphDetaild[totalTraceIndex].x.push(graphDate);
                      tableRow.graphDetaild[totalTraceIndex].y.push(
                        dataByType.count
                      );
                      tableRow.graphDetaild[totalTraceIndex].text.push(
                        `${dataByType.count} ${dataByType.device_type_name}`
                      );
                    }

                    if (inactiveTraceIndex === -1) {
                      tableRow.graphDetaild.push({
                        type: 'bar',
                        // showlegend: false,
                        name: inactiveTraceName,
                        // visible: 'legendonly',
                        x: [graphDate],
                        y: [dataByType.inactive],
                        text: [dataByType.inactive],
                        line: {
                          color: '#d62728',
                        },
                      });
                    } else {
                      tableRow.graphDetaild[inactiveTraceIndex].x.push(
                        graphDate
                      );
                      tableRow.graphDetaild[inactiveTraceIndex].y.push(
                        dataByType.inactive
                      );
                      tableRow.graphDetaild[inactiveTraceIndex].text.push(
                        dataByType.inactive
                      );
                    }

                    // add the total active trace:
                    if (tableRow.graphDetaild[0].x.indexOf(graphDate) === -1) {
                      tableRow.graphDetaild[0].x.push(graphDate);
                      tableRow.graphDetaild[0].y.push(dataByType.count);
                      tableRow.graphDetaild[0].text.push(
                        `Total ${dataByType.count}`
                      );
                    } else {
                      const index =
                        tableRow.graphDetaild[0].x.indexOf(graphDate);
                      tableRow.graphDetaild[0].y[index] += dataByType.count;
                      tableRow.graphDetaild[0].text[
                        index
                      ] = `Total ${tableRow.graphDetaild[0].y[index]}`;
                    }

                    // add the total inactive trace:
                    if (tableRow.graphDetaild[1].x.indexOf(graphDate) === -1) {
                      tableRow.graphDetaild[1].x.push(graphDate);
                      tableRow.graphDetaild[1].y.push(dataByType.inactive);
                      tableRow.graphDetaild[1].text.push(
                        `Total Inactive ${dataByType.inactive}`
                      );
                    } else {
                      const index =
                        tableRow.graphDetaild[1].x.indexOf(graphDate);
                      tableRow.graphDetaild[1].y[index] += dataByType.inactive;
                      tableRow.graphDetaild[1].text[
                        index
                      ] = `Total Inactive ${tableRow.graphDetaild[1].y[index]}`;
                    }
                  });

                  // console.log({day, dayData});
                });

                tableData.push(tableRow);
              }
            );

            setMainData(tableData);
            setTableData(tableData);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    });
  };

  const handleProjectClick = (event, rowIndex) => {
    setSelectedProject(tableData[rowIndex]);
    // console.log(tableData[rowIndex]);
  };

  const handleResize = (newColumnWidth, columnKey) => {
    // console.log({newColumnWidth, columnKey});
    setColumnsWidth((prev) => ({ ...prev, [columnKey]: newColumnWidth }));
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'month') {
      const [year, month] = value.split('-');
      const fromDateValue = new Date(parseInt(year), parseInt(month) - 1, 1);
      const toDateValue = new Date(parseInt(year), parseInt(month), 0);
      setFromDate(fromDateValue);
      setToDate(toDateValue);
    } else if (name === 'from') {
      setFromDate(new Date(value));
    } else if (name === 'to') {
      setToDate(new Date(value));
    }
  };

  return (
    <div className='dashboard-page'>
      <header>
        <h1>{context.t('dashboard')}</h1>
        <Button
          variant='contained'
          onClick={() => browserHistory.push('/alerts')}
        >
          {context.t('back_to_qalertsq')}
        </Button>
      </header>

      <main>
        <div className='dashboard-filters'>
          <Button
            variant='contained'
            onClick={() => generateReport(fromDate, toDate)}
          >
            {context.t('generate_report')}
          </Button>

          <div style={{ display: 'flex', columnGap: '10px' }}>
            <ToggleButtonGroup
              exclusive
              orientation='vertical'
              value={dateSelectionMode}
              onChange={(_event, value) => setDateSelectionMode(value)}
              aria-label={context.t('text_alignment')}
            >
              <ToggleButton value='month'>{context.t('month')}</ToggleButton>
              <ToggleButton value='from-to'>
                {context.t('from_to')}
              </ToggleButton>
            </ToggleButtonGroup>
            {dateSelectionMode === 'month' && (
              <label>
                {context.t('month')}
                <input
                  type='month'
                  name='month'
                  value={dateToInputValue(fromDate, 'month')}
                  onChange={handleDateChange}
                />
              </label>
            )}
            {dateSelectionMode === 'from-to' && (
              <div className='from-to-date'>
                <label>
                  {context.t('from')}
                  <input
                    type='date'
                    name='from'
                    value={dateToInputValue(fromDate, 'date')}
                    onChange={handleDateChange}
                  />
                </label>
                <label>
                  {context.t('to')}
                  <input
                    type='date'
                    name='to'
                    value={dateToInputValue(toDate, 'date')}
                    onChange={handleDateChange}
                  />
                </label>
              </div>
            )}
          </div>

          <ReactSelect
            isClearable
            isMulti
            closeMenuOnSelect={false}
            placeholder={context.t('filter_by_customer')}
            options={customersOptions}
            // value={null}
            value={customerFilter}
            onChange={setCustomerFilter}
          />

          {mainData.length > 0 && (
            <input
              type='text'
              placeholder={context.t('search')}
              value={filterInput}
              onChange={(event) => setFilterInput(event.target.value)}
            />
          )}

          {isLoading && (
            <Oval width={25} height={25} wrapperStyle={{ display: 'inline' }} />
          )}

          {selectedProject && (
            <Button
              variant='contained'
              onClick={() => setSelectedProject(null)}
            >
              {context.t('clear_selection')}
            </Button>
          )}
        </div>

        <div className='dashboard-container'>
          <aside>
            <Table
              maxHeight={770}
              height={770}
              width={800}
              rowsCount={tableData.length}
              rowHeight={75}
              headerHeight={30}
              onRowClick={handleProjectClick}
              onColumnResizeEndCallback={handleResize}
              rowClassNameGetter={(rowIndex) =>
                selectedProject &&
                selectedProject.project === tableData[rowIndex].project
                  ? 'active-row'
                  : ''
              }
              isColumnResizing={false}
              touchScrollEnabled={true}
            >
              <Column
                isResizable
                isReorderable
                align='center'
                columnKey='customer'
                width={columnsWidth.customer}
                flexGrow={1}
                header={<Cell>{context.t('customer')}</Cell>}
                cell={<TableCell data={tableData} keyName='customer' />}
              />
              <Column
                isResizable
                isReorderable
                align='center'
                columnKey='project'
                width={columnsWidth.project}
                flexGrow={2}
                header={<Cell>{context.t('project')}</Cell>}
                cell={<TableCell data={tableData} keyName='project' />}
              />
              <Column
                isResizable
                isReorderable
                align='center'
                columnKey='totalSensors'
                width={columnsWidth.totalSensors}
                flexGrow={1}
                header={<Cell>{context.t('count')}</Cell>}
                cell={<TableCell data={tableData} keyName='totalSensors' />}
              />
              <Column
                isResizable
                isReorderable
                align='center'
                columnKey='precent'
                width={columnsWidth.precent}
                flexGrow={1}
                header={<Cell>{context.t('active')}</Cell>}
                cell={<TableCell data={tableData} keyName='precent' />}
              />
              <Column
                isResizable
                isReorderable
                align='center'
                columnKey='Graph'
                width={columnsWidth.graphGeneral}
                flexGrow={4}
                header={<Cell>{context.t('graph')}</Cell>}
                cell={<GraphCell data={tableData} keyName='graphGeneral' />}
              />
            </Table>
          </aside>
          <aside>
            {selectedProject && (
              <Plot
                layout={{
                  height: 770,
                  width: 1000,
                  xaxis: {
                    autorange: 'reversed',
                  },
                }}
                data={selectedProject.graphDetaild}
              />
            )}
          </aside>
        </div>
      </main>
    </div>
  );
}

DashboardPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DashboardPage;
