/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React from 'react';

import DebugWaveData from './DebugWaveData';

const createTableRow = (label, value) => (
  <tr>
    <th>{label}</th>
    <td>{value}</td>
  </tr>
)

function OutputInfo(props, context) {

  function renderCorrDebug() {
    return (
      <div style={{
        display: 'flex',
        overflow: 'auto',
        height: '400px',
        width: '94%',
      }}>
        <table>
          <tbody>
            {props.selectedSample && props.selectedSample.Version && (
              <tr>
                <th>{context.t('version')}</th>
                <td>
                  <code>{props.selectedSample.Version}</code>
                </td>
              </tr>
            )}

            {props.dt && (
              <tr>
                <th>{context.t('average')} &#8710;t</th>
                <td>
                  <code>{props.dt.join(' , ')}</code>
                </td>
              </tr>
            )}

            {props.max_probability && props.max_probability_ndx && (
              <tr>
                <th>{context.t('max_probability')}</th>
                <td>
                  <code>
                    {props.max_probability_ndx
                      .map(
                        (ndxVal, index) =>
                          `[${ndxVal}: ${props.max_probability[index]}]`
                      )
                      .join(' , ')}
                  </code>
                </td>
              </tr>
            )}

            {false && props.pipe_len_m && (
              <tr>
                <th>{context.t('pipe_length')}</th>
                <td>
                  <code>{props.pipe_len_m}</code>
                </td>
              </tr>
            )}

            {props.ave_locks_in_m && (
              <tr>
                <th>ave_locs_in_m</th>
                <td>
                  <code>{props.ave_locks_in_m.join(' , ')}</code>
                </td>
              </tr>
            )}
            {props.radio_dt != null && (
              <tr>
                <th>radio_dt</th>
                <td>
                  <code>{props.radio_dt}</code>
                </td>
              </tr>
            )}
            {props.radio_quality && (
              <tr>
                <th>radio_quality</th>
                <td>
                  <code>{props.radio_quality.toFixed(1)}</code>
                </td>
              </tr>
            )}
            {false && props.instance_distance && (
              <tr>
                <th>instance_distance</th>
                <td>
                  <code>{props.instance_distance}</code>
                </td>
              </tr>
            )}
            {props.instance_distance_av && (
              <tr>
                <th>{context.t('instance_distances')}</th>
                <td>
                  <code>{props.instance_distance_av.join(' , ')}</code>
                </td>
              </tr>
            )}
            {props.instance_dt_av && (
              <tr>
                <th>{context.t('instance_dts')}</th>
                <td>
                  <code>{props.instance_dt_av.join(' , ')}</code>
                </td>
              </tr>
            )}
            {props.fmin && (
              <tr>
                <th>{context.t('fmin')}</th>
                <td>
                  <code>{props.fmin}</code>
                </td>
              </tr>
            )}
            <tr>
              <th>f1</th>
              <td>{props.selectedSample.f1}</td>
            </tr>
            <tr>
              <th>f2</th>
              <td>{props.selectedSample.f2}</td>
            </tr>
            <tr>
              <th>f_min</th>
              <td>{props.selectedSample.f_min}</td>
            </tr>
            <tr>
              <th>{context.t('wt')}</th>
              <td>{props.selectedSample.wt}</td>
            </tr>
            <tr>
              <th>{context.t('wover')}</th>
              <td>{props.selectedSample.wover}</td>
            </tr>
            <tr>
              <th>{context.t('fs')}</th>
              <td>{props.selectedSample.fs}</td>
            </tr>
            <tr>
              <th>{context.t('velocity')}</th>
              <td>{props.selectedSample.velocity}</td>
            </tr>
            <tr>
              <th>intensity_factor</th>
              <td>{props.selectedSample.intensity_factor}</td>
            </tr>
            <tr>
              <th>mean_bias</th>
              <td>{props.selectedSample.mean_bias}</td>
            </tr>
            <tr></tr>
          </tbody>
        </table>

        {props.tensor &&
        (props.tensor.bias_results ||
        props.tensor.burst_final ||
        props.tensor.included_indexes15 ||
        props.tensor.included_indexes30) &&
          <table>
            <tbody>
              {createTableRow('bias_results', JSON.stringify(props.tensor.bias_results, null, '\t'))}
              {createTableRow('burst_final', JSON.stringify(props.tensor.burst_final, null, '\t'))}
              {createTableRow('included_indexes15', JSON.stringify(props.tensor.included_indexes15, null, '\t'))}
              {createTableRow('included_indexes30', JSON.stringify(props.tensor.included_indexes30, null, '\t'))}
            </tbody>
          </table>
        }
      </div>
    );
  }

  function renderTensorDebug() {
    return (
      <table>
        <thead>
          <tr>
            <th></th>
            <th>{context.t('value')}</th>
            <th>{context.t('result')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.probabilityData.final).map((param, index) => (
            <tr key={index}>
              <th>{param}</th>
              <td>{props.probabilityData.final[param].value}</td>
              <td>{props.probabilityData.final[param].result}</td>
            </tr>
          ))}
          <hr />
          {Object.keys(props.probabilityData.res).map((param, index) => (
            <tr key={index}>
              <th>{param}</th>
              <td>{props.probabilityData.res[param].value}</td>
              <td>{props.probabilityData.res[param].result}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div className='debug'>
      {renderCorrDebug()}

      {props.probabilityData && renderTensorDebug()}

      {false && <DebugWaveData audioData={props.audioData} />}
    </div>
  );
}

OutputInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default OutputInfo;
