import { connect } from 'react-redux';
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';

import * as actionsDmas from '../../actions/DmasActions';
import DmaHistoryModal from "../../components/Modals/DmaHistoryModal";

const mapStateToProps = (state, ownProps) => {
  const {
   isFetchDmaData,
   selectedDmaId,
   selectedDmaData,
   selectedDmaCustomer,
   isDataVisible
  } = state.leaksList.dmas;

  const timeZone = state.local.timeZone;

  let valuesX = [];
  let valuesY = [];

  if(selectedDmaData != null) {
    selectedDmaData.forEach((dmaData) => {
      valuesX.push(UIDateFormater(dmaData.relevantToDate, DateType.DATE, timeZone));
      valuesY.push(dmaData.sensitivity);
    });
  }

  const plotData = [{
    x: valuesX,
    y: valuesY,
    mode: 'lines+markers',
    type: 'scatter'
}];

  return Object.assign({}, ownProps, {
     isFetchDmaData,
     selectedDmaCustomer,
     selectedDmaId,
     isDataVisible,
     plotData
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(actionsDmas.setDisplayDmaData(false));
    }
  };
};

const CDmaHistoryModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DmaHistoryModal);

export default CDmaHistoryModal;
