/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';
import NoiseAlertDetails from './NoiseAlertDetails';

import NoiseAlertHistory from './NoiseAlertHistory';
import SensorSamplesTab from '../SensorsTabs/SensorSamplesTab/SensorSamplesTab';
import Paper from '@mui/material/Paper';
import '../AlertsTabs/AlertsTabs.scss';
const classNames = require('classnames');

export default function NoiseAlertsTabs(props, context) {
  React.useEffect(() => {
    let isShown;
    if (props.selectedItem.ID) {
      isShown = true;
    } else {
      isShown = false;
    }
    props.toggleDetailsVisibility(isShown);
  }, [props.selectedItem]);

  function closePanel() {
    props.toggleDetailsVisibility(false);
    props.selectSample({});
    props.clearSelectedFeature();
  }

  const {
    distanceFactor,
    timeZone,
    alertItem,
    selectedProject,
    selectedFeature,
    selectedItem,
    uiInfo,
    showDetails,
    alertStateOptions,
    alertTypeOptions,
    projectName,
    navigateToFeature,
    onPageIndexChange,
    noiseAlertHistory,
    noiseAlertStatusOptions,
    distributionListsOptions,
  } = props;

  if (!selectedFeature) {
    return null;
  }

  const panelClasses = classNames('alert-details', { show: showDetails });
  const panelStyle = { height: 0.493 * uiInfo.pageHeight };

  return (
    <Paper className={panelClasses} style={panelStyle}>
      <TabsContainer
        title={context.t('alert_alertId', { alertId: selectedFeature })}
        handleClose={closePanel}
        tabs={[
          {
            label: context.t('samples'),
            style: {},
            component: (
              <SensorSamplesTab
                sensorsSamples={props.sensorNoiseSamples}
                uiInfo={uiInfo}
                timeZone={timeZone}
                onPageIndexChange={props.onPageIndexChange}
                selectSensorSample={props.selectSensorSample}
                selectedSensorSample={props.selectedSensorSample}
                totalNoiseSamplesCount={props.totalNoiseSamplesCount}
                noiseSamplesRequestedCount={props.noiseSamplesRequestedCount}
                numberOfRowsInNoiseSamples={props.sensorNoiseSamplesRowsCount}
                downloadFile={props.downloadFile}
              />
            ),
          },
          {
            label: context.t('details'),
            style: {},
            component: (
              <NoiseAlertDetails
                user={props.user}
                selectedFeature={selectedFeature}
                initialValues={{ ...selectedItem }}
                alertStateOptions={alertStateOptions}
                alertTypeOptions={alertTypeOptions}
                timeZone={timeZone}
                updateNoiseAlert={props.updateNoiseAlert}
                onEdit={props.onEdit}
                onCopy={props.onCopy}
                sendNotification={props.sendNotification}
                getMapCapture={props.getMapCapture}
                distributionListsOptions={distributionListsOptions}
              />
            ),
          },
          {
            label: context.t('history'),
            style: {},
            component: (
              <NoiseAlertHistory
                options={noiseAlertStatusOptions}
                timeZone={timeZone}
                history={noiseAlertHistory}
                context={context}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
}

NoiseAlertsTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};
