import React from 'react';
import { featuresTypes, zIndexs } from './Options';

import Feature from 'ol/Feature';
import { Circle, Polygon } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
const proj = require('ol/proj');

const classNames = require('classnames');
const noFillOpacity = 1;

// Generating new style when Interference 'selected' and when 'unselected'
const getFillColor = (featureType, options, isSelected, includeFill) => {
  let opacity = !includeFill ? noFillOpacity : 0.3;
  let fillColor;
  const interferenceOption = options.InterferenceTypes.find((opt) => opt.label === featureType);
  fillColor = (interferenceOption) ? `rgba(${interferenceOption.Color})` : `rgba(0, 0, 0, 0.3)`;

  switch (featureType) {
    case 'PRV':
    case 'Pump':
      fillColor = `rgba(255, 5, 255, ${opacity})`;
      break;
    case 'Electricity':
      fillColor = `rgba(255, 255, 0, ${opacity})`;
      break;
    case 'Irrigation':
      fillColor = `rgba(0, 255, 0, ${opacity})`;
      break;
    case 'Gas':
      fillColor = `rgba(66, 185, 255, ${opacity})`;
      break;
    case 'Planning':
      fillColor = `rgba(255, 119, 0, ${opacity})`;
      break;
    default:
      fillColor = `rgba(255, 5, 255, ${opacity})`;
      break;
  }

  if (isSelected) {
    fillColor = `rgba(0, 0, 0, 0.3)`;
  }


  return fillColor;
};
export const getStyle = (featureType, isSelected, props, includeFill) => {
  const { options } = props;

  let strokeWidth = isSelected ? 3 : 1.5;
  let strokeColor = isSelected ? '#ffc800' : '#a3a3a3';
  let fillColor = getFillColor(featureType, options, isSelected, includeFill);

  // If includeFill is false, set fillColor to null and adjust stroke properties
  if (!includeFill) {
    strokeColor = fillColor;
    strokeWidth = isSelected ? 3.5 : 3;
    fillColor = 'rgba(0, 0, 0, 0)'; // Transparent color
  }

  const style = new Style({
    fill: new Fill({
      color: fillColor
    }),
    stroke: new Stroke({
      color: strokeColor,
      width: strokeWidth
    }),
  });

  return style;
};
const source = new VectorSource({
  features: [],
});

export const layer = new VectorLayer({
  name: 'layerInterferences',
  // style: this.getStyle(null, false),
  source: source,
  zIndex: zIndexs.interference,
});

export const setSource = (nextIconFeatures, props) => {
  let iconFeatures = [];

  Object.keys(nextIconFeatures).forEach((key) => {
    const itrFeature = nextIconFeatures[key];
    let geometry;
    const polygonPoints = itrFeature.Boundary;
    var points = [];

    polygonPoints.forEach((itrPoint) => {
      const tranformedPoint = proj.transform([itrPoint[0], itrPoint[1]],
        'EPSG:4326',
        'EPSG:3857'
      );
      points.push(tranformedPoint);
    });


    if (itrFeature.GeometryType == "LINESTRING") {
      geometry = new Circle(points[0], points[1][0] - points[0][0]);
    }

    else if (itrFeature.GeometryType == "POLYGON") {
      geometry = new Polygon([points]);
    }

    const iconFeature = new Feature({
      geometry: geometry,
      type: featuresTypes.INTERFERENCE,
      id: itrFeature.id,
      interferenceType: itrFeature.Type,
      includeFill: itrFeature.is_fill
    });
    iconFeature.setStyle(getStyle(itrFeature.Type, false, props, itrFeature.is_fill));
    iconFeatures.push(iconFeature);
  });

  source.clear();
  source.addFeatures(iconFeatures);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();
  let infoTooltip = {
    type: featuresTypes.INTERFERENCE,
    id: attr.id,
    interferenceType: attr.interferenceType
  };
  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
  const tooltipClasses = classNames(
    'tooltip',
    'priority-' + itemInfo.priority
  );

  return (
    <span className={tooltipClasses}>
      <div className="tooltip-row">{context.t('area_of_interest_id', { id: itemInfo.id })}</div>
      <div className="tooltip-row">{context.t('type_param', { type: itemInfo.interferenceType })}</div>
    </span>
  );
};

