/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

// import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';

const SimpleColumn = () => null;
SimpleColumn.propTypes = {
  columnKey: PropTypes.string.isRequired,
  headerLabel: PropTypes.string.isRequired,
};

TableHeader.propTypes = {
  headerItems: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  disableSort: PropTypes.bool,
  hidden: PropTypes.bool
};
function TableHeader(props) {
  const { headerItems, order, orderBy, onRequestSort } = props;

  const classes = useStyles();
  function createSortHandler(property) {
    return (event) => {
      onRequestSort(property);
    };
  }

  return (
    <TableHead>
      <TableRow role="checkbox">
        {
          headerItems.map((headCell) => (
            <TableCell
              key={headCell.columnKey}
              align='center'
              sortDirection={orderBy === headCell.id ? order : false}
              classes={{root: classes.cell}}
            >
              { headCell.disableSort ? headCell.label :
              <TableSortLabel
                active={orderBy === headCell.columnKey}
                direction={orderBy === headCell.columnKey ? order : 'asc'}
                onClick={createSortHandler(headCell.columnKey)}
              >
                {headCell.label}
              </TableSortLabel>}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

SimpleTable.propTypes = {
  children: PropTypes.arrayOf(SimpleColumn),
  containerStyle: PropTypes.object,
  getCellStyleFunc: PropTypes.func,
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  reloadDevices: PropTypes.func,
  renderFunc: PropTypes.func,
  selectedKey: PropTypes.string,
  setSortItems: PropTypes.func,
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    display: 'block',
    backgroundColor: 'white',
    maxHeight: '83vh',
    maxWidth: '86vw',
    overflowY: 'scroll'
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fafafa',

    '& td': {
      padding: '5px'
    }
  },
  cell: {
    borderLeft: '1px solid #eee',
    padding: '16px 5px',
  },
  selectedRow: {
    backgroundColor: '#FFEFC5'
  }
});

function SimpleTable(props) {
  const { items, children, containerStyle, context } = props;
  const [tableHeader, setTableHeader] = React.useState([]);
  const [tableItems, setTableItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState();

  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('time');

  const classes = useStyles();

  React.useEffect(() => {
    const headerData = children.map((column) => ({
      columnKey: column.props.columnKey,
      label: column.props.headerLabel,
      renderFunc: column.props.renderFunc,
      getCellStyleFunc: column.props.getCellStyleFunc,
      disableSort: column.props.disableSort,
    }));

    let data = items.map((item) => {
      const dataItem = {};
      headerData.forEach((column) => {
        dataItem.columnKey = column.columnKey;
        dataItem[column.columnKey] = item[column.columnKey];
      });
      return dataItem;
    });

    // data = _getSortedItems(data, orderBy, order);

    setTableHeader(headerData);
    setTableItems(data);
  }, [items, children]);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   const requestedOrder = isAsc ? 'desc' : 'asc';
  //   // const sortedTableItems = tableItems.sort(getComparator(requestedOrder, property));
  //   const sortedTableItems = _getSortedItems(tableItems, property, requestedOrder);

  //   setOrder(requestedOrder);
  //   setOrderBy(property);
  //   if (props.setSortItems) {
  //     props.setSortItems(sortedTableItems);
  //   }
  // };

  // function _getSortedItems(items, sortBy, sortDir) {
  //   return items.sort((a, b) => {
  //     let item1, item2;
  //     if (sortDir === 'asc')
  //       { item1 = a; item2 = b; }
  //     else
  //       { item1 = b; item2 = a; }

  //     const value1 = item1[sortBy]; // === null ? Number.NEGATIVE_INFINITY : item1[property];
  //     const value2 = item2[sortBy]; // === null ? Number.NEGATIVE_INFINITY : item2[property];

  //     if (value1 > value2 || (value2 === null && value1 !== null)) return -1;
  //     if (value1 < value2 || (value1 === null && value2 !== null)) return 1;
  //     return 0;
  //   });
  // }

  function renderBodyCell(item, head, index) {
    let cellValue = item[head.columnKey];
    if (head.renderFunc) {
      cellValue = head.renderFunc(cellValue, item, index);
    }

    return cellValue;
  }

  function setCellStyle(item, head) {
    let style = null;
    if (head.getCellStyleFunc) {
      style = head.getCellStyleFunc(item);
    }

    return style;
  }

  const handleItemClick = (item, index) => {
    if (props.selectedKey) {
      setSelectedItem(item);

      if (props.onItemClick) {
        props.onItemClick(item, index)
      }
    }
  }

  const selectedItemIndex = selectedItem && tableItems.findIndex((item) => item[props.selectedKey] === selectedItem[props.selectedKey]) + 1;

  return (
    <Table
      style={containerStyle}
      classes={{ root: classes.table }}
      fixedHeader
      stickyHeader>

      <TableHeader
        headerItems={tableHeader}
        order={props.order}
        orderBy={props.orderBy}
        onRequestSort={props.setSortItems}
      />
      <TableBody>
        {tableItems.map((item, index) => (
          <TableRow
            hover
            key={index}
            classes={(selectedItem && selectedItem[props.selectedKey] === item[props.selectedKey]) ? { root: classes.selectedRow } : null}
            onClick={() => handleItemClick(item, index)}
          >
            {tableHeader.map((head) => (
              <TableCell
                key={`row-${index}-${head.columnKey}`}
                style={setCellStyle(item, head)}
                classes={{root: classes.cell}}
                role="checkbox"
                align='center'>
                  {renderBodyCell(item, head, index)}
                </TableCell>
            )
          )}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter classes={{root: classes.footer}}>
        <TableRow>
          <TableCell align='left'>
            { props.reloadDevices && <IconButton onClick={props.reloadDevices}> <RefreshIcon fontSize='small'/> </IconButton> }
          </TableCell>
          <TableCell colSpan={tableHeader.length - 1}>{selectedItem && `Selected: ${selectedItemIndex}.    `}{tableItems.length} {context.t('items')}</TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

export {
  SimpleTable as Table,
  SimpleColumn as Column
};



