import PropTypes from 'prop-types';
import React from 'react';

class ManageMenu extends React.Component {

  render() {
    const { addNew, onChange } = this.props;
    return (
      <div className="manage-menu">
        <button
          className="btn-success create-item"
          onClick={() => {
            addNew();
          }
          }
          type="button"
        >
          <i title={this.context.t('add')} />
        </button>

        <button
          className="btn-menu customers"
          onClick={() => {
            onChange('Customers');
          }
          }
        >
          <i title={this.context.t('customers')} />
        </button>

        <button
          className="btn-menu projects"
          onClick={() => {
            onChange('Projects');
          }
          }
        >
          <i title={this.context.t('projects')} />
        </button>

        <button
          className="btn-menu users"
          onClick={() => {
            onChange('Users');
          }
          }
        >
          <i title={this.context.t('users')} />
        </button>
      </div>
    );
  }
}

ManageMenu.contextTypes = {
  t: PropTypes.func.isRequired
}

export default ManageMenu;
