import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';

import TextCell from '../../../components/TextCell/TextCell';

import { DateType } from '../../../containers/UIhelper/UIDateFormater';


const _ = require('lodash');

export default class SensorBitsTab extends React.Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.rowClassNameGetter = this.rowClassNameGetter.bind(this);

    this.state = {
      currentPage: 1,
    };
  }

  onRowClick(event, rowIndex) {
    this.props.selectSensorBit(this.props.sensorsBits[rowIndex]);
  }

  rowClassNameGetter(rowIndex) {
    const { selectedSensorBit, sensorsBits } = this.props;
    return (
      selectedSensorBit != null
      && sensorsBits != null
      && sensorsBits[rowIndex] != null
      && selectedSensorBit.ID == sensorsBits[rowIndex].ID
    ) ? 'active-row' : '';
  }

  handlePageClick(pageNumber) {
    this.setState({
      currentPage: pageNumber
    })
    this.props.onPageIndexChange(pageNumber - 1, 'SensorBitsTab');
  }

  render() {
    const { sensorsBits, sensorsBitsCount, uiInfo, timeZone, downloadFile } = this.props;

    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight;

    const data = (sensorsBits == null || _.isEmpty(sensorsBits)) ? [] : sensorsBits;

    return (
      <div>
        <Table
          rowsCount={data.length}
          width={pWidth}
          onRowClick={this.onRowClick}
          rowClassNameGetter={this.rowClassNameGetter}
          maxHeight={pHeight - 117}
          headerHeight={46}
          rowHeight={30}>
          <Column
            columnKey="Time"
            header={<Cell>{this.context.t('date')}</Cell>}
            cell={<TextCell data={data} field="Time" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
            width={40}
            flexGrow={4}
          />
          <Column
            columnKey="IsLog"
            header={<Cell>{this.context.t('type')}</Cell>}
            cell={<TextCell data={data} field="IsLog" />}
            width={20}
            flexGrow={2}
          />
          <Column
            columnKey="IsOK"
            header={<Cell>{this.context.t('is_ok')}</Cell>}
            cell={<TextCell data={data} field="IsOK" />}
            width={20}
            flexGrow={2}
          />
          <Column
            columnKey="Battery"
            header={<Cell>{this.context.t('battery')}</Cell>}
            cell={(props) =>
              (<Cell {...props} >
                {
                     data[props.rowIndex].data
                  && data[props.rowIndex].data.BatteryVoltage
                  && data[props.rowIndex].data.BatteryVoltage.toFixed(2)
                }
              </Cell>)
            }
            width={20}
            flexGrow={3}
          />

          <Column
            columnKey="RadioFrequency"
            header={<Cell>{this.context.t('radio_frequency')}</Cell>}
            cell={<TextCell data={data} field="RadioFrequency" />}
            width={20}
            flexGrow={2} />

          <Column
            columnKey="CSQ"
            header={<Cell>{this.context.t('csq')}</Cell>}
            cell={(props) =>
              <Cell {...props} >
                {data[props.rowIndex].data && data[props.rowIndex].data.ModemAtennaSignalStrength}
              </Cell>
            }
            width={20}
            flexGrow={2}
          />
          <Column
            columnKey="Audio"
            header={<Cell>{this.context.t('download')}</Cell>}
            cell={(props) =>
              <Cell {...props} >
                <a
                  key='Audio'
                  className="btn-wav-download"
                  onClick={() => {
                    const item = data[props.rowIndex];
                    downloadFile(item.data.FileName, 'file');
                  }}
                  href='javascript:void(0);'>
                  { data[props.rowIndex].IsLog ? this.context.t('log') : this.context.t('audio')}
                </a>
              </Cell>
            }
            width={30}
            flexGrow={2}
          />
        </Table>

        {/*{ false &&*/}
        {/*  <div className="center">*/}
        {/*    <Pagination*/}
        {/*      prevPageText={this.context.t('prev')}*/}
        {/*      nextPageText={this.context.t('next')}*/}
        {/*      firstPageText={this.context.t('first')}*/}
        {/*      lastPageText={this.context.t('last')}*/}
        {/*      activePage={this.state.currentPage}*/}
        {/*      itemsCountPerPage={SENSOR_LOG_COUNT_FOR_PAGE}*/}
        {/*      totalItemsCount={sensorsBitsCount}*/}
        {/*      pageRangeDisplayed={5}*/}
        {/*      onChange={this.handlePageClick}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    );
  }
}

SensorBitsTab.contextTypes = {
  t: PropTypes.func.isRequired
};
