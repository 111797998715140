import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import WorkOrderForm from '../../components/WorkOrderModal/WorkOrderForm';
import UIDateFormater, { DateType } from "../UIhelper/UIDateFormater";

const mapStateToProps = (state, ownProps) => {
  const units = state.local.units;
  const distanceFactor = state.local.factor[units];
  const mainState = state.leaksList;
  const options = mainState.optionList.options;

  const contextType = ownProps.contextType || state.leaksList.pressure.context;

  let selectedAlertId;
  let selectedAlert;
  let selectedProject = {}; // Default to an empty object
  let Intensity;
  let selectedMobileAlertComment;

  if (contextType === 'noise') {
    selectedAlertId = state.noiseAlerts.selected.id;
    selectedAlert = state.noiseAlerts.selected;
    // selectedProject may not be applicable
  } else if (contextType === 'mobile') {
    const projectId = mainState.selectedProject;

    selectedProject = getSelectedProject(projectId, mainState.projectsList);
    // const projectName = selectedProject && selectedProject.ShortName ? selectedProject.ShortName : '';
    selectedAlertId = state.mobile.alerts.selectedAlert.alert_id;
    selectedAlert = mapMobileAlert(state.mobile.alerts.selectedAlert);
    Intensity = state.mobile.alerts.selectedAlert.intensity;

    // selectedProject may not be applicable
  }

  else {
    selectedAlertId = mainState.leaksByProject.selectedFeature;

    // Ensure that mainState.leaksByProject and mainState.selectedProject are defined
    const projectId = mainState.selectedProject;
    const projectState = mainState.leaksByProject ? mainState.leaksByProject[projectId] : null;
    selectedAlert = getSelectedAlert(selectedAlertId, projectState);

    selectedProject = getSelectedProject(projectId, mainState.projectsList);
  }

  // Handle cases where selectedProject may not have the ShortName property
  const projectName = selectedProject && selectedProject.ShortName ? selectedProject.ShortName : '';

  const selectedAlertType = getOption(selectedAlert, options, 'AlertType');
  const selectedAlertStatus = getOption(selectedAlert, options, 'AlertState');
  const selectedAlertComment = selectedAlert.Comment || '';
  const SensorID = selectedAlert.SensorID;
  // const Intensity = selectedAlert.intensity;
  const createDate = selectedAlert.CreateDate
    ? UIDateFormater(selectedAlert.CreateDate, DateType.DATE)
    : '';
  const InsertDate = selectedAlert.InsertDate
    ? UIDateFormater(selectedAlert.InsertDate, DateType.DATE)
    : '';
  const endDate = selectedAlert.EndDate
    ? UIDateFormater(selectedAlert.EndDate, DateType.DATE)
    : '';


  const initialValues = {
    ...selectedAlert,
    projectName,
    alertTypeName: selectedAlertType,
    alertStateName: selectedAlertStatus,
    serviceProviderOptions: options.ContractorID,
    sensorID: SensorID,
    comment: selectedAlertComment,
    CreateDate: createDate,
    InsertDate: InsertDate,
    EndDate: endDate,
    Intensity: Intensity
  };

  return {
    initialValues,
    units,
    distanceFactor,
    selectedAlertId,
    selectedAlert,
    contextType,
    ...ownProps,
  };

};


const mapMobileAlert = (alert) => {
  // console.log('alert mapMobileAlert', alert);

  return {
    Id: alert.alert_id,
    Probability: alert.probability,
    CreateDate: alert.create_date,
    EndDate: alert.end_date,
    AlertState: alert.alert_state,
    AlertType: alert.alert_type,
    Comment: alert.comment,
    Address: alert.address,
    Intensity: alert.intensity,
    Classification: alert.alert_classify,
    Longitude: alert.longitude,
    Latitude: alert.longitude,
    // Map other properties as needed
  };
};

const getSelectedProject = (id, projects) => {
  if (!projects || !projects.projectIndexMap) {
    return {};
  }
  const map = projects.projectIndexMap;
  const projectIndex = map[id];
  // Check if projectIndex and projects.items[projectIndex] are defined
  const project = projectIndex !== undefined && projects.items[projectIndex]
    ? projects.items[projectIndex]
    : {};
  return project;
};

const getSelectedAlert = (id, projectState) => {
  // Return an empty object if projectState or its properties are undefined
  if (!projectState || !projectState.items || !projectState.indexMap) {
    return {};
  }

  const map = projectState.indexMap;
  const alertIndex = map[id];

  // Check if alertIndex and projectState.items[alertIndex] are defined
  const alert = alertIndex !== undefined && projectState.items[alertIndex]
    ? projectState.items[alertIndex]
    : {};

  const detailsData =
    projectState.details &&
      projectState.details[id] &&
      projectState.details[id].data
      ? projectState.details[id].data
      : {};

  return { ...alert, ...detailsData };
};

const getOption = (item, options, optionName) => {
  let opt = '';
  const optionsList = options[optionName];
  for (let i = 0; i < optionsList.length; i++) {
    if (item[optionName] == optionsList[i].value) {
      opt = optionsList[i].label;
      break;
    }
  }
  return (opt);
}

const CWorkOrderForm = connect(
  mapStateToProps,
  null
)(WorkOrderForm);

export default CWorkOrderForm
