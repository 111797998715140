import * as types from '../constants/ActionTypes';

const initialState = {
  isFetching: false
}

const reports = (state = initialState, action) => {
  switch (action.type) {
    // case types.EDIT_USER:
    //   return Object.assign({}, state, {
    //     selectedUser: action.user
    //   });
    default:
      return state
  }
}

export default reports;
