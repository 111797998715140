import { connect } from 'react-redux';
import { FILTERS_SENSORS, FILTERS_SOPS } from '../../constants/SettingsKeys';

import {
    selectSensor,
    setSensorsDefFilters,
    setSensorsFilters,
    fetchSensorsIfNeeded,
    sortSensors
} from '../../actions/SensorsActions';
import { fetchNoiseSamplesIfNeeded } from '../../actions/NoiseSamplesActions';

import {
    fetchSOPsIfNeeded,
    setSopsFilters,
    setSopsDefFilters
} from '../../actions/SOPsActions';

import SensorsSettings from '../../components/SensorsSettings/SensorsSettings';

const mapStateToProps = (state, ownProps) => {
  var deviceTypeOptions  = state.leaksList.optionList.options.DeviceType || [];
  const user = state.leaksList.user;
  const {selectedProject} = state.leaksList;
  const sensorsFilters = { ...state.leaksList.filters.sensorsDefFilters };
  const sopsFilters = state.leaksList.filters.sops.defFilters;

  if (sensorsFilters.Fragmented) {
    sensorsFilters.Fragmented = sensorsFilters.Fragmented[0];
  }
  if (sensorsFilters.Resets) {
    sensorsFilters.Resets = sensorsFilters.Resets[0];
  }

  return Object.assign({}, {
    user,
    selectedProject,
    deviceTypeOptions,
    initialValues: {
      Sensors: sensorsFilters,
      SOPs: sopsFilters
    },
    optionList: state.leaksList.optionList.options
  }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values, dispatch, props) => {
    const {selectedProject, processFilters, cleanEmptyFilters} = props;

    const sensorsFiltersObj = {...values.Sensors};
    if (sensorsFiltersObj.Fragmented) {
      sensorsFiltersObj.Fragmented = [true];
    } else {
      sensorsFiltersObj.Fragmented = [];
    }
    if (sensorsFiltersObj.Resets) {
      sensorsFiltersObj.Resets = [true];
    } else {
      sensorsFiltersObj.Resets = [];
    }
    const snesorsFilters = processFilters(Object.assign({}, sensorsFiltersObj));
    const sopsFilters = processFilters(Object.assign({}, values.SOPs));

    const finalSensorsFilters = cleanEmptyFilters(snesorsFilters.filters);
    const finalSopsFilters = cleanEmptyFilters(sopsFilters.filters);

    dispatch(selectSensor(null));

    dispatch(setSensorsFilters(finalSensorsFilters));
    dispatch(setSensorsDefFilters(snesorsFilters.defFilters));
    dispatch(sortSensors(snesorsFilters.sort.field, snesorsFilters.sort.dir));

    dispatch(setSopsFilters(finalSopsFilters));
    dispatch(setSopsDefFilters(sopsFilters.defFilters));

    dispatch(fetchSensorsIfNeeded(selectedProject));
    dispatch(fetchSOPsIfNeeded(selectedProject));
    dispatch(fetchNoiseSamplesIfNeeded(selectedProject, true));

    localStorage.setItem(FILTERS_SENSORS, JSON.stringify(snesorsFilters.defFilters));
    localStorage.setItem(FILTERS_SOPS, JSON.stringify(sopsFilters.defFilters));
  }
});

const CSensorsSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SensorsSettings);

export default CSensorsSettings;
