import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TextCell from '../../components/TextCell/TextCell';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

require('../AlertsTable/AlertsTable.scss');

class InspectTabs extends React.Component {

    static get propTypes() {
        return {
            selectedFeature: PropTypes.number,
            isDisplayCityColumn: PropTypes.bool,
            items: PropTypes.array,
            uiInfo: PropTypes.object,
            onAlertRowClick: PropTypes.func,
            openPCA: PropTypes.func,
        };
     }

    constructor(props) {
      super(props);

      this.state = {
        goToRow: null
      };

      this.handleRowClick = this.handleRowClick.bind(this);
      this.getRowClassName = this.getRowClassName.bind(this);
    }

    componentWillReceiveProps(nextProps) {
      const { selectedFeature, items } = this.props;
      let selectedRow = null;
      if (selectedFeature != null) {
        selectedRow = items.map((item) => item.ID).indexOf(selectedFeature);
      }

      this.setState({
        goToRow: selectedRow
      });
    }

    handleRowClick(event, rowIndex) {
        const { items, onAlertRowClick } = this.props;
        const selectedAlert = items[rowIndex];
        onAlertRowClick(selectedAlert);

    }

    getRowClassName(rowIndex) {
      const { selectedFeature, items } = this.props;
      const rowItem = items[rowIndex];

      let className = '';

      if (rowItem.id == selectedFeature) {
          className = 'active-row';
      } else if (rowItem !== null && rowItem.Burst === 2) {
        className = 'pink-row';
      }

      return (className);
    }

    render() {
        // const { selectedProject, selectedFeature, uiInfo, showDetails, notify } = this.props;
        // const title = (this.props.installMode.newInstallItem == null) ? this.context.t('Interference {id}', {id: selectedFeature}) : this.context.t('Create new Interference');
        const { items, uiInfo, isDisplayCityColumn, openPCA, openLeakGrowth, sortProp, sortLocaly } = this.props;
        return (
          <div className="alerts-wrp alerts-header inspect">

                <Table
                    height={0.95 * uiInfo.pageHeight}
                    width={0.39 * uiInfo.pageWidth}
                    rowsCount={items.length}
                    rowHeight={30}
                    headerHeight={30}
                    onRowClick={this.handleRowClick}
                    rowClassNameGetter={this.getRowClassName}
                    scrollToRow={this.state.goToRow}
                    {...this.props}
                >

                    <Column columnKey="Burst" width={30}
                        header={<SortableHeaderCell sortTable={sortProp} />}
                        cell={<TextCell data={items} field="Burst" />} />
                    <Column columnKey="Priority" width={30}
                        header={<SortableHeaderCell sortTable={sortProp} />}
                        cell={<TextCell data={items} field="Priority" />} />
                    <Column columnKey="id" width={70} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortProp} />}
                        cell={<TextCell data={items} field="ID" />} />
                    <Column columnKey="DetectionDateTime" width={95} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortProp} />}
                        cell={<TextCell data={items} field="DetectionDateTime" dateType={DateType.DATE} />} />
                    <Column columnKey="AlertTypeName" width={95} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortProp} />}
                        cell={<TextCell data={items} field="AlertTypeName" />} />
                    <Column columnKey="AlertStateName" width={95} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortProp} />}
                        cell={<TextCell data={items} field="AlertStateName" />} />
                    <Column columnKey="StreetAddress" headerClassName="comment" cellClassName="comment" width={120} flexGrow={3}
                        header={<SortableHeaderCell sortTable={sortProp} />}
                        cell={<TextCell data={items} field="StreetAddress" />} />
                    {/* { false && */}
                    {isDisplayCityColumn &&
                        <Column columnKey="City" headerClassName="comment" cellClassName="comment" width={80} flexGrow={2}
                            header={<SortableHeaderCell sortTable={sortProp} />}
                            cell={<TextCell data={items} field="City" />} />
                    }
                    <Column columnKey="PCA" width={30} flexGrow={1}
                        header={''}
                        cell={
                            (props) =>
                                <Cell>
                                    <i
                                        className="icon-pca"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            openPCA(items[props.rowIndex]);
                                        }}/>
                                </Cell>
                        } />
                    <Column columnKey="LG" width={30} flexGrow={1}
                        header={''}
                        cell={
                            (props) => {
                              const { rowIndex } = props;
                              const item = items[rowIndex];
                              let className = 'icon-leak-growth';
                              // if (item.ManualLeakProjectID != null) {
                              //   className += ' manual'
                              // }
                              return (
                                <Cell>
                                    <i
                                        className={className}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            openLeakGrowth(item);
                                        }}/>
                                </Cell>
                              );
                            }
                        } />
                </Table>

          </div>
      );
    }

  }

  InspectTabs.contextTypes = {
    t: PropTypes.func.isRequired
  };

export default InspectTabs;
