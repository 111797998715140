import { connect } from 'react-redux';

import * as actionsPlanning from '../../actions/PlanningActions';

import CoupleDetailsTab from '../../components/CouplesTabs/DetailsTab/CoupleDetailsTab';
import { clearCouplePath } from "../../actions/setters";
import { refreshSectionsCouplePath } from "../../actions/PlanningActions";

const mapStateToProps = (state, ownProps) => {
  const { selectedProject, user } = state.leaksList;

  if (selectedProject) {
    const planning = state.planning;
    const isGenerateMode = planning.isActive;
    const isOnEditMode = planning.editMode;
    const isButtonOnSetupMode = planning.step <= 1; // 0 = idle, 1 = selecting the first sensor. 2+ have one sensor or more...
    const validateNewCouple = (isGenerateMode) ? planning.isCouplePathReady || planning.newCouple : true;
    const isSubmitting = planning.isSubmitting;
    const materials = state.leaksList.optionList.options.Materials;
    const projectState = state.leaksList.leaksByProject[selectedProject];
    const selectedFeature = state.leaksList.leaksByProject.selectedFeature;
    const units = state.local.units;
    const timeZone = state.local.timeZone;
    const distanceFactor = state.local.factor[units];
    const hasSamples = projectState.coupleSamples[selectedFeature]?.data?.result;

    // Be able to delete a SOP, that has NO sensor defined on it. Install>Select SOP>Installation Point Tab>Delete SOP button

    let couple;
    let initialValues = {};
    let couplePath = state.planning.sectionsCouplePath;
    couplePath.forEach((item) => {
      let material = materials.find((m) => m.value == item.Material);
      item.MaterialName = material.label;
    });
    // const couplePath = state.leaksList.leaksByProject[selectedProjectId].couplePath.path;

    if (isGenerateMode) {
      const coupleForm = state.form.coupleDetails;
      if (coupleForm != null) {
        couple = Object.assign(coupleForm.initial, planning.newCouple);
      } else {
        couple = planning.newCouple;
      }
    } else if (selectedFeature != null && selectedFeature != '') {
      const couples = projectState.couples;
      const selectedCouple = couples.couples[couples.indexMap[selectedFeature]];
      couple = {
        id: selectedCouple.id,
        Sensor1: selectedCouple.Sensor1,
        Sensor2: selectedCouple.Sensor2,
        Address1: selectedCouple.Address1,
        Address2: selectedCouple.Address2,
        VelocityMS: (selectedCouple.VelocityMS == null) ? null : (selectedCouple.VelocityMS * distanceFactor).toFixed(),
        MaterialCode: selectedCouple.MaterialCode,
        //PathLengthM: selectedCouple.PathLengthM,
        PathLengthM: (selectedCouple.PathLengthM == null) ? null : (selectedCouple.PathLengthM * distanceFactor).toFixed(),
        OverrideLength: selectedCouple.OverrideLength === 1,
        Attenuation: selectedCouple.Attenuation,
        NoiseLevel: selectedCouple.NoiseLevel,
        Fmin: selectedCouple.Fmin,
        Fmax: selectedCouple.Fmax,
        SensitivityLevel: selectedCouple.SensitivityLevel,
        AllowFalsePositive: selectedCouple.AllowFalsePositive,
        Prob4Burst: selectedCouple.Prob4Burst,
        Prob4Irrigation: selectedCouple.Prob4Irrigation,
        PipeAccuracy: selectedCouple.PipeAccuracy,
        MultiDiameter: selectedCouple.MultiDiameter,
        MultiMaterial: selectedCouple.MultiMaterial,
        PossibleUFR: selectedCouple.PossibleUFR,
        PossiblePumps: selectedCouple.PossiblePumps,
        Comment: selectedCouple.Comment == null ? null : selectedCouple.Comment,
        is_overlapping: selectedCouple.is_overlapping
      };
    }

    if (couple != null) {
      initialValues = Object.assign({}, couple);
    }

    // if (isGenerateMode) {
    //   if (couple != null && !_.isEmpty(couple)) {
    //     // continue with the current data
    //     initialValues = Object.assign({}, couple);
    //   }
    // } else {
    //   if (couple != null) {
    //     initialValues = Object.assign({}, couple);
    //   }
    //   if (isOnEditMode) {
    //     if (planning.newCouple != null) {
    //       // set path length for update UI field:
    //       initialValues.PathLengthM = planning.newCouple.PathLengthM;
    //     }
    //   }
    // }

    // if (couple != null && !_.isEmpty(couple)) {
    //   initialValues = Object.assign({}, couple);

    //   if (isGenerateMode) {
    //     initialValues.PathLengthM = planning.newCouple.PathLengthM;
    //     if (initialValues.MaterialCode == null) {
    //       initialValues.MaterialCode = defaultMaterialCode;
    //     }
    //   }
    // } else {
    //   initialValues.MaterialCode = defaultMaterialCode;
    // }

    return Object.assign({}, {
      planning,
      isGenerateMode,
      isOnEditMode,
      isButtonOnSetupMode,
      distanceFactor,
      timeZone,
      user,
      selectedFeature,
      selectedProject,
      initialValues,
      isSubmitting,
      couplePath,
      couplePathLength: couplePath.length,
      validateNewCouple,
      materials,
      hasSamples,
    }, ownProps);
  }

  return { timeZone: state.local.timeZone, user, initialValues: {} };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      // console.log('onSubmit');
      // const material = values.MaterialCode;
      const callback = ((result) => {

        let message;
        let level;
        if (result.status == false) {
          level = 'error';
          const error = result.err;
          // console.log(error);
          message = 'Couple generation failed';
          if (error.code == 'TIMEOUT') {
            message = 'Timeout';
          } else {
            message = error.message;
          }
        } else {
          level = 'success';
          message = 'Couple generated successfuly';
        }

        ownProps.notify(message, level);

      });

      if (values.id == null) {
        dispatch(actionsPlanning.generateNewCouple(callback, values));
      }
      else {
        var data = {
          Material: values.MaterialCode,
          // PathLengthM: values.PathLengthM,
          ...values
        }
        if (data.VelocityMS != null) {
          data.VelocityMS = data.VelocityMS / props.distanceFactor
        }
        if (data.PathLengthM != null) {
          data.PathLengthM = data.PathLengthM / props.distanceFactor
        }
        dispatch(actionsPlanning.updateCouple(props.selectedFeature, data, callback));
      }
    },
    setupSensors: (action) => {
      if (action == 'setup') {
        dispatch(actionsPlanning.setupSensors(true));
      } else if (action == 'reset') {
        dispatch(actionsPlanning.reset());
        ownProps.openNewCoupleForm(true);
      }
    },
    setEditMode: (bEditMode) => {
      dispatch(actionsPlanning.setEditMode(bEditMode, (success) => {
        if (!success) {
          ownProps.notify(`Can't edit couple without both sensors visible, Please remove sensors filters`, 'error');
        }
      }));
    },
    resetSensors: () => {
      dispatch(actionsPlanning.reset());
      ownProps.openNewCoupleForm(true);
    },
    setEditPathMode: (mode) => {
      dispatch(actionsPlanning.setEditPathMode(mode));
    },
    clearCouplePath: () => {
      dispatch(clearCouplePath());
    },
    generateAutoCouple: () => {
      dispatch(actionsPlanning.generateAutoCouple());
    },
    onMaterialChange: (materialOption) => {
      dispatch(actionsPlanning.setMaterial(materialOption));
      dispatch(actionsPlanning.refreshSectionsCouplePath());
    }
  };
};

const CCoupleDetailsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoupleDetailsTab);

export default CCoupleDetailsTab;
