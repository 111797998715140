import React from 'react';

import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

export default class OprSelect extends React.Component {
  render() {
    const { input: { value, onChange }} = this.props;
    return (
      <ReactSelect {...this.props}
        clearable={false}
        backspaceRemoves={false}
        value={value}
        onChange={onChange}
        deleteRemoves={false}
        options={[
          {value: '>', label: '>'},
          {value: '>=', label: '>='},
          {value: '=', label: '='},
          {value: '<=', label: '<='},
          {value: '<', label: '<'}
        ]}
      />
    );
  }
}
