import React from 'react';
import PropTypes from 'prop-types';

const JsonToTable = ({ headers, jsonData, handleSearch, searchQuery, requestSort, sortConfig }, context) => {

    if (!headers) {
        return null;
    }

    const getRoundNumber = (number) => {
        const roundedNumber = Number(number.toFixed(2));
        return roundedNumber;
    };

    const getArrow = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? ' ▲' : ' ▼';
        }
        return ' ↕';
    };

    const getColumnStyle = (key) => {
        if (typeof key === 'number' || key === 'g5' || key === 'g4' || key === 'g5mini') {
            return 'center'
        } else {
            return 'left'
        }
    }

    const getColWidth = (key) => {
        if (typeof key === 'number' || key === 'g5' || key === 'g4' || key === 'g5mini' || key?.length < 6) {
            return '150px'
        } else {
            return '300px'
        }
    }

    const getColMaxWidth = (key) => {
        if (typeof key === 'number' || key === 'g5' || key === 'g4' || key === 'g5mini' || key?.length < 6) {
            return '150px'
        } else {
            return '350px'
        }
    }

    const changeColHeaderName = (key) => {
        if (key === 'ProjectDisplayName') {
            return context.t('project_display_name');
        }
        return context.t(key);
    };


    return (
        <div className='table-con'>
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                style={{ marginBottom: '10px', marginBottom: ' 0.7em', padding: '0.7em', border: '1px solid #d3d3d3' }}
            />

            <div className='admin-report-table'>
                <table>
                    <thead>
                        <tr>
                            {headers.map((key) => (
                                <th
                                    key={key}
                                    onClick={() => requestSort(key)}
                                    style={{ minWidth: '150px' }}
                                // style={{ minWidth: getColWidth(key) }}
                                >
                                    {changeColHeaderName(key)}
                                    {getArrow(key)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {jsonData.map((item, index) => (
                            <tr key={index}>
                                {headers.map((key) => (
                                    <td style={{ textAlign: getColumnStyle(item[key]), minWidth: getColWidth(item[key]), maxWidth: getColMaxWidth(item[key]) }} key={key}>
                                        {typeof item[key] === 'number' ? getRoundNumber(item[key]) : item[key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

JsonToTable.contextTypes = {
    t: PropTypes.func.isRequired,
};


export default JsonToTable;
