import React from 'react';
import PropTypes from 'prop-types';


const classNames = require('classnames');

require('./Scale.scss');

export default class Scale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showScale: false
        };
        this.toggleManagerDisplay = this.toggleManagerDisplay.bind(this);
    }

    toggleManagerDisplay() {
        const visible = !this.state.showScale;
        this.setState({ showScale: visible });
        this.props.isOpen(visible);
    }

    render() {
        const { clear, undo, distance, distanceFactor, units } = this.props;
        const panelClasses = classNames(
            'scale',
            { 'show': this.state.showScale }
        );
        return (
            <div className={panelClasses}>
                <div className="actions">
                    <i className="icon-toggle" onClick={this.toggleManagerDisplay} />
                </div>
                <h2>{this.context.t('distance_calculation')}</h2>
                <div className="distance-data">
                    <h1>{Math.round(distance * distanceFactor)}</h1>
                    <span className="units">[{units}]</span>
                    <i className="clear" onClick={clear} />
                </div>
            </div>
        );
    }
}

Scale.contextTypes = {
    t: PropTypes.func.isRequired
}
