import React from 'react';
import { featuresTypes, zIndexs } from './Options';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';
const proj = require('ol/proj');

// Alerts layer style
const source = new VectorSource({
  features: [],
});

// Alerts layer
export const layer = new VectorLayer({
  name: featuresTypes.VALVE_ALERT,
  source: source,
  zIndex: zIndexs.alerts,
});

export const setSource = (valveAlerts, props) => {
  let iconFeatures = [];
  valveAlerts.forEach((vAlert) => {
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          [vAlert.Longitude, vAlert.Latitude],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: featuresTypes.VALVE_ALERT,
      id: vAlert.id,
      status: vAlert.alert_state,
      createDate: vAlert.create_date,
    });
    iconFeature.setStyle(getStyle(iconFeature));
    iconFeatures.push(iconFeature);
  });
  source.addFeatures(iconFeatures);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (_feature, bSelected) => {
  const iconStyle = {
    opacity: 0.8,
    scale: 0.04,
    anchor: [0.5, 0.5],
    src: require(`../../../../images/map/valves/valve.png`),
  };

  const alertStyle = new Style({
    image: new Icon(iconStyle),
  });

  if (bSelected) {
    alertStyle.setImage(
      new Icon({
        src: require('../../../../images/map/sensors/indications/selected-background.png'),
        opacity: 0.75,
        scale: 0.5,
        anchor: [0.5, 0.5],
      })
    );
  }

  return [alertStyle];
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();
  let infoTooltip = {
    type: attr.type,
    id: attr.id,
    status: attr.status,
    createDate: attr.createDate,
  };
  return infoTooltip;
};

export const tooltipRender = (itemInfo, context, props) => {
  const statusOptions = props.options.AlertStateNoise;
  const alertStatus = statusOptions.find((opt) => opt.value === itemInfo.status);
  const alertCreateDate = UIDateFormater(itemInfo.createDate, DateType.DATE, props.timeZone);
  return (
    <span className={'tooltip'}>
      <div className='tooltip-row'>
        {context.t('alert_id_param', { id: itemInfo.id })}
      </div>
      <div className='tooltip-row'>
        {context.t('status_param', { status: alertStatus.DisplayName })}
      </div>
      <div className='tooltip-row'>
        {context.t('created_date') + ' ' + alertCreateDate}
      </div>
    </span>
  );
};
