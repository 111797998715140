import React from 'react';
import PropTypes from 'prop-types';

import actions from '../../actions/AlertsValveActions';
import { useDispatch } from 'react-redux';

const AlertsValveSampleStrip = (props, context) => {
  const dispatch = useDispatch();

  const closePanel = () => {
    dispatch(actions.selectValveAlertSample(undefined));
  };
  const [audioUrls, setAudioUrls] = React.useState({});

  React.useEffect(() => {
    if (props.sample) {
      setAudioUrls({});
      dispatch(
        actions.getSignedUrlForFiles(props.sample, (data) => {
          if (data) {
            console.log('data in getSignedUrlForFiles', data);

            setAudioUrls(data);
          }
          // console.log('urldata',data);
        })
      );
    }
  }, [props.sample]);

  return (
    <div className='sample-info'>
      <div className='sample-audio'>
        <div className='player'>
          <div>
            {audioUrls?.audio_alert3_key && (
              <div>
                <span>{context.t('audio_alert_3')}</span>
                <audio src={audioUrls.audio_alert3_key} controls />
              </div>
            )}
          </div>
          <div>
            {audioUrls?.audio_alert3_key_clean && (
              <div>
                <span>{context.t('audio_alert_3_clean')}</span>
                <audio src={audioUrls.audio_alert3_key_clean} controls />
              </div>
            )}
          </div>

          <div>
            {audioUrls.audio_base1_key && (
              <div>
                <span>{context.t('audio_base_1')}</span>
                <audio src={audioUrls.audio_base1_key} controls />
              </div>
            )}
          </div>

          <div>
            {audioUrls.audio_base1_key_clean && (
              <div>
                <span>{context.t('audio_base_1_clean')}</span>
                <audio src={audioUrls.audio_base1_key_clean} controls />
              </div>
            )}
          </div>

          <div>
            {audioUrls.audio_base2_key && (
              <div>
                <span>{context.t('audio_base_2')}</span>
                <audio src={audioUrls.audio_base2_key} controls />
              </div>
            )}
          </div>
          <div>
            {audioUrls.audio_base2_key_clean && (
              <div>
                <span>{context.t('audio_base_2_clean')}</span>
                <audio src={audioUrls.audio_base2_key_clean} controls />
              </div>
            )}
          </div>

          <div>
            {audioUrls.audio_sample_key && (
              <div>
                <span>{context.t('audio_sample')}</span>
                <audio src={audioUrls.audio_sample_key} controls />
              </div>
            )}
          </div>

          <div>
            {audioUrls.audio_sample_key_clean && (
              <div>
                <span>{context.t('audio_sample_clean')}</span>
                <audio src={audioUrls.audio_sample_key_clean} controls />
              </div>
            )}
          </div>
        </div>

        <i className='icon-close' onClick={closePanel} />
      </div>
    </div>
  );
};

AlertsValveSampleStrip.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AlertsValveSampleStrip;
