import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import React from 'react';

import { SYSTEM_RESOURCE } from '../../constants/EndPoints';
import './Loading.scss';

export default class Loading extends React.Component {
  render() {
    const sys = SYSTEM_RESOURCE;
    const backgroundImagePath = require(`../../../images/system/${sys}/login_bg.png`);

    const loadingScreenStyle = {
      backgroundImage: `url(${backgroundImagePath})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      // width: '100%', // Adjust as needed to maintain dimensions
      // height: '100%', // Adjust as needed to maintain dimensions
    };

    return (
      <div className='loading-page'>
        <div className="loading-screen" style={loadingScreenStyle}>
          <LinearProgress />
          <Typography variant="h1" component="h2" align='center'>
            {this.context.t('loading')}
          </Typography>
        </div>
      </div>
    );
  }
}

Loading.contextTypes = {
  t: PropTypes.func
}
