import React from 'react';

import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Select from './../Select/Select';
import { positiveNum } from '../../validators/formValidators';
const ReactSelect = require('react-select');
const classNames = require('classnames');

require('./AlertsSettings.scss');

class ValveAlertsSettings extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, uiInfo, optionList, toggleSettingsDisplay } = this.props;
        const panelClasses = classNames(
            'alerts-settings',
            { 'show': this.props.showSettings }
        );


        const panelStyle = { height: uiInfo.pageHeight, width: 0.395 * (uiInfo.pageWidth - 10) };

        return (
            <div className={panelClasses} style={panelStyle}>
                <div className="alerts-header">
                    <div className="actions">
                        <i className="icon-close" onClick={toggleSettingsDisplay} />
                    </div>
                    <h2>{this.context.t('valve_alert_options')}</h2>
                </div>
                <form className="form-horizontal" onSubmit={handleSubmit}>

                    <fieldset>
                        <legend>{this.context.t('filters')}</legend>
                        <div className="form-group">
                            <label className="col-xs-4 control-label">{this.context.t('alert_state')}</label>
                            <div className="col-xs-8">
                                <Field name="alert_state"
                                    component={props =>
                                        <Select {...props}
                                            placeholderTitle={this.context.t('select')}
                                            clearable={true}
                                            multi={true}
                                            closeMenuOnSelect={false}
                                            options={optionList.AlertStateNoise}
                                        />
                                    }
                                />
                            </div>
                        </div>


                        <div className="form-group">
                            <label className="col-xs-4 control-label">{this.context.t('alert_date')}</label>
                            <div className="col-xs-3 control-date-form">
                                <Field name="create_date-From" component="input" type="date" className="form-control" />
                            </div>
                            <div className="col-xs-1 control-label"> {this.context.t('to')}</div>
                            <div className="col-xs-3 control-date-to">
                                <Field name="create_date-To" component="input" type="date" className="form-control" />
                            </div>
                        </div>

                        {/* {this.props.user.isAQS &&
                            <div className="form-group">
                                <label className="col-xs-4 control-label">{this.context.t('event_date')}</label>
                                <div className="col-xs-3 control-date-form">
                                    <Field name="CreateDate-From" component="input" type="date" className="form-control" />
                                </div>
                                <div className="col-xs-1 control-label"> {this.context.t('to')}</div>
                                <div className="col-xs-3 control-date-to">
                                    <Field name="CreateDate-To" component="input" type="date" className="form-control" />
                                </div>
                            </div>
                        } */}

                        <div className="text-right">
                            <button className="btn btn-success" type="submit" disabled={submitting} onClick={toggleSettingsDisplay} >
                                {this.context.t('save_and_apply')}
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }

}

function validate(formProps) {
    const errors = {}
    if (positiveNum(formProps.DaysDetected)) {
        errors.DaysDetected = 'must be positive';
    }
    return errors;

}


ValveAlertsSettings.contextTypes = {
    t: PropTypes.func.isRequired
}

// Decorate the form component
ValveAlertsSettings = reduxForm({
    form: 'alerts-valve-settings', // a unique name for this form
    enableReinitialize: true,
})(ValveAlertsSettings);

export default ValveAlertsSettings;
