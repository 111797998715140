import * as types from '../constants/ActionTypes';


const initialState = {
  items: [],
  indexMap: {},
  selected: {},
  sortBy: {
    field: 'InsertDate',
    dir: 'desc',
  },
  history: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_NOISE_ALERTS:
      return initialState;

    case types.RECEIVE_NOISE_ALERTS:
      return {
        ...state,
        items: action.payload.items,
        indexMap: action.payload.indexMap
      };

    case types.SELECT_NOISE_ALERT:
      return { ...state, selected: action.payload };

    case types.NOISE_ALERTS_SET_SORT_PROPS:
      return {
        ...state,
        sortBy: {
          field: action.payload.field,
          dir: action.payload.dir
        }
      };

    case types.REQUEST_NOISE_ALERTS_HISTORY:
      return { ...state, history: [] };
    case types.RECEIVE_NOISE_ALERTS_HISTORY:
      return { ...state, history: action.payload };

    default:
      return state;
  }
};
