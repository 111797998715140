import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import * as CouplesCoverage from './CouplesCoverage';


import { Style, Stroke } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import LineString from 'ol/geom/LineString';
const proj = require('ol/proj');


const WIDTH_PIPE_STROKE = 10;

const strokeLayerStyle = new Style({
  stroke: new Stroke({
    color: [20, 0, 255, 1], // Purple
    width: WIDTH_PIPE_STROKE,
  })
});

// SOURCE.VECTORS:
const source = new VectorSource({
  features: [],
});
const strokeSource = new VectorSource({
  features: [],
});

// LAYER.VECTORS:
const layer = new VectorLayer({
  name: 'layerCouplePathFill',
  style: CouplesCoverage.getStyle(),
  source: source,
  zIndex: zIndexs.couplePathLine,
});
const strokeLayer = new VectorLayer({
  name: 'layerCouplePathStroke',
  style: strokeLayerStyle,
  source: strokeSource,
  zIndex: zIndexs.couplePathLine - 1,
});

export const setSource = (paths, props) => {
  source.clear();
  strokeSource.clear();

  switch (props.selectedFeatureType) {
    case 'sensor':
      setSourceRelatedCouples(paths, props);
      break;

    case 'alert':
    case 'couple':
      setSourceSelectedCouple(paths, props);
      break;
  }

};
function setSourceRelatedCouples(paths, props) {
  if (paths && paths.length > 0) {
    for (let i = 0; i < paths.length; i++) {
      const coupleItr = paths[i];

      if (coupleItr.pathPoints) {
        const pathCoordinates = coupleItr.pathPoints.map((lngLatArr) => proj.transform(lngLatArr, 'EPSG:4326', 'EPSG:3857'));

        const lineFeature = new Feature({
          geometry: new LineString(pathCoordinates, 'XY'),
          name: 'Line',
          id: coupleItr.CoupleID,
          type: featuresTypes.COUPLE_PATH,
        });
        const strokeFeature = lineFeature.clone();

        const style = CouplesCoverage.getStyle(coupleItr, false, props);

        lineFeature.setStyle(style);
        strokeFeature.setStyle(strokeLayerStyle);

        source.addFeature(lineFeature);
        strokeSource.addFeature(strokeFeature);
      }
    }
  }
}

function setSourceSelectedCouple(paths, props) {
  if (paths && paths.length > 1) {
    for (let i = 1; i < paths.length; i++) {
      const point1 = paths[i - 1];
      const point2 = paths[i];

      const lineCoordinates = [[point1.Longitude, point1.Latitude],[point2.Longitude, point2.Latitude]];

      const pathCoordinates = lineCoordinates.map((point) => proj.transform(point, 'EPSG:4326', 'EPSG:3857'));

      const lineFeature = new Feature({
        geometry: new LineString(pathCoordinates, 'XY'),
        name: 'Line',
        id: point1.CoupleID,
        type: featuresTypes.COUPLE_PATH,
      });
      const strokeFeature = lineFeature.clone();

      const style = CouplesCoverage.getStyle(point1, false, props);

      lineFeature.setStyle(style);
      strokeFeature.setStyle(strokeLayerStyle);

      source.addFeature(lineFeature);
      strokeSource.addFeature(strokeFeature);
    }
  }
}

export const getLayers = () => {
  return [layer, strokeLayer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
}

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
    strokeSource.clear();
  }
};

export const getInfoTooltip = (feature) => {
  return (CouplesCoverage.getInfoTooltip(feature));
};

export const tooltipRender = (itemInfo, context, props) => {
  return (CouplesCoverage.tooltipRender(itemInfo, context, props));
};
