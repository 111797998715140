import React, { Component } from 'react';
import PropTypes from "prop-types";

class ReportForDates extends Component {

  constructor(props) {
    super(props);

    this.state = {
      from: '',
      to: '',
    }
  }

  setDefaultValues = () => {
    const now = new Date();
    const weekAgo = new Date(now.getTime() - (1000 * 60 * 60 * 24 * 7));
    this.setState({
      from: weekAgo.toJSON().substring(0,10),
      to: now.toJSON().substring(0,10),
    });
  }

  componentDidMount() {
    this.setDefaultValues();
  }

  onChange = (e) => {
    const fieldName = e.target.name;
    this.setState({
      [fieldName]: e.target.value
    });
  }

  reset = () => {
    this.setDefaultValues();
  }

  done = () => {
    this.props.done(this.state.from, this.state.to);
  }

  render() {
    return (
      <div className='dates-filter'>
        <label htmlFor='from'>{('from')}</label>
        <input
          id='from'
          type='date'
          name='from'
          max={this.state.to}
          value={this.state.from}
          onChange={this.onChange}
        />

        <label htmlFor='to'>{('to2')}</label>
        <input
          id='to'
          type='date'
          name='to'
          min={this.state.from}
          value={this.state.to}
          onChange={this.onChange}
        />

        <ion-icon
          style={{color: 'white'}}
          name="trash-outline"
          onClick={this.reset}
          />

        <ion-icon
          style={{ color: 'white', marginLeft: '10px', cursor: 'pointer' }}
          name="send"
          disabled={this.state.from === '' || this.state.to === ''}
          onClick={this.done}
        />
      </div>
    )
  }
}


export default ReportForDates;
