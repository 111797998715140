import React from 'react';

import { Cell, Column, Table } from 'fixed-data-table-2';
import Plot from 'react-plotly.js';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';
import CSensorsSettings from '../../containers/CSensorsSettings/CSensorsSettings';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';


const BarsChart = ({ rowIndex, data }) => {
  const item = data[rowIndex];
  const { Nc3f1, Nc3f2, Nc3f3 } = item;
  return (
    <Plot
      data={[{
        type: 'bar',
        x: ['F1', 'F2', 'F3'],
        y: [Nc3f1, Nc3f2, Nc3f3],
        // orientation: 'h',
        marker: {
          color: [
            'rgb(20, 190, 40)',
            'rgb(174, 199, 232)',
            'rgb(255, 127, 14)',
          ]
        },
        hoverinfo: 'none',
      }]}
      layout={{
        margin: {
          l: 0, r: 0, b: 3, t: 0, pad: 1
        },
        height: 27,
        width: 50,
        xaxis: {
          showgrid: false,
          zeroline: true,
          showline: true,
          zerolinecolor: 'black',
          zerolinewidth: 3,
        },
        yaxis: {
          showgrid: false,
          showline: false,
        }
      }}
    />
  )
};

const SensorsTable = (props) => {

  const { items, indexMap, selectedFeature, pHeight, pWidth, sortProp, localSortProp, rowClickEvent, rowClassNameGetter, goToRow, user, t } = props;
  const totalItemsStyle = {
    float: 'right',
    padding: '2px 8px',
  };


  return (
    <div>
      <Table
        maxHeight={pHeight - 93}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={rowClassNameGetter}
        scrollToRow={goToRow}
        {...props}
      >
        <Column columnKey="warningEnum" width={30}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field="warningEnum" path={props.path} />} />
        <Column
          columnKey="id"
          width={40}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="id" />} />
        <Column
          columnKey="SensorStatus"
          width={65}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="SensorStatus" />} />

        {JSON.parse(process.env.REACT_APP_SENSORS_SHOW_BOUNDARY_COLUMN) &&
          <Column
            columnKey="DmaComment"
            width={55}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="DmaComment" />} />
        }

        <Column
          columnKey="DaysInactive"
          width={55} flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="DaysInactive" />} />
        {user.editMode &&
          <Column
            columnKey="Nc1"
            width={45} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} title={props.t('min_noise')} />}
            cell={<TextCell data={items} field="Nc1" user={user} />} />
        }
        <Column
          columnKey="Nc2"
          width={45} flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} title={props.t('sample_noise')} />}
          cell={<TextCell data={items} field="Nc2" user={user} />} />
        {false &&
          <Column
            columnKey="Nc3"
            width={45} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Nc3" user={user} />} />
        }
        {user.editMode &&
          <Column
            columnKey="Nc3f1"
            width={45} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} title={props.t('low_frequency')} />}
            cell={<TextCell data={items} field="Nc3f1" user={user} />} />
        }
        {user.editMode &&
          <Column
            columnKey="Nc3f2"
            width={45} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} title={props.t('medium_frequency')} />}
            cell={<TextCell data={items} field="Nc3f2" user={user} />} />
        }
        {user.editMode &&
          <Column
            columnKey="Nc3f3"
            width={45} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} title={props.t('high_frequency')} />}
            cell={<TextCell data={items} field="Nc3f3" user={user} />} />
        }
        {user.editMode &&
          <Column
            width={45} flexGrow={1}
            header={<Cell><img height={22} src={require('../../../images/graph.png')} /></Cell>}
            cell={<BarsChart data={items} />} />
        }
        <Column
          columnKey="DeviceID"
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="DeviceID" />} />
        <Column
          columnKey="DeviceInstallationDate"
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="DeviceInstallationDate" dateType={DateType.DATE} />} />
        {false &&
          <Column
            columnKey="StreetAddress"
            width={50}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="StreetAddress" />} />
        }
      </Table>

      {items && items.length > 0 &&
        <CTableActions
          type="sensors"
          notify={props.notify}
        />
      }
      {items && items.length > 0 &&
        <span style={totalItemsStyle}>{t('selected_selectedindex_total_total', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
      }

    </div>
  );
}


const sensorsTable = TableMixin(SensorsTable, CSensorsSettings);
export default sensorsTable;
