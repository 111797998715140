import * as types from '../constants/ActionTypes';

export function sensorHistory(state = {
    data: [],
    isFeching: false,
}, action) {
    switch (action.type) {
        case types.REQUEST_SENSOR_HISTORY:
            return Object.assign({}, state, {
                isFetching: true,
                data: []
            });
        case types.RECEIVE_SENSOR_HISTORY:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.history.data,
            });
        default:
            return state;
    }
}