import { connect } from 'react-redux';
import PipeAssessmentReportModal from '../../components/Modals/PipeAssessmentReportModal';
import * as actionsReports from '../../actions/ReportsActions';

import { featuresTypes } from "../../components/Map/Layers/Options";

const mapStateToProps = (state, ownProps) => {
  const selectedProjectId = state.leaksList.selectedProject;
  const projectsData = state.leaksList.projectsList;
  const selectedProjectObject = projectsData.items[projectsData.projectIndexMap[selectedProjectId]] || {};

  const isAqsAdmin = state.user.isAQS;
  const options = state.leaksList.optionList.options;
  const alertStateOptions = options.AlertState;
  const timeZone = state.local.timeZone
  const coverageSource = state.local.coverageSource;
  const coverageLayerSelected = state.mapState.layers[featuresTypes.COUPLES_COVERAGE] || false;
  const records = state.pipeAssessments.records;

  return Object.assign({}, {
    isAqsAdmin,
    timeZone,
    alertStateOptions,
    coverageSource,
    coverageLayerSelected,
    records,
    project: selectedProjectObject,
  }, ownProps)
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadReport: () => {
      dispatch(actionsReports.fetchPipeAssessmentsReport());
    }
  };
}

const CPipeAssessmentReportModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(PipeAssessmentReportModal);

export default CPipeAssessmentReportModal;
