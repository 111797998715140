import React from 'react';
import PropTypes from 'prop-types';

import PivotTableComponent  from '../../../components/Inspect/PivotTableComponent';
import { Button } from '@mui/material';

const pivotTableDefaults = [
   {
      name: '1',
      state: {
         rendererName: 'Table Heatmap',
         rows: ['Material'],
         cols: ['PlacementYear'],
      },
   },
   {
      name: '2',
      state: {
         rendererName: 'Table Heatmap',
         rows: ['DiameterInch'],
         cols: ['PlacementYear'],
      },
   },
   {
      name: '3',
      state: {
         rendererName: 'Table Heatmap',
         rows: ['Material', 'DiameterInch'],
         cols: ['PlacementYear'],
      },
   },
   {
      name: '4',
      state: {
         rendererName: 'Stacked Column Chart',
         rows: ['Material'],
         cols: ['PlacementYear'],
      },
   },
   {
      name: '5',
      state: {
         rendererName: 'Stacked Column Chart',
         rows: ['DiameterInch'],
         cols: ['PlacementYear'],
      },
   },
   {
      name: '6',
      state: {
         rendererName: 'Stacked Column Chart',
         rows: ['Material', 'DiameterInch'],
         cols: ['PlacementYear'],
      },
   }
];

export default class PivotTableTab extends React.Component {

   constructor(props) {
      super(props);

      this.renderDefaultsButtons = this.renderDefaultsButtons.bind(this);

      this.state = {
         pivotState: {
            rendererName: 'Table Heatmap',
            rows: ['Material'],
            cols: ['PlacementYear'],
         }
      };
   }

   renderDefaultsButtons() {
      return (
         <div>
         {
            pivotTableDefaults.map((item, index) => {
               return (
                  <Button
                    variant='contained'
                     key={index}
                     onClick={() => {
                        const pivotState = Object.assign({}, this.state, {
                           pivotState: Object.assign({}, this.state.pivotState, item.state)
                        });
                        this.setState(pivotState);
                     }}
                  >{item.name}
                  </Button>
               );
            })
         }
         </div>
      );
   }

   render() {
      return (
         <div>
            {this.renderDefaultsButtons()}

            <PivotTableComponent
               sortedAlertsDataSet={this.props.sortedAlertsDataSet}
               selectAlertsByFilters={this.props.selectAlertsByFilters}
               pivotState={this.state.pivotState}
            />
         </div>
      );
   }
}
