import React from 'react';
import PropTypes from 'prop-types';
import UIDateFormater, {
  DateType,
} from '../../../containers/UIhelper/UIDateFormater';
import SensorInfoAdvanced from './SensorInfoAdvanced';

import Plot from 'react-plotly.js';
import { useDispatch, useSelector } from 'react-redux';

import { selectSensorSample } from '../../../actions/SensorsActions';
import IntensityNoiseGraph from '../IntensityNoiseGraph';

require('./SensorInfo.scss');

const _ = require('lodash');

const graphWidth = 330;
const graphHeigh = 220;
const markerSize = 7;

const SensorInfo = (props, context) => {
  const { sensorDetails, user } = props;
  const dispatch = useDispatch();

  const [input, setInput] = React.useState(props.noiseAlertCount || 10);
  const [nc1Data, setNc1Data] = React.useState([]);
  const [nc2Data, setNc2Data] = React.useState([]);
  const [nc3Data, setNc3Data] = React.useState([]);
  const [times, setTimes] = React.useState([]);
  const [xValues, setXValues] = React.useState([]);

  React.useEffect(() => {
    if (
      props.sensorDetails !== undefined &&
      props.sensorDetails.details !== undefined
    ) {
      const samplesLength = props.sensorDetails.details.length;
      const nc1Array = [];
      const nc2Array = [];
      const nc3Array = [];
      const timesArray = [];
      const xValuesArr = [];

      props.sensorDetails.details.forEach((sample, index) => {
        nc1Array.push(sample.Nc1);
        nc2Array.push(sample.Nc2);
        nc3Array.push(sample.Nc3);
        timesArray.push(
          UIDateFormater(
            sample.SampleTime,
            DateType.DATE_AND_TIME,
            props.timeZone
          )
        );
        xValuesArr.push(samplesLength - index);
      });

      setNc1Data(nc1Array);
      setNc2Data(nc2Array);
      setNc3Data(nc3Array);
      setTimes(timesArray);
      setXValues(xValuesArr);
    }
  }, [props.sensorDetails]);

  const handleChange = (event) => {
    // const value = e.target.value <= 60 ? e.target.value : 60;
    const value = Math.min(event.target.value, 60);
    setInput(value);
  };

  const reloadNoiseSamples = () => {
    props.reloadNoiseSamples(input);
  };

  const closeNoise = () => {
    const sensorId = props.selectedFeature;
    const noiseAlertId = props.sensorDetails.noiseAlertDetails.ID;
    props.closeNoise(sensorId, noiseAlertId);
  };

  const closePanel = () => {
    props.handleClosePannel('snesorSample');
  };

  const noiseAlertRender = (details) => {
    const { timeZone } = props;
    let alertDetails =
      details != null && details.noiseAlertDetails != null
        ? details.noiseAlertDetails
        : {};
    const createDate =
      alertDetails.CreateDate != null
        ? UIDateFormater(alertDetails.CreateDate, DateType.DATE, timeZone)
        : '';

    const minIntensity =
      alertDetails.Nc1Ave != null ? alertDetails.Nc1Ave.toFixed() : '';
    const avgIntensity =
      alertDetails.Nc3Ave != null ? alertDetails.Nc3Ave.toFixed() : '';
    const frequency = alertDetails.Freq || '';
    const Consistency = alertDetails.Consistency || '';

    return (
      <div className='noise-alert-details'>
        <h2>{context.t('alert_information')}</h2>
        <table>
          <tbody>
            <tr>
              <th>{context.t('created_date')}:</th>
              <td>{createDate}</td>
            </tr>
            <tr>
              <th>{context.t('raw_intensity')}:</th>
              <td>{minIntensity}</td>
            </tr>
            <tr>
              <th>{context.t('filtered_intensity')}:</th>
              <td>{avgIntensity}</td>
            </tr>
            <tr>
              <th>{context.t('frequency')}:</th>
              <td>{frequency}</td>
            </tr>
            <tr>
              <th>{context.t('consistency')}:</th>
              <td>{Consistency}</td>
            </tr>
          </tbody>
        </table>
        <button className='btn-close-noises' onClick={closeNoise}>
          {context.t('close_alert')}
        </button>
      </div>
    );
  };

  const renderAudioElement = (audioUrl) => (
    <audio className='grid-item' src={audioUrl} controls />
  );

  if (
    sensorDetails.nc1Array == null ||
    (sensorDetails.nc1Array.length == 0 && sensorDetails.nc2Array == null) ||
    (sensorDetails.nc2Array.length == 0 && sensorDetails.nc3Array == null) ||
    (sensorDetails.nc3Array.length == 0 && sensorDetails.nc3aveArray == null) ||
    (sensorDetails.nc3aveArray.length == 0 && sensorDetails.barsData == null)
  ) {
    return null;
  }

  const hoverTemplate = '%{y:d}<br>%{text}<extra></extra>';

  const filterTrace = {
    x: xValues,
    y: nc3Data,
    text: times,
    mode: 'lines+markers',
    type: 'scatter',
    name: 'Filtered Intensity',
    hovertemplate: hoverTemplate,
    textposition: 'top center',
    line: {
      color: 'rgb(255, 127, 14)',
    },
    marker: {
      color: 'rgba(255, 255, 255, 100)',
      size: markerSize,
      line: {
        color: 'rgb(255, 127, 14)',
        width: 1,
      },
    },
  };

  const nc1Trace = {
    x: xValues,
    y: nc1Data,
    text: times,
    mode: 'lines+markers',
    type: 'scatter',
    name: 'Minimal',
    hovertemplate: hoverTemplate,
    textposition: 'top center',
    line: {
      color: 'rgb(0, 7, 255)',
    },
    marker: {
      color: 'rgba(255, 255, 255, 100)',
      size: markerSize,
      line: {
        color: 'rgb(0, 7, 255)',
        width: 1,
      },
    },
  };

  const nc2Trace = {
    x: xValues,
    y: nc2Data,
    text: times,
    mode: 'lines+markers',
    type: 'scatter',
    name: 'Sampled',
    hovertemplate: hoverTemplate,
    textposition: 'top center',
    line: {
      color: 'rgb(255, 0, 0)',
    },
    marker: {
      color: 'rgba(255, 255, 255, 100)',
      size: markerSize,
      line: {
        color: 'rgb(255, 0, 0)',
        width: 1,
      },
    },
  };

  const freqBars = {
    x: [1, 2, 3],
    y: props.sensorDetails.barsData,
    type: 'bar',
    marker: {
      color: ['rgb(20, 190, 40)', 'rgb(174, 199, 232)', 'rgb(255, 127, 14)'],
    },
  };

  const plotLayout = {
    showlegend: true,
    showdividers: true,
    width: graphWidth,
    height: graphHeigh,
    // xaxis: {
    //   // autorange: 'reversed',
    //   title: context.t('Samples')
    // },
    yaxis: {
      rangemode: 'tozero',
      title: context.t('intensity'),
    },
    legend: { orientation: 'h' },
    margin: {
      l: 50,
      r: 10,
      b: 40,
      t: 30,
    },
    hoverlabel: {
      // bgcolor: 'lightgrey',
      // bordercolor: 'darkgrey',
      font: {
        // color: 'blue',
        // family: 'Open Sans',
        size: 16,
      },
    },
  };

  const handlePlotMarkerClick = (point) => {
    const index = point.x - 1;
    const item = props.sensorDetails.details[index];
    dispatch(selectSensorSample(props.project, item));
  };

  const project = useSelector((state) => state.leaksList.selectedProject);
  const sensors = useSelector(
    (state) => state.leaksList.leaksByProject?.[project]?.sensors || {}
  );

  const timeZone = useSelector((state) => state.local.timeZone);

  const noiseGraphData = sensors?.g5NoiseGraphData;

  const noiseIntensityGraphData = props.sensorDetails.details;



  let intensitySampleTime = useSelector(
    (state) => state.leaksList.leaksByProject.selectedSensorSample.SampleTime
  );
// Retrieve the DeviceGenerationValue using a single useSelector
  const DeviceGenerationValue = useSelector((state) => {
    // Try to get the value from the primary path
    let value = state.leaksList.leaksByProject[project]?.sensors?.details?.DeviceGenerationValue;

    // If not found, try to get it from the noise alert path
    if (value === undefined || value === null) {
      const selectedSensorID = state.noiseAlerts.selected.SensorID;
      const sensorsArray = state.leaksList.leaksByProject[project]?.sensors?.sensors;
      const sensor = sensorsArray?.find(sensor => sensor.id === selectedSensorID);
      // Access DeviceGenerationValue directly from the sensor object
      value = sensor?.DeviceGenerationValue ?? null;
    }

    // Return the found value or null
    return value;
  });
// console.log('DeviceGenerationValue',DeviceGenerationValue);
// Use the retrieved DeviceGenerationValue
  const isG5Sensor = DeviceGenerationValue === 1;
  const SHOW_NOISE_GRAPH = JSON.parse(process.env.REACT_APP_SHOW_NC1_NOISE_GRAPH) || false;


  return (
    <div className='sample-info'>
      {user.analysesExpert && <SensorInfoAdvanced project={props.project} />}

      <div>
        <i className='icon-close' onClick={closePanel} />
        <div className='reload-noises-parent'>
          <label>{context.t('number_of_samples')}:</label>
          <input
            className='input-field'
            type='number'
            placeholder={context.t('number_of_samples')}
            min='0'
            max='60'
            onChange={handleChange}
            value={input}
          />
          <button className='btn-reload-noises' onClick={reloadNoiseSamples}>
            {context.t('load')}
          </button>
        </div>

        {!_.isEmpty(sensorDetails.noiseAlertDetails) && user.isAQS && (
          <div className='close-noise-parent'>
            <button className='btn-close-noise' onClick={closeNoise}>
              {context.t('close_alert')}
            </button>
          </div>
        )}

        <div className='sample-graphs'>
          {/* Conditionally render IntensityNoiseGraph or Plot */}
          {SHOW_NOISE_GRAPH && isG5Sensor ? (
            <IntensityNoiseGraph
              data={noiseGraphData}
              sampleTime={intensitySampleTime}
              timeZone={timeZone}
            />
          ) : (
            <Plot
              data={[filterTrace]}
              layout={plotLayout}
              onClick={(e) => {
                handlePlotMarkerClick(e.points[0]);
              }}
            />
          )}

          <Plot
            data={[nc1Trace, nc2Trace]}
            layout={plotLayout}
            onClick={(e) => {
              handlePlotMarkerClick(e.points[0]);
            }}
          />

          <Plot
            data={[freqBars]}
            layout={{
              ...plotLayout,
              showlegend: false,
              xaxis: {
                ...plotLayout.xaxis,
                title: 'Frequency Hz',
                type: 'category',
                tickvals: [1, 2, 3],
                ticktext: ['300', '600', '1200'],
              },
            }}
          />

          {false && noiseAlertRender(sensorDetails)}
        </div>
      </div>
      <div style={{ padding: '2px', margin: '2px', textAlign: 'center' }}>
        {props.sampleAudio && (
          // <AudioComponent
          //   audioSrc={props.sampleAudio}
          //   lable={context.t('Sample')}
          // />
          <div className='grid-container'>
            <strong className='grid-item'>{context.t('sample')}</strong>
            {/* <AudioView audioSrc={props.sampleAudio} /> */}
            {renderAudioElement(props.sampleAudio)}
          </div>
        )}
        {props.sampleAudio && (
          // <AudioComponent
          //   audioSrc={props.cleanSampleUrl}
          //   lable={context.t('Clean Sample')}
          // />

          <div className='grid-container'>
            <strong className='grid-item'>{context.t('clean_sample')}</strong>
            {/* <AudioView audioSrc={props.cleanSampleUrl} /> */}
            {renderAudioElement(props.cleanSampleUrl)}
          </div>
        )}
        {props.radioAudio && (
          // <AudioComponent
          //   audioSrc={props.radioAudio}
          //   lable={context.t('Radio')}
          // />

          <div className='grid-container'>
            <strong className='grid-item'>{context.t('radio')}</strong>
            {renderAudioElement(props.radioAudio)}
          </div>
        )}
      </div>
    </div>
  );
};

SensorInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SensorInfo;
