// import { assign } from 'lodash';
import * as types from '../constants/ActionTypes';
const initialState = {
  user: null,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoadingData: false,
  isAdmin: false,
  isAQS: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoggingIn: true,
      });
    case types.LOGIN_FAILED:
      return Object.assign({}, state, {
        isLoggingIn: false,
      });
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoggedIn: true,
        isLoggingIn: false,
      });
    case types.USER_LOGGED_IN: // bad name. have to be like 'REFRESH_USER_TOKEN'
      return Object.assign({}, state, {
        isLoggingIn: true,
        uid: action.uid,
        token: action.payload,
        user: action.user,
      });
    case types.USER_LOGGED_OUT:
      return Object.assign({}, state, {
        user: null,
        uid: false,
        token: false,
        status: false,
        isLoggedIn: false,
        isLoggingIn: false,
      });
    case types.REQUEST_OPTIONS:
      return { ...state, isLoadingData: true };
    case types.RECEIVE_PROJECTS:
      return { ...state, isLoadingData: false };
      // return Object.assign({}, state, {
      //   isLoggedIn: true,
      // });
    case types.SET_EDIT_MODE:
      return Object.assign({}, state, {
        isAdmin: action.userEditMode,
        isAQS: action.isAQS,
      });

    default:
      return state;
  }
};

// const user = {}
export default user;
