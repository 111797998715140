import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import AddTask from '../../components/MobileForms/AddTask';
import * as mobileTasksActions from "../../actions/MobileTasksActions";
const _ = require('lodash');

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, {
  }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doClose: () => {
      ownProps.showAddTaskForm(false);
    },
    onSubmit: async(values, dispatch, props) => {
      const requiredFields = ['TaskName'];

      requiredFields.forEach((fieldKey) => {
        if (values[fieldKey] == null || values[fieldKey] === '') {
          throw new SubmissionError({
            [fieldKey]: 'Require',
            _error: fieldKey + ' is Require'
          });
        }
      });


      dispatch(mobileTasksActions.createNewTask(values, ownProps.showAddTaskForm));
      ownProps.showAddTaskForm(false);

    },
    onCreateNewValue: (valueKey, value) => {
    }
  };
};

const CAddTask = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTask);

export default CAddTask;
