import React from 'react';
import PropTypes from 'prop-types';

import { reduxForm, Field } from 'redux-form';
import { BarLoader } from 'react-spinners';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/system';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import DeleteModal from '../../CouplesTabs/DetailsTab/DeleteModal';
import CoupleView from '../../Custom/CoupleView';
import CouplePathView from '../../Custom/CouplePathView';
import CoupleGenerationControlPanel from '../../Custom/CoupleGenerationControlPanel';
import CoupleFields from '../../Custom/CoupleFields';
import ConfirmRerunDialog from './confirmRerunDIalog';
import { requestRerunCouplesSamples } from '../../../actions/CouplesActions';
import CommentField from '../../CommentField/CommentField';
import Input from '../../Input/Input';


const CoupleDetailsTab = (props, context) => {

  const dispatch = useDispatch();


  const classes = useStyles();
  const [selectedTimeframe, setSelectedTimeframe] = React.useState([]);
  const [selectedTimeframeLabel, setSelectedTimeframeLabel] = React.useState('');
  const [rerunConfirmOpen, setRerunConfirmOpen] = React.useState(false);

  const timeFrameOptions = [
    { value: 7, label: 'One week' },
    { value: 14, label: 'Two weeks' },
    { value: 21, label: 'Three weeks' },
  ];


  const {
    isGenerateMode,
    isOnEditMode,
    initialValues,
    pristine,
    isSubmitting,
    user,
    handleSubmit,
    onDelete,
    setEditPathMode,
    clearCouplePath,
    couplePath,
    validateNewCouple,
    materials,
    distanceFactor,
    onMaterialChange,
    // alertSensorData,
    selectedFeature,
    hasSamples,
    notify,
    onEdit,
    onCopy,
  } = props;


  const handleSetDrawMode = (mode) => {
    props.setEditPathMode(mode);
  }

  const reset = () => {
    props.resetSensors();
  }

  const handleSelectTimeframe = (selectedOption) => {
    if (selectedOption) {
      setSelectedTimeframe(selectedOption.value);
      setSelectedTimeframeLabel(selectedOption.label);
    }
  };

  const handleConfirm = () => {
    setRerunConfirmOpen(false);
    handleSubmitRerun();
  };

  const handleRerunClose = () => {
    setRerunConfirmOpen(false);
  };

  const handleRerunClick = () => {
    if (hasSamples.length === 0) {
      handleSubmitRerun();
    } else {
      setRerunConfirmOpen(true);
    }
  };

  const handleSubmitRerun = async () => {

    try {
      const resData = await dispatch(requestRerunCouplesSamples(selectedFeature, selectedTimeframe));
      if (resData.status) {

        notify(context.t('confirmation_success'), 'success');

      } else {
        console.log('error in res.data handleSubmitRerun');
        notify(context.t('confirmation_error'), 'error');

      }
    } catch (error) {
      console.error('Fetch error:', error);
    }

  };


  const sensorsHasSet = initialValues.Sensor1 != null && initialValues.Sensor2 != null;
  // for times that in deit mode - we cahnge the couple path but the length is not changed-
  // so we change the disable expression only for validateNewCouple attribute;
  const buttonDisable = !validateNewCouple; // (isGenerateMode) ? (!validateNewCouple) : validateNewCouple && pristine;// for edit couple details switch the 'true' with: 'pristine'

  return (
    <form className="sticky-actions couple-details-form" onSubmit={handleSubmit}>
      <div style={{
        position: 'absolute',
        top: '0px',
        width: '100%'
      }}>
        <BarLoader
          color="#4cae4c"
          width="110%"
          loading={isSubmitting}
        />
      </div>

      <CoupleView
        distanceFactor={distanceFactor}
        initialValues={initialValues}
        resetSensors={reset}
        materials={materials}
        user={user}
        editMode={isOnEditMode}
        buttonTitle={context.t('reset')}
        isGenerateMode={isGenerateMode}
        onMaterialChange={onMaterialChange}
        requiredValitate={requiredValitate}
      />

      {!isOnEditMode && <CommentField
        fieldName="Comment"
        featureID={selectedFeature}
        placeholder={context.t('couple_comment')}
        onEdit={() => onEdit('coupleDetails', 'Comment')}
        onCopy={() => onCopy('coupleDetails', 'Comment')}
        disabled={!user.editMode}
      />}

      {user.isAQS && !isOnEditMode && <div className={classes.rerunBox}>
        <div className={classes.selectCon}>
          <div className={classes.selectBox}>
            <Typography component='p' variant='p'>
              {context.t('rerun_historical_data')}
            </Typography>
            <ReactSelect
              className={classes.select}
              options={timeFrameOptions}
              onChange={handleSelectTimeframe}
              placeholder={
                selectedTimeframeLabel
                  ? selectedTimeframeLabel
                  : context.t('select_time_frame')
              }
              value={selectedTimeframe}
            />
          </div>
          <Button
            variant='contained'
            onClick={() => handleRerunClick()}
            className={classes.rerunBtn}
            disabled={selectedTimeframe.length <= 0}
          >
            {context.t('rerun')}
          </Button>
        </div>
        <ConfirmRerunDialog
          handleRerunClose={handleRerunClose}
          handleConfirm={handleConfirm}
          rerunConfirmOpen={rerunConfirmOpen}
        />

      </div>}

      <div className="couple-details-checkbox">
        <div>
          <Field
            style={{ width: '30px' }}
            className="checkbox-control"
            name="is_overlapping"
            component={Input}
            id={initialValues.is_overlapping}
            type="checkbox"
            maxlength={15}
            size={15}
            disabled={!user.editMode} />
        </div>
        <label className="col-xs-4 control-label">{context.t('is_overlapping')}</label>
      </div>


      {((isGenerateMode && sensorsHasSet) || isOnEditMode) &&
        <CoupleGenerationControlPanel
          setDrawMode={handleSetDrawMode}
          generateAutoCouple={props.generateAutoCouple}
          materials={materials}
        />
      }

      {((isGenerateMode && sensorsHasSet) || isOnEditMode) &&
        <CouplePathView
          editCouplePathToggle={setEditPathMode}
          clearCouplePath={clearCouplePath}
          couplePath={couplePath}
          generateMode={isGenerateMode}
        />
      }

      {user.analysesExpert && ((isGenerateMode && sensorsHasSet) || isOnEditMode) &&
        <CoupleFields
          setDrawMode={handleSetDrawMode}
          generateAutoCouple={props.generateAutoCouple}
        />
      }

      <div className="form-actions">
        <button
          className="btn btn-success btn-submit"
          disabled={buttonDisable}
          type="submit">{context.t('save')}</button>
        {!isGenerateMode &&
          <div>
            <a
              className='btn btn-info'
              onClick={() => { props.setEditMode(!isOnEditMode); }}>
              {
                isOnEditMode ? context.t('discard') : context.t('edit')
              }
            </a>

            <DeleteModal
              id={props.initialValues.id}
              name={props.initialValues.id}
              type={'couple'}
              disabled={!user.installerPlanner}
              onDelete={onDelete}
            />
          </div>
        }
      </div>

    </form>
  );
}


CoupleDetailsTab.propTypes = {
  isGenerateMode: PropTypes.bool,
  isOnEditMode: PropTypes.bool,
  initialValues: PropTypes.object,
  pristine: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  user: PropTypes.object,
  handleSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  setEditPathMode: PropTypes.func,
  clearCouplePath: PropTypes.func,
  couplePath: PropTypes.array,
  validateNewCouple: PropTypes.bool,
  materials: PropTypes.array,
  distanceFactor: PropTypes.number,
  resetSensors: PropTypes.func,
  generateAutoCouple: PropTypes.func,
  setEditMode: PropTypes.func,
  onMaterialChange: PropTypes.func
}

CoupleDetailsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

const requiredValitate = (value) => value || value == 0 ? undefined : 'Required';

const coupleDetailsTab = reduxForm({
  form: 'coupleDetails', // a unique name for this form
  enableReinitialize: true,
})(CoupleDetailsTab);
export default coupleDetailsTab;


const useStyles = makeStyles({
  container: {
    minHeight: '300px',
    height: '80vh',
    maxHeight: '80vh',
    backgroundColor: '#ededed',
    padding: '1em',
    overflow: 'hidden',
  },
  rerunBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '2em'
  },
  selectCon: {
    display: 'flex',
    marginRight: '10px',
    flexWrap: 'wrap',
    zIndex: 100,
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
    margin: '0 2em'
  },
  selectBox: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.2em',
  },
  rerunBtn: {
    height: '40px',
    margin: '0 3em'
  },
  select: {
    width: '250px',
    marginTop: '1em',
  },
  btnBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
});
