import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input/Input';
import Select from '../Select/Select';
import { Field, reduxForm } from 'redux-form';

class ProjectAdvancedForm extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         fields: []
      };
   }

   componentDidMount() {
      this.setState({
         fields: [
            {
               type: 'number',
               name: 'CouplesDaysBackForOnlyUpdated',
               label: this.context.t('couples_days_back_for_only_updated')
            },
            {
               type: 'number',
               name: 'SensitivityLevel',
               label: this.context.t('sensitivity_level')
            },
            {
               type: 'checkbox',
               name: 'PossibleUFR',
               label: this.context.t('possible_ufr')
            },
            {
               type: 'checkbox',
               name: 'AllowProd2Run',
               label: this.context.t('allow_prod2_run')
            },
            {
               type: 'checkbox',
               name: 'PossiblePumps',
               label: this.context.t('possible_pumps')
            },
            {
               type: 'checkbox',
               name: 'AllowFalsePositive',
               label: this.context.t('allow_false_positive')
            },
            {
               type: 'number',
               name: 'Prob4Burst',
               label: this.context.t('prob4_burst')
            },
            {
               type: 'number',
               name: 'Prob4Irrigation',
               label: this.context.t('prob4_consumption')
            },
            {
               type: 'checkbox',
               name: 'UseFeetInMatlabGraphs',
               label: this.context.t('use_feet_in_matlab_graphs')
            },
            {
               type: 'number',
               name: 'MultiMaterial',
               label: this.context.t('multi_material')
            },
            {
               type: 'number',
               name: 'MultiDiameter',
               label: this.context.t('multi_diameter')
            },
            {
               type: 'number',
               name: 'balagan_dist_ratio_thr',
               label: this.context.t('balagan_dist_ratio_thr')
            },
            {
               type: 'number',
               name: 'irrig_dist',
               label: this.context.t('irrig_dist')
            },
            {
               type: 'checkbox',
               name: 'MinNc1Days',
               label: this.context.t('min_nc1_days')
            },
            {
               type: 'number',
               name: 'Fmin',
               label: this.context.t('fmin')
            },
            {
               type: 'number',
               name: 'Fmax',
               label: this.context.t('fmax')
            },
            {
               type: 'number',
               name: 'NC3projMean',
               label: this.context.t('nc3_proj_mean')
            },
            {
               type: 'number',
               name: 'NC3StdMeanRatio',
               label: this.context.t('nc3_std_mean_ratio')
            },
            {
               type: 'number',
               name: 'NoiseAlertStepProbThr',
               label: this.context.t('noise_alert_step_prob_thr')
            },
            {
               type: 'number',
               name: 'NoiseAlertConstProbThr',
               label: this.context.t('noise_alert_const_prob_thr')
            },
            {
               type: 'number',
               name: 'sum_included_thr',
               label: this.context.t('sum_included_thr')
            },
            {
               type: 'number',
               name: 'NumBadBurstSamples_thr',
               label: this.context.t('numbadburstsamples_thr')
            },
            {
               type: 'checkbox',
               name: 'IsDisplayCityColumn',
               label: this.context.t('is_display_city_column')
            },
            {
               type: 'number',
               name: 'DaysCorrelationIncative',
               label: this.context.t('days_correlation_incative')
            },
            {
               type: 'number',
               name: 'NoiseOkThreshold',
               label: this.context.t('noise_ok_threshold')
            },
            {
               type: 'checkbox',
               name: 'ExcludeSample1by4flag',
               label: this.context.t('exclude_sample_1_by_4_flag')
            },
            {
               type: 'number',
               name: 'ExcludeSample1by4radius',
               label: this.context.t('exclude_sample_1_by_4_radius')
            },
            {
               type: 'number',
               name: 'ExcludeSample1by4number',
               label: this.context.t('exclude_sample_1_by_4_number')
            },
            {
               type: 'checkbox',
               name: 'DoNotTake0samplesInAverage',
               label: this.context.t('do_not_take_0_samples_in_average')
            },
            {
               type: 'checkbox',
               name: 'OpenNewLeakOn0sample',
               label: this.context.t('open_new_leak_on_0_sample')
            },
            {
               type: 'number',
               name: 'ProjIntFactor',
               label: this.context.t('proj_int_factor')
            },
            {
               type: 'number',
               name: 'ProjIntLenFactor',
               label: this.context.t('proj_int_len_factor')
            },
            {
               type: 'number',
               name: 'PressureValueCoefficient',
               label: this.context.t('prs_calib_multiply')
            },
            {
               type: 'number',
               name: 'pressure_calib_coeff_y_axis',
               label: this.context.t('prs_calib_shift')
            },
            {
               type: 'select',
               name: 'MapPipesDiameterType',
               label: this.context.t('map_pipes_diameter_type'),
               componemt: (props) => (
                  <Select {...props}
                    placeholderTitle={this.context.t('select')}
                    options={[
                      {value: 0, label: 'Inch'},
                      {value: 1, label: 'Millimeter'},
                    ]}
                  />
               )
            },
         ],
      });
   }

   render() {
      const divStyle = { float: 'left', width: '100%' };
      const lsbelClassNameLeftColumn = 'col-xs-4 control-label';
      const fieldsClassLeftColumn = 'col-xs-6 control-input';

      return (
         <div>
            <form
            style={{height: '366px', overflow: 'auto'}}
            className="form-horizontal sticky-actions">
               <div
                  style={{ display: 'flex' }}
               >
                  <div style={divStyle}>
                     {this.state.fields.map((field, index) => {
                        let className;
                        switch (field.type) {
                          case 'select':
                            className = 'col-xs-8 control-input'
                            break;
                          case 'checkbox':
                            className = 'checkbox-control'
                            break;
                          default:
                            className = 'form-control'
                            break;
                        }
                        return (
                           <div
                              key={index}
                              className="form-group">
                              <label
                                 className={lsbelClassNameLeftColumn}>{field.label}:</label>

                              <div className={fieldsClassLeftColumn}>
                                 <Field
                                    name={field.name}
                                    component={field.componemt || Input}
                                    type={field.type}
                                    className={className}
                                 />
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </form>
            <a
               style={{left: '6px', bottom: '6px', position: 'absolute'}}
               className="btn btn-info"
               onClick={this.props.previousPage}>

               {this.context.t('back')}

            </a>
         </div>
      );
   }
}

ProjectAdvancedForm.contextTypes = {
   t: PropTypes.func.isRequired
};

const projectAdvancedForm = reduxForm({
   form: 'projectDetails', // a unique name for this form
   destroyOnUnmount: false, // <------ preserve form data
   forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
   enableReinitialize: true,
 })(ProjectAdvancedForm);

 export default projectAdvancedForm;
