import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import MobileSamplesTable from './MobileSamplesTable';
import CMobileDetailsTab from './../../../containers/CMobileDetailsTab/CMobileDetailsTab';

import Paper from '@mui/material/Paper';
import TabsContainer from '../../Custom/Tabs/TabsContainer';
// import {
//   decToHex,
//   formatMobileDeviceID,
// } from '../../../containers/UIhelper/UIhelper';

const MobileAlertsTabs = (props, context) => {
  const {
    uiInfo,
    selectedFeature,
    featureType,
    showDetails,
    timeZone,
    samples,
    samplesIndexMap,
    displayItems,
    projectName,
    onCreateNewValue,
  } = props;
  const panelStyle = { height: 0.435 * uiInfo.pageHeight };

  const panelClasses = classNames('alert-details', { show: showDetails });

  function closePanel() {
    props.toggleDetailsVisibility(false);
    props.clearSelectedFeature();
  }

  React.useEffect(() => {
    if (featureType === 'mobileAlert') {
      if (selectedFeature) {
        if (!showDetails) {
          props.toggleDetailsVisibility(true);
        }
      } else if (showDetails) {
        props.toggleDetailsVisibility(false);
      }
    } else {
      if (showDetails) {
        props.toggleDetailsVisibility(false);
      }
    }
  }, [featureType, selectedFeature, showDetails]);

  return (
    <Paper className={panelClasses} style={panelStyle}>
      <TabsContainer
        title={context.t('mobile_alert_id_val', {
          id: selectedFeature.alert_id,
        })}
        handleClose={closePanel}
        tabs={[
          {
            label: context.t('details'),
            component: (
              <CMobileDetailsTab
                projectName={projectName}
                onCreateNewValue={onCreateNewValue}
                pageNotification={props.notify}
                getMapCapture={props.getMapCapture}
              />
            ),
          },
          {
            label: context.t('samples'),
            component: (
              <MobileSamplesTable
                setIsAddMobileAlertOpen={props.setIsAddMobileAlertOpen}
                selectedFeature={selectedFeature}
                timeZone={timeZone}
                uiInfo={uiInfo}
                samples={samples}
                samplesIndexMap={samplesIndexMap}
                onSelectMobileSample={props.selectMobileSample}
                selectedSample={props.selectedSample}
                checkedSamples={props.checkedSamples}
                displayItems={displayItems}
                setDisplayItems={props.setDisplayItems}
                setCheckedSamples={props.setCheckedSamples}
                options={props.options}
                notify={props.notify}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

MobileAlertsTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileAlertsTabs;
