import React from 'react';

const CLIENT_ID = '129574208525-bqkqmm3tc346o7iq79dk5rbal98uas1e.apps.googleusercontent.com';
const API_KEY = 'AIzaSyBMiC7cOUf1B0PSaKhceiu75EPUXBLf2gU';
const DISCOVERY_DOC = 'https://sheets.googleapis.com/$discovery/rest?version=v4';
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';

export const GoogleSheetsContextProvider = React.createContext(undefined);
const GoogleSheetsContext = ({ children }) => {
  const useGSheet = useGoogleSheet(API_KEY, CLIENT_ID, DISCOVERY_DOC);
  return (
    <GoogleSheetsContextProvider.Provider value={useGSheet}>
      {children}
    </GoogleSheetsContextProvider.Provider>
  )
}

const useGoogleSheet = (apiKey, clientId, discoveryDoc) => {
  const [isApiLoaded, setApiLoaded] = React.useState(false);
  const [isGisLoaded, setGisLoaded] = React.useState(false);
  const [isAuthenticated, setAuthenticated] = React.useState(false);

  const isInitialized = React.useRef(false);

  const tokenClient = React.useRef(null);


  const init = () => {
    if (!isInitialized.current) {
      _load('https://apis.google.com/js/api.js', () => {
        window.gapi.load('client', _initializeGapiClient);
      });
      _load('https://accounts.google.com/gsi/client', () => {
        tokenClient.current = window.google.accounts.oauth2.initTokenClient({
          client_id: clientId,
          scope: SCOPES,
          callback: '', // defined later
        });
        isInitialized.current = true;
        setGisLoaded(true);
      });
    }
  }

  const authenticate = () => {
    tokenClient.current.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw (resp);
      }
      setAuthenticated(true);
    };

    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.current.requestAccessToken({prompt: 'consent'});
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.current.requestAccessToken({prompt: ''});
    }
  }

  const signout = () => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      window.google.accounts.oauth2.revoke(token.access_token);
      window.gapi.client.setToken('');
    }
  }

  const writeFile = async (fileName, sheetTitle, sheetValues) => {
    try {
      // Step 1: Prepare the values to be added in the columns (2D array format)

      // Step 2: Create the Google Spreadsheet and set values in the default sheet
      const createAndWriteRes = await window.gapi.client.sheets.spreadsheets.create({
        properties: {
          title: fileName,
        },
        sheets: [{
          properties: {
            title: sheetTitle,
          },
          data: [{
            startRow: 0,
            startColumn: 0,
            rowData: sheetValues.map(row => ({
              values: row.map(cellValue => {
                // Check if the cellValue is a number and convert it to a numeric format
                if (!isNaN(cellValue)) {
                  return { userEnteredValue: { numberValue: Number(cellValue) } };
                } else {
                  return { userEnteredValue: { stringValue: cellValue } };
                }
              }),
            })),
          }],
        }],
      });

      const spreadsheetId = createAndWriteRes.result.spreadsheetId; // Get the Spreadsheet ID from the API response

      // console.log('Spreadsheet created and data written successfully.');
      return createAndWriteRes.result;
    } catch (error) {
      throw error;
    }
  };

  const _initializeGapiClient = async () => {
    try {
      await window.gapi.client.init({
        apiKey: apiKey,
        discoveryDocs: [discoveryDoc],
        client_id: clientId,
        scope: SCOPES,
      });
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.debug(error);
      }
    }

    setApiLoaded(true);
  }

  const _load = (url, callback) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;

    script.onload = callback;

    document.head.appendChild(script);
  }

  return {
    isApiLoaded,
    isGisLoaded,
    isAuthenticated,
    isReady: isApiLoaded && isGisLoaded,
    init,
    authenticate,
    signout,
    writeFile,
  };
}

export default GoogleSheetsContext;
