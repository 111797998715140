import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../Custom/Tabs/TabsContainer';
import CCustomerDetailsTab from '../../containers/CCustomerTab/CCustomerDetailsTab';
const classNames = require('classnames');

export default class CustomersTabs extends React.Component {

  constructor(props) {
    super();
    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);
    this.props.clearSelectedFeature();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.manageItem !== null && this.props.manageItem === null) ||
      nextProps.manageItem !== null && this.props.manageItem.id !== nextProps.manageItem.id) {
      this.props.toggleDetailsVisibility(true);
    }
  }

  render() {
    const { showDetails, manageItem, uiInfo, user } = this.props;
    if (manageItem == null || manageItem.item == null) {
      return null;
    }

    const customerName = (manageItem.id == -1) ? this.context.t('New Customer') : manageItem.item.Name;

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
    );
    const panelStyle = { height: 0.493 * uiInfo.pageHeight };

    return (
      <div className={panelClasses} style={panelStyle}>
        <TabsContainer
          title={this.context.t('customer_name_', { name: customerName})}
          handleClose={this.closePanel}
          tabs={[
            {
              label: this.context.t('details'),
              component: (
                <CCustomerDetailsTab
                  user={user}
                  closePanel={this.closePanel}
                  notify={this.props.notify}
                />
              )
            }
          ]}
        />
      </div>
    );
  }
}

CustomersTabs.contextTypes = {
  t: PropTypes.func.isRequired
}
