/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { makeStyles } from '@mui/styles';
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';

require('./style.scss');

const getDateString = (timeMillis) => {
  let strVal = '--';
  if (timeMillis) {
    strVal = UIDateFormater(timeMillis, DateType.DATE_AND_TIME_WITH_SECONDS)
  }
  return strVal;
};

const fontSizeStyle = '1.5rem';
const labelShift = '4px';

const useStyles = makeStyles({
  root: {
    margin: '40px 50px',
  },
  margin: {
    margin: 0.75,
    display: 'block',
    fontSize: fontSizeStyle,
    padding: '4px'
  },
  label: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'teal !important',
    top: labelShift,
    left: labelShift,
  },
  input: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'black !important'
  },
  fields: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    width: 'fit-content',
    margin: '20px auto',
  }
});

const renderAudioElement = (audioUrl) => (
  <audio className='grid-item' src={audioUrl} controls />
);

const G5BitSampleStrip = (props, context) => {
  const classes = useStyles();

  const { item } = props;

  const closePanel = () => {
    props.handleClosePannel('g5-bis-sample-msg');
  };

  return (
    <div className='sample-info g5-bit-sample-msg'>
      <i className="icon-close" onClick={closePanel} />

      <div style={{ display: 'inline-block' }}>
        <Box className={classes.fields}>
          {item && item.audio &&
            <div className='grid-container-bit-samples'>
            { item.audio.radio &&
              <>
                <strong className='grid-item'>{context.t('radio')}</strong>
                {renderAudioElement(item.audio.radio)}
              </>
            }
            { item.audio.radioClean &&
              <>
                <strong className='grid-item'>{context.t('radio_clean')}</strong>
                {renderAudioElement(item.audio.radioClean)}
              </>
            }
            { item.audio.audio &&
              <>
                <strong className='grid-item'>{context.t('audio')}</strong>
                {renderAudioElement(item.audio.audio)}
              </>
            }
            { item.audio.audio &&
              <>
                <strong className='grid-item'>{context.t('audio_clean')}</strong>
                {renderAudioElement(item.audio.audioClean)}
              </>
            }
            </div>
          }
        </Box>
      </div>

    </div>
  );
}

G5BitSampleStrip.contextTypes = {
  t: PropTypes.func.isRequired
};

export default G5BitSampleStrip;
