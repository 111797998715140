/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { graphsBackgroundColor } from '../../constants/Misc';

const CorrelationGraph = (props) => {

  if (props.data == null || props.data[0] == null) {
    return null;
  }

  const factor = props.factor;
  const xAxisValues = props.data[0].map((_, index) => index);
  const mode = (props.showPoints) ? 'lines+markers' : 'lines';

  const config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: [
      'drawrect',
      'zoomIn2d',
      'zoomOut2d',
      'resetScale2d',
      'select2d',
      'lasso2d',
      'toggleSpikelines',
      'resetViewMapbox',
    ]
  };

  let data;

  if (! props.sum) {
    data = props.data.map((correlationArray) => ({
        x: xAxisValues,
        y: correlationArray,
        type: 'scatter',
        line: {shape: 'spline'},
        mode: mode,
        showlegend: false,
      })
    );
  } else {
    const dataArr = [...props.data[0]];
    for (let i = 1; i < props.data.length; i++) {
      const tempArr = props.data[i];

      for (let j = 0; j < dataArr.length; j++) {
        dataArr[j] += tempArr[j];
      }
      // dataArr.forEach((x, index) => {
      //   x = x + tempArr[index];
      // });
    }

    data = [
      {
        x: xAxisValues,
        y: dataArr,
        type: 'scatter',
        line: {shape: 'spline'},
        showlegend: false,
      }
    ];
  }

  const maxValues = data.map((itrData) => Math.max(...itrData.y));
  const maxVal = Math.max(...maxValues);

  const xAxisConfig = {
    title: {
      text: props.xTitle,
      font: {
        color: '#8d8d8d'
      }
    },
    tickfont: {
      size: 10
    },
  };

  if (props.xAxisLabels) {
    xAxisConfig.tickmode = 'array';
    xAxisConfig.tickvals = xAxisValues;
    xAxisConfig.ticktext = props.xAxisLabels;
  }

  const layout = {
    paper_bgcolor: graphsBackgroundColor,
    autosize: false,
    title: {
      text: props.title,
      x: 0.02,
      y: 0.97
    },
    margin: {
      l: 35,
      r: 10,
      b: 40,
      t: 30,
    },
    xaxis: xAxisConfig,
    yaxis: {
      autorange: false,
      range: [0, Math.max(maxVal, 3)],
      title: {
        text: props.yTitle,
        standoff: 40,
        font: {
          color: '#8d8d8d'
        }
      }
    },
    hovermode: 'closest',
  };

  if (! props.large) {
    layout.width = props.small ? 270 : 350;
    layout.height = props.small ? 200 : 240;
  }

  if (props.leaksMarkers) {
    const addedValue = maxVal * 0.05;
    props.leaksMarkers.forEach((locsVal) => {
      //const xVal = Math.round(locsVal / factor) * factor;
      data.push({
        showlegend: false,
        // hoverinfo: 'none',
        x: [locsVal, locsVal],
        // y: [-addedValue, maxVal + addedValue],
        // y: [-addedValue, Math.max(maxVal + addedValue, 3)],
        y: [-0.05, Math.max(maxVal + addedValue, 3)],
        mode: 'lines',
        line: {
          color: '#ff000088',
        }
      });
    });
  }

  if (props.sensorsOnAxis) {
    data.push({
      showlegend: false,
      name: 'Sensor',
      x: props.sensorsOnAxis,
      y: [0, 0],
      type: 'scatter',
      mode: 'markers',
      marker: {
        symbol: 'diamond-tall',
        size: 15,
        color: 'green',
        opacity: 0.5
      }
    });
  }

  const plotConfig = (props.small) ?
  {...config, modeBarButtonsToRemove: [...config.modeBarButtonsToRemove, 'pan2d','zoom2d','toImage']}
  : config;

  return (
    <Plot
      layout={layout}
      config={plotConfig}
      data={data}
    />
  );
};

CorrelationGraph.propTypes = {
  data: PropTypes.array.isRequired,
  factor: PropTypes.number.isRequired,
  showPoints: PropTypes.bool,
  sum: PropTypes.bool,
  title: PropTypes.string,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
};

CorrelationGraph.defaultProps = {
  sum: false
};

export default CorrelationGraph;
