import React from 'react';
import PropTypes from 'prop-types';

import CustomerDistributionListsModal from './CustomerDistributionListsModal';
import PhoneInputComp from '../Input/PhoneInputComp';
import Input from '../Input/Input';
import { Field, reduxForm } from 'redux-form';
import { ENV, ENVIRONMENTS } from "../../constants/EndPoints";

class CustomerDetails extends React.Component {

  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      distributionListManageOpen: false
    };
  }

  openModal() {
    this.setState({
      distributionListManageOpen: true
    });
  }

  closeModal() {
    this.setState({
      distributionListManageOpen: false
    });
  }

  render() {
    const { handleSubmit, user, pristine, submitting, isNew, userEditMode } = this.props;
    const labelClassName = 'col-xs-4 control-label';
    const controlClassName = 'col-xs-6 control-input';
    const buttonTitle = (isNew) ? this.context.t('Create New Customer') : this.context.t('update_customer_details');
    return (
      <div>
        <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('customer_name')}:</label>
            <div className={controlClassName}>
              <Field name="Name" component={Input} type="text" className="form-control" disabled={!isNew} />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('contact_person')}:</label>
            <div className={controlClassName}>
              <Field name="ContactPerson" component={Input} type="text" className="form-control"
              // disabled={!isNew}
              />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('contact_email')}:</label>
            <div className={controlClassName}>
              <Field name="ContactMail" component={Input} type="email" className="form-control"
              // disabled={!isNew}
              />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('contact_phone_1')}:</label>
            <div className={controlClassName}>
              <Field name="ContactPhone1" component={PhoneInputComp} type="tel" className="tel-input" />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('contact_phone_2')}:</label>
            <div className={controlClassName}>
              <Field name="ContactPhone2" component={PhoneInputComp} type="tel" className="tel-input" />
            </div>
          </div>

          <div className="form-actions">
            <button
              className="btn btn-success btn-submit"
              disabled={pristine || submitting}
              type="submit">
              {buttonTitle}
            </button>

            <a
              style={{ float: 'left' }}
              className="btn btn-info btn-submit"
              onClick={this.openModal}
              disabled={!userEditMode}
            >
              {this.context.t('manage_distribution_lists')}
            </a>
            {JSON.parse(process.env.REACT_APP_FILES) && user.isAQS && <a
              style={{ float: 'left', marginLeft: '10px' }}
              className="btn btn-info"
              onClick={this.props.configureCustomerBucket}
              disabled={!userEditMode}
            >
              {this.context.t('Configure Bucket')}
            </a>}
          </div>
        </form>

        <CustomerDistributionListsModal
          open={this.state.distributionListManageOpen}
          closeFunction={this.closeModal}
          notify={this.props.notify}
          isProjectLevel={false}
        />
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const requireFields = ['Name', 'ContactPerson', 'ContactMail', 'ContactPhone1'];

  requireFields.forEach((key) => {
    if (values[key] == null) {
      errors[key] = 'Can\'t be empty';
    }
  });

  return errors;
};

CustomerDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

const customerDetails = reduxForm({
  form: 'customerDetails', // a unique name for this form
  enableReinitialize: true,
  validate,
})(CustomerDetails);

export default customerDetails;
