import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';

import { types } from '../../../constants/G5MessagesTypes';
import './style.scss';

const fontSizeStyle = '1.5rem';

const useStyles = makeStyles({
  root: {
    margin: '5px 10px',
  },
  margin: {
    margin: '3px 5px',
    display: 'block',
    fontSize: fontSizeStyle,
    padding: '5px',
  },
  largeFont: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
  },
  fields: {
    padding: '5px 0',
    display: 'grid',
    gridTemplateColumns: '140px 100px',
    gridRowGap: '3px',
  },
  errorField: {
    backgroundColor: '#ffbbbb',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '100px 140px',
    gridRowGap: '3px',
    columnGap: '5px',
  },
  labelStyle: {
    minInlineSize: 'max-content',
  },
});

export default function OperationForm(props, context) {
  const { fields, type } = props;

  const classes = useStyles();
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    setError,
    errors,
    clearErrors,
    watch,
  } = useForm();

  React.useEffect(() => {
    fields.forEach((field) => {
      const val = field.attr && field.attr.defaultValue;
      setValue(field.id, val);
    });
  }, [fields, setValue]);

  const checkValues = (values) => {
    const maxTotal = 1380;
    return (
      // values.corrInterval * values.corrNumSamples +
      values.noiseInterval * values.noiseNumSamples > maxTotal
    );
  };

  const submitFunc = handleSubmit((data) => {
    const values = getValues();
    let haveErrors = false;
    if (props.type === types.Scheduler) {
      if (checkValues(values)) {
        haveErrors = true;
        props.notify('sum interval is to big', 'error');
      }
    }
    if (!haveErrors) {
      props.onSubmit(data);
    }
  });
  return (
    <form className={classes.root} onSubmit={submitFunc}>
      <Box component='fieldset' className={classes.fields}>
        {fields.map((field) => {
          switch (field.type) {
            case 'select':
              return (
                <React.Fragment key={field.id}>
                  <label htmlFor={field.id}>{field.title}</label>
                  <select
                    ref={register}
                    id={field.id}
                    name={field.id}
                    disabled={field.disabled || false}
                    {...field.attr}
                  >
                    {field.options?.map((option) => (
                      <option
                        key={option.optionKey}
                        value={option?.value || option.optionKey}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </React.Fragment>
              );
            default:
              return (
                <React.Fragment key={field.id}>
                  <label className={classes.labelStyle} htmlFor={field.id}>
                    {field.title}
                  </label>
                  <input
                    ref={register}
                    id={field.id}
                    name={field.id}
                    type={field.type || 'text'}
                    className={errors[field.id] && classes.errorField}
                    placeholder={errors[field.id] && errors[field.id].message}
                    onChange={() => errors[field.id] && clearErrors(field.id)}
                    disabled={field.disabled || false}
                    {...(field.attr || null)}
                  />
                </React.Fragment>
              );
          }
        })}
      </Box>
      <Box className={classes.buttons}>
        <Button
          className={classes.largeFont}
          variant='contained'
          color='primary'
          type='submit'
          disabled={!props.enable}
        >
          {context.t('submit')}
        </Button>
        <Button
          className={classes.largeFont}
          variant='contained'
          color='inherit'
          disabled={!props.advancedOptions}
          onClick={() => {
            clearErrors();
            const values = getValues();
            let haveErrors = false;
            if (props.type === types.Scheduler) {
              if (checkValues(values)) {
                haveErrors = true;
                props.notify('sum interval is to big', 'error');
              }
            }

            Object.entries(values).forEach(([paramKey, value]) => {
              if (!value) {
                if (!haveErrors) haveErrors = true;

                setError(paramKey, {
                  type: 'required',
                  message: context.t('required'),
                });
              }
            });

            if (!haveErrors) {
              props.onSaveDefault(values);
            }
          }}
        >
          {context.t('set_default')}
        </Button>
      </Box>
    </form>
  );
}

OperationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

OperationForm.propTypes = {
  advancedOptions: PropTypes.bool,
  enable: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveDefault: PropTypes.func,
  notify: PropTypes.func.isRequired, // Add this line
};
