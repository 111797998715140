import React from 'react';
import PropTypes from 'prop-types';

import AppModal from '../Modals/AppModal';

import LeakGrowth from '../AlertsTabs/LeakGrowth';

export default class LeakGrowthModal extends React.Component {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const { data, isOpen, alertId } = this.props;

    return (
      <AppModal
        closeIcon
        open={isOpen}
        onClose={this.onClose}
        title={this.context.t('alert_id_param', { id: alertId })}
        content={
          <LeakGrowth
            data={data || []}
          />
        }
      />
        // <Modal
        //   show={isOpen}
        //   onHide={this.onClose}
        //   aria-labelledby="ModalHeader"
        //   dialogClassName="dma-history-modal"
        // >
        //   <Modal.Header closeButton>
        //     <Modal.Title id='ModalHeader'>
        //       <h1>{this.context.t('Alert ID {id}', { id: alertId })}</h1>
        //     </Modal.Title>
        //   </Modal.Header>
        //   <Modal.Body>
        //     <LeakGrowth
        //       data={data || []}
        //     />
        //   </Modal.Body>
        // </Modal>
    );
  }
}

LeakGrowthModal.contextTypes = {
  t: PropTypes.func.isRequired
};
