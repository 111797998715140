import React from 'react';
import PropTypes from 'prop-types';

import './Menu.scss';

class InstallMenu extends React.Component {

  render() {
    //equal to (for example) const addNewInstallItem = this.props.addNewInstallItem
      const { addNewInstallItem, onChange, installContext } = this.props;
    return (
      <div className="manage-menu">
        { (installContext == 'SOPs' || installContext == 'Interferences') &&
          <button
            className="btn-success create-item"
            onClick={() => {
              addNewInstallItem(installContext);
            }}
          >
            <i title={this.context.t('add')} />
          </button>
        }

        {/* SOP's button */}
        <button
          className="btn-menu SOPs"
          onClick={() => {
            onChange('SOPs');
          }
          }
        >
          <i title={this.context.t('sops')} />
        </button>

        {/* Sensors button */}
        <button
          className="btn-menu Sensors"
          onClick={() => {
            onChange('Sensors');
          }
          }
        >
          <i title={this.context.t('sensors')} />
        </button>

        {/* Interference button */}
        <button
          className="btn-menu interference"
          onClick={() => {
            onChange('Interferences');
          }}>
            <i title={this.context.t("interferences")} />
          </button>
      </div>
);
  }
}

InstallMenu.contextTypes = {
  t: PropTypes.func.isRequired
};

export default InstallMenu;
