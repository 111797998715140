import React from 'react';
import PropTypes from 'prop-types';

import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';

import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const _ = require('lodash');

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

export default class PivotTableComponent extends React.Component {

   static get propTypes() {
      return {
         sortedAlertsDataSet: PropTypes.array
      };
   }

   constructor(props) {
      super(props);

      const pivotTableState = {
         data: this.props.sortedAlertsDataSet, // data to be summarized
         rows: ['PlacementYear'],
         cols: ['Material'],
         aggregatorName: 'Count',
         rendererName: 'Table Heatmap',
         plotlyOptions: {
            width: 1000,
            height: 400},
         plotlyConfig: {},
         tableOptions: {
            clickCallback: (e, value, filters, pivotData) => {
               this.props.selectAlertsByFilters(filters);
            },
         }
      };
      this.state = {
         mode: 'Inspect',
         filename: 'Sample Dataset: Tips',
         pivotState: pivotTableState
      };
   }

   componentWillReceiveProps(nextProps) {
      const { pivotState } = nextProps;
      if (!_.isEqual(this.props.pivotState, pivotState)) {
         const newState = Object.assign({}, this.state, {
            pivotState: Object.assign({}, this.state.pivotState, pivotState)
         });
         this.setState(newState);
      }
   }

   render() {
      const style = {
         overflow: 'auto',
         maxHeight: '800px',
         maxWidth: '1450px',
      };
      return (
         <div className='analysis-pivottable'>
            <PivotTableUI style={style}
               onChange={(s) => {
                  this.setState({ pivotState: s });
                  // console.log(s);
               }}
               renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
               {...this.state.pivotState}
               data={this.props.sortedAlertsDataSet}
               unusedOrientationCutoff={Infinity}
            />
         </div>
      );
      // {false &&
         // <button onClick={ () => { importAlertInvestigation(selectedProject); }}>{this.context.t('Alerts')}</button>
      // }
   }

}
