import React from 'react';
import PropTypes from 'prop-types';

import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';

export default class Input extends React.Component {
  render() {
    const { disabled, input, label, type, className, meta: { touched, error }, timeZone, placeholder, distanceFactor } = this.props
    if (type != null) {
      switch (type) {
        case 'date':
          input.value = UIDateFormater(input.value, DateType.DATE, timeZone) || '';
          break;
        case 'time':
          input.value = UIDateFormater(input.value, DateType.TIME, timeZone) || '';
          break;
      }
    }
    if (distanceFactor != null) {
      input.value = (input.value * distanceFactor).toFixed();
    }

    return (
      <div className="tooltip-cont">
        <input
          className={className}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          autoComplete="off"
          {...input}
          />
        {touched && error && <span className="tooltip">{error}</span>}
      </div>
    );
  }
}

Input.contextTypes = {
  t: PropTypes.func.isRequired
};
