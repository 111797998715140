import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// Extend String prototype (if needed)
String.prototype.splice = function(idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

export default function InputField(props) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='login-input-field'>
      <label htmlFor={props.name}>{props.label}</label>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <input
          id={props.name}
          name={props.name}
          type={showPassword && props.type === 'password' ? 'text' : props.type}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          style={{ flexGrow: 1, paddingRight: '40px' }} // Ensure enough space for the icon
        />
        {props.type === 'password' && (
          <button
            type="button"
            onClick={toggleShowPassword}
            style={{
              position: 'absolute',
              right: '10px',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </button>
        )}
      </div>
      {props.error && <p>{props.error}</p>}
    </div>
  );
}

InputField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};
