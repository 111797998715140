/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-pascal-case */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import UIDateFormater, {
  DateType,
} from '../../containers/UIhelper/UIDateFormater';
import BIAS from './BIAS';
import SensorsNoiseGraph from './SensorsNoiseGraph';
import SpectrogramGraph from './SpectrogramGraph';

function PreviousGraps(props, context) {
  const getTitle = (index) => {
    const relatedItem = props.historyNoiseData[index];
    let strValue = '--';
    if (relatedItem != null) {
      const date = UIDateFormater(
        relatedItem[0],
        DateType.SHORT_DATE,
        props.timeZone
      );
      const sensor1Nc1 = relatedItem[1] ? relatedItem[1] : '--';
      const sensor2Nc1 = relatedItem[2] ? relatedItem[2] : '--';
      const sensor1Nc2 = relatedItem[3] ? relatedItem[3] : '--';
      const sensor2Nc2 = relatedItem[4] ? relatedItem[4] : '--';

      strValue = `#${index} ${date}, Nc1/Nc2: ${sensor1Nc1}/${sensor1Nc2}, ${sensor2Nc1}/${sensor2Nc2}`;
    }
    return strValue;
  };

  const getHistoryParams = (index) => {
    const relatedItem = props.historyNoiseData[index];

    const data = {
      date: '--',
      s1Nc1: '--',
      s1Nc2: '--',
      s2Nc1: '--',
      s2Nc2: '--',
    };
    if (relatedItem != null) {
      data.date = UIDateFormater(
        relatedItem[0],
        DateType.SHORT_DATE,
        props.timeZone
      );
      data.s1Nc1 = relatedItem[1] ? relatedItem[1] : '--';
      data.s2Nc1 = relatedItem[2] ? relatedItem[2] : '--';
      data.s1Nc2 = relatedItem[3] ? relatedItem[3] : '--';
      data.s2Nc2 = relatedItem[4] ? relatedItem[4] : '--';
    }
    return data;
  };

  const Cell = (props) => (
    <TableCell style={{ color: 'white' }} align='center' {...props}>
      {props.value}
    </TableCell>
  );

  return (
    <div>
      <div className='history-graphs'>
        {props.displayType === 'instance' && (
          <SensorsNoiseGraph
            title={context.t('noise_NC1_NC2')}
            titleY={context.t('intensity')}
            titleX={context.t('date')}
            historyNoiseData={props.historyNoiseData}
            timeZone={props.timeZone}
            small
          />
        )}

        <SpectrogramGraph
          graphTitle={context.t('average_last')}
          titleSize={11}
          data={props.histogramAve}
          xValues={{
            start: props.graphParams.freq.start,
            step: props.graphParams.freq.step,
          }}
          yAxisLabels={props.graphsDistancesForAxis}
          xTitle={context.t('frequency')}
          yTitle={context.t('distance')}
          small
        />

        {props.bias && props.bias[0] && (
          <BIAS
            title={'bias'}
            data={props.bias}
            xTitle={context.t('bands')}
            yTitle={context.t('days')}
            small
            hoverMode
          />
        )}

        {/* todo lia 263 */}

        {props.historyData &&
          props.historyData.map((itrData, index) => {
            const included = itrData.included;

            const itrDate = UIDateFormater(
              itrData.date,
              DateType.DATE_AND_TIME_WITH_SECONDS,
              props.timeZone
            );
            const params = getHistoryParams(index);

            const tooltipContent = (
              <Box>
                <Typography variant='h4' component='h4' align='center'>
                  {itrDate}
                </Typography>
                <Divider
                  style={{ background: 'white', opacity: 0.4 }}
                  variant='middle'
                  light
                />
                <Table aria-label={context.t('sensors_nc_table')} size='small'>
                  <TableHead>
                    <TableRow>
                      <Cell />
                      <Cell value='Sensor 1' />
                      <Cell value='Sensor 2' />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <Cell component='th' scope='row' value='Nc1' />
                      <Cell value={params.s1Nc1} />
                      <Cell value={params.s2Nc1} />
                    </TableRow>
                    <TableRow>
                      <Cell component='th' scope='row' value='Nc2' />
                      <Cell value={params.s1Nc2} />
                      <Cell value={params.s2Nc2} />
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            );

            return (
              <Tooltip
                key={index}
                className='prev-graphs-with-tooltip'
                placement='top'
                enterDelay={1000}
                title={tooltipContent}
              >
                <div>
                  <SpectrogramGraph
                    graphTitle={getTitle(index)}
                    titleSize={11}
                    data={itrData.histogram}
                    xValues={{
                      start: props.graphParams.freq.start,
                      step: props.graphParams.freq.step,
                    }}
                    yAxisLabels={props.graphsDistancesForAxis}
                    xTitle={context.t('frequency')}
                    yTitle={context.t('distance')}
                    small
                    included={included}
                  />
                </div>
              </Tooltip>
            );
          })}
      </div>
    </div>
  );
}

PreviousGraps.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PreviousGraps;
