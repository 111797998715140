import { connect } from 'react-redux';
import { editComment, copyComment, getAddressByLatLong } from '../../actions/GlobalsActions';
import { updateSensor } from '../../actions/SensorsActions';

import SensorTab from '../../components/SensorsTabs/SensorTab/SensorTab';

const diff = require('deep-diff').diff;

const mapStateToProps = (state, ownProps) => {
    const { selectedProject, user, } = state.leaksList;
    const selectedFeature = state.leaksList.leaksByProject.selectedFeature;
    if (selectedProject) {
        const leaks = state.leaksList.leaksByProject[selectedProject];
        if (leaks && selectedFeature && leaks.sensors && leaks.sensors.sensors && leaks.sensors.indexMap) {
            const projectSensors = leaks.sensors;
            const images = leaks.images;
            const sensor = projectSensors.sensors[projectSensors.indexMap[selectedFeature]];
            const projectsList = state.leaksList.projectsList;
            const projectInfo = projectsList.items[projectsList.projectIndexMap[selectedProject]];

            let fileDetails = false;
            if (projectSensors.details) {
                let fileName = false;
                let originalFileName = false;
                if (projectSensors.details != null) {
                    if (projectSensors.details.fileName != null) {
                        fileName = projectSensors.details.fileName;
                    }
                    if (projectSensors.details.originalFileName != null) {
                        originalFileName = projectSensors.details.originalFileName;
                    }
                }

                fileDetails = {
                    fileName,
                    originalFileName,
                    sampleTime: projectSensors.details.sampleTime,
                    deviceID: projectSensors.details.deviceID,
                };
            }

            if (sensor != null && sensor.ConfigRecordingTime != null) {
              sensor.ConfigRecordingTimeStr = sensor.ConfigRecordingTime + (' [sec]');
            }

            return Object.assign({}, {
                user,
                selectedFeature,
                selectedProject,
                timeZone: state.local.timeZone,
                initialValues: sensor,
                fileDetails,
                images
            }, ownProps);
        }
    }

    return { user };
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {

      var differences = diff(values, props.initialValues);
      let res = {};
      differences.forEach((diff) => {
        res[diff.path[0]] = diff.lhs
      })
      res.SOPointID = props.initialValues.SOPointID;
      if (props.initialValues.DeviceID) {
        res.DeviceID = props.initialValues.DeviceID;
      }
      dispatch(updateSensor(res));
    },
    onEdit: (proxi, object, event) => {
      dispatch(editComment('sensor', 'MaintenanceComment'));
      return true;
    },
    onCopy: (proxi, object, event) => {
      dispatch(copyComment('sensor', 'MaintenanceComment'));
      return true;
    },
    getAddressByLatLong: (proxi, object, event) => {
      dispatch(getAddressByLatLong('sensor', 'StreetAddress'));
      return true;
    },
  }
}

const CSensorTab = connect(
    mapStateToProps,
    mapDispatchToProps
)(SensorTab);

export default CSensorTab
