import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ConfirmRerunDialog = (props, context) => {

    const StyledDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialog-paper': {
            width: '30%',
            maxWidth: 'none',
            padding: '20px',
            zIndex: 3000,
        },
    }));

    return (
        <div className='confirm-rerun-dialog'>

            <React.Fragment>
                <StyledDialog
                    open={props.rerunConfirmOpen}
                    onClose={props.handleRerunClose}
                    aria-labelledby="confirm-rerun-dialog-title"
                    aria-describedby="confirm-rerun-dialog-description"
                    fontSize="20px !important"
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '30%',
                            maxWidth: 'none',
                            padding: '20px',
                            zIndex: 3000,
                            fontSize: '1.7em !important',
                        },
                    }}
                >
                    <DialogTitle
                        id="confirm-rerun-dialog"
                        sx={{
                            fontSize: '1em',
                        }}
                    >
                        {context.t('are_you_sure')}
                    </DialogTitle>
                    <DialogContent>
                        <p style={{ fontSize: '0.7em', color: '#919191', fontWeight: 500 }}> {context.t('this_action_will_rerun_override')}.</p>
                        {/* <DialogContentText id="confirm-dialog-description">
                            {context.t('this_action_will_rerun_override')}.
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                fontSize: '0.5em',
                            }}
                            onClick={props.handleRerunClose} color="primary">
                            {context.t('cancel')}
                        </Button>
                        <Button
                            sx={{
                                fontSize: '0.5em',
                            }}
                            onClick={props.handleConfirm} color="primary" autoFocus>
                            {context.t('yes')}
                        </Button>
                    </DialogActions>
                </StyledDialog>
            </React.Fragment>
        </div>
    );
}

ConfirmRerunDialog.propTypes = {
    rerunConfirmOpen: PropTypes.bool.isRequired,
    handleRerunClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
};

ConfirmRerunDialog.contextTypes = {
    t: PropTypes.func.isRequired
};

export default ConfirmRerunDialog;