import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const hardBorderStyle = '3px solid #777';
const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '400px',
    marginTop: 2,
  },
  table: {
    minWidth: 700,
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiTableCell-root:nth-child(even)': {
      borderLeft: hardBorderStyle,
    },
    '& th': {
      fontWeight: 'bold',
      backgroundColor: 'aliceblue',
    },
  },
  tableHeader: {
    '& .MuiTableCell-root': {
      borderLeft: hardBorderStyle,
    },
  },
  divider: {
    height: '4px',
    backgroundColor: '#37adb5',
    borderRadius: '5px',
  },
});

function getProbFinalItem(data, key) {
  if (data && data.prob_final && data.prob_final[key]) {
    return data.prob_final[key];
  } else {
    return {};
  }
}

function getProbItem(data, key) {
  if (data.prob_res && data.prob_res[key]) {
    return data.prob_res[key];
  } else {
    return {};
  }
}

function AnalysisDetails(props, context) {
  const classes = useStyles();

  if (props.analysisData == null || props.analysisData[0] == null) {
    return null;
  }

  const analysis0 = props.analysisData[0]['leak_list'][0] || {};
  const analysis1 = props.analysisData[0]['leak_list'][1] || {};
  const analysis2 = props.analysisData[0]['leak_list'][2] || {};

  const dataKeys = analysis0.prob_final
    ? Object.keys(analysis0.prob_final)
    : [];

  const resKeys = analysis0.prob_res ? Object.keys(analysis0.prob_res) : [];

  const distanceCalc0 = analysis0.distance * props.distanceFactor;
  const distanceCalc1 = analysis1.distance * props.distanceFactor;
  const distanceCalc2 = analysis2.distance * props.distanceFactor;

  const simpleDistance0 =
    analysis0.instance_distance_good * props.distanceFactor;
  const simpleDistance1 =
    analysis1.instance_distance_good * props.distanceFactor;
  const simpleDistance2 =
    analysis2.instance_distance_good * props.distanceFactor;

  const calcDistanceValue = (val) => {
    if (typeof val === 'number' && !Number.isNaN(val)) {
      return val.toFixed(1);
    } else {
      return 'no data';
    }
  };

  return (
    <Box className={classes.root} overflow='auto'>
      {/* <pre style={{ textAlign: 'start' }}>
        <code>
          {JSON.stringify(props.analysisData, null, 4)}
        </code>
      </pre> */}
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell />
            <TableCell align='center' colSpan={2}>
              {context.t('peak_0', {
                ndx: analysis0.max_probability_ndx,
                maxProb: analysis0.max_probability,
              })}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {context.t('peak_1', {
                ndx: analysis1.max_probability_ndx,
                maxProb: analysis1.max_probability,
              })}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {context.t('peak_2', {
                ndx: analysis2.max_probability_ndx,
                maxProb: analysis2.max_probability,
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableHeader} hover>
            <TableCell component='th' align='right'>
              {context.t('weighted_distance')}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {calcDistanceValue(distanceCalc0)}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {calcDistanceValue(distanceCalc1)}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {calcDistanceValue(distanceCalc2)}
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableHeader} hover>
            <TableCell component='th' align='right'>
              {context.t('sample_distance')}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {calcDistanceValue(simpleDistance0)}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {calcDistanceValue(simpleDistance1)}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {calcDistanceValue(simpleDistance2)}
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableHeader} hover>
            <TableCell component='th' align='right'>
              {context.t('probability')}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {analysis0.max_probability}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {analysis1.max_probability}
            </TableCell>
            <TableCell align='center' colSpan={2}>
              {analysis2.max_probability}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={7}>
              <Divider className={classes.divider} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component='th' />
            <TableCell component='th' align='center'>
              {context.t('value')}
            </TableCell>
            <TableCell component='th' align='center'>
              {context.t('result')}
            </TableCell>
            <TableCell component='th' align='center'>
              {context.t('value')}
            </TableCell>
            <TableCell component='th' align='center'>
              {context.t('result')}
            </TableCell>
            <TableCell component='th' align='center'>
              {context.t('value')}
            </TableCell>
            <TableCell component='th' align='center'>
              {context.t('result')}
            </TableCell>
          </TableRow>

          {dataKeys.map((key, index) => (
            <TableRow key={`${key}-${index}`} hover>
              <TableCell component='th' align='right'>
                {key}
              </TableCell>
              <TableCell align='center'>
                {getProbFinalItem(analysis0, key).value}
              </TableCell>
              <TableCell align='center'>
                {getProbFinalItem(analysis0, key).result}
              </TableCell>
              <TableCell align='center'>
                {getProbFinalItem(analysis1, key).value}
              </TableCell>
              <TableCell align='center'>
                {getProbFinalItem(analysis1, key).result}
              </TableCell>
              <TableCell align='center'>
                {getProbFinalItem(analysis2, key).value}
              </TableCell>
              <TableCell align='center'>
                {getProbFinalItem(analysis2, key).result}
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell colSpan={7}>
              <Divider className={classes.divider} />
            </TableCell>
          </TableRow>

          {resKeys.map((key, index) => (
            <TableRow key={`${key}-${index}`} hover>
              <TableCell component='th' align='right'>
                {key}
              </TableCell>
              <TableCell align='center'>
                {getProbItem(analysis0, key).value}
              </TableCell>
              <TableCell align='center'>
                {getProbItem(analysis0, key).result}
              </TableCell>
              <TableCell align='center'>
                {getProbItem(analysis1, key).value}
              </TableCell>
              <TableCell align='center'>
                {getProbItem(analysis1, key).result}
              </TableCell>
              <TableCell align='center'>
                {getProbItem(analysis2, key).value}
              </TableCell>
              <TableCell align='center'>
                {getProbItem(analysis2, key).result}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

AnalysisDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

AnalysisDetails.propTypes = {
  analysisData: PropTypes.array,
  classes: PropTypes.object.isRequired,
  distanceFactor: PropTypes.number,
};

export default AnalysisDetails;
