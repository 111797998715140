import * as types from '../constants/ActionTypes';

const initialState = {
  samples: {
    total: 0,
    data: []
  },
  isFetching: false,
  selectedSample: {},
  graphsData: null,
  audioData: [],
  tensor: {},
  trend: {},
  analysisData: [],
  probability: null
};

function samples(state = initialState, action) {
  switch (action.type) {
    case types.SELECT_PROJECT:
    case types.SELECT_LEAK:
    case types.SELECT_COUPLE_ALERT:
    case types.ASSESSMENT_SET_SHOW_ONLY_REAL:
      return initialState;

    case types.REQUEST_SAMPLES:
      return {
        ...initialState,
        isFetching: true
      };
    case types.RECEIVE_SAMPLES:
      return {
        ...state,
        isFetching: false,
        samples: {
          total: action.samples.total,
          data: action.samples.data,
          pageIndex: action.pageIndex,
        }
      };

    case types.SELECT_SAMPLE:
      return {
        ...state,
        selectedSample: action.selectedSample
      };
    case types.SELECT_COUPLE_SAMPLE:
      return {
        ...state,
        selectedSample: action.selectedCoupleSample
      };
    case types.REQUEST_SAMPLE_OUTPUT_DATA:
      return {
        ...state,
        graphsData: null
      };
    case types.RECEIVE_SAMPLE_OUTPUT_DATA:
      return {
        ...state,
        graphsData: action.rawData
      };
    case types.REQUEST_DEVICE_MESSAGE_DATA:
      return {
        ...state,
        audioData: []
      };

    case types.RECEIVE_DEVICE_MESSAGE_DATA:
      return {
        ...state,
        audioData: action.data
      };

    case types.UPDATE_AUDIO_CLASSIFICATION: {
      const newAudioData = [...state.audioData];
      newAudioData.forEach((data) => {
        if (data.ID === action.sampleId) {
          data.AudioClassification = action.classification;
        }
      });
      return {
        ...state,
        audioData: newAudioData
      };
    }
    case types.REQUEST_DEVICE_PROBABILITY_DATA:
      return {
        ...state,
        probability: null
      };

    case types.RECEIVE_DEVICE_PROBABILITY_DATA:
      return { ...state, analysisData: action.payload };
      // if (action.payload
      //   && action.payload.prob_data
      //   && action.payload.prob_data.prob_final
      //   && action.payload.prob_res) {
      //   return {
      //     ...state,
      //     probability: {
      //       final: action.payload.prob_data.prob_final,
      //       res: action.payload.prob_res
      //     }
      //   };
      // } else {
      //   return state;
      // }

    case types.REQUEST_TENSOR_DATA:
      return {
        ...state,
        tensor: {}
      };

    case types.RECEIVE_TENSOR_DATA:
      return {
        ...state,
        tensor: action.payload.tensor,
        trend: action.payload.trendData,
      };

    default:
      return state;
  }
}

export default samples;
