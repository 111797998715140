import { dateFields } from '../../constants/Misc';
export function getUIinfo(opts) {
  // opts may contain:
  //  minWidth - number, default 0
  opts = opts || {};

  // Calculate screen available space.
  const headerHeight = 111;
  const scrollBarHeight = 10;
  return {
    pageHeight: window.innerHeight - headerHeight - scrollBarHeight,
    pageWidth: Math.max(window.innerWidth, opts.minWidth || 0)
  };
}

export function dateFieldsToLocalTime(items) {
  items.forEach((obj) => {
    for(let prop in obj) {
      if (dateFields.indexOf(prop) !== -1 && obj.hasOwnProperty(prop)) {
        if (obj[prop]) {
          obj[prop] = convertToLocalTime(obj[[prop]]);
        }
      }
    }
  });
  return items;
}

export function convertToLocalTime(timestamp) {
  return timestamp;
  // let date = new Date(timestamp * 1000);
  // return date.toLocaleString();
}

/* global BigInt */
export const decToHex = (dec) =>
  BigInt(dec).toString(16).toUpperCase();

export const formatMobileDeviceID = (deviceId) => {
  if (!deviceId || deviceId.length !== 8) {
    return deviceId;
  }

  const dParts = [
    deviceId.substring(0, 4),
    ':',
    deviceId.substring(4, 8),
  ]

  return (dParts.join(''));
}

export const prettyRound = (value) => {
  return Math.round(value * 10) / 10;
}
