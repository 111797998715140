import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';

export function fetchMeterAlertssIfNeeded(project) {
  return (dispatch, getState) => {
    if (shouldFetchMeterAlerts(getState(), project)) {
      return dispatch(fetchMeterAlerts(project));
    }
  }
}

function shouldFetchMeterAlerts(state, project) {
  const meterAlerts = state.leaksList.leaksByProject[project].meterAlerts
  if (!meterAlerts) {
    return true
  }
  return !meterAlerts.isFetching;

}

function fetchMeterAlerts(project) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/waterMeter";

  return (dispatch, getState) => {
    dispatch(requestMeterAlerts(project))
    return fetchMiddleware(path, {}, getState).then(json => {
      const data = json.data;
      return [data, getIconFeatures('waterMeterAlerts', data), setIndexes(data, 'Id')];

    })
      .then(args => dispatch(receiveMeterAlerts(project, ...args)));
  }
}

function requestMeterAlerts(project) {
  return {
    type: types.REQUEST_WATER_METER_ALERTS,
    project
  };
}

function receiveMeterAlerts(project, meterAlerts, meterIcons, indexMap) {
  return {
    type: types.RECEIVE_WATER_METER_ALERTS,
    project,
    meterAlerts,
    meterIcons,
    indexMap
  }
}
