import * as types from '../constants/ActionTypes';

const ACTIONS = {
  SET_DISPLAY_MODE: "SET_DISPLAY_MODE",
};

export const setDisplayMode = (mode) => ({
  type: ACTIONS.SET_DISPLAY_MODE,
  payload: mode,
});

export function setIQ100BReportsModalVisibility(visible) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject; 
    dispatch({
      type: types.IQ100B_MOBILE_REPORTS_DATA_SET_VISIBILITY,
      project: projectId,
      payload: visible
    });
  };
}

/*REDUCER*/

const initialState = {
  mode: "Tasks", // 'Tasks' OR 'Alerts' OR 'Devices'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_DISPLAY_MODE:
      return { ...state, mode: action.payload };

    default:
      return state;
  }
};

export default reducer;
