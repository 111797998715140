import { connect } from 'react-redux';
import CoupleAssessmentReportModal from '../../components/CoupleAssessmentReportModal/CoupleAssessmentReportModal';

const mapStateToProps = (state, ownProps) => {
  const timeZone = state.local.timeZone
  const isAqsAdmin = state.user.isAQS;

  const projectId = state.leaksList.selectedProject;
  const selectedCoupleId = state.leaksList.leaksByProject.selectedFeature;
  const assessments = state.leaksList.leaksByProject[projectId].assessments[selectedCoupleId].assessments;
  
  return {
    isAqsAdmin,
    timeZone,
    assessments,
    coupleId: selectedCoupleId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

  };
}

const CCoupleAssessmentReportModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoupleAssessmentReportModal);

export default CCoupleAssessmentReportModal;
