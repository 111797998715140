import PropTypes from 'prop-types';
import React from 'react';
import Favicon from 'react-favicon';

import { SYSTEM_RESOURCE } from '../../constants/EndPoints';
import * as misc from '../../constants/Misc';

import { connect } from 'react-redux';

import LinearProgress from '@material-ui/core/LinearProgress';

import ResetPassword from '../../components/Login/ResetPasswrd';
import SignIn from '../../components/Login/SignIn';
import VerifyNewPassword from '../../components/Login/VerifyNewPassword';

import AuthenticateMfaCode from '../../components/Login/AuthenticateMfaCode';
import { getMfaResolver } from '../../services/authFirebase';
import './Login.scss';

// Import the auth service or module here
import auth from '../../services/appAuth'; // Ensure this path is correct

const NEED_MFA_ERROR = 'auth/multi-factor-auth-required';
const SMS_MFA = 'SMS_MFA';
const FIREBASE_SMS_MFA = 'FIREBASE_SMS_MFA';

const systemName = process.env.REACT_APP_SYSTEM_NAME;

function CLogin(props, context) {
  const [mode, setMode] = React.useState('signIn');
  const [additionalData, setAdditionalData] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState();

  const sys = SYSTEM_RESOURCE;

  // Dynamically construct the background image path
  const backgroundImagePath = require(`../../../images/system/${sys}/login_bg.png`);

  const mainStyle = {
    backgroundImage: `url(${backgroundImagePath})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  function changeMode(newMode, additional) {
    setMode(newMode);
    if (additional) {
      setAdditionalData(additional);
    }
  }

  function handleAuthCode(code, errCallback) {
    if (!user) {
      console.log('NO USER!!');
    } else {
      props.verifyMfaCode(user, code, errCallback);
    }
  }

  const handleFireBaseAuthCode = async (code, errCallback) => {
    try {
      await props.finishMfa(code, errCallback);
      // If MFA verification succeeds, proceed with the next steps
    } catch (error) {
      // If an error occurs, check if it's due to wrong MFA code
      if (error.message === 'Wrong MFA code.') {
        // Handle the case where the user enters the wrong MFA code
        console.error('Wrong MFA code.');
        // Call the error callback to handle the UI update or display error message
        if (typeof errCallback === 'function') {
          errCallback(error.message);
        }
      } else {
        // Handle other errors
        console.error(error);
      }
    }
  };

  return (
    <section className='page login-page'>
      <Favicon url={require(`./../../../images/system/${sys}/favicon_1.png`)} />

      <div className='main' style={mainStyle}>
        <div className='login-form form-horizontal'>
          <header>
            <div className='logo'>{systemName}</div>
            <div className='version'>
              {context.t('ver')}. <strong>{misc.versionNumber}</strong>
            </div>
          </header>

          {isLoading && <LinearProgress style={{ position: 'sticky' }} />}

          {mode === 'signIn' && (
            <SignIn
              signInFunc={props.signIn}
              changeLoginMode={changeMode}
              loading={isLoading}
              setLoading={setLoading}
              setLoggingInUser={setUser}
            />
          )}

          {mode === 'resetPassword' && (
            <ResetPassword
              backTo={changeMode}
              loading={isLoading}
              setLoading={setLoading}
              signInFunc={props.signIn}
            />
          )}

          {mode === 'setMfaCode' && (
            <AuthenticateMfaCode
              signInFunc={handleAuthCode}
              setLoading={setLoading}
              backTo={changeMode}
              context={context}
            />
          )}
          {mode === 'setFirebaseMfaCode' && (
            <AuthenticateMfaCode
              signInFunc={handleFireBaseAuthCode}
              setLoading={setLoading}
              backTo={changeMode}
              context={context}
            />
          )}

          {mode === 'newPasswordRequired' && (
            <VerifyNewPassword
              loginData={additionalData}
              changeNewPassword={props.changeNewPassword}
              setLoading={setLoading}
            />
          )}

          <footer className='form-group'>
            <div className='control-label' style={{ textAlign: 'center' }}>
              <span>{context.t('by_clicking_login_you_agree_to_our')}</span>
              <a
                style={{ marginLeft: '5px', marginRight: '5px' }}
                href={`${process.env.PUBLIC_URL}/certificates/Terms_and_Conditons.pdf`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {context.t('terms_and_conditions')}
              </a>
              <span>{context.t('and_our')}</span>
              <a
                style={{ marginLeft: '5px' }}
                href={`${process.env.PUBLIC_URL}/certificates/Privacy_Policy.pdf`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {context.t('privecy_policy')}
              </a>
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
}

CLogin.contextTypes = {
  t: PropTypes.func.isRequired,
};

CLogin.propTypes = {
  changeNewPassword: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  verifyMfaCode: PropTypes.func.isRequired,
};

/*
 *
 */
function mapDispatchToProps(dispatch, props) {
  return {
    ...props,
    signIn: (email, password, callback) => {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((data) => {
          if ([SMS_MFA, 'NEW_PASSWORD_REQUIRED'].includes(data.challengeName)) {
            callback(data);
          } else {
            const err = new Error('CHECK');
            callback(err);
          }
        })
        .catch(async (error) => {
          if (error.code === NEED_MFA_ERROR) {
            try {
              const resolver = getMfaResolver(error);
              await auth.startMfaSignin(resolver.hints[0], resolver.session);
            } catch (error) {
              console.log(error);
            }
            callback({ challengeName: FIREBASE_SMS_MFA });
          }
          const err = new Error(error.message);
          callback(err);
        });
    },
    finishMfa: async (code, errCallback) => {
      try {
        await auth.finishMfaSignIn(code);
      } catch (error) {
        errCallback(error);
        console.log(error);
      }
    },

    changeNewPassword: (email, password, newPassword, callback) => {
      auth
        .loginSetNewPassword(email, password, newPassword)
        .then((data) => {
          // console.log(data);
        })
        .catch((error) => {
          const err = new Error(error.message);
          callback(err);
        });
    },

    verifyMfaCode: (user, code, errCallback) => {
      auth
        .verifySmsMfaCode(user, code)
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          errCallback(err);
        });
    },
  };
}

const cLogin = connect(null, mapDispatchToProps)(CLogin);

export default cLogin;
