import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import CorrelationGraph from './CorrelationGraph';

export default function AvGraph(props, context) {

  const xAxisLabels = new Array(props.avData.contrast_reduced.length).fill('');
  props.avData.locs_in_reduce_samples.forEach((loc, i) => {
    xAxisLabels[loc] = props.avData.distance_m[i];
  });
  return (
    <AppModal
      closeIcon
      onTop
      open={props.open}
      onClose={props.closeAvGraph}
      content={
        <CorrelationGraph
          data={[props.avData.contrast_reduced || []]}
          factor={props.factor}
          xAxisLabels={xAxisLabels}
          leaksMarkers={props.avData.locs_in_reduce_samples}
          large
        />
      }
    />
  );
}

AvGraph.propTypes = {
  avData: PropTypes.array,
  closeAvGraph: PropTypes.func,
  factor: PropTypes.number,
  open: PropTypes.bool,
};

AvGraph.contextTypes = {
  t: PropTypes.func.isRequired
};
