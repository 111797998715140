import { setLanguage } from 'redux-i18n';
import { browserHistory } from 'react-router';

import * as types from '../constants/ActionTypes';
import * as settings from '../constants/SettingsKeys';
import { fetchOptionsIfNeeded, setUnit, setZone } from './GlobalsActions';
import { setPipesSource } from './SettingsActions';
import { featchCustomers } from './CustomersActions';
import { fetchProjectsIfNeeded, selectProjectAction } from './ProjectsActions';
import * as auth from '../services/appAuth';

export function userLogin(payload, uid, user) {
  return {
    type: types.USER_LOGGED_IN,
    payload,
    uid,
    user
  };
}

export function userLogout() {
  return {
    type: types.USER_LOGGED_OUT
  };
}

function loginRequest(email, pass) {
  return {
    type: types.LOGIN_REQUEST,
    email,
    pass,
  };
}

export const loginSuccess = () => ({
  type: types.LOGIN_SUCCESS,
});

function loginFailed(err) {
  return {
    type: types.LOGIN_FAILED,
    err
  };
}

export function doLoginIfNeeded(email, pass, errorCallback) {
  return (dispatch, getState) => {
    if (shouldDoLogin(getState())) {
      return dispatch(doLogin(email, pass, errorCallback));
    }
  };
}

export function loginSetNewPassword(email, prevPassword, newPassword) {
  return (dispatch, getState) => {
    auth.default.loginSetNewPassword(email, prevPassword, newPassword)
    .then(data => {
      // console.log(data);
    })
    .catch(err => {
      // console.log(err);
    })
  }
}

function shouldDoLogin(state) {
  const user = state.user || {};
  const bRc = !user.isLoggedIn && !user.isLoggingIn;
  return bRc;
}

function doLogin(email, pass, errorCallback) {
  return (dispatch) => {
    dispatch(loginRequest(email, pass));
    auth.default.signInWithEmailAndPassword(email, pass)
      .then((userData) => {
        if (userData.challengeName && userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
          errorCallback(new Error('NEW_PASSWORD_REQUIRED'));
        }
      })
      .catch(function (error) {
        if (error.code === 11) {
          // InvalidStateError - Failed to execute 'transaction' on 'IDBDatabase'
          const refreshError = new Error('Something went wrong. The system will restart soon');
          errorCallback(refreshError);
          errorCallback(refreshError);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          // Handle Errors here.
          dispatch(loginFailed(error));
          errorCallback(error);
        }
      });
  };
}

export function doSignOut() {
  return async (dispatch) => {
    await auth.default.signOut();
    dispatch(userLogout());
  };
}

export function doResetPassword(email) {
  return auth.default.sendPasswordResetEmail(email);
}

export function setNewPassword(email, code, newPassword) {
  return auth.default.setNewPassword(email, code, newPassword);
}

export function sendVerificationCode(email, code) {
  return auth.default.sendVerificationCode(email, code);
}

export function loadDataOnLoggedIn() {
  return async (dispatch) => {
    loadLocalSettings(dispatch);
    await dispatch(fetchOptionsIfNeeded());
    await dispatch(featchCustomers());
    await dispatch(fetchProjectsIfNeeded());
    // .then(() => {

    //   .then(() => {
    //     const { locationBeforeTransitions } = state.routing;
    //     const { optionList } = state.leaksList;

    //     if (!optionList.hasData) {
    //       dispatch(fetchOptionsIfNeeded()).then(() => {
    //         if (locationBeforeTransitions && (locationBeforeTransitions.pathname == '/login') || locationBeforeTransitions.pathname == '/') {
    //           console.log('@@@@@@@@@@@@@@ push query @@@@@@@@@@@@@@');
    //           console.log(locationBeforeTransitions.query.redirect);
    //           const path = (locationBeforeTransitions.query.redirect == null || locationBeforeTransitions.query.redirect == '/') ? 'alerts' : locationBeforeTransitions.query.redirect;
    //           dispatch(push(path));
    //         }
    //       });
    //     } else {
    //       if (locationBeforeTransitions && (locationBeforeTransitions.pathname == '/login') || locationBeforeTransitions.pathname == '/') {
    //         console.log('@@@@@@@@@@@@@@ push query @@@@@@@@@@@@@@');
    //         console.log(locationBeforeTransitions.query.redirect);
    //         const path = (locationBeforeTransitions.query.redirect == null || locationBeforeTransitions.query.redirect == '/') ? 'alerts' : locationBeforeTransitions.query.redirect;
    //         dispatch(push(path));
    //     }
    //     }
    //   });
    // });
  };
}

function loadLocalSettings(dispatch) {
  const units = localStorage.getItem(settings.UNITS);
  const zone = localStorage.getItem(settings.ZONE);
  const language = localStorage.getItem(settings.LANGUAGE);
  const pipesDisplayType = localStorage.getItem(settings.PIPES_DISPLAY_TYPE);
  // const coverageDisplayType = localStorage.getItem(settings.COVERAGE_DISPLAY_TYPE);
  const lastProject = localStorage.getItem(settings.LAST_PROJECT);

  if (units) {
      dispatch(setUnit(units));
  }
  if (zone) {
      dispatch(setZone(zone));
  }
  if (language) {
      dispatch(setLanguage(language));
  }
  if (pipesDisplayType) {
      dispatch(setPipesSource(pipesDisplayType));
  }
  if (lastProject) {
      dispatch(selectProjectAction(lastProject));
  }
}
