/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/lab/Alert';

import InputField from './InputField';

const PASSWORD_MIN_LENGTH = 12;

function validPasswordLength(password) {
  const pattern = /^(?=.{12})/;
  return pattern.test(password);
}
function validPasswordLowercase(password) {
  const pattern = /^(?=.*[a-z])/;
  return pattern.test(password);
}
function validPasswordUppercase(password) {
  const pattern = /^(?=.*[A-Z])/;
  return pattern.test(password);
}
function validPasswordContainNumber(password) {
  const pattern = /^(?=.*[0-9])/;
  return pattern.test(password);
}

export default function VerifyNewPassword(props, context) {
  const [error, setError] = React.useState(null);
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [password1Error, setPassword1Error] = React.useState('');
  const [password2Error, setPassword2Error] = React.useState('');

  React.useEffect(() => {
    let errorMessage = null;
    if (!validPasswordLength(password1)) {
      errorMessage = context.t('at_least_length_characters_are_required', {length: PASSWORD_MIN_LENGTH});
    } else if (!validPasswordLowercase(password1)) {
      errorMessage = context.t('at_least_one_lowercase_letter');
    } else if (!validPasswordUppercase(password1)) {
      errorMessage = context.t('at_least_one_uppercase_letter');
    } else if (!validPasswordContainNumber(password1)) {
      errorMessage = context.t('at_least_one_number');
    }
    setPassword1Error(errorMessage);

    if (password2.length > 0) {
      checkSamePasswordValue();
    }
  }, [password1]);

  React.useEffect(() => {
    checkSamePasswordValue();
  }, [password2]);

  function checkSamePasswordValue() {
    const error = (password1 !== password2) ? context.t('the_passwords_are_not_the_same') : null;
    setPassword2Error(error);
  }

  function handleInputChange(setter) {
    return (event) => {
      setter(event.target.value);
    };
  }

  function doChangePassword() {
    const { loginData } = props;

    if (
      !validPasswordLength(password1) ||
      !validPasswordLowercase(password1) ||
      !validPasswordUppercase(password1) ||
      !validPasswordUppercase(password1)
    ) {
      setError({message: context.t('invalid_password')});
    } else if (password1 !== password2) {
      setError({message: context.t('the_passwords_are_not_the_same')});
    } else {
      props.setLoading(true);
      props.changeNewPassword(loginData.email, loginData.password, password1, (data) => {
        props.setLoading(false);

        if (data instanceof Error) {
          setError(data);
        }
      });
    }
  }

  return (
    <div style={{ margin: '10px 30px' }}>
      { error && <Alert style={{position: 'absolute', top: '-35%', fontSize: 'large'}} severity="error">{error.message}</Alert>}

      <h2>{context.t('change_password')}</h2>
      <p>{context.t('please_enter_your_new_password_below')}</p>

      <InputField
        label={context.t('new_password')}
        name='password1'
        type='password'
        value={password1}
        onChange={handleInputChange(setPassword1)}
        disabled={props.loading}
        error={password1Error}
      />
      <InputField
        label={context.t('enter_new_password_again')}
        name='password2'
        type='password'
        value={password2}
        onChange={handleInputChange(setPassword2)}
        disabled={props.loading}
        error={password2Error}
      />

      <div className="form-group form-actions">
        <div className="col-xs-6 col-xs-offset-3 login-field">
          <button
            className="login-submit btn btn-success btn-block"
            type="submit"
            onClick={doChangePassword}>
              {context.t('login')}
            </button>
        </div>
      </div>
    </div>
  );
}

VerifyNewPassword.contextTypes = {
  t: PropTypes.func.isRequired
};

VerifyNewPassword.propTypes = {
  changeNewPassword: PropTypes.func,
  loading: PropTypes.bool,
  loginData: PropTypes.objectOf({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  setLoading: PropTypes.func.isRequired,
  signInFunc: PropTypes.func.isRequired,
};
