import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeCircles } from 'react-loader-spinner'

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import AppModal from './AppModal';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import { makeStyles } from '@mui/styles';
import { getIQ100BReport, getIQ100BReportProjectUsers } from '../../actions/MobileDevicesActions';

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import ReactExport from 'react-data-export';
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';
import { prettyRound } from '../../containers/UIhelper/UIhelper';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function generateTabelBodyForPdf(data, timeZone) {
  const result = [];
  data.forEach((row) => {
    const res = Object.entries(row).map(cell =>
      `${cell[0]}`.toLocaleLowerCase().includes(`date`) ||
      `${cell[0]}`.toLocaleLowerCase().includes(`time`) ?
      UIDateFormater(cell[1], DateType.DATE_AND_TIME_WITH_SECONDS, timeZone) :
      `${cell[0]}`.toLocaleLowerCase().includes('intensity') ||
      `${cell[0]}`.toLocaleLowerCase().includes('rate') ?
      prettyRound(cell[1]) :
      cell[1])
    result.push(res);
  });
  return result;
}

const xlsColumns = [
  {title: "User name", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Task name", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Date", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Start Time", width: {wch: 23}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "End Time", width: {wch: 23}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Count samples", width: {wch: 13}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Rate (S/H)", width: {wch: 10}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Min Intensity", width: {wch: 13}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Max Intensity", width: {wch: 13}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Average Quality", width: {wch: 22}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Suspected", width: {wch: 10}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Detected", width: {wch: 10}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
];

const animatedComponents = makeAnimated();

const checkboxOptions = ['monthly','daily']

const ReportFilter = ({
  selectedProject,
  dispatch,
  isVisible,
  context,
  users,
  isGenereated,
  // onClearReport,
  onGeneratedReport}) => {
  const [selectedUserList,setSelectedUserList] = useState([])
  const [selected,setSelected] = useState('monthly')
  const [selectedMonth, setSelectedMonth] = React.useState(new Date().toISOString().substring(0,'YYYY-MM'.length));
  const [selectedMonthFrom, setSelectedMonthFrom] = React.useState(new Date().toISOString().substring(0,'YYYY-MM'.length));
  const [selectedMonthTo, setSelectedMonthTo] = React.useState(new Date().toISOString().substring(0,'YYYY-MM'.length));

  const actionSelected = useCallback((val)=>{
    setSelected(val)
  },[setSelected])

  const selectMonth = useCallback((e) => {
    setSelectedMonth(e.target.value);
  },[setSelectedMonth])

  const selectMonthFrom = useCallback((e) => {
    setSelectedMonthFrom(e.target.value);
  },[setSelectedMonthFrom])

  const selectMonthTo = useCallback((e) => {
    setSelectedMonthTo(e.target.value);
  },[setSelectedMonthTo])

  const checkboxOptionsItem = useCallback((opt)=>{
    const onClick = () => {
      actionSelected(opt)
    }
    return(<div key={opt} className='checkbox-iq100b-mobile'>
    <input type="checkbox" onChange={onClick} checked={selected === opt} value={opt}/>
    <label onClick={onClick} style={selected !== opt ? {backgroundColor: '#fefefe', color: 'gray'} : undefined}>{opt}</label><br/>
    </div>)
  },[selected])

  const actionUserList = useCallback((users)=>{
    setSelectedUserList(users)
  },[setSelectedUserList])

  const onClickGeneratedReport = useCallback(()=>{
    onGeneratedReport({
      filter: selected,
      users: selectedUserList,
      dates: selected === 'monthly' ? [`${selectedMonth}-01`] : [selectedMonthFrom, selectedMonthTo]
    })
  },[selected, selectedUserList, selectedMonth, selectedMonthFrom, selectedMonthTo])


  const types = useCallback(()=>{
    const type = {
      daily: <div className='checkbox-iq100b-mobile-type-view-daily'>
        <div>
          <label>{context.t('from')}:&nbsp;</label>
          <input type="date" min="2016-01" onChange={selectMonthFrom} value={selectedMonthFrom} />
        </div>
        <div>
          <label>{context.t('to')}:&nbsp;</label>
          <input type="date" min="2016-01" onChange={selectMonthTo} value={selectedMonthTo} />
        </div>
      </div>,
      monthly: <div className='checkbox-iq100b-mobile-type-view-monthly'>
                <input type="month" min="2016-01" onChange={selectMonth} value={selectedMonth} />
              </div>
    }
    return(<div className='checkbox-iq100b-mobile-type-view'>
      {type[selected]}
    </div>)
  },[selected, selectedMonthFrom, selectedMonthTo, selectedMonth])

  return(<Fragment>
          {checkboxOptions.map(checkboxOptionsItem)}
          {types()}
          <UserList {...{users, actionUserList, context, selectedProject, dispatch, isVisible}}/>
          <div className='checkbox-iq100b-mobile-button-view'>
            <Button disabled={isGenereated} variant='contained' color='primary' onClick={onClickGeneratedReport}>{context.t('generate_report')}</Button>
          </div>
      </Fragment>)
}

const UserList = ({users, actionUserList, context, selectedProject, dispatch, isVisible}) => {
  const [options,setOptions] = useState([])
  const [selectAll,setSelectAll] = useState(false)
  useEffect(()=>{
    if(!options.length && users && selectedProject && isVisible){
      (async ()=>{
        try{
          const response = await dispatch(getIQ100BReportProjectUsers({
            body: {
              projectId: selectedProject.ID
            }
          }));
          // console.log('getIQ100BReportProjectUsers response: ',response)
          if(response.status)
            setOptions(response.data)
          else setOptions([])
        }catch(error){
          setOptions([])
          console.error('useEffect_error_UserList: ',error)
        }
      })()
    }
  },[users])

  const onChange = useCallback(()=>{
    setSelectAll(s => {
      if(!s)
        actionUserList(options)
      else actionUserList([])
      return !s
    })
  },[setSelectAll, options])

  return( <div className='checkbox-iq100b-mobile-user-list'>
    <label>{context.t('users_list')}</label>
    {!options.length ?
      <ThreeCircles
        height="30"
        width="30"
        color={'#6090B1'}
      /> :
      <Fragment>
      <div className='checkbox-iq100b-mobile-selected-row-type'>
        <input type="checkbox" onChange={onChange} checked={selectAll} value={selectAll}/>
        <label onClick={onChange}>&nbsp;{context.t('select_all_users')}</label>
      </div>
        <Select
          isDisabled={selectAll}
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          placeholder={selectAll ? 'Selected All Users List' : 'select'}
          onChange={actionUserList}
          options={options}
          styles={colourStyles}
          />
      </Fragment>
      }
  </div>)
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: !data.value ? 'red' : undefined,
    };
  },
};

function IQ100BReportsModal(props, context) {
  const timeZone = useSelector((state) => state.local.timeZone);
  const [isLoading,setIsLoading] = useState(false)
  const [exportFileName,setExportFileName] = useState('')
  const dispatch = useDispatch()
  const [data,setData] = useState([])
  const onClearReport = useCallback(()=>{
    setIsLoading(false)
    setData([])
  },[])

  const [xlsData, setXlsData] = React.useState({});

  React.useEffect(() => {
    // set the data for export to xlsx:
    if (data.length) {
      setXlsData([
        {
          columns: xlsColumns,
          data: data.map((item) => {

            return [
              { value: `${item.userName}`},//, style: {font: {sz: "12"}}
              { value: `${item.taskName}`},//, style: { font: {sz: "12"}, numFmt: '0'}
              { value: `${UIDateFormater(item.date, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}`},//, style: { font: {sz: "12"}, numFmt: '0'}
              { value: `${UIDateFormater(item.startTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}`},// , style: { font: {sz: "12"} }
              { value: `${UIDateFormater(item.endTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}`},// , style: { font: {sz: "12", numFmt: '0%'} }
              { value: `${item.countSamples}`},//, style: { font: {sz: "12"}}
              { value: prettyRound(+`${item.rate}`)},// , style: { font: {sz: "12"} }
              { value: prettyRound(+`${item.minIntensity}`)},// , style: { font: {sz: "12", numFmt: '0%'} }
              { value: prettyRound(+`${item.maxIntensity}`)},// , style: { font: {sz: "12", numFmt: '0%'} }
              { value: `${item.averageQuality}`},//, style: { font: {sz: "12"} }
              { value: `${item.suspected}`},//, style: { font: {sz: "12"} }
              { value: `${item.detected}`},//, style: { font: {sz: "12"} }
            ];
          })
        }
      ]);
    }
  }, [data]);

  // const { month, year } = props;

  async function exportPdf() {
    const tableBody = generateTabelBodyForPdf(data, timeZone);
    const tableHeader = xlsColumns.map(col => `${col.title}`)//[ 'Device ID', 'Sensor ID', 'Active', 'Non Active' ];

    const doc = new jsPDF({ orientation: 'landscape' });
    const horizontalPadding = 10;
    doc.setFontSize(16);
    doc.text(`IQ100B Report`, horizontalPadding, 10);
    doc.setFontSize(12);
    doc.text(`generate for ${exportFileName.split('IQ100B Report - ')[1]}`, horizontalPadding, 25);

    // doc.addImage(plotImage, 'PNG', horizontalPadding, 30, 120, 100, 'pie graph', 'NONE', 0);
    doc.autoTable({
      styles: {
        valign: 'center',
        fontSize: 5,
      },
      startX: 0,
      startY: 35,
      columns: tableHeader,
      body: tableBody,
      minCellWidth: 5,
      cellPadding: 2,
      didParseCell: (data) => {
        if (data.section === 'body') {

          data.cell.cellPadding = 5;

          switch (data.cell.raw) {
            case 'V':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'green';
              break;
            case 'X':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'red';
              break;

            default:
              break;
          }
        }
      }
    });

    doc.save(exportFileName);
  }


  const onGeneratedReport = useCallback(({filter, users, dates})=>{
    const usersIds = users.map(user=>user.value)
    if(!usersIds.length) return alert('Please Choose User First!')
    setData([]);
    (async()=>{
      setIsLoading(true)
      try{
        const response = await dispatch(getIQ100BReport({
          body: {
            type: filter,
            usersIds,
            date: [dates[0], dates[1] ? dates[1] : null],
            projectId: props.selectedProject.ID
          }
        }));
        // console.log('response: ',response)
        if(response.status){
          setExportFileName(`IQ100B Report - ${dates[1] ? `${dates[0]} - ${dates[1]}` : dates[0]}`)
          setData(response.data)
        }else {
          alert('This Report Is Empty!')
          setData([])
        }
      }catch(error){
        console.error('useEffect_error_setData: ',error)
      }finally{
        setIsLoading(false);
      }
    })()
  },[data, setData, dispatch])

  return (
    <AppModal
      closeIcon
      fullWidth
      maxWidth='xl'
      open={props.isVisible}
      title={context.t('iq_100b_mobile')}
      onClose={props.close}
      content={(
        <div style={{ height: '75vh', overflow: 'hidden' }}>
          { !props.isFetching && props.selectedProject &&
            <div style={{
              display: 'flex',
            }}>
              <div>
                <Typography component='p' variant='p'>{context.t('create_report')}</Typography>
                <ReportFilter {...{
                  selectedProject: props.selectedProject,
                  dispatch,
                  isVisible: props.isVisible,
                  context,
                  users: props.users,
                  isGenereated: isLoading,
                  onClearReport,
                  onGeneratedReport
                  }}/>

            <div className='iq100b-export-buttons'>
                <Button
                  disabled={isLoading}
                  style={{ width: 'max-content' }}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  onClick={exportPdf}
                >
                  {context.t('save_as_pdf')}
                </Button>

                <ExcelFile
                  filename={exportFileName}
                  element={
                    <Button
                      disabled={isLoading}
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<SaveIcon />}
                    >{context.t('save_as_excel')}</Button>
                }>
                  <ExcelSheet dataSet={xlsData} name={exportFileName}/>
                </ExcelFile>
            </div>
              </div>
              <div style={{ display: 'inline-flex', flex: 1, margin: 10, alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
                {!isLoading && data.length ? <IQ100BReportTable
                  timeZone={timeZone}
                  data={data}
                /> : isLoading && !data.length ?
                <ThreeCircles
                  height="30"
                  width="30"
                  color={'#6090B1'}
                /> : <></>}
              </div>
          </div>
          }
        </div>
      )}
    />
  );
}

IQ100BReportsModal.contextTypes = {
  t: PropTypes.func.isRequired
};

IQ100BReportsModal.propTypes = {
  close: PropTypes.func,
  data: PropTypes.objectOf({
    activityData: PropTypes.arrayOf({
      DaysCount: PropTypes.number,
      DeviceID: PropTypes.string,
    }),
    daysReport: PropTypes.arrayOf({
      deviceId: PropTypes.string,
      dates: PropTypes.arrayOf(PropTypes.number)
    })
  }),
  isFetching: PropTypes.bool,
  isVisible: PropTypes.bool,
  loadData: PropTypes.func,
  month: PropTypes.number,
  selectedProject: PropTypes.object,
  year: PropTypes.number,
};

IQ100BReportsModal.defaultProps = {
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '600px',
  },
  cell: {
    borderLeft: '1px solid #eee',
    '&.$nth-of-type(odd)': {
      background: '#aaa'
    }
  }
});

const Cells = () => {
  const classes = useStyles();
  const cellItem = (item) => (<TableCell key={`cell_${item.title}`} style={{fontWeight: 'bold', whiteSpace: 'nowrap'}} className={classes.cell}>{item.title}</TableCell>)
  return(xlsColumns.map(cellItem))
}

function IQ100BReportTable({data, timeZone}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <Cells/>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((row, index) => {
              return (
                <TableRow hover key={`${row.date}_${index}`}>
                  <TableCell className={classes.cell}>{row.userName}</TableCell>
                  <TableCell className={classes.cell}>{row.taskName}</TableCell>
                  <TableCell className={classes.cell} style={{ minWidth: '170px' }}>{UIDateFormater(row.date, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}</TableCell>
                  <TableCell className={classes.cell} style={{ minWidth: '170px' }}>{UIDateFormater(row.startTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}</TableCell>
                  <TableCell className={classes.cell} style={{ minWidth: '170px' }}>{UIDateFormater(row.endTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}</TableCell>
                  <TableCell className={classes.cell}>{row.countSamples}</TableCell>
                  <TableCell className={classes.cell}>{prettyRound(row.rate)}</TableCell>
                  <TableCell className={classes.cell}>{prettyRound(row.minIntensity)}</TableCell>
                  <TableCell className={classes.cell}>{prettyRound(row.maxIntensity)}</TableCell>
                  <TableCell className={classes.cell}>{row.averageQuality}</TableCell>
                  <TableCell className={classes.cell}>{row.suspected}</TableCell>
                  <TableCell className={classes.cell}>{row.detected}</TableCell>
                  {/* {Object.entries(row).map(cell => <TableCell key={`${row.date}_${index}_${cell[0]}`} className={classes.cell}
                    style={
                      `${cell[0]}`.toLocaleLowerCase().includes(`date`) ||
                      `${cell[0]}`.toLocaleLowerCase().includes(`time`) ?
                      { minWidth: '170px' } : null
                    }
                  >{`${
                      `${cell[0]}`.toLocaleLowerCase().includes(`date`) ||
                      `${cell[0]}`.toLocaleLowerCase().includes(`time`) ?
                      UIDateFormater(cell[1], DateType.DATE_AND_TIME_WITH_SECONDS, timeZone) :
                      `${cell[0]}`.toLocaleLowerCase().includes('intensity') ||
                      `${cell[0]}`.toLocaleLowerCase().includes('rate') ?
                      prettyRound(cell[1]) :
                      cell[1]
                    }`}</TableCell>)} */}
                </TableRow>);
            })
          }
        </TableBody>
      </Table>
    </TableContainer>

  );
}

IQ100BReportTable.propTypes = {
  date: PropTypes.objectOf(Date),
};

export default React.memo(IQ100BReportsModal)


// import React, { Fragment, useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { useDispatch, useSelector } from 'react-redux';
// import { ThreeCircles } from 'react-loader-spinner'

// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

// import AppModal from './AppModal';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';

// import Button from '@mui/material/Button';
// import SaveIcon from '@mui/icons-material/Save';

// import { makeStyles } from '@mui/styles';
// import { getIQ100BReport, getIQ100BReportProjectUsers } from '../../actions/MobileDevicesActions';

// import { jsPDF } from "jspdf";
// import 'jspdf-autotable';

// import ReactExport from 'react-data-export';
// import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';


// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

// function generateTabelBodyForPdf(data, timeZone) {
//   const result = [];
//   data.forEach((row) => {
//     const res = Object.entries(row).map(cell =>
//       `${cell[0]}`.toLocaleLowerCase().includes(`date`) ||
//       `${cell[0]}`.toLocaleLowerCase().includes(`time`) ?
//       UIDateFormater(cell[1], DateType.DATE_AND_TIME_WITH_SECONDS, timeZone) :
//       cell[1])
//     result.push(res);
//   });
//   return result;
// }

// const xlsColumns = [
//   {title: "User name", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Task name", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Date", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Count samples", width: {wch: 25}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Average Quality", width: {wch: 22}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Suspected", width: {wpx: 100}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Detected", width: {wpx: 100}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Start Time", width: {wch: 23}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "End Time", width: {wpx: 70}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Rate (S/H)", width: {wch: 23}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Min Intensity", width: {wpx: 70}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
//   {title: "Max Intensity", width: {wpx: 70}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
// ];

// const animatedComponents = makeAnimated();

// const checkboxOptions = ['monthly','daily']

// const ReportFilter = ({
//   isVisible,
//   context,
//   users,
//   isGenereated,
//   onClearReport,
//   onGeneratedReport,
//   selectedProject,
//   dispatch}) => {
//   const [selectedUserList,setSelectedUserList] = useState([])
//   const [selected,setSelected] = useState('monthly')
//   const [selectedMonth, setSelectedMonth] = React.useState(new Date().toISOString().substring(0,'YYYY-MM'.length));
//   const [selectedMonthFrom, setSelectedMonthFrom] = React.useState(new Date().toISOString().substring(0,'YYYY-MM'.length));
//   const [selectedMonthTo, setSelectedMonthTo] = React.useState(new Date().toISOString().substring(0,'YYYY-MM'.length));

//   const actionSelected = useCallback((val)=>{
//     setSelected(val)
//   },[setSelected])

//   const selectMonth = useCallback((e) => {
//     setSelectedMonth(e.target.value);
//   },[setSelectedMonth])

//   const selectMonthFrom = useCallback((e) => {
//     setSelectedMonthFrom(e.target.value);
//   },[setSelectedMonthFrom])

//   const selectMonthTo = useCallback((e) => {
//     setSelectedMonthTo(e.target.value);
//   },[setSelectedMonthTo])

//   const checkboxOptionsItem = useCallback((opt)=>{
//     const onClick = () => {
//       actionSelected(opt)
//     }
//     return(<div key={opt} className='checkbox-iq100b-mobile'>
//     <input type="checkbox" onChange={onClick} checked={selected === opt} value={opt}/>
//     <label onClick={onClick} style={selected !== opt ? {backgroundColor: '#fefefe', color: 'gray'} : undefined}>{opt}</label><br/>
//     </div>)
//   },[selected])

//   const actionUserList = useCallback((users)=>{
//     setSelectedUserList(users)
//   },[setSelectedUserList])

//   const onClickGeneratedReport = useCallback(()=>{
//     onGeneratedReport({
//       filter: selected,
//       users: selectedUserList,
//       dates: selected === 'monthly' ? [`${selectedMonth}-01`] : [selectedMonthFrom, selectedMonthTo]
//     })
//   },[selected, selectedUserList, selectedMonth, selectedMonthFrom, selectedMonthTo])


//   const types = useCallback(()=>{
//     const type = {
//       daily: <div className='checkbox-iq100b-mobile-type-view-daily'>
//         <div>
//           <label>{context.t('from')}:&nbsp;</label>
//           <input type="date" min="2016-01" onChange={selectMonthFrom} value={selectedMonthFrom} />
//         </div>
//         <div>
//           <label>{context.t('to')}:&nbsp;</label>
//           <input type="date" min="2016-01" onChange={selectMonthTo} value={selectedMonthTo} />
//         </div>
//       </div>,
//       monthly: <div className='checkbox-iq100b-mobile-type-view-monthly'>
//                 <input type="month" min="2016-01" onChange={selectMonth} value={selectedMonth} />
//               </div>
//     }
//     return(<div className='checkbox-iq100b-mobile-type-view'>
//       {type[selected]}
//     </div>)
//   },[selected, selectedMonthFrom, selectedMonthTo, selectedMonth])

//   return(<Fragment>
//           {checkboxOptions.map(checkboxOptionsItem)}
//           {types()}
//           <UserList {...{users, actionUserList, context, selectedProject, dispatch, isVisible}}/>
//           <div className='checkbox-iq100b-mobile-button-view'>
//             <div>
//               <Button variant='contained' color='primary' disabled={!isGenereated} onClick={onClearReport}>{context.t('Clear')}</Button>
//             </div>
//             <div>
//               <Button disabled={isGenereated} variant='contained' color='primary' onClick={onClickGeneratedReport}>{context.t('Generate Report')}</Button>
//             </div>
//           </div>
//       </Fragment>)
// }

// const UserList = ({users, actionUserList, context, selectedProject, dispatch, isVisible}) => {
//   const [options,setOptions] = useState([])
//   const [selectAll,setSelectAll] = useState(false)
//   useEffect(()=>{
//     if(!options.length && users && selectedProject && isVisible){
//       (async ()=>{
//         try{
//           const response = await dispatch(getIQ100BReportProjectUsers({
//             body: {
//               projectId: selectedProject.ID
//             }
//           }));
//           console.log('getIQ100BReportProjectUsers response: ',response)
//           if(response.status)
//             setOptions(response.data)
//           else setOptions([])
//         }catch(error){
//           setOptions([])
//           console.error('useEffect_error_UserList: ',error)
//         }
//       })()
//     }
//   },[users])

//   const onChange = useCallback(()=>{
//     setSelectAll(s => {
//       if(!s)
//         actionUserList(options)
//       else actionUserList([])
//       return !s
//     })
//   },[setSelectAll, options])

//   return( <div className='checkbox-iq100b-mobile-user-list'>
//     <label>{context.t('Users List')}</label>
//     {!options.length ?
//       <ThreeCircles
//         height="30"
//         width="30"
//         color={'#6090B1'}
//       /> :
//       <Fragment>
//       <div className='checkbox-iq100b-mobile-selected-row-type'>
//         <input type="checkbox" onChange={onChange} checked={selectAll} value={selectAll}/>
//         <label onClick={onChange}>&nbsp;{context.t('Select All Users')}</label>
//       </div>
//         <Select
//           isDisabled={selectAll}
//           closeMenuOnSelect={false}
//           components={animatedComponents}
//           isMulti
//           placeholder={selectAll ? 'Selected All Users List' : 'select'}
//           onChange={actionUserList}
//           options={options}
//           />
//       </Fragment>
//       }
//   </div>)
// }

// function IQ100BReportsModal(props, context) {
//   const timeZone = useSelector((state) => state.local.timeZone);
//   const [isGenereated,setIsGenereated] = useState(false)
//   const [exportFileName,setExportFileName] = useState('')
//   const dispatch = useDispatch()
//   const [data,setData] = useState([])
//   const onClearReport = useCallback(()=>{
//     setIsGenereated(false)
//     setData([])
//   },[])

//   const [xlsData, setXlsData] = React.useState({});

//   React.useEffect(() => {
//     // set the data for export to xlsx:
//     if (data.length) {
//       setXlsData([
//         {
//           columns: xlsColumns,
//           data: data.map((item) => {

//             return [
//               { value: `${item.userName}`},//, style: {font: {sz: "12"}}
//               { value: `${item.taskName}`},//, style: { font: {sz: "12"}, numFmt: '0'}
//               { value: `${UIDateFormater(item.date, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}`},//, style: { font: {sz: "12"}, numFmt: '0'}
//               { value: `${item.countSamples}`},//, style: { font: {sz: "12"}}
//               { value: `${item.averageQuality}`},//, style: { font: {sz: "12"} }
//               { value: `${item.suspected}`},//, style: { font: {sz: "12"} }
//               { value: `${item.detected}`},//, style: { font: {sz: "12"} }
//               { value: `${UIDateFormater(item.startTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}`},// , style: { font: {sz: "12"} }
//               { value: `${UIDateFormater(item.endTime, DateType.DATE_AND_TIME_WITH_SECONDS, timeZone)}`},// , style: { font: {sz: "12", numFmt: '0%'} }
//               { value: `${item.rate}`},// , style: { font: {sz: "12"} }
//               { value: `${item.minIntensity}`},// , style: { font: {sz: "12", numFmt: '0%'} }
//               { value: `${item.maxIntensity}`},// , style: { font: {sz: "12", numFmt: '0%'} }
//             ];
//           })
//         }
//       ]);
//     }
//   }, [data]);

//   // const { month, year } = props;

//   async function exportPdf() {
//     const tableBody = generateTabelBodyForPdf(data, timeZone);
//     const tableHeader = xlsColumns.map(col => `${col.title}`)//[ 'Device ID', 'Sensor ID', 'Active', 'Non Active' ];

//     const doc = new jsPDF({ orientation: 'landscape' });
//     const horizontalPadding = 10;
//     doc.setFontSize(16);
//     doc.text(`IQ100B Report`, horizontalPadding, 10);
//     doc.setFontSize(12);
//     doc.text(`generate for ${exportFileName.split('IQ100B Report - ')[1]}`, horizontalPadding, 25);

//     // doc.addImage(plotImage, 'PNG', horizontalPadding, 30, 120, 100, 'pie graph', 'NONE', 0);
//     doc.autoTable({
//       styles: {
//         valign: 'center',
//         fontSize: 5,
//       },
//       startX: 0,
//       startY: 35,
//       columns: tableHeader,
//       body: tableBody,
//       minCellWidth: 5,
//       cellPadding: 2,
//       didParseCell: (data) => {
//         if (data.section === 'body') {

//           data.cell.cellPadding = 5;

//           switch (data.cell.raw) {
//             case 'V':
//               data.cell.styles.font = 'courier';
//               data.cell.styles.fontStyle = 'bolditalic';
//               data.cell.styles.textColor = 'green';
//               break;
//             case 'X':
//               data.cell.styles.font = 'courier';
//               data.cell.styles.fontStyle = 'bolditalic';
//               data.cell.styles.textColor = 'red';
//               break;

//             default:
//               break;
//           }
//         }
//       }
//     });

//     doc.save(exportFileName);
//   }


//   const onGeneratedReport = useCallback(({filter, users, dates})=>{
//     const usersIds = users.map(user=>user.value)
//     if(!usersIds.length) return alert('Please Choose Users First!')
//     if(!data.length)(async()=>{
//       try{
//         const response = await dispatch(getIQ100BReport({
//           body: {
//             type: filter,
//             usersIds,
//             date: [dates[0], dates[1] ? dates[1] : null],
//             projectId: props.selectedProject
//           }
//         }));
//         console.log('response: ',response)
//         if(response.status){
//           setExportFileName(`IQ100B Report - ${dates[1] ? `${dates[0]} - ${dates[1]}` : dates[0]}`)
//           setData(response.data)
//           setIsGenereated(true)
//         }else {
//           alert('This Report Is Empty!')
//           setData([])
//           setIsGenereated(false)
//         }
//       }catch(error){
//         console.error('useEffect_error_setData: ',error)
//       }
//     })()
//   },[data, setData, dispatch])

//   return (
//     <AppModal
//       closeIcon
//       fullWidth
//       maxWidth='xl'
//       open={props.isVisible}
//       title={context.t('IQ100B - Mobile')}
//       onClose={props.close}
//       content={(
//         <div style={{ height: '75vh' }}>
//           { !props.isFetching && props.selectedProject &&
//             <div style={{
//               display: 'flex',
//             }}>
//               <div>
//                 <Typography component='p' variant='p'>{context.t('Create Report')}</Typography>
//                 <ReportFilter {...{
//                   isVisible: props.isVisible,
//                   context,
//                   users: props.users,
//                   isGenereated,
//                   onClearReport,
//                   onGeneratedReport,
//                   selectedProject: props.selectedProject,
//                   dispatch
//                   }}/>

//             <div className='iq100b-export-buttons'>
//                 <Button
//                   disabled={!isGenereated}
//                   style={{ width: 'max-content' }}
//                   variant="contained"
//                   color="primary"
//                   size="small"
//                   startIcon={<SaveIcon />}
//                   onClick={exportPdf}
//                 >
//                   {context.t('Save as PDF')}
//                 </Button>

//                 <ExcelFile
//                   filename={exportFileName}
//                   element={
//                     <Button
//                       disabled={!isGenereated}
//                       variant="contained"
//                       color="primary"
//                       size="small"
//                       startIcon={<SaveIcon />}
//                     >{context.t('Save as Excel')}</Button>
//                 }>
//                   <ExcelSheet dataSet={xlsData} name={exportFileName}/>
//                 </ExcelFile>
//             </div>
//               </div>
//               <div style={{ display: 'inline-flex', flex: 1, margin: 10, alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
//                 {isGenereated ? <IQ100BReportTable
//                   timeZone={timeZone}
//                   data={data}
//                 /> :
//                 <ThreeCircles
//                   height="30"
//                   width="30"
//                   color={'#6090B1'}
//                 /> }
//               </div>
//           </div>
//           }
//         </div>
//       )}
//     />
//   );
// }

// IQ100BReportsModal.contextTypes = {
//   t: PropTypes.func.isRequired
// };

// IQ100BReportsModal.propTypes = {
//   close: PropTypes.func,
//   data: PropTypes.objectOf({
//     activityData: PropTypes.arrayOf({
//       DaysCount: PropTypes.number,
//       DeviceID: PropTypes.string,
//     }),
//     daysReport: PropTypes.arrayOf({
//       deviceId: PropTypes.string,
//       dates: PropTypes.arrayOf(PropTypes.number)
//     })
//   }),
//   isFetching: PropTypes.bool,
//   isVisible: PropTypes.bool,
//   loadData: PropTypes.func,
//   month: PropTypes.number,
//   selectedProject: PropTypes.object,
//   year: PropTypes.number,
// };

// IQ100BReportsModal.defaultProps = {
//   month: new Date().getMonth(),
//   year: new Date().getFullYear(),
// };

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
//   container: {
//     maxHeight: '600px',
//   },
//   cell: {
//     borderLeft: '1px solid #eee',
//     '&.$nth-of-type(odd)': {
//       background: '#aaa'
//     }
//   }
// });

// const Cells = () => {
//   const classes = useStyles();
//   const cellItem = (item) => (<TableCell key={`cell_${item.title}`} style={{fontWeight: 'bold'}} className={classes.cell}>{item.title}</TableCell>)
//   return(xlsColumns.map(cellItem))
// }

// function IQ100BReportTable({data, timeZone}) {
//   const classes = useStyles();
//   return (
//     <TableContainer component={Paper} className={classes.container}>
//       <Table stickyHeader size='small'>
//         <TableHead>
//           <TableRow>
//             <Cells/>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {
//             data.map((row, index) => {
//               return (
//                 <TableRow hover key={`${row.date}_${index}`}>
//                   {Object.entries(row).map(cell => <TableCell key={`${row.date}_${index}_${cell[0]}`} className={classes.cell}>{`${
//                       `${cell[0]}`.toLocaleLowerCase().includes(`date`) ||
//                       `${cell[0]}`.toLocaleLowerCase().includes(`time`) ?
//                       UIDateFormater(cell[1], DateType.DATE_AND_TIME_WITH_SECONDS, timeZone) :
//                       cell[1]
//                     }`}</TableCell>)}
//                 </TableRow>);
//             })
//           }
//         </TableBody>
//       </Table>
//     </TableContainer>

//   );
// }

// IQ100BReportTable.propTypes = {
//   date: PropTypes.objectOf(Date),
// };

// export default React.memo(IQ100BReportsModal)
