import { connect } from 'react-redux';
import { fetchNoiseAlerts, setFilters, setDefFilters } from '../../actions/NoiseAlertsActions';
import { FILTERS_NOISE_ALERTS } from '../../constants/SettingsKeys';

import NoiseAlertsSettings from '../../components/AlertsSettings/NoiseAlertsSettings';
import { invalidateProject } from "../../actions/setters";

const mapStateToProps = (state, ownProps) => {
  const {
    selectedProject
  } = state.leaksList;
  const user = state.leaksList.user;
  return Object.assign({}, {
    user,
    selectedProject,
    initialValues: state.leaksList.filters.noiseAlerts.defFilters,
    optionList: state.leaksList.optionList.options
  }, ownProps);
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values, dispatch, props) => {
    const {
      selectedProject,
      processFilters,
      cleanEmptyFilters
    } = props;

    var filters = processFilters(Object.assign({}, values));
    delete filters.defFilters.sortBy;
    delete filters.defFilters.sortByDir;
    dispatch(invalidateProject(selectedProject));
    dispatch(setDefFilters(filters.defFilters));
    dispatch(setFilters(cleanEmptyFilters(filters.filters)));
    dispatch(fetchNoiseAlerts(selectedProject));

    localStorage.setItem(FILTERS_NOISE_ALERTS, JSON.stringify(filters.defFilters));
  }
});

const CAlertsSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(NoiseAlertsSettings);

export default CAlertsSettings;
