import * as types from '../constants/ActionTypes';
import { UninstallMode } from '../constants/Misc';

const initialState = {
  installContext: 'SOPs',
  editedInterference: null,
  newInstallItem: null,
  newInstallItemDefaults: {
    AvizarCode: 100,
    State: 0,
  },
  configs: null,
  sensorDetails: null,
  isFetchingDetails: false,
  uninstallMode: UninstallMode.NONE,
  uninstallBusyDeviceDetails: null,
  isWorking: false,
  interferenceMarkMode: false, // TODO: remove
};

const install = (state = initialState, action) => {
  switch (action.type) {
    case '@@redux-form/BLUR':
      if (action.meta.form == 'sopForm' && action.meta.field == 'Coordinate') {
        const Coordinates = require('coordinate-parser');
        try {
          const positionObj = new Coordinates(action.payload);
          const potision = [positionObj.getLongitude(), positionObj.getLatitude()];
          return Object.assign({}, state, {
            newInstallItem: Object.assign({}, state.newInstallItem, {
              position: potision,
            })
          });
        } catch (e) {
          return state;
        }
      } else {
        return state;
      }

    case types.CHANGE_INSTALL_CONTEXT:
      return Object.assign({}, state, {
        installContext: action.context,
        newInstallItem: null,
      });

    case types.ADD_NEW_INSTALL_ITEM:
      return Object.assign({}, state, {
        newInstallItem: {
          id: -1,
          item: {},
        }
      });

    case types.SET_NEW_SOP_TEMP_POSITION:
      return Object.assign({}, state, {
        newInstallItem: Object.assign({}, state.newInstallItem, {
          position: action.position,
        })
      });

    case types.CLEAR_INSTALL_ITEM:
    case types.SELECT_LEAK:
      return Object.assign({}, state, {
        newInstallItem: null,
        sensorDetails: null,
        uninstallMode: UninstallMode.NONE,
        uninstallBusyDeviceDetails: null
      });

    case types.SELECT_SOP: //TODO: check, maybe not used (and bad action case).
      return Object.assign({}, state, {
        newInstallItem: {
          id: action.user.UID,
          item: action.user,
        }
      });

    case types.SELECT_SENSOR: //TODO: check, maybe not used (and bad action case).
      return Object.assign({}, state, {
        newInstallItem: {
          id: action.customer.ID,
          item: action.customer,
        }
      });

      case types.REQUEST_CONFIGS:
        return { ...state, configs: [] };
      case types.RECEIVE_CONFIGS:
      return { ...state, configs: action.configs };

    case types.REQUEST_SONSOR_ON_SOP_DETAILS:
      return Object.assign({}, state, {
        sensorDetails: null,
        isFetchingDetails: true,
      });

    case types.RECEIVE_SONSOR_ON_SOP_DETAILS:
      return Object.assign({}, state, {
        sensorDetails: {
          sopID: action.sopID,
          ...action.data
        },
        isFetchingDetails: false,
      });

    case types.SET_SENSORS_UNINSTALL_MODE:
      return Object.assign({}, state, {
        uninstallMode: action.isUninstall,
      });

    case types.SET_SENSOR_FOR_UNINSTALL_DETAILS:
      return Object.assign({}, state, {
        uninstallBusyDeviceDetails: action.details,
      });

    case types.SET_INSTALL_ACTION_WORKING_STATE:
      return Object.assign({}, state, {
        isWorking: action.isWorking
      });

    case types.INSTALL_ACTION_DONE_SUCCESSFULLY:
      return Object.assign({}, state, {
        uninstallMode: UninstallMode.NONE,
        uninstallBusyDeviceDetails: null,
      });

    case types.MARK_INTERFERENCES:
      return Object.assign({}, state, {
        interferenceMarkMode: true,
      });

    case types.SET_INTERFERENCE_POLYGON_POINTS:
      // const obj = state.newInstallItem ? state.newInstallItem.item : {};
      if (state.newInstallItem){
        return Object.assign({}, state, {
          newInstallItem: Object.assign({}, state.newInstallItem, {
            item: Object.assign({}, state.newInstallItem.item, {
              polygon: action.points
            })
          })
        });
      }
      else {
        // editedInterference
        return Object.assign({}, state, {
          editedInterference: action.points
        });
      }

    case types.SET_SEFAULT_FOR_NEW_SOP:
      return Object.assign({}, state, {
        newInstallItemDefaults: Object.assign({}, state.newInstallItemDefaults, {
          [action.name]: action.value,
        })
      });

    default: return state;
  }
};

export default install;
