import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MobileSamplesTable from './MobileAlertsTabs/MobileSamplesTable';

import Paper from '@mui/material/Paper';
import TabsContainer from '../Custom/Tabs/TabsContainer';
import { useSelector } from 'react-redux';

const MobileSamplesTabs = (props, context) => {
  const {
    uiInfo,
    selectedFeature,
    featureType,
    showDetails,
    timeZone,
    samples,
    samplesIndexMap,
  } = props;

  const tasks = useSelector((state) => state.mobile.tasks.items);
  const [samplesCount, setSamplesCount] = React.useState();

  const panelStyle = { height: 0.495 * uiInfo.pageHeight };

  const panelClasses = classNames('alert-details', { show: showDetails });

  function closePanel() {
    setTimeout(() => {
      props.clearSelectedFeature();
    }, 500);
    props.toggleDetailsVisibility(false)
  }

  React.useEffect(() => {
    if (featureType === 'mobileTask') {
      if (selectedFeature.length > 0) {
        if (!showDetails) {
          props.toggleDetailsVisibility(true);
        }

        // calculate total samples:
        const selectedTasks = tasks.filter(({ task_uuid }) =>
          selectedFeature.includes(task_uuid)
        );
        const total = selectedTasks.reduce(
          (sum, task) => sum + task.samples_count,
          0
        );
        setSamplesCount(total);
      } else if (showDetails) {
        props.toggleDetailsVisibility(false);
      }
    } else {
      if (showDetails) {
        props.toggleDetailsVisibility(false);
      }
    }
  }, [featureType, selectedFeature, showDetails]);

  // const onPageIndexChange = (page) => {
  //   // console.log(page);
  // };

  return (
    <Paper className={panelClasses} style={panelStyle}>
      <TabsContainer
        title={context.t('task_samples', { alertId: selectedFeature })}
        handleClose={closePanel}
        tabs={[
          {
            label: context.t('samples'),
            component: (
              <MobileSamplesTable
                setIsAddMobileAlertOpen={props.setIsAddMobileAlertOpen}
                selectedFeature={selectedFeature}
                samplesCount={samplesCount}
                // onPageIndexChange={onPageIndexChange}
                timeZone={timeZone}
                uiInfo={uiInfo}
                samples={samples}
                samplesIndexMap={samplesIndexMap}
                onSelectMobileSample={props.selectMobileSample}
                selectedSample={props.selectedSample}
                checkedSamples={props.checkedSamples}
                displayItems={props.displayItems}
                setDisplayItems={props.setDisplayItems}
                setCheckedSamples={props.setCheckedSamples}
                options={props.options}
                notify={props.notify}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

MobileSamplesTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileSamplesTabs;
