/* eslint-disable camelcase */
import React from 'react';
import Plot from 'react-plotly.js';
import { graphsBackgroundColor } from '../../constants/Misc';

const config = {
  displaylogo: false,
  responsive: true,
  displayModeBar: true,
  modeBarButtonsToRemove: [
    'drawrect',
    'zoom2d',
    'zoomIn2d',
    'zoomOut2d',
    'resetScale2d',
    'select2d',
    'lasso2d',
    'toggleSpikelines',
    'resetViewMapbox',
    'toImage',
  ],
};

function getShortDateString(dateMillis, timeZone) {
  const finalDateMillis =
    timeZone !== 'local'
      ? dateMillis
      : dateMillis - new Date().getTimezoneOffset() * 60 * 1000;
  const date = new Date(finalDateMillis).toISOString();
  const [dateSection, timeSection] = date.split('T');
  const [_year, month, day] = dateSection.split('-');
  const [hour, minuts] = timeSection.split(':');
  return `${day}/${month}, ${hour}:${minuts}`;
}

const DateDistanceScatterTrend = (props) => {
  const getNameByProbabilityIndex = (index) => {
    switch (index) {
      case 0:
        return 'Low';
      case 1:
        return 'Med';
      case 2:
        return 'Hi';
      default:
        return '--';
    }
  };

  const getFillColorByProbabilityIndex = (index) => {
    const hiValue = 'red';
    const lowValue = 'black';

    switch (index) {
      case 0:
        return lowValue;
      case 1:
        return lowValue;
      case 2:
        return hiValue;
      default:
        return lowValue;
    }
  };

  const getOpacityByProbabilityIndex = (index) => {
    const lowOpacityValue = 0.6;
    switch (index) {
      case 0:
        return lowOpacityValue / 3;
      case 1:
        return lowOpacityValue;
      case 2:
        return 1;
      default:
        return lowOpacityValue / 3;
    }
  };

  const layout = {
    paper_bgcolor: graphsBackgroundColor,
    showlegend: false,
    autosize: false,
    width: props.small ? 270 : 360,
    height: props.small ? 200 : 250,
    title: {
      text: props.title,
      font: { size: props.titleSize || 17 },
      x: 0.02,
      y: 0.97,
    },
    margin: {
      l: 50,
      r: 10,
      b: 65,
      t: 30,
    },
    hovermode: 'closest',
    yaxis: { title: { text: props.titleY, font: { color: '#8d8d8d' } } },
    xaxis: {
      // type: 'date',
      autorange: 'reversed',
      rangemode: 'tozero',
      title: {
        text: props.titleX,
        font: { color: '#8d8d8d' },
      },
      tickfont: {
        size: 10,
      },
    },
  };

  const dates = Object.keys(props.tensor).map((key) =>
    getShortDateString(Number(key), props.timeZone)
  );

  const limit = 20;
  const recentDates = dates.slice(0, limit);

  const low_med_hight_probabilityValues = [
    Object.values(props.tensor).map((val) => val[0] || {}),
    Object.values(props.tensor).map((val) => val[1] || {}),
    Object.values(props.tensor).map((val) => val[2] || {}),
  ].map((arr) => arr.slice(0, limit));

  const data = low_med_hight_probabilityValues.map((arrObjValues, index) => ({
    x: recentDates,
    y: arrObjValues.map((obj) => {
      const val = obj.distance ? obj.distance * props.distanceFactor : 0;
      return val;
    }),
    name: getNameByProbabilityIndex(index),
    text: props.dates,
    mode: 'markers',
    type: 'scatter',
    marker: {
      symbol: 'circle',
      size: 6,
      color: getFillColorByProbabilityIndex(index),
      opacity: getOpacityByProbabilityIndex(index),
      line: {
        width: 1,
        color: '#000000',
      },
    },
  }));
  return <Plot layout={layout} config={config} data={data} />;
};

export default DateDistanceScatterTrend;
