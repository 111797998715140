import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MobileSamplesTable from './MobileAlertsTabs/MobileSamplesTable';

import Paper from '@mui/material/Paper';
import TabsContainer from '../Custom/Tabs/TabsContainer';
import MobileDevicesBIST from './MobileDevicesBIST';
import {
  decToHex,
  formatMobileDeviceID,
} from '../../containers/UIhelper/UIhelper';

const MobileDevicesTabs = (props, context) => {
  const {
    uiInfo,
    selectedFeature,
    selectedDeviceBist,
    featureType,
    showDetails,
    timeZone,
    samples,
    samplesIndexMap,
    selectedDeviceId,
  } = props;
  const panelStyle = { height: 0.435 * uiInfo.pageHeight };

  const panelClasses = classNames('alert-details', { show: showDetails });

  function closePanel() {
    props.toggleDetailsVisibility(false);
    props.clearSelectedFeature();
  }

  React.useEffect(() => {
    if (featureType === 'mobileTask') {
      if (selectedFeature.length > 0 || selectedDeviceBist.length > 0) {
        if (!showDetails) {
          props.toggleDetailsVisibility(true);
        }
      } else if (showDetails) {
        props.toggleDetailsVisibility(false);
      }
    } else {
      if (showDetails) {
        props.toggleDetailsVisibility(false);
      }
    }
  }, [featureType, selectedFeature, showDetails]);

  const onPageIndexChange = (page) => {
    // console.log(page);
  };

  return (
    <Paper className={panelClasses} style={panelStyle}>
      <TabsContainer
        title={context.t('device_id_val', {
          id:
            selectedDeviceId &&
            formatMobileDeviceID(decToHex(selectedDeviceId)),
        })}
        handleClose={closePanel}
        tabs={[
          {
            label: context.t('samples'),
            component: (
              <MobileDevicesBIST
                timeZone={timeZone}
                uiInfo={uiInfo}
                bist={props.selectedDeviceBist}
                notify={props.notify}
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

MobileDevicesTabs.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileDevicesTabs;
