import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column } from 'fixed-data-table-2';

import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

require('./AlertsTable.scss');

class PressureAlertsTable extends React.Component {


  constructor(props) {
    super(props);

    this.showAddAlertForm = this.showAddAlertForm.bind(this);

    this.state = {
      isAddAlertOpen: false
    };
  }

  showAddAlertForm(isShown) {
    if (this.state.isAddAlertOpen != isShown) {
      this.setState({
        isAddAlertOpen: isShown
      });
    }
  }

  render() {

    const { items, indexMap, selectedFeature, isDisplayCityColumn, addingManualAlertMode,
      pHeight, pWidth, sortProp,
      rowClickEvent, goToRow, rowClassNameGetter, t } = this.props;

    //document.title = 'AQS • Alerts';

    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };

    return (
      <div>
        <Table
          maxHeight={pHeight - 93}
          width={pWidth}
          rowsCount={items.length}
          rowHeight={30}
          headerHeight={30}
          onRowClick={rowClickEvent}
          rowClassNameGetter={rowClassNameGetter}
          scrollToRow={goToRow}
          {...this.props}
        >
          <Column columnKey="id" width={40} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="id" />} />
          <Column columnKey="DeviceID" width={110} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="DeviceID" />} />
          <Column columnKey="SessionNumEvents" width={30} flexGrow={0.75}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="SessionNumEvents" />} />
          <Column columnKey="CreateDate" width={80} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="CreateDate" dateType={DateType.DATE} />} />
          <Column columnKey="EndDate" width={80} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="EndDate" dateType={DateType.DATE} />} />
          <Column columnKey="LastEventTime" width={80} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="LastEventTime" dateType={DateType.DATE} />} />
          <Column columnKey="StreetAddress" width={95} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="StreetAddress" />} />
        </Table>

        {items && items.length > 0 &&
          <CTableActions
            type="pressure"
            notify={this.props.notify}
          />
        }

        {items && items.length > 0 &&
          <span style={totalItemsStyle}>{t('selected_selectedindex_total_total', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
        }

        {/* <button
          className="btn-success create-item"
          onClick={() => { this.props.addNewManualAlert(true) }}
          type="button"
        >
          <i title={this.context.t('Add')} />
        </button> */}

      </div>
    );
  }
}

PressureAlertsTable.contextTypes = {
  t: PropTypes.func.isRequired
};

PressureAlertsTable = TableMixin(PressureAlertsTable, null /*CAlertsSettings*/);
export default PressureAlertsTable;


/*

ID: 3
DeviceID: "351580053917205"
CreateDate: 1581548175000
EndDate: 1581634575000
AutoCloseDate: 1581864221620
LastEventTime: 1581548175000
LastEventMinValue: 136
LastEventMaxValue: 157
LastEventFrequency: 512
SessionNumEvents: 1
SessionMinValue: 136
SessionMaxValue: 157
LastEventData: "12:02:2020 22:56:15-512-512-149,144,152,145,

*/
