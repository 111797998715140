import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';
import TextCell from '../../components/TextCell/TextCell';
import { Button } from '@mui/material';

require('fixed-data-table-2/dist/fixed-data-table.css');
require('../CouplesTabs/OutputTab/CoupleOutputTab.scss');

const classNames = require('classnames');


export default class TotalPipeLengthTab extends React.Component {

	constructor(props) {
      super(props);
      this.getPipeLengthTable = this.getPipeLengthTable.bind(this);
    }

    getPipeLengthTable() {
      this.props.getCalculateTotalPipes();
    }

    render() {

    const { distanceFactor, uiInfo } = this.props;
    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight;

    const divLeft = {
      width: '30%',
      float: 'left',
    };

    const divRight = {
      width: '70%',
      float: 'left',
      display: 'flex',
    };

    const tableHeight = pHeight - 117;
    const tableWidth = pWidth * 0.35;
    const columnWidth = 40;
    return (
      <div>

        <div style={divLeft}>
          <Button variant='contained' style={{ margin: '20px' }} className="btn btn-success" type="submit" onClick={this.getPipeLengthTable} >{this.context.t('pipe_length')}</Button>
        </div>
        <div style={divRight}>
          <div className="couples-table">
            <Table
              rowsCount={this.props.calculatedTotalLengthTable.materialsArray.length}
              width={tableWidth}
              maxHeight={tableHeight}
              headerHeight={40}
              rowHeight={25}>
              <Column
                columnKey="MaterialName"
                header={this.context.t('material_name')}
                cell={<TextCell data={this.props.calculatedTotalLengthTable.materialsArray} field="Name" />}
                width={columnWidth}
                flexGrow={1}
              />
              <Column
                columnKey="TotalMeters"
                header={this.context.t('total_length_unitslable', { unitsLable: this.props.units})}
                cell={<TextCell data={this.props.calculatedTotalLengthTable.materialsArray} field="TotalLength" distanceFactor={distanceFactor} />}
                width={columnWidth}
                flexGrow={1}
              />
            </Table>
          </div>
          <div className="couples-table">
            <Table
              rowsCount={this.props.calculatedTotalLengthTable.diametersArray.length}
              width={tableWidth}
              maxHeight={tableHeight}
              headerHeight={40}
              rowHeight={25}>
              <Column
                columnKey="PipeDiameterInch"
                header={this.context.t('diameter_inch')}
                cell={<TextCell data={this.props.calculatedTotalLengthTable.diametersArray} field="PipeDiameterInch" />}
                width={columnWidth}
                flexGrow={1}
              />
              <Column
                columnKey="TotalMeters"
                header={this.context.t('total_length_unitslable', { unitsLable: this.props.units})}
                cell={<TextCell data={this.props.calculatedTotalLengthTable.diametersArray} field="TotalLength" distanceFactor={distanceFactor} />}
                width={columnWidth}
                flexGrow={1}
              />
            </Table>
          </div>
        </div>
        <Button variant='contained' onClick={this.props.exportPipesReport}>{this.context.t('export')}</Button>
        <Button variant='contained' onClick={this.props.exportAlertInvestigation}>{this.context.t('alerts')}</Button>
      </div>
		)
	}
}

TotalPipeLengthTab.contextTypes = {
	t: PropTypes.func.isRequired
}
