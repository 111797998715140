import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column } from 'fixed-data-table-2';
import TextCell from '../../components/TextCell/TextCell';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

import { useDispatch } from 'react-redux';
import actions from '../../actions/AlertsValveActions';

const AlertsValveSamples = (props, context) => {
  const { uiInfo, samples, local, selectedAlertSample, timeZone } = props;
  const dispatch = useDispatch();

  let pWidth = 0.395 * (uiInfo.pageWidth - 10);
  let pHeight = 0.494 * uiInfo.pageHeight;

  const handleRowClick = (e, rowIndex) => {
    const selecedItem = samples[rowIndex];
    dispatch(actions.selectValveAlertSample(selecedItem));
  };

  const getRowClassName = (rowIndex) => {
    if (selectedAlertSample) {
      const rowItem = samples[rowIndex];
      if (selectedAlertSample.id === rowItem.id) {
        return 'active-row';
      }
    }
  };

  return (
    <div className='samples-table'>
      <Table
        rowsCount={props.samples.length}
        width={pWidth}
        maxHeight={pHeight - 117}
        headerHeight={30}
        rowHeight={30}
        onRowClick={handleRowClick}
        rowClassNameGetter={getRowClassName}
      >
        <Column
          columnKey='time_stamp'
          header={context.t('time_stamp')}
          cell={
            <TextCell
              data={samples}
              field='time_stamp'
              additional={timeZone}
              dateType={DateType.DATE_AND_TIME}
            />
          }
          width={120}
          flexGrow={1}
        />
        <Column
          columnKey='sensor_id'
          header={context.t('sensor_id')}
          cell={<TextCell data={samples} field='sensor_id' />}
          width={40}
          flexGrow={1}
        />
        <Column
          columnKey='event_rms'
          header={context.t('event_rms')}
          cell={<TextCell data={samples} field='event_rms' />}
          width={20}
          flexGrow={1}
        />
        <Column
          columnKey='base_mean'
          header={context.t('base_mean')}
          cell={<TextCell data={samples} field='base_mean' />}
          width={20}
          flexGrow={1}
        />
        <Column
          columnKey='base_std'
          header={context.t('base_std')}
          cell={<TextCell data={samples} field='base_std' />}
          width={20}
          flexGrow={1}
        />
        <Column
          columnKey='samples_mean'
          header={context.t('samples_mean')}
          cell={<TextCell data={samples} field='samples_mean' />}
          width={20}
          flexGrow={1}
        />
        <Column
          columnKey='samples_std'
          header={context.t('samples_std')}
          cell={<TextCell data={samples} field='samples_std' />}
          width={20}
          flexGrow={1}
        />
        <Column
          columnKey='event_gain'
          header={context.t('event_gain')}
          cell={<TextCell data={samples} field='event_gain' />}
          width={20}
          flexGrow={1}
        />
        <Column
          columnKey='samples_gain'
          header={context.t('samples_gain')}
          cell={<TextCell data={samples} field='samples_gain' />}
          width={20}
          flexGrow={1}
        />
        <Column
          columnKey='base_magnitude'
          header={context.t('base_magnitude')}
          cell={<TextCell data={samples} field='base_magnitude' />}
          width={30}
          flexGrow={1}
        />
        <Column
          columnKey='samples_magnitude'
          header={context.t('samples_magnitude')}
          cell={<TextCell data={samples} field='samples_magnitude' />}
          width={30}
          flexGrow={1}
        />
        <Column
          columnKey='factor_baseline_rms'
          header={context.t('factor_baseline_rms')}
          cell={<TextCell data={samples} field='factor_baseline_rms' />}
          width={20}
          flexGrow={1}
        />
      </Table>
    </div>
  );
};

AlertsValveSamples.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AlertsValveSamples;
