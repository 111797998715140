import * as types from '../constants/ActionTypes';

export function log(state = {}, action) {
    switch (action.type) {
        case types.REQUEST_DEVICE_LOG:
            return Object.assign({}, state, {
                [action.id]: { isFetching: true, data: [] }
            });
        case types.RECEIVE_DEVICE_LOG:
            return Object.assign({}, state, {
                [action.id]: { data: action.log.data, isFetching: false }
            });
        default:
            return state;
    }
}