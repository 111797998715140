import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import * as actionsHistory from './HistoryActions';
import * as actionsSensors from './SensorsActions';
import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';
// import fetch from 'isomorphic-fetch';

//--------------------------------
// Receive & Request
//--------------------------------

function receiveNoiseSamples(project, noiseSamples, noiseSamplesIcons, indexMap) {
  return {
    type: types.RECEIVE_NOISE_SAMPLES,
    project,
    noiseSamples,
    noiseSamplesIcons,
    indexMap
  };
}

function requestNoiseSamples(project) {
  return {
    type: types.REQUEST_NOISE_SAMPLES,
    project
  };
}

//--------------------------------
// Fetch
//--------------------------------

export function fetchNoiseSamplesIfNeeded(project, force = false) {
  return (dispatch, getState) => {
    if (shouldFetchNoiseSamples(getState(), project, force)) {
      return dispatch(fetchNoiseSamples(project));
    }
  };
}

function shouldFetchNoiseSamples(state, project, force) {
  const noiseSamples = state.leaksList.leaksByProject[project].noiseSamples;
  if (force || !noiseSamples) {
    return true;
  }
  return !noiseSamples.isFetching;
}

function fetchNoiseSamples(project) {
  return (dispatch, getState) => {
    const state = getState();
    const filters = state.leaksList.filters.sensorsFilters;
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.NOISE_SAMPLES_ENDPOINT + "?filters=" + JSON.stringify(filters);
    dispatch(requestNoiseSamples(project));
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        let noiseSamples = json.data;
        setNoiseColorValue(noiseSamples, 'Nc1', 'colorNc1');
        setNoiseColorValue(noiseSamples, 'Nc3', 'colorNc3');
        setNoiseColorValue(noiseSamples, 'Noise', 'colorValue');
        return [noiseSamples, getIconFeatures('noiseSamples', noiseSamples), setIndexes(noiseSamples, 'id')];
      })
      .then(args => {
        dispatch(receiveNoiseSamples(project, ...args));
      });
  };
}

function setNoiseColorValue(noiseSamples, forAttrName, destAttrName) {
  if (noiseSamples.length) {

    // console.log('noiseSamples', noiseSamples);

    let tempNoiseSample2 = noiseSamples.slice();

    //First stage - remove 10% of largest noises
    sortByProperty(tempNoiseSample2, forAttrName); //Sort by Noise
    const countToRemoveFromArray = (tempNoiseSample2.length * 0.1).toFixed(0);
    for (let i = 0; i < countToRemoveFromArray; i++) {
      tempNoiseSample2.pop();
    }

    //Second stage - calculate average
    let sum = 0;
    //let sumSqrt = 0;
    tempNoiseSample2.forEach(val => {
      sum += val[forAttrName];
      //sumSqrt += Math.sqrt(val[forAttrName]);
    });
    const average = sum / tempNoiseSample2.length;

    //Third stage - calculate variance
    //let sensorVar = 0;
    let sensorVarSqrt = 0;
    tempNoiseSample2.forEach(value => {
      //sensorVar += Math.pow((value[forAttrName] - average), 2);
      sensorVarSqrt += Math.pow((Math.sqrt(value[forAttrName]) - average), 2);
    });

    //sensorVar = sensorVar / tempNoiseSample2.length;//Maybe need sqrt
    sensorVarSqrt = sensorVarSqrt / tempNoiseSample2.length;
    //const sigma = Math.sqrt(sensorVar);
    const sigmaSqrt = Math.sqrt(sensorVarSqrt);

    let upper = average + 4 * sigmaSqrt;  //sensorVar;
    const lower = average - sigmaSqrt / 2;  //sigma / 2;   // Bori

    if (upper == lower) { // avoid dividing by zero...
      upper += 1;
    }

    //Fourth stage - create the icon according to the Noise
    noiseSamples.forEach(item => {
      let c;

      if (item[forAttrName] > (upper)) {
        //Above upper
        c = 255;
      }
      else if (item[forAttrName] < lower) {
        c = 0;
      }
      else {
        c = ((item[forAttrName] - lower) / (upper - lower)) * 255;
      }

      item[destAttrName] = Math.round(c / 10);
    });
    //noiseSamples = ARRAY;
  }
}

function sortByProperty(array, propertyName) {
  return array.sort(function (a, b) {
    return a[propertyName] - b[propertyName];
  });
}

export const closeNoiseAlert = (sensorId, noiseAlertId) => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const path = endPoints.END_POINT + '/sensor/' + sensorId + '/noiseAlert/' + noiseAlertId;

    fetchMiddleware(path, { method: 'DELETE' }, getState).then(json => {
      dispatch(fetchNoiseSamples(projectId));
      dispatch(actionsSensors.fetchSensorsIfNeeded(projectId, true));
      dispatch(actionsSensors.fetchSensorDetails(projectId, sensorId));
      dispatch(actionsHistory.fetchSensorHistoryIfNeeded(projectId, sensorId, true));
    });
  };
};

export const setNoiseColorByAttribute = (attrName) => (
  {
    type: types.SET_NOISE_SAMPLE_COLOR_SOURCE_ATTR,
    colorAttr: attrName
  }
);
