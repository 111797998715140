import { connect } from 'react-redux';

import { updateSensor } from '../../actions/SensorsActions';

import DeviceTab from '../../components/SensorsTabs/DeviceTab/DeviceTab';

const diff = require('deep-diff').diff;

const mapStateToProps = (state, ownProps) => {
  var deviceTypeOptions  = state.leaksList.optionList.options.DeviceType || [];
  const {selectedProject, user} = state.leaksList;
  const selectedFeature = state.leaksList.leaksByProject.selectedFeature;
  if (selectedProject) {
    let leaks = state.leaksList.leaksByProject[selectedProject];
    if (leaks && selectedFeature && leaks.sensors && leaks.sensors.sensors && leaks.sensors.indexMap) {
      let log;
      let indexMap = state.leaksList.leaksByProject[selectedProject].sensors.indexMap;
      var initValues = leaks.sensors.sensors[indexMap[selectedFeature]];
      if (initValues != null) {
        log = leaks.log[initValues.DeviceID] || { data: [] };
      }

      return Object.assign({}, {
        units: state.local.units,
        timeZone: state.local.timeZone,
        user,
        deviceTypeOptions,
        selectedFeature,
        selectedProject,
        initialValues: initValues,
        log
      }, ownProps);
    }
  }
  return {units: state.local.units, deviceTypeOptions, user, log : {data: []} };
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {

      var differences = diff(values, props.initialValues);
      let res = {};
      differences.forEach((diff) => {
        res[diff.path[0]] = diff.lhs
      })
      res.SOPointID = props.initialValues.SOPointID;
      if (props.initialValues.DeviceID) {
        res.DeviceID = props.initialValues.DeviceID;
      }
      dispatch(updateSensor(res));
    }
  }
}

const CDeviceTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceTab)

export default CDeviceTab
