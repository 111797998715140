/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import HeatMap from "react-heatmap-grid";
import Plot from 'react-plotly.js';
import { SegmentedControl } from 'segmented-control';

const classNames = require('classnames');
const numeral = require('numeral');

const modeEnum = {
   LEAKS: 'leaks',
   INFRASTRUCTURE: 'infra',
   LEAKS_BY_INFRA: 'leaksByInfra',
};

export default class AnalysisTab extends React.Component {

   static get propTypes() {
      return {
         layoutVisibility: PropTypes.string,
         rawLeaksData: PropTypes.array,
         valuesSets: PropTypes.object,
         pipesList: PropTypes.array,
         pipesValuesSets: PropTypes.object,
         xAttribute: PropTypes.string,
         yAttribute: PropTypes.string,
         onSelectGroup: PropTypes.func,
      };
   }

   constructor(props) {
      super(props);

      this.onChange = this.onChange.bind(this);
      this.updateStateForMode = this.updateStateForMode.bind(this);
      this.heatmapCellRender = this.heatmapCellRender.bind(this);

      this.state = {
         mode: modeEnum.LEAKS,
         plotLayout: {
            autorange: true,
            barmode: 'stack',
            width: 1340,
            height: 380,
            margin: {
               t: 30,
               b: 50,
               l: 60,
               r: 30
            },
            xaxis: {
               type: 'category',
            },
            showlegend: true,
            legend: {
               x: 1,
               y: 0.5
            }
         },
         plotConfig: {
            displaylogo: false,
            responsive: true,
            displayModeBar: true,
         }
     };
   }

   UNSAFE_componentWillMount() {
      this.updateStateForMode(this.state.mode);
   }

   updateStateForMode(mode) {
      const { analysisData, xAttribute, yAttribute, valuesSets, pipesValuesSets } = this.props;

      const heatMapData2D = [];
      let plotData = [];
      let analysisSpecificData;
      let xValuesSet = [];
      let yValuesSet = [];

      switch (mode) {
        case modeEnum.LEAKS:
          analysisSpecificData = analysisData.fixedLeaks;
          break;

        case modeEnum.INFRASTRUCTURE:
          analysisSpecificData = analysisData.infrastructure;
          break;

        case modeEnum.LEAKS_BY_INFRA:
          analysisSpecificData = analysisData.leaksByInfrastructure;
          break;

        default:
          break;
      }
      plotData = Object.entries(analysisSpecificData).map((category) => {
        const categoryName = category[0];
        const xValues = Object.keys(category[1]);
        const yValues = Object.values(category[1]);

        xValues.forEach((xVal) => {
          if (xValuesSet.indexOf(xVal) == -1) {
            xValuesSet.push(xVal);
          }
        });

        return {
          type: 'bar',
          name: categoryName,
          x: xValues,
          y: yValues
          };
      });

      xValuesSet = xValuesSet.sort((a, b) => a - b);

      yValuesSet = Object.keys(analysisSpecificData);

      plotData = plotData.sort((a, b) => b.x.length - a.x.length);

      yValuesSet.forEach((category) => {
        const quantityArray = [];
        xValuesSet.forEach((year) => {
          quantityArray.push(analysisSpecificData[category][year] || 0);
        });
        heatMapData2D.push(quantityArray);
      });

      // let yVal = 'a';
      // Object.entries(boundle).forEach((itrTraceValues) => {
      //   data.push(itrTraceValues[1]);
      //   let trace = {
      //      type: 'bar',
      //      name: yVal,
      //      x: itrTraceValues[0],
      //      y: itrTraceValues[1],
      //   };
      //   plotData.push(trace);

      // });

      // preparing the data:
      // const xAxisValues = (mode == modeEnum.INFRASTRUCTURE) ? pipesValuesSets[xAttribute] : valuesSets[xAttribute];
      // const yAxisValues = (mode == modeEnum.INFRASTRUCTURE) ? pipesValuesSets[yAttribute] : valuesSets[yAttribute];

      /*

      // create 2d array: y-materials, x-years. input data for heatmap.
      yAxisValues.forEach((yVal) => {
         let itrRowValues = [];
         xAxisValues.forEach((xVal) => {
            let cellValue;

            switch (mode) {
               case modeEnum.LEAKS: {
                  const filteredArray = rawLeaksData.filter((item) => item[xAttribute] == xVal && item[yAttribute] == yVal);
                  cellValue = filteredArray.length;
                  break;
               }
               case modeEnum.INFRASTRUCTURE: {
                  // sum the Length of each pipe section:
                  cellValue = 0;
                  pipesList.forEach((section) => {
                     if (section[xAttribute] == xVal && section[yAttribute] == yVal) {
                        cellValue += section.Length;
                     }
                  });
                  break;
               }

               case modeEnum.LEAKS_BY_INFRA: {
                  cellValue = 0;
                  let pipesCategorieLength = 0;
                  pipesList.forEach((section) => {
                     if (section[xAttribute] == xVal && section[yAttribute] == yVal) {
                        pipesCategorieLength += section.Length;
                     }
                  });

                  if (pipesCategorieLength > 0) {
                     const filteredArray = rawLeaksData.filter((item) => item[xAttribute] == xVal && item[yAttribute] == yVal);
                     const pipeLengthByKm = (Math.max(pipesCategorieLength, 200) / 1000); // not low than 200 meters
                     cellValue = filteredArray.length / pipeLengthByKm;
                  }
                  break;
               }

               default:
                  break;
            }
            itrRowValues.push(cellValue);
         });
         data.push(itrRowValues);
         let trace = {
            type: 'bar',
            name: yVal,
            x: xAxisValues,
            y: itrRowValues,
         };
         plotData.push(trace);

      });

*/

      this.setState({
         heatmap: {
            x: xValuesSet,
            y: yValuesSet,
            z: heatMapData2D
         },
         plotData: plotData,
         plotLayout: Object.assign({}, this.state.plotLayout, {
          xaxis: Object.assign({}, this.state.plotLayout.xaxis, {
            categoryorder: "array",
            categoryarray:  xValuesSet,
          })
         })
      });
   }

   onChange(newModeValue) {
      this.setState({
         mode: newModeValue
      }, () => {
         this.updateStateForMode(this.state.mode);
      });
   }

   heatmapCellRender(value) {
      let renderValue = '';
      if (value != null) {
         switch (this.state.mode) {
            case modeEnum.LEAKS: {
               renderValue = `${value}`;
               break;
            }

            case modeEnum.INFRASTRUCTURE: {
               let unit;
               let val;
               if (value < 1000) {
                  unit = 'm';
                  val = value.toFixed(1);
               } else {
                  unit = 'km';
                  val = (value / 1000).toFixed(1);
               }
               renderValue = `${val} ${unit}`;
               break;
            }

            case modeEnum.LEAKS_BY_INFRA: {
               if (value == 0) {
                  renderValue = 0;
               } else if (value < 0.1) {
                  renderValue = numeral(value).format('0.00e+0');
               } else {
                  renderValue = value.toFixed(1);
               }
               break;
            }

            default: {
               renderValue = `${value}`;
               break;
            }
         }
      }
      return (renderValue);
   }

   render() {
      //const { heatMapData } = this.props;
      const { heatmap, plotData, plotLayout, plotConfig } = this.state;
      const { layoutVisibility } = this.props;
      const heatMapClasses = classNames(
         'analysis-heatmap',
         layoutVisibility
      );
      const plotClasses = classNames(
         'analysis-plot',
         layoutVisibility
      );

      return (
         <div>
            <div>
               <SegmentedControl
                  className='analysis-mode-segmented-controll'
                  name="analysisMode"
                  options={[
                     { value: modeEnum.LEAKS, label: this.context.t('fixed_leaks') },
                     { value: modeEnum.INFRASTRUCTURE, label: this.context.t('infrastructure') },
                     { value: modeEnum.LEAKS_BY_INFRA, label: this.context.t('leaks_infrastructure'), default: true }
                  ]}
                  setValue={this.onChange}
                  style={{ width: 400, color: '#ab47bc' }} // purple400
               />
            </div>
            <div
               className={plotClasses}
             style={{marginLeft: '40px'}}>
               <Plot
                  data={plotData}
                  layout={plotLayout}
                  config={plotConfig}
                  onClick={(event) => {
                     if (this.state.mode != modeEnum.INFRASTRUCTURE) {
                        const xAttr = this.props.xAttribute;
                        const xValue = event.points[0].x;
                        const filters = {
                           [xAttr]: xValue,
                        };
                        this.props.onSelectGroup(filters);
                     }
                  }}
               />
            </div>
            <div
               className={heatMapClasses}>
               <HeatMap
                  xLabels={heatmap.x}
                  yLabels={heatmap.y}
                  data={heatmap.z}
                  xLabelsLocation={"top"}
                  // squares
                  cellRender={this.heatmapCellRender}
                  cellStyle={(value, min, max) => {
                     const colorFactor = (255 * ((max - value) / (max - min)));
                     return {
                        background: `rgb(255, ${colorFactor}, ${colorFactor})`,
                        fontSize: "12px",
                        color: "#000"
                     };
                  }}
                  onClick={(x, y) => {
                     if (this.state.mode != modeEnum.INFRASTRUCTURE) {
                        const yAttr = this.props.yAttribute;
                        const xAttr = this.props.xAttribute;
                        const filters = {
                           [yAttr]: heatmap.y[y],
                           [xAttr]: heatmap.x[x],
                        };
                        this.props.onSelectGroup(filters);
                     }
                  }}
               />
            </div>
         </div>
      );
   }
}

AnalysisTab.contextTypes = {
   t: PropTypes.func.isRequired
};
