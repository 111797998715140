import { zIndexs } from './Options';

import XYZ from 'ol/source/XYZ.js';
import TileLayer from 'ol/layer/Tile.js';

// Pipes layer
export const layer = new TileLayer({
    name: 'layerPipes',
    source: new XYZ({
      crossOrigin: null
    }),
    zIndex: zIndexs.pipes,
});

export const setSource = (newSource, props) => {
    layer.setSource(newSource);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
    return false;
};

// Clean the layer
export const clear = () => {
};

export const getStyle = (feature, bSelected) => {
    return null;
};

export const getInfoTooltip = (feature) => {
    return null;
};

export const tooltipRender = (itemInfo, context) => {
    return null;
};
