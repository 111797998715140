import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import  { setUnit } from '../../actions/GlobalsActions'
import { UNITS } from '../../constants/SettingsKeys';

require('./UserSettings.scss');

class UnitSelect extends React.Component {
  constructor(props, context) {
    super(props);

    // Moved hhere since we need context.
    this.options = [
      {ID: 'm', Name: context.t('meter')},
      {ID: 'ft', Name: context.t('fit')},
    ]

  }

  onChange(unit) {
      this.props.dispatch(setUnit(unit));
      localStorage.setItem(UNITS, unit);
  }

  render() {
    const { unit } = this.props;
    return (
      <div className="lang-select">
        <select onChange={e => this.onChange(e.target.value)}
                value={unit || 'm'}>
          {this.options.map(option =>
            <option key={option.ID} value={option.ID}>
              {option.Name}
            </option>)
          }
        </select>
      </div>
    );
  }
}

UnitSelect.contextTypes = {
  t: PropTypes.func.isRequired
}
export default connect(state => ({
  unit: state.local.units
}))(UnitSelect)
