import React from 'react';
import PropTypes from 'prop-types';

import TabsContainer from '../../Custom/Tabs/TabsContainer';

import Select from 'react-select';

import { PRESSURE_DISPLAY_MODE } from "../../../constants/Misc";
import PagesBrowser from '../../Custom/PagesBrowser';
import ModalPlot from '../../SensorsTabs/Pressure/ModalPlot';
import SensorPressureGraph from '../../SensorsTabs/Pressure/SensorPressureGraph';

export default class PressureTabs extends React.Component {

  static get propTypes() {
    return {
      mode: PropTypes.number,
      title: PropTypes.string,
      trend: PropTypes.array,
      transient: PropTypes.array,
      sensorPrsDaysBack: PropTypes.number,
      uiInfo: PropTypes.object,
      reloadDataDaysBack: PropTypes.func,
      changeDisplayMode: PropTypes.func,
      isTrendFetching: PropTypes.bool,
      isTransientFetching: PropTypes.bool,
      transientTabByDefault: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);

    this.onPlotValueClick = this.onPlotValueClick.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.renderDailyDisplay = this.renderDailyDisplay.bind(this);
    this.renderWeekliDisplay = this.renderWeekliDisplay.bind(this);

    this.state = {
      modalData: {
        isOpen: false,
        data: null
      },
    };
  }

  onPlotValueClick(data) {
    const pointData = data.points[0];
    const { name, y } = pointData.data;

    const plotData = [{
      y: y,
      mode: 'lines+points',
      type: 'scatter'
    }];

    this.setState({
      modalData: {
        isOpen: true,
        plotName: name,
        plotData,
      }
    });
  }

  onModalClose() {
    this.setState({
      modalData: {
        isOpen: false,
      }
    });
  }

  renderDailyDisplay() {
    const { isOpen, plotData, plotName } = this.state.modalData;
    const {
      title,
      trend,
      transient,
      isTrendFetching,
      isTransientFetching,
      uiInfo } = this.props;

    return(
      <div>
        <TabsContainer
          tabs={
            [
              {
                label: this.context.t('trend'),
                component: (
                  <SensorPressureGraph
                    title={title}
                    plotData={trend}
                    uiInfo={uiInfo}
                    isFetching={isTrendFetching}
                    allowChangeUnits
                  />
                )
              },
              {
                label: this.context.t('transient'),
                component: (
                  <SensorPressureGraph
                    title={title}
                    plotData={transient}
                    uiInfo={uiInfo}
                    isFetching={isTransientFetching}
                    onPlotValueClick={this.onPlotValueClick}
                    allowChangeUnits
                  />
                )
              },
            ]
          }
        />

        <ModalPlot
          isOpen={isOpen}
          plotData={plotData}
          plotName={plotName}
          onClose={this.onModalClose}
          uiInfo={uiInfo}
          allowChangeUnits
        />
      </div>
    );
  }

  renderWeekliDisplay() {
    const { title, trend, transient, uiInfo, isTrendFetching, isTransientFetching } = this.props;
    const { isOpen, plotData, plotName } = this.state.modalData;

    return(
      <div>
        <SensorPressureGraph
          title={title}
          plotData={[(trend || []), (transient || [])]}
          uiInfo={uiInfo}
          isFetching={isTrendFetching || isTransientFetching}
          onPlotValueClick={this.onPlotValueClick}
        />
        <ModalPlot
          isOpen={isOpen}
          plotData={plotData}
          plotName={plotName}
          onClose={this.onModalClose}
          uiInfo={uiInfo}
        />

      </div>
    );
  }

  render() {
    const { sensorPrsDaysBack, mode, reloadDataDaysBack, changeDisplayMode } = this.props;

    return (
      <PagesBrowser
        nextDisabled={sensorPrsDaysBack <= 0}
        onBrowsing={(direction) => {
          reloadDataDaysBack(direction, sensorPrsDaysBack, mode);
        }}>

        { false && <Select
          className='pressure-display-mode'
          isClearable={false}
          isSearchable={false}
          onChange={changeDisplayMode}
          value={mode}
          options={[
            { value: PRESSURE_DISPLAY_MODE.DAY, label: this.context.t('day') },
            { value: PRESSURE_DISPLAY_MODE.WEEK, label: this.context.t('week') },
          ]}
        /> }

          { mode == PRESSURE_DISPLAY_MODE.DAY &&
            this.renderDailyDisplay()
          }

          { mode == PRESSURE_DISPLAY_MODE.WEEK &&
            this.renderWeekliDisplay()
          }

      </PagesBrowser>
    );
  }
}

PressureTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
