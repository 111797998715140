import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, {
  DateType,
} from '../../../containers/UIhelper/UIDateFormater';
import { calculateColorValues } from '../helper';
import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Point from 'ol/geom/Point';
import { convertIntensityToDb } from '../../../actions/MobileSamplesActions';
import _ from 'lodash';
const proj = require('ol/proj');

const colorValues = {
  thr_strong_red: 80,
  thr_red: 70,
  thr_orange: 55
};

const style = new Style({
  image: new Icon({
    src: require('../../../../images/map/ic_noise_sample.png'),
    anchor: [0.5, 1],
  }),
});

const source = new VectorSource({
  features: [],
});

export const layer = new VectorLayer({
  name: 'layerMobileSamples',
  style: style,
  source: source,
  zIndex: zIndexs.noiseSamples,
});

const icons = {
  red_high: require('./icons/red_deep.png'),
  red: require('./icons/red.png'),
  orange: require('./icons/orange.png'),
  orange_light: require('./icons/orange_light.png'),
  yellow: require('./icons/yellow.png'),
  green: require('./icons/green.png'),
};

export const setSource = (features, props) => {
  // const colorAttr = props.mobileNoiseSamplesColorAttr;
  // colorValues = calculateColorValues(features, colorAttr);
  //console.log(colorValues);
  const iconFeatures = features.map((itrFeature) => {
    const iconFeature = new Feature({
      // value: itrFeature[colorAttr],
      geometry: new Point(
        proj.transform(
          [itrFeature.longitude, itrFeature.latitude],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      sampleTime: itrFeature.sample_time,
      intensity: convertIntensityToDb(
        itrFeature.mobile_intensity,
        props.mobileAlgParams.noise_ref_delta_db,
        props.mobileAlgParams.engine_calibration
      ),
      quality: itrFeature.mobile_quality,
      clarity: itrFeature.engine_quality,
      device: itrFeature.device_id_hex,
      gain: itrFeature.gain,
      frequency: itrFeature.audio_frequency,
      duration: itrFeature.audio_duration,
      type: featuresTypes.MOBILE_SAMPLES,
      id: itrFeature.sample_uid,
    });
    const style = getStyle(iconFeature, false, props);
    iconFeature.setStyle(style);
    return iconFeature;
  });
  source.addFeatures(iconFeatures);
  return iconFeatures;
};

const getIconFromVal = (value, thresholds) => {
  if (value >= thresholds.thr_strong_red) {
    return icons.red_high;
  }
  if (value >= thresholds.thr_red) {
    return icons.red;
  }
  if (value >= thresholds.thr_orange) {
    return icons.orange;
  }
  if (value >= thresholds.thr_orange_light) {
    return icons.orange_light;
  }
  if (value >= thresholds.thr_yellow) {
    return icons.yellow;
  }
  return icons.green;
};

export const getStyle = (feature, selected, props) => {
  const attr = feature.getProperties();
  const value = attr.intensity;

  // console.log({mobileAlgParams: props.mobileAlgParams});
  const thresholds = _.isEmpty(props.mobileAlgParams) ? colorValues : props.mobileAlgParams;

  const style = [];

  if (selected) {
    style.push(new Style({
      image: new Icon({
        src: require('./icons/selected_background.png'),
        anchor: [0.5, 0.5],
      })
    }))
  }
  style.push(new Style({
    image: new Icon({
      src: getIconFromVal(value, thresholds),
      anchor: [0.5, 0.5],
      scale: 0.6
      // size: [30, 30],
    }),
  }));

  return style;
};
//
// export const getStyle = (feature, selected) => {
//   // let style = false;
//   // let iconType = false;
//
//   // const attr = feature.getProperties();
//   // iconType = (attr.userName2 == null || attr.userName2 == '') ? 'ic_IQsample_survey' : 'ic_IQsample_correlation'
//
//   // selected = selected ? '-selected' : '';
//
//   // if (iconType) {
//   // style  = new Style({
//   return new Style({
//     image: new Icon({
//       src: require('../../../../images/map/noises/20.png'),
//       anchor: [0.5, 1],
//     }),
//   });
// };
// // return style ;
// // }

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

export const getLayers = () => {
  return [layer];
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();

  const infoTooltip = {
    sampleTime: attr.sampleTime,
    intensity: attr.intensity,
    quality: attr.quality,
    device: attr.device,
    gain: attr.gain,
    frequency: attr.frequency,
    duration: attr.duration,
    type: attr.type,
    id: attr.id,
  };

  return infoTooltip;
};

export const tooltipRender = (itemInfo, context, props) => {
  return (
    <span className="tooltip">
      <div className="tooltip-row">{context.t('sample_time')} { UIDateFormater(itemInfo.sampleTime, DateType.DATE_AND_TIME_WITH_SECONDS, props.timeZone) }</div>
      <div className="tooltip-row">{context.t('intensity', { intensity: itemInfo.intensity })}</div>
      <div className="tooltip-row">{context.t('quality')} { (Math.max(itemInfo.quality, 0.2) * 100).toFixed() }%</div>
      <div className="tooltip-row">{context.t('device_id')}: { itemInfo.device }</div>
      <div className="tooltip-row">{context.t('gain')}: { itemInfo.gain }</div>
      <div className="tooltip-row">{context.t('frequency_hz', { freq: itemInfo.frequency / 8 })}</div>
      <div className="tooltip-row">{context.t('duration_sec', { duration: itemInfo.duration })}</div>
      {/* <div className="tooltip-row">{context.t('intensity: ')} { itemInfo.type  }</div> */}
      {/* <div className="tooltip-row">{context.t('Time: ')} { UIDateFormater(itemInfo.sampleTime, DateType.DATE_AND_TIME, this.props.timeZone) }</div>
        <div className="tooltip-row">{context.t('Quality: ')} { Math.round(itemInfo.quality) }</div>
        <div className="tooltip-row">{context.t('Clarity: ')} { Math.round(itemInfo.probability) }</div>
        <div className="tooltip-row">{context.t('Comment: ')} { itemInfo.comments }</div> */}
    </span>
  );
};
