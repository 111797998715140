import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SubjectIcon from '@mui/icons-material/Subject';

import { makeStyles } from '@mui/styles';
import { green, grey } from '@mui/material/colors'

import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      padding: '4px',
      borderLeft: "1px solid #eee"
    }
  },
  reportButton: {
    position: 'absolute',
    bottom: '5px',
    left: '5px',
    opacity: 0.8,
    borderRadius: '50%',
    color: grey[50],
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    }
  }
});

function getAccessTechnology(value, context) {
  switch (value) {
    default:
    case 0: return context.t('unknown');
    case 1: return context.t('cat_m');
    case 2: return context.t('nb');
  }
}

function G5StatusMessages(props, context) {
  const classes = useStyles();
  return (
    <Paper style={{maxHeight: 'inherit'}}>
      <TableContainer style={{maxHeight: 'inherit'}}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align='center'>{context.t('time')}</TableCell>
              <TableCell align='center'>{context.t('batt')}</TableCell>
              <TableCell align='center'>{context.t('acc_tech')}</TableCell>
              <TableCell align='center'>{context.t('rsrq')}</TableCell>
              <TableCell align='center'>{context.t('rsrp')}</TableCell>
              <TableCell align='center'>{context.t('iccid')}</TableCell>
              <TableCell align='center'>{context.t('radio')}</TableCell>
              <TableCell align='center'>{context.t('fw_v')}</TableCell>
              { props.user.isAQS && <TableCell align='center'>{context.t('rst')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props.data.map((item) => (
                <TableRow hover key={item.ID}>
                  <TableCell align='center'>{UIDateFormater(item.TimeStamp, DateType.DATE_AND_TIME, props.timeZone)}</TableCell>
                  <TableCell align='center'>{item.BatteryLevel ? item.BatteryLevel.toFixed(2) : '--'} - {item.BatteryLevelPost ? item.BatteryLevelPost.toFixed(2) : '--'}</TableCell>
                  <TableCell align='center'>{getAccessTechnology(item.AccessTechnology, context)}</TableCell>
                  <TableCell align='center'>{item.RSRQ_db}</TableCell>
                  <TableCell align='center'>{item.RSRP_dbm}</TableCell>
                  <TableCell align='center'>{item.ICCID}</TableCell>
                  <TableCell align='center'>{item.FmChannel}</TableCell>
                  <TableCell align='center'>{item.FirmwareRevision}</TableCell>
                  { props.user.isAQS && <TableCell align='center'>{item.rst_info}</TableCell>}

                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <IconButton className={classes.reportButton} onClick={() => {
        if (props.selectedSensor && props.selectedSensor.DeviceID) {
          props.downloadReport(props.selectedSensor.DeviceID);
        } else {
          // console.log('NO Device ID');
        }
      }}>
        <SubjectIcon/>
      </IconButton>
    </Paper>
  );
}

G5StatusMessages.contextTypes = {
  t: PropTypes.func.isRequired
};

G5StatusMessages.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  downloadReport: PropTypes.func,
  selectedSensor: PropTypes.object,
  timeZone: PropTypes.object,
  user: PropTypes.object,
};

export default G5StatusMessages;
