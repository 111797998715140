/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';

import HistoryTab from '../AlertsTabs/HistoryTab/HistoryTab';
import TabsContainer from '../Custom/Tabs/TabsContainer';
import CoupleSamplesTable from './OutputTab/CoupleSamplesTable';
import CCoupleDetailsTab from '../../containers/CDetailsTab/CCoupleDetailsTab';

const classNames = require('classnames');
const _ = require('lodash');

export default class CouplesTabs extends React.Component {

  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
    //this.sortCoupleSamples = this.sortCoupleSamples.bind(this);
    this.onSelectTab = this.onSelectTab.bind(this);

    this.state = {
      tabIndex: 0,
      goToRow: null,
    };
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.displayAssessments && nextProps.selectedAssessment !== "" && nextProps.selectedAssessment !== null) {

      const coupleSamplesList = nextProps.coupleSamples[nextProps.selectedFeature].data;
      const thisCoupleSamplesList = this.coupleSamples !== null && this.selectedFeature != null ? this.coupleSamples[this.selectedFeature].data : [];

      if (coupleSamplesList.rows != null && (this.props.selectedAssessment !== nextProps.selectedAssessment || thisCoupleSamplesList.rows == null)) {
        if (nextProps.selectedSample != null && !_.isEmpty(nextProps.selectedSample)) {
          if (this.state.goToRow != null) {
            this.setState({
              goToRow: null,
            });
          }
        } else {
          for (let i = 0; i < coupleSamplesList.rows.length; i++) {
            if (coupleSamplesList.rows[i].LeakID == nextProps.selectedAssessment) {
              if (i !== null && i != this.state.goToRow) {
                this.setState({
                  goToRow: i
                });
              }
            }
          }
        }
      }
    }
    //if (nextProps.selectedFeature !== "" && (this.props.selectedFeature !== nextProps.selectedFeature || !nextProps.showDetails)) {
    if (nextProps.featureType == 'couple') {
      if (nextProps.selectedFeature !== "" && this.props.selectedFeature !== nextProps.selectedFeature) {
        if (this.state.tabIndex != 0) {
          this.setState({
            tabIndex: 0,
          });
        }
        if (!nextProps.showDetails) {
          this.props.toggleDetailsVisibility(true);
        }
      } else if (nextProps.selectedAssessment !== "" && this.props.selectedAssessment !== nextProps.selectedAssessment) {
        if (!nextProps.showDetails) {
          this.props.toggleDetailsVisibility(true);
        }
      }
    } else {
      if (!nextProps.planningState.isActive) {
        if (nextProps.showDetails) {
          this.props.toggleDetailsVisibility(false);
        }
      }
    }

    if (nextProps.selectedFeature == null || nextProps.selectedFeature == '') {
      if (this.state.tabIndex != 1) {
        this.setState({
          tabIndex: 1,
        });
      }
    }
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);
    this.props.selectSample({});
    this.props.clearSelectedFeature(); // clear couple generation data also.
  }

  onSelectTab = (tabIndex) => {
    this.setState({ tabIndex: tabIndex });
  }

  render() {
    const {
      coupleSamples,
      coupleItem,
      user,
      timeZone,
      selectedFeature,
      selectedAssessment,
      uiInfo,
      showDetails,
      selectCoupleSample,
      selectedSample,
      onPageIndexChange,
      navigateToFeature,
      distanceFactor,
      handleDeleteCouple,
      displayAssessments,
      history,
      planningState,
      alertStatesOpitons,
      alertTypesOpitons,
      algParams,
    } = this.props;

    let leakHistory = [];
    if (history && history[selectedAssessment]) {
      leakHistory = history[selectedAssessment].data;
    }

    const isFetching = planningState.isSubmitting;

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
      (isFetching ? 'fetching' : '')
    );
    const coupleSamplesItems = coupleSamples[selectedFeature] || {};

    const panelStyle = { height: 0.493 * uiInfo.pageHeight };
    const title = selectedFeature ? this.context.t('couple_and_id', { id: selectedFeature }) : this.context.t('new_couple');

    let sortCoupleSamplesPrup = {};

    const { filters } = this.props;

    if (filters.coupleSamplesSort) {
      sortCoupleSamplesPrup = {
        sort: this.props.sortCoupleSamples,
        sortBy: filters.coupleSamplesSort.field,
        sortDir: filters.coupleSamplesSort.dir
      };
    }

    const tabs = [];

    if (selectedFeature) {
      tabs.push({
        label: this.context.t('samples'),
        style: {},
        component: (
          <CoupleSamplesTable
            coupleSamples={coupleSamplesItems}
            selectedFeature={selectedFeature}
            selectCoupleSample={selectCoupleSample}
            selectedSample={selectedSample}
            navigateToFeature={navigateToFeature}
            onPageIndexChange={onPageIndexChange}
            timeZone={timeZone}
            distanceFactor={distanceFactor}
            algParams={algParams}
            deviceSync={coupleItem.DeviceSync}
          />
        )
      });
    }

    if (displayAssessments) {
      tabs.push({
        label: this.context.t('history'),
        component: (
          <HistoryTab
            history={leakHistory}
            uiInfo={uiInfo}
            local={timeZone}
            alertStatesOpitons={alertStatesOpitons}
            alertTypesOpitons={alertTypesOpitons} />
        )
      });
    } else {
      tabs.push({
        label: this.context.t('details'),
        component: (
          <CCoupleDetailsTab
            user={user}
            onDelete={handleDeleteCouple}
            notify={this.props.notify}
            openNewCoupleForm={this.props.openNewCoupleForm}
            onEdit={this.props.onEdit}
            onCopy={this.props.onCopy}
          />
        )
      });
    }

    return (
      <div id="couples-tab" className={panelClasses} style={panelStyle}>
        <TabsContainer
          title={title}
          handleClose={this.closePanel}
          tabs={tabs}
        />
      </div>
    );
  }
}

CouplesTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
