import { connect } from 'react-redux';
import * as actions from '../../actions/CustomersActions';

import CustomerDetails from '../../components/CustomersTabs/CustomerDetails';
const diff = require('deep-diff');

const mapStateToProps = (state, ownProps) => {
  let initialValues = {};
  const userEditMode = state.leaksList.user.editMode;
  const editedCustomer = state.manage.selectedManageItem.item;
  const isNew = state.manage.selectedManageItem.id == -1;

  if (editedCustomer != null && state.manage.selectedManageItem.id !== -1) {
    Object.assign(initialValues, editedCustomer);
  }

  return Object.assign({}, {
    initialValues: initialValues,
    isNew: isNew,
    userEditMode,
  }, ownProps);
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      const callback = (result) => {
        if (result.status) {
          ownProps.notify('Changes saved', 'success');
          ownProps.closePanel();
        } else {
          ownProps.notify('Changes not saved', 'error');
        }
      };
      const isNew = props.isNew;
      if (isNew) {
        dispatch(actions.createNewCustomer(values, callback));
      } else {
        dispatch(actions.updateCustomerDetails(values, callback));
      }
      //const initialValues = props.initialValues;
      //const isNewProject = initialValues.ID == null;
      //let res = [];


      //const callback = (result) => {
      //  if (result.status) {
      //    ownProps.notify('Changes saved', 'success');
      //  } else {
      //    ownProps.notify('Changes not saved', 'error');
      //  }
      //}

      //if (isNewProject) {
      //  dispatch(actions.createNewCustomer(values, callback));
      //} else {
      //  const differences = diff.default.diff(values, initialValues);
      //  if (differences != null) {
      //    differences.forEach((diff) => {
      //      const field = diff.path[0];
      //      const value = diff.lhs;
      //      const param = { field, value };
      //      res.push(param)
      //    })
      //    dispatch(actions.updateProject(initialValues.ID, res, callback));
      //  }
      //}
    },
    configureCustomerBucket: () => {
      dispatch(actions.configureCustomerBucket());
    }
  };
};

const CCustomerDetailsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails);

export default CCustomerDetailsTab;

