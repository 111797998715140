import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import Plot from 'react-plotly.js';
import { CircleLoader } from 'react-spinners';

require('./ModalsStyle.scss');

export default class DmaHistoryModal extends React.Component {

  static get propTypes() {
    return ({
      selectedDmaCustomer: PropTypes.string,
      uiInfo: PropTypes.object,
      selectedDmaId: PropTypes.number,
      isDataVisible: PropTypes.bool,
      plotData: PropTypes.array,
      isFetchDmaData: PropTypes.bool,
      onClose: PropTypes.func
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      isUpdated: false,
      layout: {
        autorange: true,
        showlegend: false,
        type: 'date',
        margin: {
          t: 30,
          b: 50,
          l: 60,
          r: 30
        },
      },
      config: {
        displaylogo: false,
        responsive: true,
        displayModeBar: true,
      }
    };

    this.onClose = this.onClose.bind(this);

  }

  componentDidUpdate() {
    const { uiInfo, selectedDmaCustomer } = this.props;

    if (uiInfo != null && this.state.isUpdated == false) {
      const pWidth = 0.395 * (uiInfo.pageWidth - 10);
      const pHeight = 0.392 * uiInfo.pageHeight;

      const newLayout = Object.assign({}, this.state.layout);
      newLayout.width = pWidth;
      newLayout.height = pHeight;
      newLayout.title = this.context.t('customer_customerParam', { customer: selectedDmaCustomer });

      this.setState({
        isUpdated: true,
        layout: newLayout
      });
    }
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const { isDataVisible, plotData, isFetchDmaData } = this.props;
    const { layout, config } = this.state;

    return (
      <AppModal
        open={isDataVisible}
        onClose={this.onClose}
        content={(
          <div>
            { isFetchDmaData &&
              <div style={{ paddingLeft: '35%', paddingTop: '5%' }}>
                <CircleLoader
                  sizeUnit={"px"}
                  size={60}
                  margin='10px'
                  color={'#123abc'}
                  loading={isFetchDmaData}
                />
              </div>
            }

            { !isFetchDmaData &&
              <Plot
                data={plotData}
                layout={layout}
                config={config}
              />
            }
          </div>
        )}
      />
    );
  }
}

DmaHistoryModal.contextTypes = {
  t: PropTypes.func.isRequired
};
