import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { useForm } from 'react-hook-form';

// import OperationForm from './OperationForm';
const fontSizeStyle = '1.5rem';
const useStyles = makeStyles({
  root: {
    margin: '5px 10px',
  },
  largeFont: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
  },
  fields: {
    padding: '5px 0',
    display: 'grid',
    gridTemplateColumns: '140px 100px',
    gridRowGap: '3px',
  },
  errorField: {
    backgroundColor: '#ffbbbb',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '100px 140px',
    gridRowGap: '3px',
    columnGap: '5px',
  },
});

export default function ConfCorrelation(props, context) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    errors,
    clearErrors,
  } = useForm();
  const classes = useStyles();

  const [audioMaxVal, setAudioMaxVal] = React.useState(120);

  React.useEffect(() => {
    if (props.defaultsValues) {
      Object.entries(props.defaultsValues).forEach(([field, value]) => {
        setValue(field, value);
      });
    }
  }, [props.defaultsValues]);

  const watchAudioFS = watch(['audioFS2N', 'audioDuration']);

  React.useEffect(() => {
    const maxValue = 600 / watchAudioFS.audioFS2N;
    if (maxValue !== audioMaxVal) {
      setAudioMaxVal(maxValue);
    }
    if (watchAudioFS.audioDuration > maxValue) {
      setValue('audioDuration', maxValue);
    }
  }, [watchAudioFS]);
  return (
    <form className={classes.root} onSubmit={handleSubmit(props.updateConfig)}>
      <fieldset className={classes.fields}>
        <label htmlFor='audioDuration'>{context.t('audio_duration')}</label>
        <input
          id='audioDuration'
          name='audioDuration'
          type='number'
          max={audioMaxVal}
          ref={register}
        />

        <label htmlFor='radioDuration'>{context.t('radio_duration')}</label>
        <input id='radioDuration' name='radioDuration' ref={register} />

        <label htmlFor='audioFS2N'>{context.t('audio_fs')}</label>
        <select id='audioFS2N' name='audioFS2N' ref={register}>
          <option value='5'>5</option>
          <option value='8'>8</option>
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='40'>40</option>
        </select>
      </fieldset>

      <fieldset className={classes.fields} disabled>
        <label htmlFor='samplingWindow'>{context.t('sampling_window')}</label>
        <input
          id='samplingWindow'
          name='samplingWindow'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='audioNumBands'>{context.t('audio_num_bands')}</label>
        <input
          id='audioNumBands'
          name='audioNumBands'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='audioNumCoff'>{context.t('audio_num_coff')}</label>
        <input
          id='audioNumCoff'
          name='audioNumCoff'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='audioBandMask1'>{context.t('audio_band_mask_1')}</label>
        <input
          id='audioBandMask1'
          name='audioBandMask1'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='audioBandMask2'>{context.t('audio_band_mask_2')}</label>
        <input
          id='audioBandMask2'
          name='audioBandMask2'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='audioBandMask3'>{context.t('audio_band_mask_3')}</label>
        <input
          id='audioBandMask3'
          name='audioBandMask3'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='audioBandMask4'>{context.t('audio_band_mask_4')}</label>
        <input
          id='audioBandMask4'
          name='audioBandMask4'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='radioNumBands'>{context.t('radio_num_bands')}</label>
        <input
          id='radioNumBands'
          name='radioNumBands'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='radioNumCoff'>{context.t('radio_num_coff')}</label>
        <input
          id='radioNumCoff'
          name='radioNumCoff'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='radioBandMask1'>{context.t('radio_band_mask_1')}</label>
        <input
          id='radioBandMask1'
          name='radioBandMask1'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='radioBandMask2'>{context.t('radio_band_mask_2')}</label>
        <input
          id='radioBandMask2'
          name='radioBandMask2'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='radioBandMask3'>{context.t('radio_band_mask_3')}</label>
        <input
          id='radioBandMask3'
          name='radioBandMask3'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='radioBandMask4'>{context.t('radio_band_mask_4')}</label>
        <input
          id='radioBandMask4'
          name='radioBandMask4'
          defaultValue='1'
          ref={register}
        />

        <label htmlFor='compressEnabled'>{context.t('compress_enabled')}</label>
        <input
          id='compressEnabled'
          name='compressEnabled'
          defaultValue='0'
          ref={register}
        />
      </fieldset>

      <Box className={classes.buttons}>
        <Button
          className={classes.largeFont}
          variant='contained'
          color='primary'
          type='submit'
          disabled={!props.enable}
        >
          {context.t('submit')}
        </Button>
        <Button
          className={classes.largeFont}
          variant='contained'
          color='inherit'
          disabled={!props.advancedOptions}
          onClick={() => {
            clearErrors();
            const values = getValues();
            let haveErrors = false;
            Object.entries(values).forEach(([paramKey, value]) => {
              if (!value) {
                if (!haveErrors) haveErrors = true;

                setError(paramKey, {
                  type: 'required',
                  message: context.t('required'),
                });
              }
            });

            if (!haveErrors) {
              props.onSaveDefault(values);
            }
          }}
        >
          {context.t('set_default')}
        </Button>
      </Box>
    </form>
  );

  // return (
  //   <OperationForm
  //     enable={props.enable}
  //     fields={props.fields}
  //     onSubmit={props.updateConfig}
  //   />
  // );
}

ConfCorrelation.propTypes = {
  advancedOptions: PropTypes.bool,
  defaultsValues: PropTypes.object,
  enable: PropTypes.bool,
  onSaveDefault: PropTypes.func,
  updateConfig: PropTypes.func,
};

ConfCorrelation.contextTypes = {
  t: PropTypes.func.isRequired,
};
