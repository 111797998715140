import React from 'react';
import UIDateFormater, {
  DateType,
} from '../../../containers/UIhelper/UIDateFormater';
import { zIndexs, featuresTypes } from './Options';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');

const style = new Style({
  image: new Icon({
    src: require('../../../../images/map/ic_noise_sample.png'),
    anchor: [0.5, 0.5],
  }),
});

const source = new VectorSource({
  features: [],
});

export const layer = new VectorLayer({
  name: 'layerNoiseSamples',
  style: style,
  source: source,
  zIndex: zIndexs.noiseSamples,
});

const getStyle = (feature, selected) => {
  let sensorStyle = false;
  const attr = feature.getProperties();
  const dirNameForNoiseAlert = attr.noiseAlertId != null ? 'AlertNoises/' : '';
  const size = attr.noiseAlertId != null ? [24, 24] : [12, 12];
  let value = Math.floor(attr.value);
  if (value > 25) {
    value = 'max';
  }
  sensorStyle = new Style({
    image: new Icon({
      src: require('../../../../images/map/noises/' +
        dirNameForNoiseAlert +
        value +
        '.png'),
      anchor: [0.5, 0.5],
      size: size,
    }),
  });

  return sensorStyle;
};

export const setSource = (nextIconFeatures, props) => {
  clear();
  let iconFeatures = [];
  const colorAttr = props.noiseSamplesColorAttr;
  Object.keys(nextIconFeatures).forEach((key) => {
    const feature = nextIconFeatures[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(feature.position, 'EPSG:4326', 'EPSG:3857')
      ),
      type: featuresTypes.NOISE,
      id: feature.sensorID,
      sensorID: feature.sensorID,
      deviceID: feature.deviceID,
      noise: feature[colorAttr],
      value: feature['color' + colorAttr],
      noiseAlertId: feature.NoiseAlertID,
      noiseAlertDate: feature.NoiseAlertDate,
    });
    iconFeature.setStyle(getStyle(iconFeature));
    iconFeatures.push(iconFeature);
  });
  source.addFeatures(iconFeatures);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();
  let infoTooltip = {
    type: featuresTypes.NOISE,
    deviceID: attr.deviceID,
    noiseLevel: attr.noise,
    noiseAlertId: attr.noiseAlertId,
    noiseAlertDate: attr.noiseAlertDate,
  };
  return infoTooltip;
};

export const tooltipRender = (itemInfo, context, props) => {
  return (
    <span className="tooltip">
      <div className="tooltip-row">
        {context.t('noise_level')} {itemInfo.noiseLevel}
      </div>
      {itemInfo.deviceID && (
        <div className="tooltip-row">
          {context.t('device_id')} {itemInfo.deviceID}
        </div>
      )}
      {itemInfo.noiseAlertDate && (
        <div>
          <div className="tooltip-row">
            {context.t('alert_id')} {itemInfo.noiseAlertId}
          </div>
          <div className="tooltip-row">
            {context.t('alert_date')}{' '}
            {UIDateFormater(
              itemInfo.noiseAlertDate,
              DateType.DATE_AND_TIME,
              props.timeZone
            )}
          </div>
        </div>
      )}
    </span>
  );
};
