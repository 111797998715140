import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import Select from './../Select/Select';

import CanvasDraw from "react-canvas-draw";

class WorkOrderForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      selectedFileImgSrc: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleSubmit(event) {
    // Handle form submission if needed
    event.preventDefault();
  }

  handleFileChange(event) {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (event) => {
      this.setState({ selectedFileImgSrc: event.target.result });
      this.props.setImgPng(event.target.result);
    }
  }

  render() {
    const { initialValues, distanceFactor, units, contextType } = this.props || {};
    // console.log('initialValues in WORKORDERFORM', initialValues);

    const { selectedFileImgSrc } = this.state;

    return (
      <form className="form-horizontal form-work-order" onSubmit={this.handleSubmit}>
        <div className="helfWidth">
          {/* Common Fields */}
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('service_provider2')}</label>
            <div className="col-xs-8 control-input">
              <Field
                name="serviceProvider"
                component={props =>
                  <Select {...props}
                    placeholderTitle={this.context.t('select')}
                    clearable={false}
                    backspaceRemoves={false}
                    multi={false}
                    options={initialValues.serviceProviderOptions}
                  />
                }
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('project')}</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.projectName || '-'}</span>
            </div>
          </div>

          {/* Conditional Fields Based on contextType */}
          {contextType === 'mobile' ? (
            <>
              {/* Fields specific to 'mobile' contextType */}

              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('alert_id')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Id || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('alert_status')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.alertStateName || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('alert_type')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.alertTypeName || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('create_date')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.CreateDate || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('end_date')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.EndDate || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('intensity')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Intensity || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('classification')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Classification || '-'}</span>
                </div>
              </div>
              {/* <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('location')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Location || '-'}</span>
                </div>
              </div> */}
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('address')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Address || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('comment')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Comment || '-'}</span>
                </div>
              </div>
            </>
          ) : contextType === 'noise' ? (
            <>
              {/* Fields specific to 'noise' contextType */}
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('leak_id')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.id || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('alert_status')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.alertStateName || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('event_date')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.CreateDate || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('alert_date')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.InsertDate || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('sensor_id')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.sensorID || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('comment')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.comment || '-'}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Fields for regular alerts */}
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('leak_id')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.id || '-'}</span>
                </div>
              </div>

              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('alert_type')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.alertTypeName || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('alert_status')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.alertStateName || '-'}</span>
                </div>
              </div>

              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('severity')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Priority || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('intensity')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Intensity || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('estimated_address')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.StreetAddress || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('x') + " , " + this.context.t('y')}:</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{(initialValues.X + ' , ' + initialValues.Y) || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('sensor1_address')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Sop1Address || '-'}</span>
                </div>
              </div>

              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('sensor2_address')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{initialValues.Sop2Address || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('distance_between_sensors')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{((initialValues.PathLengthM * distanceFactor).toFixed() + ' ' + units) || '-'}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('distance_from_sensor1')}</label>
                <div className="col-xs-8 control-input">
                  <span className="control-readonly">{((initialValues.Distance * distanceFactor).toFixed() + ' ' + units) || '-'}</span>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Map and Other Elements */}
        <div className="helfWidth">
          {this.props.hasMapError && !selectedFileImgSrc ? (
            <div className="errorContainer">
              <div className="errorIconContainer">
                <span className="errorIcon">!</span>
              </div>
              <div className="errorTextContainer">
                <p className="errorText">{this.context.t('map_could_not_be_captured_please_take_a_screenshot')}</p>
                <br />
                <label className="browseButton" style={{ display: 'inline-block', cursor: 'pointer', padding: '10px 15px', backgroundColor: '#3498db', color: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                  <span className="buttonText">{this.context.t('upload_screenshot')}</span>
                  <input type="file" style={{ display: "none" }} onChange={this.handleFileChange} />
                </label>
              </div>
            </div>
          ) : (
            <>
              <div className="workOrderCanvas">
                <CanvasDraw
                  canvasWidth={580}
                  canvasHeight={416}
                  lazyRadius={8}
                  brushRadius={2}
                  hideGrid
                  immediateLoading
                />
              </div>
              <img src={this.props.hasMapError ? selectedFileImgSrc : this.props.imgSrc} className="workOrderImage" id="workOrderMap" />
            </>
          )}
        </div>

        {/* Comments and Guidelines */}
        <br /><br />
        <div className="form-group rightSideGuidlinesInWorkOrder">
          <label className="col-xs-4 control-label">{this.context.t('last_comment')}</label>
          <div className="col-xs-8 control-input">
            <span className="control-readonly">{initialValues.Comment || '-'}</span>
          </div>
        </div>
        <div className="form-group rightSideGuidlinesInWorkOrder">
          <label className="col-xs-4 control-label">{this.context.t('guidelines')}</label>
          <div className="col-xs-8 control-input">
            <Field name="guidelines" component="textarea" className="form-control textAreaGuidline" />
          </div>
        </div>
      </form>
    );
  }
}

WorkOrderForm.contextTypes = {
  t: PropTypes.func.isRequired
}

// Decorate the form component
WorkOrderForm = reduxForm({
  form: 'workorder' // a unique name for this form
})(WorkOrderForm);

export default WorkOrderForm;
