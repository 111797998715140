import * as types from '../constants/ActionTypes';
const initialState = {
	  customers: [],
    tasks: [],
    samples: [],
    selectedCustomerId: 0,
    selectedTaskId: 0,
}

const iQuarius = (state = initialState, action) => {
	switch (action.type) {
		case types.RECEIVE_IQUARIUS_TASKS:
			return Object.assign({}, state, {
				tasks: action.tasks
            });
        case types.RECEIVE_IQUARIUS_CUSTOMERS:
          return Object.assign({}, state, {
            customers: action.customers
          });
        case types.SET_SELECTED_IQUARIUS_CUSTOMER:
        return Object.assign({}, state, {
          selectedCustomerId: action.customerId
        });
        case types.SET_SELECTED_TASK:
          return Object.assign({}, state, {
            selectedTaskId: action.taskId
          });
        case types.RECEIVE_IQUARIUS_SAMPLES:
          return Object.assign({}, state, {
            samples: action.samples
          });
		default:
			return state;
	}
}

export default iQuarius;
