import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import TextCell from '../../../components/TextCell/TextCell';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';

import ReactPaginate from "react-paginate";
import { SENSOR_LOG_COUNT_FOR_PAGE } from '../../../constants/Misc';

const _ = require('lodash');

class SensorLogsTab extends React.Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.rowClassNameGetter = this.rowClassNameGetter.bind(this);

    this.state = {
      currentPage: 1,
    }
  }

  onRowClick(event, rowIndex) {
    this.props.selectSensorLog(this.props.sensorsLogs[rowIndex]);
  }

  rowClassNameGetter(rowIndex) {
    const { selectedSensorLog, sensorsLogs } = this.props;
    return (selectedSensorLog && sensorsLogs && sensorsLogs[rowIndex]
         && selectedSensorLog.ID == sensorsLogs[rowIndex].ID) ? 'active-row' : '';
  }

  handlePageClick(event) {
    this.setState({
      currentPage: event.selected + 1,
    });
    this.props.onPageIndexChange(event.selected , 'SensorLogsTab');
  }

  render() {
    const { sensorsLogs, sensorsLogsCount, downloadFile, uiInfo, timeZone } = this.props;

    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight;

    const data = (sensorsLogs != null && !_.isEmpty(sensorsLogs)) ? sensorsLogs : [];

    return (
      <div>
        <Table
          rowsCount={data.length}
          width={pWidth}
          onRowClick={this.onRowClick}
          rowClassNameGetter={this.rowClassNameGetter}
          maxHeight={pHeight - 117}
          headerHeight={46}
          rowHeight={30}>

          <Column
            columnKey="TimeAnalyze"
            header={<Cell>{this.context.t('date')}</Cell>}
            cell={<TextCell data={data} field="TimeAnalyze" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
            width={30}
            flexGrow={1}
          />

          <Column
            columnKey="BatteryVoltage"
            header={<Cell>{this.context.t('battery')}</Cell>}
            width={20}
            flexGrow={1}
            cell={(props) =>
              (<Cell {...props} >
                <div style={{
                  textAlign: 'center',
                  margin: 'auto 5px'}}
                >
                  { (data[props.rowIndex].BatteryVoltagePreRec?.toFixed(2) || '?') + ' - ' + (data[props.rowIndex].BatteryVoltage?.toFixed(2) || '?') }

                  { data[props.rowIndex].FileName?.indexOf('LLauto') != -1 &&
                    <img
                      src={require('../../../../images/map/sensors/indications/indication-resets.png')}
                      style={{
                        height: '17px',
                        width: '17px',
                        position: 'absolute',
                        right: '5px'
                      }}
                    />
                  }
                </div>
              </Cell>)
            } />

          <Column
            columnKey="ModemAtennaSignalStrength"
            header={<Cell>{this.context.t('csq')}</Cell>}
            cell={<TextCell data={data} field="ModemAtennaSignalStrength" />}
            width={10}
            flexGrow={1} />

          <Column
            columnKey="RadioFrequency"
            header={<Cell>{this.context.t('radio_frequency')}</Cell>}
            cell={<TextCell data={data} field="RadioFrequency" />}
            width={10}
            flexGrow={1} />

          <Column
            columnKey="RadioSNR"
            header={<Cell>{this.context.t('snr')}</Cell>}
            cell={<TextCell data={data} field="RadioSNR" />}
            width={10}
            flexGrow={1} />

          <Column
            columnKey="FirmwareVersion"
            header={<Cell>{this.context.t('firmware_version')}</Cell>}
            cell={<TextCell data={data} field="FirmwareVersion" />}
            width={10}
            flexGrow={1} />

          <Column
            columnKey="Download"
            width={10}
            flexGrow={1}
            header={<Cell>{this.context.t('download')}</Cell>}
            cell={(props) =>
              (<Cell {...props} >
                <a
                  key='Audio'
                  className="btn-wav-download"
                  title={data[props.rowIndex].FileName}
                  onClick={() => {
                    const item = data[props.rowIndex];
                    downloadFile(item.FileName, 'file');
                  }}
                  href='javascript:void(0);'>
                  { (data[props.rowIndex].IsLog == null) ? this.context.t('download') : (data[props.rowIndex].IsLog ? this.context.t('log') : this.context.t('audio'))}
                </a>
              </Cell>)
            }
          />
        </Table>

        <div className="center">

            <ReactPaginate
                previousLabel={this.context.t('prev')}
                nextLabel={this.context.t('next')}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(sensorsLogsCount / SENSOR_LOG_COUNT_FOR_PAGE)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                onPageActive={this.state.currentPage}
            />
        </div>
      </div>
    );
  }
}

SensorLogsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SensorLogsTab;
