import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Select from '../Select/Select';
import SensorDetailsView from '../Custom/SensorDetailsView';
import Input from '../Input/Input';

require('./style.scss');

export default class CoupleView extends React.Component {

  static get propTypes() {
    return {
      buttonTitle: PropTypes.string,
      initialValues: PropTypes.object,
      isGenerateMode: PropTypes.bool,
      editMode: PropTypes.bool,
      resetSensors: PropTypes.func,
      materials: PropTypes.array,
      user: PropTypes.object,
      distanceFactor: PropTypes.number,
      onMaterialChange: PropTypes.func,
      requiredValitate: PropTypes.func
    };
  }

  render() {
    const { buttonTitle, initialValues, isGenerateMode, editMode, resetSensors, materials, user, distanceFactor, onMaterialChange, requiredValitate } = this.props;
    return (
      <div className="couple-view">
        <SensorDetailsView
          fieldName="Sensor1"
          addressFieldName="Address1"
          index={1}
          id={initialValues.Sensor1 || ''}
          address={initialValues.Address1 || ''}
          disabled
        />

        <div className="path-details">
          <div>
            {/* get in units according to the Setting, store in Meters */}
            <div className="inline-group">
              <label className="col-xs-6 control-label">{this.context.t('couple_length')}:</label>
              <div className="col-xs-6 control-input">
                <Field
                  className="form-control"
                  name="PathLengthM"
                  component={Input}
                  type="number"
                  // disabled={true}
                  disabled={!user.isAQS || isGenerateMode || !user.editMode}
                  validate={requiredValitate} />
              </div>
            </div>

            <div className="inline-group">
              <label className="col-xs-4 control-label">{this.context.t('material')}:</label>
              <div className="col-xs-8 control-input">
                <Field name="MaterialCode"
                  disabled={(!isGenerateMode && !editMode) || !user.editMode}
                  validate={requiredValitate}
                  component={(props) => (
                    <Select {...props}
                      key={"MaterialCode"}
                      placeholderTitle={this.context.t('select')}
                      clearable={false}
                      options={materials}
                      onChangeHandler={onMaterialChange}
                    />
                  )}
                />
              </div>
            </div>

            <div className="inline-group">
              <label className="col-xs-4 control-label">{this.context.t('override_length')}:</label>
              <div className="col-xs-8 control-input">
                <Field
                  className="checkbox-control"
                  name="OverrideLength"
                  component={Input}
                  type="checkbox"
                  disabled={!user.isAQS || isGenerateMode || !user.editMode} />
              </div>
            </div>

            {/* ########## Check how to store in Meters/Sec ##########*/}
            {/* get in units according to the Setting, store in Meters/Sec */}
            {user.isAQS &&
              <div className="inline-group">
                <label className="col-xs-6 control-label">{this.context.t('velocity')}:</label>
                <div className="col-xs-6 control-input">
                  <Field
                    className="form-control"
                    name="VelocityMS"
                    component={Input}
                    type="number"
                    // disabled='true'
                    disabled={!user.isAQS || isGenerateMode || !user.editMode}
                  // validate={requiredValitate}
                  // distanceFactor={distanceFactor}
                  />
                </div>
              </div>
            }

            {isGenerateMode &&
              <div className="inline-group">
                {/* <label className="col-xs-6 control-label">{this.context.t(buttonTitle)}:</label> */}
                <div className="col-xs-6 control-input">
                  <div
                    className="btn btn-info"
                    onClick={resetSensors}>
                    <span> {buttonTitle} </span>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>


        <SensorDetailsView
          fieldName="Sensor2"
          addressFieldName="Address2"
          index={2}
          id={initialValues.Sensor2 || ''}
          address={initialValues.Address2 || ''}
        />

      </div>
    );
  }
}

CoupleView.contextTypes = {
  t: PropTypes.func.isRequired
};
