import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import { Field, reduxForm } from 'redux-form';
import Input from '../../components/Input/Input';

const Coordinates = require('coordinate-parser');
const _ = require('lodash');

class ActualLeakLocationWindow extends React.Component {
    static get propTypes() {
        return {
            offsetParent: PropTypes.object,
            close: PropTypes.func,
            handleSubmit: PropTypes.func,
        };
    }

    render() {
        const { offsetParent, close, handleSubmit } = this.props;

        const positionX = (offsetParent == null) ? 0: (0.05 * offsetParent.offsetWidth);
        const positionY = (offsetParent == null) ? 0: (-0.95 * offsetParent.offsetHeight);

        return (
            <Draggable
                axis='both'
                handle=".handle"
                bounds="parent"
                offsetParent={offsetParent}
                defaultPosition={{x: positionX, y: positionY}}
                position={null}
                scale={1}>
                <div className='actual-leak-position-form'>
                    <div className="draggable-header">
                        <i className='handle'/>
                        <p>{this.context.t('set_actual_leak_position_on_map')}</p>
                        <i className='close' onClick={close}/>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="col-xs-12">
                            <div className="inline-group">
                                <label className="col-xs-2 control-label">{this.context.t('coordinate')}</label>
                                <div className="col-xs-10 control-input">
                                    <Field
                                        className="form-control"
                                        name="Coordinate"
                                        placeholder={this.context.t('click_on_map_to_set_coordinate')}
                                        component={Input}
                                    />
                                </div>
                            </div>
                        </div>

                        <button
                            className="btn btn-success btn-submit"
                            type='submit'
                        >{this.context.t('save')}</button>
                    </form>
                </div>
            </Draggable>
        );
    }
}

const validate = (values) => {
  const errors = {};

  const coordinateInput = values.Coordinate;

  if (_.isEmpty(coordinateInput)) {
    errors.Coordinate = 'Require';
  } else {
    try {
      new Coordinates(coordinateInput);
    } catch (error) {
      errors.Coordinate = 'Invalid coordinate';
    }
  }

  return (errors);
};

ActualLeakLocationWindow.contextTypes = {
    t: PropTypes.func.isRequired
};

const actualLeakLocationWindow = reduxForm({
    form: 'actual-leak-position', // a unique name for this form
    enableReinitialize: true,
    validate,
})(ActualLeakLocationWindow);

export default actualLeakLocationWindow;
