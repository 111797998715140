import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import TextCell from '../../components/TextCell/TextCell';
require('fixed-data-table-2/dist/fixed-data-table.css');

export default class ReportsTable extends React.Component {
    render() {
        const { pageHeight, pageWidth, data, columns, report } = this.props;
        return (
            <div className="reports-table">
                <Table
                    height={pageHeight - 45}
                    width={pageWidth}
                    rowsCount={report.length}
                    rowHeight={30}
                    headerHeight={30}
                    onRowClick={this.onRowClick}
                    {...this.props}
                >

                    {columns.map(function (column) {
                        return <Column
                            key={column.key}
                            columnKey={column.key}
                            width={120}
                            flexGrow={1}
                            header={<Cell>{column.key}</Cell>}
                            cell={<TextCell data={report} field={column.key} />}
                        />
                    })}

                </Table>
            </div>
        );
    }

}
