import * as types from '../constants/ActionTypes';

export function files(state = {}, action) {
    switch (action.type) {
        case types.REQUEST_LEAK_FILES:
            return Object.assign({}, state, {
                [action.leak]: { isFetching: true }
            });
        case types.RECEIVE_LEAK_FILES:
            return Object.assign({}, state, {
                [action.leak]: { data: action.files.data, isFetching: false }
            });

        case types.UPLOAD_FILE_REQ:
            return Object.assign({}, state, {
                [action.leak]: { isFetching: true }
            });
            break;

        case types.UPLOAD_FILE_RES:
            return Object.assign({}, state, {
                [action.leak]: { isFetching: false }
            });
        default:
            return state;
    }
}