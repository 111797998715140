import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { graphsBackgroundColor } from '../../constants/Misc';
import SpectrogramGraph from './SpectrogramGraph';
import CorrelationGraph from './CorrelationGraph';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const config = {
  displaylogo: false,
  responsive: true,
  // displayModeBar: false,
};

const useStyle = makeStyles(() => ({
  root: {
    height: '435px',
    overflowY: 'auto'
  }
}));

export default function Signals(props, context) {

  const styles = useStyle();
  const commonLayout = {
    paper_bgcolor: graphsBackgroundColor,
    autosize: false,
    width: 360,
    height: 250,
    margin: {
      l: 35,
      r: 10,
      b: 40,
      t: 30,
    },
    hovermode: 'closest',
  };

  const dataRadio = [
    {
      y: props.radio,
      type: 'scatter',
      line: {shape: 'spline'},
      showlegend: false,
    }
  ];

  let dataDual;

  if (props.dual && props.dual.a && props.dual.b) {
    dataDual = [
      {
        y: props.dual.a,
        type: 'scatter',
        line: {
          shape: 'spline',
          color: 'blue'
        },
        showlegend: false,
      },
      {
        y: props.dual.b,
        type: 'scatter',
        line: {
          shape: 'spline',
          color: 'green'
        },
        showlegend: false,
      }
    ];
  }

  const quality = (props.metaData.radio_quality) ? props.metaData.radio_quality.toFixed(1) : '-';
  const radioTitle = context.t('radio_quality', { dt: props.metaData.radio_dt, quality: quality });

  return (
    <Box className={styles.root}>
      <div>
        <Plot
          config={config}
          layout={{...commonLayout, title: {text: radioTitle}}}
          data={dataRadio}
        />
        <Plot
          config={config}
          layout={{...commonLayout, title: {text: 'Dual'}}}
          data={dataDual}
        />
      </div>

      <div>
        <SpectrogramGraph
          data={props.histogramInstance}
          graphTitle={context.t('last_spectrogram')}
          xValues={{
            start: props.graphParams.freq.start,
            step: props.graphParams.freq.step
          }}
          // yAxisLabels={props.graphsDistancesForAxis}
          xTitle={context.t('frequency')}
          yTitle={context.t('distance')}
          yAxisLabels={props.distancesForAxis}
        />
        <CorrelationGraph
          data={[props.correlationInstance]}
          title={context.t('last_correlation')}
          factor={props.graphParams.factor}
          xTitle={context.t('distance')}
          yTitle={context.t('contrast')}
          xAxisLabels={props.distancesForAxis}
          leaksMarkers={props.maxProbabilityPositions}
          sensorsOnAxis={props.sensorsPositions}
        />
      </div>
    </Box>
  );
}

Signals.contextTypes = {
  t: PropTypes.func.isRequired
};
