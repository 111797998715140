import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';

import { fetchMiddleware } from './MiddlewareActions';

import { setSelectedItem } from './GlobalsActions';
import * as actionsCouples from './CouplesActions';

import { PipeGraph } from './Utilities/PipeGraph';
import { shortestPathsWithPath } from './Utilities/dijkstra';
import { headingDistanceTo } from 'geolocation-utils';
import { selectFeature } from "./selectors";
import { clearCouplePath, resetForm, setCouplePathReady, setFieldText } from "./setters";

const _ = require('lodash');

const getSelectedCouple = (state) => {
  let selectedCouple = null;

  if (state.leaksList.leaksByProject.selectedFeatureType == 'couple') {
    const selectedFeatureId = state.leaksList.leaksByProject.selectedFeature;
    const selectedProjectId = state.leaksList.selectedProject;
    const couplesBundle = state.leaksList.leaksByProject[selectedProjectId].couples;

    selectedCouple = couplesBundle.couples[couplesBundle.indexMap[selectedFeatureId]];
  }

  return (selectedCouple);
};

export const setEditMode = (bEditMode, callback) => {
  return (dispatch, getState) => {
    if (!bEditMode) {
      dispatch(clearAll());
    } else {
      const state = getState();
      const materials = state.leaksList.optionList.options.Materials;
      const selectedCouple = getSelectedCouple(state);
      const selectedProjectId = state.leaksList.selectedProject;
      const sensorsList = state.leaksList.leaksByProject[selectedProjectId].sensors.sensors;
      const coupleSensorsIds = [selectedCouple.Sensor1, selectedCouple.Sensor2];
      const coupleDetails = state.form.coupleDetails.values;

      const coupleSensorsItems = sensorsList.filter((itrSensor) => coupleSensorsIds.indexOf(itrSensor.id) != -1);
      if (coupleSensorsItems.length != 2) {
        if (callback != null) {
          callback(false);
        }
      } else {
        const sensor1 = coupleSensorsItems.find((sensor) => sensor.id == selectedCouple.Sensor1);
        const sensor2 = coupleSensorsItems.find((sensor) => sensor.id == selectedCouple.Sensor2);
        const couplePath = state.leaksList.leaksByProject[selectedProjectId].couplePath.path;

        const isCouplePathReady = isPathIsReady(state, couplePath);

        const pathSectionsArray = [];
        couplePath.forEach((itrPoint, index) => {
          if (index != couplePath.length - 1) {
            const pathSectionItem = {
              index: parseInt(index + 1),
              Latitude1: itrPoint.Latitude,
              Longitude1: itrPoint.Longitude,
              Latitude2: couplePath[index + 1].Latitude,
              Longitude2: couplePath[index + 1].Longitude,
              Length: itrPoint.Length ? parseFloat(itrPoint.Length).toFixed(3) : null,
              Material: itrPoint.Material ? materials[itrPoint.Material].value : '',
            };
            pathSectionsArray.push(pathSectionItem);
          }
        });

        let commentInCouples = state.form.coupleDetails.values.Comment;

        dispatch(setEditPathMode('auto'));
        dispatch(setFieldText('coupleDetails', 'Sensor1', sensor1.id));
        dispatch(setFieldText('coupleDetails', 'Address1', sensor1.address));
        dispatch(setFieldText('coupleDetails', 'Sensor2', sensor2.id));
        dispatch(setFieldText('coupleDetails', 'Address2', sensor2.address));

        dispatch(setFieldText('coupleDetails', 'Comment', commentInCouples));
        dispatch(updateCoulePath(pathSectionsArray, couplePath));

        dispatch(setEditModeAction(bEditMode));

        dispatch(setCouplePathReady(isCouplePathReady));
      }
    }
  };
};

const setEditModeAction = (bEditMode) => {
  return {
    type: types.SET_COUPLE_EDIT_MODE,
    editMode: bEditMode,
  };
};

export const setupSensors = (isSetup) => {
  return {
    type: types.SETUP_NEW_COUPLE,
    isSetup
  };
};

export const reset = () => {
  return (dispatch) => {
    dispatch(resetForm('coupleDetails'));
    dispatch({
      type: types.RESET_NEW_COUPLE
    });
  };
};

export const handleMapClick = (LatLng, feature) => {
  return (dispatch, getState) => {
    const state = getState();
    const planning = state.planning;
    const step = planning.step;
    const coupleDetails = state.form.coupleDetails.values;

    if (planning.setupSensors) {
      if (feature != null && feature.type == 'sensor') {
        if (coupleDetails == null || coupleDetails.Sensor1 == null) {
          // have to set Sensor 1
          dispatch(setFieldText('coupleDetails', 'Sensor1', feature.id));
          dispatch(setFieldText('coupleDetails', 'Address1', feature.address));
          // console.log("feature1");
          // console.log(feature);
          return dispatch(selectSensorForCouple('1'));
        } else if (coupleDetails.Sensor2 == null) {
          // have to set Sensor 2
          // console.log("feature2");
          // console.log(feature);
          dispatch(setupSensors(false));
          dispatch(setFieldText('coupleDetails', 'Sensor2', feature.id));
          dispatch(setFieldText('coupleDetails', 'Address2', feature.address));
          return dispatch(selectSensorForCouple('2'));
        }
      }
    }

    if (planning.editMode && planning.edittingMode != null && step >= 4) {
      let index = null;
      if (feature != null && feature.type == 'generationCouplePath') {
        index = feature.id + 1;
      }
      return dispatch(addCouplePathPoint(LatLng, feature, index));
    }
  };
};

const calculateSectionsCouplePath = (state, couplePath, setMaterial) => {
  const materials = state.leaksList.optionList.options.Materials;
  const coupleDetails = Object.assign([], state.form.coupleDetails.values);

  let sectionsCouplePath = [];

  couplePath.forEach((section, index) => {
    if (index < couplePath.length - 1) {
      let p1 = { lat: couplePath[index].Latitude, lon: couplePath[index].Longitude };
      let p2 = { lat: couplePath[index + 1].Latitude, lon: couplePath[index + 1].Longitude };
      let distance = headingDistanceTo(p1, p2).distance;
      const m = setMaterial ? materials[coupleDetails.MaterialCode].value : couplePath[index].Material;


      let pathSectionItem = {
        index: couplePath[index].index,
        Latitude1: couplePath[index].Latitude,
        Longitude1: couplePath[index].Longitude,
        Latitude2: couplePath[index + 1].Latitude,
        Longitude2: couplePath[index + 1].Longitude,
        Length: couplePath ? distance.toFixed(3) : null,
        Material: m ?? materials[coupleDetails.MaterialCode].value
      };
      sectionsCouplePath.push(pathSectionItem);
    }
  });

  return sectionsCouplePath;
};

const addCouplePathPoint = (latLngPoint, feature, index) => {
  return (dispatch, getState) => {
    const state = getState();
    const isCouplePathReady = state.planning.isCouplePathReady;
    const couplePath = Object.assign([], state.planning.couplePath);

    let pointIndex;

    let sectionsCouplePath = [];

    // for click on the couple path for split:
    if (feature != null && feature.type == 'generationCouplePath') {
      // have to split the clicked line:
      pointIndex = couplePath.findIndex((p) => p.index == feature.id);

      //update next indexs:
      couplePath.forEach((point, index) => {
        if (index > pointIndex) {
          point.index += 1;
        }
      });

      // insert the new point in its position in the couple path array:
      couplePath.splice(pointIndex + 1, 0, {
        Latitude: latLngPoint[1],
        Longitude: latLngPoint[0],
        index: pointIndex + 1,
      });
      sectionsCouplePath = calculateSectionsCouplePath(state, couplePath, false);
      dispatch(updateCoulePath(sectionsCouplePath, couplePath));

    } else if (!isCouplePathReady) {
      pointIndex = couplePath.length;
      couplePath.push({
        index: pointIndex,
        Longitude: latLngPoint[0],
        Latitude: latLngPoint[1],
      });
      sectionsCouplePath = calculateSectionsCouplePath(state, couplePath, false);
      dispatch(updateCoulePath(sectionsCouplePath, couplePath));

    }

    const isNewCouplePathReady = isPathIsReady(state, couplePath);

    if (isNewCouplePathReady != isCouplePathReady) {
      dispatch(setCouplePathReady(isNewCouplePathReady));
    }
  };
};

const isPathIsReady = (state, couplePath) => {
  let bRc = false;

  if (couplePath.length > 0) {
    let newAndLastPoint = couplePath[couplePath.length - 1];
    let lastPointPosition;
    const digits = 7;
    const selectedProject = state.leaksList.selectedProject;
    const projectSensors = state.leaksList.leaksByProject[selectedProject].sensors;
    const newCoupleDetails = state.form.coupleDetails.values;
    const sensor2Id = newCoupleDetails.Sensor2;
    const sensor2 = projectSensors.sensors[projectSensors.indexMap[sensor2Id]];
    const sensor2Position = [sensor2.Longitude.toFixed(digits), sensor2.Latitude.toFixed(digits)];

    if (newAndLastPoint != null &&
      newAndLastPoint.Longitude && newAndLastPoint.Latitude
    ) {
      lastPointPosition = [newAndLastPoint.Longitude.toFixed(digits), newAndLastPoint.Latitude.toFixed(digits)];
    } else {
      const lastPathPoint = couplePath[couplePath.length - 1];
      lastPointPosition = [lastPathPoint.Longitude.toFixed(digits), lastPathPoint.Latitude.toFixed(digits)];
    }
    bRc = _.isEqual(lastPointPosition, sensor2Position);
  }
  return (bRc);
};

const updateCoulePath = (sectionsCouplePath, pathPoints = []) => {
  return {
    type: types.UPDATE_COUPLE_PATH_POINTS,
    pathPoints,
    sectionsCouplePath
  };
};

const selectSensorForCouple = (key) => {
  return {
    type: types.SELECT_SENSOR_FOR_NEW_COUPLE,
    key
  };
};

export const setEditPathMode = (edittingMode) => {
  const action = {
    type: types.SET_EDIT_COUPLE_PATH_MODE,
    edittingMode
  };
  return (dispatch, getState) => {
    dispatch(clearCouplePath());

    switch (edittingMode) {

      case 'auto':
        return dispatch(action);
      case 'manual': {
        const state = getState();
        const projectId = state.leaksList.selectedProject;
        const projectState = state.leaksList.leaksByProject[projectId];
        const sensors = projectState.sensors;
        const newCoupleDetails = state.form.coupleDetails.values;
        const firstSensor = sensors.sensors[sensors.indexMap[newCoupleDetails.Sensor1]];
        let position = null;
        if (firstSensor != null) {
          position = [firstSensor.Longitude, firstSensor.Latitude];
        } else {
          const couplePath = projectState.couplePath;
          if (couplePath != null) {
            if (couplePath.path != null && couplePath.path.length > 0) {
              const firstSegment = couplePath.path[0];
              position = [firstSegment.Longitude1, firstSegment.Latitude1];
            }
          }
        }

        if (position != null) {
          dispatch(addCouplePathPoint(position));
        }

        return dispatch(action);
      }
    }
  };
};

export const setCouplePathPointPosition = (index, newLatLngPoint) => {
  const setPointPositionAction = {
    type: types.SET_COUPLE_PATH_POINT,
    index,
    point: newLatLngPoint,
  };
  return (dispatch, getState) => {
    const state = getState();
    if (state.planning.edittingMode || state.planning.editMode) {
      const couplePath = Object.assign([], state.planning.couplePath);
      if (index == couplePath.length - 1) {
        const isCouplePathReady = state.planning.isCouplePathReady;
        const isNewPathReady = isPathIsReady(state, couplePath);

        if (isNewPathReady != isCouplePathReady) {
          dispatch(setCouplePathReady(isNewPathReady));
        }
      }
      let sectionsArr = [];
      const couplePath2 = Object.assign([], state.planning.couplePath);
      const sectionsCouplePath = state.planning.sectionsCouplePath;
      const edittedPointItem = couplePath.find(item => {
        return item.index == index;
      });

      let edittedPointItemLat = parseFloat(edittedPointItem.Latitue);
      let edittedPointItemLng = parseFloat(edittedPointItem.Longitude);

      sectionsCouplePath.forEach((item, index) => {
        if (item.Latitude1 == edittedPointItemLat && item.Longitude1 == edittedPointItemLng) {
          let newLat = parseFloat(newLatLngPoint[1]);
          let newlng = parseFloat(newLatLngPoint[0]);
          sectionsCouplePath[index].Latitude1 = newLat;
          sectionsCouplePath[index].Longitude1 = newlng;
        }
        else if (item.Latitude2 == edittedPointItemLng && item.Longitude2 == edittedPointItemLng) {
          let newLat = parseFloat(newLatLngPoint[1]);
          let newlng = parseFloat(newLatLngPoint[0]);
          sectionsCouplePath[index].Latitude2 = newLat;
          sectionsCouplePath[index].Longitude2 = newlng;
        }
      });
      sectionsArr = calculateSectionsCouplePath(state, couplePath2, false);
      dispatch(updateCoulePath(sectionsArr, couplePath2));

      return dispatch(setPointPositionAction);
    }
  };
};

export const refreshSectionsCouplePath = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.planning.edittingMode || state.planning.editMode) {
      const couplePath = Object.assign([], state.planning.couplePath);
      // couplePath.forEach((item) => {
      //   item.Material = materialOption.value;
      //   item.MaterialName = materialOption.label;
      //   }
      // );
      const sectionsArr = calculateSectionsCouplePath(state, couplePath, true);
      return dispatch(updateCoulePath(sectionsArr, couplePath));

      // return dispatch(setPointPositionAction);
    }
  };
};

export const setCoupleLength = (length) => {
  return setFieldText('coupleDetails', 'PathLengthM', length);
  //return {
  //  type: types.SET_COUPLE_PATH_LENGTH,
  //  length
  //};
};

export const setComment = (comment) => {
  return setFieldText('coupleDetails', 'Comment', comment);
};

export const clearAll = () => {
  return {
    type: types.CLEAR_ALL_NEW_COUPLE_DATA,
  };
};

export const generateAutoCouple = () => {
  return (dispatch, getState) => {

    const state = getState();
    const projectId = state.leaksList.selectedProject;

    return dispatch(feachPipesForAutoPath(projectId)).then(() => {
      return dispatch(findAutoCouplePath());
    });

    //console.log('tada');
    //return {
    //  type: 'TODO_CHOOSE_NAME'
    //};
  };
};

const findAutoCouplePath = () => {
  return (dispatch, getState) => {
    const state = getState();
    const planning = state.planning;
    const pipes = planning.pipes;
    const newCoupleDetails = state.form.coupleDetails.values;

    const materials = state.leaksList.optionList.options.Materials;
    const sensorId1 = newCoupleDetails.Sensor1;
    const sensorId2 = newCoupleDetails.Sensor2;

    const projectId = state.leaksList.selectedProject;
    const sensors = state.leaksList.leaksByProject[projectId].sensors;
    const sensor1 = sensors.sensors[sensors.indexMap[sensorId1]];
    const sensor2 = sensors.sensors[sensors.indexMap[sensorId2]];

    const sensor1LocationForGraph = [-1, [sensor1.Latitude, sensor1.Longitude], 0, 0];
    const sensor2LocationForGraph = [-2, [sensor2.Latitude, sensor2.Longitude], 0, 0];

    const pipeGraph = new PipeGraph(pipes, sensor1LocationForGraph, sensor2LocationForGraph, 4);

    const pathPipeRes = shortestPathsWithPath(pipeGraph, sensor1LocationForGraph, sensor2LocationForGraph);

    let newAutoPath = [];
    pathPipeRes[0].forEach((point, index, allPoints) => {
      const indexForList = allPoints.length - 1 - index;
      const newPoint = {
        Latitude: point.lat,
        Longitude: point.lng,
        Material: point.Material,
        LengthM: point.LengthM,
        index: indexForList
      };
      newAutoPath.unshift(newPoint);
    });

    var pathSectionsArray = [];

    let sectionsByMaterial = {}; // for find the large section for set the main Couple Material.

    newAutoPath.forEach((section, index, allSections) => {
      if (index >= 0 && index < allSections.length - 1) {
        let pathSectionItem = {
          index: section.index,
          Latitude1: section.Latitude,
          Longitude1: section.Longitude,
          Latitude2: allSections[index + 1].Latitude,
          Longitude2: allSections[index + 1].Longitude,
          Length: section.LengthM ? parseFloat(section.LengthM).toFixed(3) : null,
          Material: (section.Material != null) ? materials[section.Material].value : materials[newCoupleDetails.MaterialCode].value,
        };
        pathSectionsArray.push(pathSectionItem);
      }

      if (section.Material != null) {
        // initiate material attribute if needed:
        if (sectionsByMaterial[section.Material] == null) sectionsByMaterial[section.Material] = 0;

        sectionsByMaterial[section.Material] += (section.LengthM || 0);
      }
    });

    // find the larges section type:
    let sectionsByMaterialSorted = Object.keys(sectionsByMaterial).sort((a, b) => sectionsByMaterial[b] - sectionsByMaterial[a]);
    if (sectionsByMaterialSorted != null && sectionsByMaterialSorted[0] != null) {
      const materialValue = parseInt(sectionsByMaterialSorted[0]);
      const materialOption = materials.find((material) => material.value == materialValue);

      dispatch(setMaterial(materialOption));
      dispatch(setFieldText('coupleDetails', 'MaterialCode', materialValue));
    }


    // newAutoPath.forEach( (section, index) => {
    //   if(index < newAutoPath.length-1 && index > 0){
    //     let pathSectionItem = {
    //       index: newAutoPath[index].index-1,
    //       Latitude1: newAutoPath[index].Latitude,
    //       Longitude1: newAutoPath[index].Longitude,
    //       Latitude2: newAutoPath[index+1].Latitude,
    //       Longitude2: newAutoPath[index+1].Longitude,
    //       Length: newAutoPath[index].LengthM ? parseFloat(newAutoPath[index].LengthM).toFixed(3) : null,
    //       Material: newAutoPath[index].Material ? materials[newAutoPath[index].Material].value : materials[newCoupleDetails.Material].value,
    //     };
    //     pathSectionsArray.push(pathSectionItem);
    //   }
    // });

    dispatch(setCouplePathReady(true));

    dispatch(setFieldText('coupleDetails', 'Sensor1', sensor1.id));
    dispatch(setFieldText('coupleDetails', 'Address1', sensor1.address));
    dispatch(setFieldText('coupleDetails', 'Sensor2', sensor2.id));
    dispatch(setFieldText('coupleDetails', 'Address2', sensor2.address));

    return dispatch(updateCoulePath(pathSectionsArray, newAutoPath));
  };
};

export const generateNewCouple = (cb, values) => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const sensors = state.leaksList.leaksByProject[projectId].sensors;
    const newCoupleDetails = state.form.coupleDetails.values;
    const sensor1 = sensors.sensors[sensors.indexMap[newCoupleDetails.Sensor1]];
    const sensor2 = sensors.sensors[sensors.indexMap[newCoupleDetails.Sensor2]];
    const sop1 = sensor1.SOPointID;
    const sop2 = sensor2.SOPointID;
    const material = newCoupleDetails.MaterialCode;
    const velocity = values.VelocityMS;
    const length = newCoupleDetails.PathLengthM;
    const pathPoints = state.planning.couplePath;
    const sectionsCouplePath = state.planning.sectionsCouplePath;
    const is_overlapping = newCoupleDetails.is_overlapping;

    const data = {
      material,
      velocity,
      sop1,
      sop2,
      length,
      pathPoints,
      sectionsCouplePath,
      is_overlapping,
      ...values
    };

    dispatch(setEditPathMode(null));
    dispatch(selectFeature(projectId, '', null));
    dispatch(generateCoupleRequest(data));

    const path = endPoints.END_POINT + '/install/couple/' + projectId;
    return fetchMiddleware(path, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    }, getState).then((json) => {

      dispatch(generateCoupleReceive());
      if (cb != null) {
        cb(json);
      }
      if (json.status) {
        const newCoupleId = json.data[0].CoupleID;

        dispatch(clearAll());
        return dispatch(actionsCouples.fetchCouplesIfNeeded(projectId, true)).then(() => {
          return dispatch(selectTheNewGeneratedCouple(newCoupleId));
        });
      }
    });
  };
};

export const updateCouple = (coupleId, values, cb) => {
  return (dispatch, getState) => {
    const state = getState();
    const length = state.form.coupleDetails.values.PathLengthM;
    const VelocityMS = values.VelocityMS;
    const sectionsCouplePath = state.planning.sectionsCouplePath;

    const data = {
      ...values,
      VelocityMS,
      length,
      sectionsCouplePath,
    };

    const selectedProjectId = state.leaksList.selectedProject;
    const editedCouplePathData = state.planning.couplePath;
    const currentCouplePathData = state.leaksList.leaksByProject[selectedProjectId].couplePath;

    const coordinateCompareDigits = 7;
    const comparableEditedData = editedCouplePathData.map((item) => {
      return [item.Latitude.toFixed(coordinateCompareDigits), item.Longitude.toFixed(coordinateCompareDigits)];
    });
    const comparableCurrentData = [];
    currentCouplePathData.path.forEach((item) => {
      comparableCurrentData.push([item.Latitude.toFixed(coordinateCompareDigits), item.Longitude.toFixed(coordinateCompareDigits)]);
    });
    // currentCouplePathData.path[0].pathPoints.forEach((item, index) => {
    //   comparableCurrentData.push([item[1].toFixed(coordinateCompareDigits), item[0].toFixed(coordinateCompareDigits)]);
    // });
    if (!_.isEqual(comparableEditedData, comparableCurrentData)) {
      values.couplePath = editedCouplePathData;
    }

    const path = `${endPoints.INSTALL_ENDPOINT}/couple/${selectedProjectId}/${coupleId}`;


    dispatch(setEditPathMode(null));
    dispatch(selectFeature(selectedProjectId, '', null));

    return fetchMiddleware(path, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    }, getState).then((json) => {
      if (json.status) {
        dispatch(clearAll());
        return dispatch(actionsCouples.fetchCouplesIfNeeded(selectedProjectId, true)).then(() => {
          return dispatch(selectTheNewGeneratedCouple(coupleId));
        });
      }
      // console.log(json);
    });
  };
};

const selectTheNewGeneratedCouple = (newCoupleId) => {
  return (dispatch, getState) => {
    if (newCoupleId != null) {
      const state = getState();
      const projectId = state.leaksList.selectedProject;
      const couplesData = state.leaksList.leaksByProject[projectId].couples;
      const newCoupleItem = couplesData.couples[couplesData.indexMap[newCoupleId]];
      if (newCoupleItem != null) {
        dispatch(setSelectedItem(newCoupleItem));
        dispatch(actionsCouples.fetchCouplePathIdNeeded(projectId, newCoupleId));
      }
    }
  };
};

const feachPipesForAutoPath = (projectId) => {
  return (dispatch, getState) => {
    const state = getState();
    const planning = state.planning;
    const newCoupleDetails = state.form.coupleDetails.values;

    const sensors = state.leaksList.leaksByProject[projectId].sensors;
    const sensor1 = sensors.sensors[sensors.indexMap[newCoupleDetails.Sensor1]];
    const sensor2 = sensors.sensors[sensors.indexMap[newCoupleDetails.Sensor2]];
    const queryParams = {
      lat1: sensor1.Latitude,
      lng1: sensor1.Longitude,
      lat2: sensor2.Latitude,
      lng2: sensor2.Longitude
    };
    const projectPipes = planning.pipes;

    const path = endPoints.END_POINT + '/install/pipes/' + projectId + '?coordinates=' + JSON.stringify(queryParams);

    dispatch(requestProjectPipes());

    return fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        const pipes = json.data;
        return dispatch(receiveProjectPipes(pipes));
      }
    });
  };
};

const requestProjectPipes = () => {
  return {
    type: types.REQUEST_PROJECT_PIPES,
  };
};

const receiveProjectPipes = (pipes) => {
  return {
    type: types.RECEIVE_PROJECT_PIPES,
    pipes,
  };
};

const generateCoupleRequest = (values) => {
  return {
    type: types.GENERATE_COUPLE_REQUEST,
    values,
  };
};

const generateCoupleReceive = () => {
  return {
    type: types.GENERATE_COUPLE_RECEIVE,
  };
};

export const setMaterial = (materialOption) => {
  return (dispatch) => {
    if (materialOption != null && materialOption.velocity != null) {
      dispatch(setFieldText('coupleDetails', 'VelocityMS', materialOption.velocity));
    }
  };
};
