import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const classNames = require('classnames');
require('./Scale.scss');

const CropSelect = (
  {
    clear,
    setActive,
    polygonPoints,
    selectedSensors,
    getPipesInPolygon,
    setSelectedPolygonSensors,
    generateSensorsReportFromSelections,
    user,
  },
  context
) => {
  const [isShow, setIsShow] = React.useState(false);
  const [loadingPipes, setLoadingPipes] = React.useState(false);
  const [pipesLength, setPipesLength] = React.useState();

  const classes = useStyles();

  const panelClasses = classNames('crop-select', { show: isShow });

  const toggleClick = () => {
    const visible = !isShow;
    setIsShow(visible);
    setActive(visible);
  };

  return (
    <div className={panelClasses}>
      <div className='actions'>
        <i className='icon-toggle' onClick={toggleClick} />
      </div>
      <h1>{context.t('draw_sensors_selection_area')}</h1>
      <div className='distance-data'>
        {selectedSensors.length > 0 && (
          <p>
            {context.t('sensors_selected', { num: selectedSensors.length })}
          </p>
        )}
        {selectedSensors.length > 0 && (
          <div className='crop-select-buttons'>
            {user.g5Operator && (
              <Button
                color='primary'
                variant='contained'
                onClick={() => setSelectedPolygonSensors(selectedSensors)}
              >
                {context.t('show_in_5g')}
              </Button>
            )}
            <Button
              color='primary'
              variant='contained'
              onClick={() =>
                generateSensorsReportFromSelections(selectedSensors)
              }
            >
              {context.t('report')}
            </Button>
            <IconButton color='secondary' variant='contained' onClick={clear}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
        {user.isAQS && polygonPoints.length > 0 && (
          <div>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setLoadingPipes(true);
                setPipesLength(undefined);
                getPipesInPolygon(polygonPoints)
                  .then((data) => {
                    setLoadingPipes(false);
                    if (data && data.length > 0) {
                      const totalLength = data.reduce(
                        (total, itr) => total + itr.sectionLength,
                        0
                      );
                      setPipesLength(totalLength);
                    }
                  })
                  .catch((err) => {
                    setLoadingPipes(false);
                    // console.log(err);
                  });
              }}
            >
              {context.t('pipe_length')}
            </Button>
            {loadingPipes && (
              <Typography variant='inherit' component={'strong'}>
                {context.t('loading')}
              </Typography>
            )}
            {pipesLength && (
              <Typography
                variant='inherit'
                component={'strong'}
                classes={{ root: classes.lengthTitle }}
              >
                {Math.round(pipesLength)} [m]
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  lengthTitle: {
    margin: 10,
  },
});

CropSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CropSelect;
