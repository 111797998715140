import PropTypes from 'prop-types';
import React from 'react';

import Alert from '@mui/material/Alert';
import InputField from './InputField';

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
const SMS_MFA = 'SMS_MFA';
const FIREBASE_SMS_MFA = 'FIREBASE_SMS_MFA';

export default function SignIn(props, context) {
  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  function handleInputChange(setter) {
    return (event) => {
      setter(event.target.value);
    };
  }

  function doSignIn() {
    setError(null);
    props.setLoading(true);
    props.signInFunc(email, password, (data) => {
      props.setLoading(false);

      if (data instanceof Error) {
        setError(data);
      } else if (data.challengeName === NEW_PASSWORD_REQUIRED) {
        props.changeLoginMode('newPasswordRequired', { email, password });
      } else if (data.challengeName === SMS_MFA) {
        props.setLoggingInUser(data);
        props.changeLoginMode('setMfaCode');
      } else if (data.challengeName === FIREBASE_SMS_MFA) {
        props.changeLoginMode('setFirebaseMfaCode');
      }
    });
  }

  return (
    <div style={{ margin: '50px 30px' }}>
      {error && (
        <Alert
          style={{ position: 'absolute', top: '-35%', fontSize: 'large' }}
          severity='error'
        >
          Invalid email or password. Please try again.
        </Alert>
      )}
      <InputField
        label={context.t('email')}
        name='email'
        type='email'
        value={email}
        onChange={handleInputChange(setEmail)}
        disabled={props.loading}
      />
      <InputField
        label={context.t('password')}
        name='password'
        type='password'
        value={password}
        onChange={handleInputChange(setPassword)}
        disabled={props.loading}
      />
      <a
        className='forgot-link'
        onClick={() => props.changeLoginMode('resetPassword')}
        href='#'
      >
        {context.t('forgot_password')}
      </a>

      <div className='form-group form-actions'>
        <div className='col-xs-6 col-xs-offset-3 login-field'>
          <button
            className='login-submit btn btn-success btn-block'
            type='submit'
            onClick={doSignIn}
          >
            {context.t('login')}
          </button>
        </div>
      </div>
    </div>
  );
}

SignIn.contextTypes = {
  t: PropTypes.func.isRequired,
};

SignIn.propTypes = {
  changeLoginMode: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  setLoggingInUser: PropTypes.func.isRequired,
  signInFunc: PropTypes.func.isRequired,
};
