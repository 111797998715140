import React from 'react';
import PropTypes from 'prop-types';

import { ENV, ENVIRONMENTS } from '../../constants/EndPoints';

import { featuresTypes } from '../Map/Layers/Options';

import { PIPES_DISPLAY_TYPE } from '../../constants/SettingsKeys';
import { COVERAGE_DISPLAY_TYPE } from '../../constants/SettingsKeys';

const classNames = require('classnames');

require('./LayerManager.scss');

const LayerManager = (props, context) => {
  const {
    layers,
    pipesSource,
    pipesSourceChange,
    viewWaterMeterLayer,
    noiseSamplesColorAttr,
    mobileNoiseSamplesColorAttr,
  } = props;

  const [showManager, setShowManager] = React.useState(false);

  const onLayerChange = (event, layerName) => {
    props.layersHandler(event, layerName);
    props.onLayerChange(event, layerName);
  };

  const panelClasses = classNames('layer-manager', {
    show: showManager,
  });

  return (
    <div className={panelClasses}>
      <div className='actions'>
        <i
          className='icon-toggle'
          onClick={() => setShowManager(!showManager)}
        />
      </div>
      <h2>{context.t('layer_manager')}</h2>
      <form>
        <LayerChecbox
          id='form-satellite'
          name='satellite'
          checked={layers.satelliteLayer}
          onChange={(e) => onLayerChange(e, 'satelliteLayer')}
          label={context.t('satellite_view')}
        />
        <LayerChecbox
          id='form-leaks'
          name='leaks'
          checked={layers[featuresTypes.ALERT]}
          onChange={(e) => onLayerChange(e, featuresTypes.ALERT)}
          label={context.t('show_alerts')}
        />
        <LayerChecbox
          id='form-mobile-alerts'
          name='leaks'
          checked={layers[featuresTypes.MOBILE_ALERTS]}
          onChange={(e) => onLayerChange(e, featuresTypes.MOBILE_ALERTS)}
          label={context.t('show_mobile_alerts')}
        />
        <LayerSelect
          id='form-noise-samples'
          name='noiseSamples'
          checked={layers[featuresTypes.NOISE]}
          onChange={(e) => onLayerChange(e, featuresTypes.NOISE)}
          selection={noiseSamplesColorAttr}
          onSelect={(e) => {
            const value = e.target.value;
            props.noiseSamplesSourceChange(e, value);
          }}
          options={[
            { value: 'Nc1', label: context.t('minimal_noise_intensity') },
            { value: 'Nc3', label: context.t('filtered_noise_intensity') },
          ]}
        />
        {JSON.parse(process.env.REACT_APP_MOBILE_MENU) && (
          <LayerChecbox
            id='form-mobile-noise-samples'
            name='mobileNoiseSamples'
            checked={layers[featuresTypes.MOBILE_SAMPLES]}
            onChange={(e) => onLayerChange(e, featuresTypes.MOBILE_SAMPLES)}
            label={context.t('show_mobile_samples')}
          />
        )}

        <LayerChecbox
          id='form-sensors'
          name='sensors'
          checked={layers[featuresTypes.SENSOR]}
          onChange={(e) => onLayerChange(e, featuresTypes.SENSOR)}
          label={context.t('show_sensors')}
        />
        <LayerChecbox
          id='form-sops'
          name='sops'
          checked={layers[featuresTypes.SOP]}
          onChange={(e) => onLayerChange(e, featuresTypes.SOP)}
          label={context.t('show_installation_points')}
        />
        <LayerChecbox
          id='form-interferences'
          name={featuresTypes.INTERFERENCE}
          checked={layers[featuresTypes.INTERFERENCE]}
          onChange={(e) => onLayerChange(e, featuresTypes.INTERFERENCE)}
          label={context.t('show_areas_of_interest')}
        />
        {JSON.parse(process.env.REACT_APP_SHOW_LAYER_PIPES) && <LayerSelect
          id='form-pipes'
          name='pipes'
          checked={layers[featuresTypes.PIPES]}
          onChange={(e) => onLayerChange(e, featuresTypes.PIPES)}
          selection={pipesSource}
          onSelect={(e) => {
            const value = e.target.value;
            pipesSourceChange(e, value);
            localStorage.setItem(PIPES_DISPLAY_TYPE, value);
          }}
          options={[
            { value: 'materials', label: context.t('pipes_by_material') },
            { value: 'presure', label: context.t('pipes_by_pressure') },
            { value: 'diameter', label: context.t('pipes_by_diameter') },
            { value: 'year', label: context.t('pipes_by_placement_year') },
          ]}
        />
        }
        <LayerChecbox
          id='form-couples-coverage'
          name='couplesCoverage'
          checked={layers[featuresTypes.COUPLES_COVERAGE]}
          onChange={(e) => onLayerChange(e, featuresTypes.COUPLES_COVERAGE)}
          label={context.t('couples_coverage')}
        />

        {false && (
          <LayerChecbox
            id='form-coupels-path'
            name='couplesPath'
            checked={layers.couplePathLayer}
            onChange={(e) => onLayerChange(e, 'couplePathLayer')}
            label={context.t('show_couples_path')}
          />
        )}
        {false && (
          <LayerChecbox
            id='form-couples-coverage'
            name='couplesCoverage'
            checked={layers.couplesCoverageLayer}
            onChange={(e) => onLayerChange(e, 'couplesCoverageLayer')}
            label={context.t('couples_coverage')}
          />
        )}
        {false && (
          <LayerChecbox
            id='form-project-boundaries'
            name='projectBoundaries'
            checked={layers.projectBoundariesLayer}
            onChange={(e) => onLayerChange(e, 'projectBoundariesLayer')}
            label={context.t('project_boundaries')}
          />
        )}
        {viewWaterMeterLayer && (
          <LayerChecbox
            id='form-water-meter'
            name={featuresTypes.WATER_METER}
            checked={layers[featuresTypes.WATER_METER]}
            onChange={(e) => onLayerChange(e, featuresTypes.WATER_METER)}
            label={context.t('show_water_meter_layer')}
          />
        )}
        <LayerChecbox
          id='form-projects-coverage'
          name={featuresTypes.PROJECTS_COVERAGE}
          checked={layers[featuresTypes.PROJECTS_COVERAGE]}
          onChange={(e) => onLayerChange(e, featuresTypes.PROJECTS_COVERAGE)}
          label={context.t('project_coverage')}
        />
        <div className='form-group'>
          <input
            id='form-dmas'
            name={featuresTypes.DMAS}
            type='checkbox'
            checked={layers[featuresTypes.DMAS]}
            onChange={(e) => onLayerChange(e, featuresTypes.DMAS)}
          />
          <label htmlFor='form-dmas'>{context.t('dmas_layer')}</label>
        </div>
      </form>
    </div>
  );
};

LayerManager.contextTypes = {
  t: PropTypes.func.isRequired,
};

const LayerChecbox = ({ id, name, checked, onChange, label }) => (
  <div className='form-group'>
    <input
      id={id}
      name={name}
      type='checkbox'
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

const LayerSelect = ({
  id,
  name,
  checked,
  onChange,
  selection,
  onSelect,
  options,
}) => (
  <div className='form-group'>
    <input
      id={id}
      name={name}
      type='checkbox'
      checked={checked}
      onChange={onChange}
    />
    <select style={{ marginLeft: '5px' }} onChange={onSelect} value={selection}>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

export default LayerManager;
