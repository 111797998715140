import PropTypes from 'prop-types';
import React from 'react';

import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';

import Plot from 'react-plotly.js';

import { graphsBackgroundColor } from '../../constants/Misc';
// import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';

function createInitialTrace(name, dash, color) {
  return {
    name: name,
    type: 'scatter',
    mode: 'lines',
    x: [],
    y: [],
    line: { dash, color }
  };
}

function getShortDateString(dateMillis, timeZone) {
  const finalDateMillis = (timeZone !== 'local') ? dateMillis : (dateMillis - (new Date().getTimezoneOffset() * 60 * 1000));
  const date = new Date(finalDateMillis).toISOString();
  const dateSectionsArr = date.split('T')[0].split('-');
  const month = dateSectionsArr[1];
  const day = dateSectionsArr[2];
  return (`${day}/${month}`);
}

function SensorsNoiseGraph(props, context) {
  const [displayValue, setDisplayValue] = React.useState('all');

  const handleChange = (event) => {
    setDisplayValue(event.target.value);
  };

  const config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: [
      'drawrect',
      'zoom2d',
      'zoomIn2d',
      'zoomOut2d',
      'resetScale2d',
      'select2d',
      'lasso2d',
      'toggleSpikelines',
      'resetViewMapbox',
      'toImage',
    ]
  };

  const layout = {
    'paper_bgcolor': graphsBackgroundColor,
    showlegend: false,
    autosize: false,
    width: props.small ? 270 : 360,
    height: props.small ? 200 : 250,
    title: {
      text: props.title,
      font: { size: props.titleSize || 17 },
      x: 0.02,
      y: 0.97,
    },
    margin: {
      l: 50,
      r: 10,
      b: 40,
      t: 30,
    },
    hovermode: 'closest',
    yaxis: {
      rangemode: 'tozero',
      title: { text: props.titleY, font: { color: '#8d8d8d' } }
    },
    xaxis: {
      autorange: 'reversed',
      title: {
        text: props.titleX,
        font: { color: '#8d8d8d' },
      },
      tickfont: {
        size: 10
      },
      tickvals: [],
      ticktext: [],
    },
  };

  let data = [];

  if (props.historyNoiseData != null && props.historyNoiseData.length > 0) {
    const sensor1Nc1 = createInitialTrace('Sensor1 NC1', 'solid', 'blue');
    const sensor2Nc1 = createInitialTrace('Sensor2 NC1', 'solid', 'green');
    const sensor1Nc2 = createInitialTrace('Sensor1 NC2', 'dash', 'blue');
    const sensor2Nc2 = createInitialTrace('Sensor2 NC2', 'dash', 'green');

    const noiseData = props.historyNoiseData;//.sort((a, b) => a[0] - b[0]);

    noiseData.forEach((item, index) => {
      // const date = getShortDateString(new Date(item[0]));

      const xValue = index;
      // const date = UIDateFormater(item[0], DateType.DATE_AND_TIME, props.timeZone);
      const date = getShortDateString(Number(item[0]), props.timeZone);
      const s1Nc1 = item[1];
      const s2Nc1 = item[2];
      const s1Nc2 = item[3];
      const s2Nc2 = item[4];

      sensor1Nc1.y.push(s1Nc1);
      sensor2Nc1.y.push(s2Nc1);
      sensor1Nc2.y.push(s1Nc2);
      sensor2Nc2.y.push(s2Nc2);

      sensor1Nc1.x.push(xValue);
      sensor2Nc1.x.push(xValue);
      sensor1Nc2.x.push(xValue);
      sensor2Nc2.x.push(xValue);

      layout.xaxis.tickvals.push(xValue);

      layout.xaxis.ticktext.push(date);
    });

    switch (displayValue) {
      default:
      case 'all':
        data = [
          sensor1Nc1,
          sensor2Nc1,
          sensor1Nc2,
          sensor2Nc2,
        ];
        break;

      case 's1':
        data = [
          sensor1Nc1,
          sensor1Nc2,
        ];
        break;

      case 's2':
        data = [
          sensor2Nc1,
          sensor2Nc2,
        ];
        break;
    }
  }

  return (
    <div>
      <Plot
        config={config}
        layout={layout}
        data={data}
      />
      <div style={{
        margin: '0px auto',
        width: 'fit-content'
      }}>
        <FormControlLabel control={<Radio color="primary" onChange={handleChange} value="all" checked={displayValue === 'all'} />} label={context.t('all')} />
        <FormControlLabel control={<Radio color="primary" onChange={handleChange} value="s1" checked={displayValue === 's1'} />} label={context.t('sensor_1')} />
        <FormControlLabel control={<Radio color="primary" onChange={handleChange} value="s2" checked={displayValue === 's2'} />} label={context.t('sensor_2')} />
      </div>
    </div>
  );
}

SensorsNoiseGraph.contextTypes = {
  t: PropTypes.func.isRequired
};

SensorsNoiseGraph.propTypes = {
  historyNoiseData: PropTypes.array,
  small: PropTypes.bool,
  timeZone: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.number,
  titleX: PropTypes.string,
  titleY: PropTypes.string,
};

export default SensorsNoiseGraph;
