import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';
// import fetch from 'isomorphic-fetch';

//--------------------------------
// Receive & Request
//--------------------------------

function receiveIQuariusSamples(project, iQuariusSamples, iQuariusSamplesIcons, indexMap) {
    return {
        type: types.RECEIVE_IQUARIUS_PUBLISHED_SAMPLES,
        project,
        iQuariusSamples,
        iQuariusSamplesIcons,
        indexMap
    }
}

function requestIQuariusSamples(project) {
    return {
        type: types.REQUEST_IQUARIUS_PUBLISHED_SAMPLES,
        project
    };
}

//--------------------------------
// Fetch
//--------------------------------

export function fetchiQuariusSamplesIfNeeded(project) {
    return (dispatch, getState) => {
        if (shouldFetchIQuariusSamples(getState(), project)) {
            return dispatch(fetchIQuariusSamples(project))
        }
    }
}

function shouldFetchIQuariusSamples(state, project) {
    const iQuariusSamples = state.leaksList.leaksByProject[project].iQuariusSamples
    if (!iQuariusSamples) {
        return true
    }
    return !iQuariusSamples.isFetching;
}

function fetchIQuariusSamples(project) {
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.IQUARIUS_SAMPLES_ENDPOINT;

    return (dispatch, getState) => {
        dispatch(requestIQuariusSamples(project))
        return fetchMiddleware(path, {}, getState)
        .then((json) => {
          if (json.status) {
            return [json.data, getIconFeatures('iQuariusSamples', json.data), setIndexes(json.data, 'ID')];
          } else {
            return [[], {}, {}];
          }
        })
        .then(([data, icons, indexMap]) => dispatch(receiveIQuariusSamples(project, data, icons, indexMap)));
    };
}
