import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import UIDateFormater, {
  DateType,
} from '../../containers/UIhelper/UIDateFormater';
import { WARNINGS as COUPLE_WARNINGS } from '../../actions/CouplesActions';
import { convertIntensityToDb } from '../../actions/MobileSamplesActions';

const classNames = require('classnames');

export default class TextCell extends React.Component {
  static get propTypes() {
    return {
      // rowIndex: PropTypes.number.isRequired,
      // field: PropTypes.number.isRequired,
      data: PropTypes.array.isRequired,
      additional: PropTypes.string,
      // dateType: PropTypes.string
    };
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const func = this.props.onClick;
    if (func) {
      const { rowIndex, field, data } = this.props;
      const value = data[rowIndex][field];
      func(value);
    }
  }

  setEnumConfig(path, value, item, outParams) {
    outParams.tooltipValue = '';
    outParams.textValue = value; //'';
    outParams.columnKeyClass = '';
    if (value != null) {
      let sufix = '';
      switch (path) {
        case 'sensors':
          switch (value) {
            case 1:
              sufix = ' comm';
              outParams.tooltipValue = this.context.t('communication_problem');
              break;
            case 2:
              sufix = ' gps';
              if (item.SyncMethod != null) {
                outParams.tooltipValue = this.context.t(
                  item.SyncMethod + ' problem'
                );
              }
              break;
            case 3:
              sufix = ' non-associate';
              outParams.tooltipValue = this.context.t('non_associated');
              break;
            case 4:
              const { data, rowIndex } = this.props;
              const noiseVale = data[rowIndex].Noise.toFixed();
              sufix = ' noise-alert';
              if (noiseVale != null) {
                outParams.tooltipValue = this.context.t('noise_value_val', {
                  val: noiseVale,
                });
              }
              break;
            default:
              break;
          }
          break;
        case 'couples':
          switch (value) {
            case COUPLE_WARNINGS.INACTIVE_COUPLE:
              outParams.tooltipValue = this.context.t('inactive_couple');
              sufix = ' inactive';
              break;

            case COUPLE_WARNINGS.RM_PROBLEM:
              // if (item.RM > 0) {
              outParams.tooltipValue = item.RM && item.RM.toFixed(2);
              sufix = ' rm';
              // }
              break;

            case COUPLE_WARNINGS.INACTIVE_NO_SAMPLES:
              outParams.tooltipValue = this.context.t(
                'no_correlations_warning'
              );
              sufix = ' not-sampling';
              break;

            case COUPLE_WARNINGS.HAVE_TO_INSTALL_SENSORS:
              outParams.tooltipValue = this.context.t(
                'inactive_couple_no_sensors_planning'
              );
              sufix = ' have-to-install';
              break;

            case COUPLE_WARNINGS.HAVE_TO_UNINSTALL_SENSORS:
              outParams.tooltipValue = this.context.t(
                'inactive_couple_no_sensors'
              );
              sufix = ' have-to-uninstall';
              break;

            default:
              break;
          }
          break;
      }
      outParams.columnKeyClass = 'warning warning' + sufix;
    }
  }

  render() {
    const {
      rowIndex,
      field,
      data,
      additional,
      title,
      dateType = UIDateFormater.NONE,
      distanceFactor = false,
    } = this.props;
    const item = data[rowIndex];
    const value = item[field];
    let columnKeyClass = '';
    let textValue = value;
    let tooltipValue = title || value;

    switch (field) {
      case 'IsLog':
        textValue = value ? 'Log' : 'Bit';
        break;
      case 'IsOK':
        textValue = value ? 'YES' : 'NO';
        break;
      case 'Lat':
        textValue = item.data[field];
        break;
      case 'Lng':
        textValue = item.data[field];
        break;
      case 'Latitude':
      case 'Latitude1':
      case 'Latitude2':
      case 'Longitude':
      case 'Longitude1':
      case 'Longitude2':
        if (textValue != null) {
          textValue = parseFloat(textValue).toFixed(6);
        }
        break;
      case 'Sensor1':
      case 'Sensor2':
      case 'LeakID':
        columnKeyClass = 'sensor-link';
        break;
      case 'Burst':
        textValue = '';
        if (value === 1) {
          columnKeyClass = 'red warning warning burst';
          tooltipValue = this.context.t('fast_development');
        } else if (value === 2) {
          columnKeyClass = 'red warning warning bad-pipe';
          tooltipValue = this.context.t('poor_condition_pipe');
        } else if (value === 0.5) {
          columnKeyClass = 'warning info fading';
          tooltipValue = this.context.t('fading_alert');
          // } else if (value === 3) {
          //   columnKeyClass = 'warning info growing-med' ;
          //   tooltipValue = this.context.t('Slow growing alert');
        } else if (value === 3) {
          columnKeyClass = 'warning info growing';
          tooltipValue = this.context.t('fast_growing_alert');
        } else if (value === 4) {
          columnKeyClass = 'warning info growing';
          tooltipValue = this.context.t('fast_growing_alert');
        }
        break;
      case 'Priority':
        columnKeyClass = 'priority priority-' + value;
        break;
      case 'NoisePriority':
        columnKeyClass = 'priority noise-priority-' + value;
        break;
      case 'warningEnum':
        let outParams = {};
        this.setEnumConfig(this.props.path, value, item, outParams);
        tooltipValue = outParams.tooltipValue;
        textValue = outParams.textValue;
        columnKeyClass = outParams.columnKeyClass;
        break;
      case 'RM':
        tooltipValue = '';
        textValue = '';
        if (value > 0 && value < 6) {
          // TODO: replace with const in Misc.js
          columnKeyClass = 'warning warning rm';
          tooltipValue =
            this.context.t('rf_sync_problem_') + '[' + value.toFixed(2) + ']';
        }
        break;
      case 'DaysInactive':
        const sensorRecord = data[rowIndex];
        const dateValue = Math.min(
          sensorRecord.LastCommunicationTime,
          sensorRecord.LastGpsSampleTime
        );
        tooltipValue = UIDateFormater(dateValue, DateType.DATE, additional);
        break;
      case 'IsLeak': // CoupleOutput algoStatus
        textValue = '';
        columnKeyClass = 'algo ';
        switch (value) {
          case 0:
            columnKeyClass += 'green';
            tooltipValue = this.context.t('no_leak');
            break;
          case 1:
          case 2:
            if (item.IsBurst) {
              columnKeyClass += 'red';
              tooltipValue = this.context.t('suspected_fast_developing_leak');
            } else {
              columnKeyClass += 'orange';
              tooltipValue = this.context.t('suspected_leak');
            }
            // if burst - RED 'Suspected fast developing leak'
            break;
          case 3:
            columnKeyClass += 'blue';
            tooltipValue = this.context.t('consumption');
            break;
          case 7:
            columnKeyClass += 'yellow';
            tooltipValue = this.context.t('uncertain');
            break;
          default:
            columnKeyClass += 'grey';
            tooltipValue = this.context.t('undefined');
            break;
        }
        break;
      case 'State':
        const options = this.props.options;
        const sopState = options[value];
        textValue = sopState;
        tooltipValue = sopState;
        break;
      case 'Nc3':
        if (!item.regularNoiseValue) {
          if (this.props.user != null && this.props.user.isAQS) {
            columnKeyClass = 'red-background';
          }
        }
        break;
      case 'mobile_intensity': {
        const dbValue = convertIntensityToDb(
          value,
          additional.noise_ref_delta_db,
          additional.engine_calibration
        );
        textValue = dbValue;
        tooltipValue = dbValue;
        break;
      }
      case 'mobile_quality': {
        textValue = (Math.max(value, 0.2) * 100).toFixed();
        break;
      }
      default:
        // columnKeyClass = value;
        textValue = value;
        tooltipValue = value;
        break;
    }

    const cellClasses = classNames('cell', columnKeyClass);
    if (dateType !== UIDateFormater.NONE && value !== undefined) {
      textValue = UIDateFormater(value, dateType, additional);
      tooltipValue = textValue;
    }
    if (distanceFactor) {
      textValue = (textValue * distanceFactor).toFixed();
      tooltipValue = textValue;
    }
    //textValue = (dateType !== UIDateFormater.NONE && value !== undefined) ? UIDateFormater(value, dateType, additional) : (field === 'warningEnum' ? '' : value);

    return (
      <Cell
        title={`${tooltipValue}`}
        className={cellClasses}
        onClick={this.handleClick}
      >
        {textValue}
      </Cell>
    );
  }
}
TextCell.contextTypes = {
  t: PropTypes.func.isRequired,
};
