/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// import Button from '@mui/material/Button';
// import SaveIcon from '@mui/icons-material/Save';

import { makeStyles } from '@mui/styles';
// import { END_POINT } from '../../constants/EndPoints';
// import { useSelector } from 'react-redux';
import IntensityNoiseGraph from './IntensityNoiseGraph';

require('./style.scss');

const fontSizeStyle = '1.5rem';
const labelShift = '4px';

const useStyles = makeStyles({
  root: {
    margin: '40px 50px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    columnGap: '5px',
  },
  margin: {
    margin: 0.75,
    display: 'block',
    fontSize: fontSizeStyle,
    padding: '4px',
  },
  label: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'teal !important',
    top: labelShift,
    left: labelShift,
  },
  input: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'black !important',
  },
  fields: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    width: 'fit-content',
    margin: '20px auto',
    padding: '0px !important',
    textAlign: 'initial',
  },
  attrTable: {
    border: '1px solid #000',
  },
  attrTableRow: {
    borderBottom: '1px solid #aaa',
  },
  attrTableCell: {
    padding: '5px',
    textAlign: 'center',
  },
});

// function downloadFileFromURl(url) {
//   // script that download file from url:
//   if (url) {
//     const tempElement = document.createElement('a');
//     tempElement.href = url;
//     tempElement.target = '_blank';
//     tempElement.download = url.substring((url.lastIndexOf('/') + 1), url.lastIndexOf('?'));
//     document.body.appendChild(tempElement);
//     tempElement.click();
//     document.body.removeChild(tempElement);
//   }
// }
function getUiElement(fs, url) {
  return <audio src={url} controls />;
  // if (fs > 4000) {
  //   return <audio src={url} controls />;
  // } else {
  //   return (
  //     <Button
  //       variant="contained"
  //       size="small"
  //       startIcon={<SaveIcon />}
  //       onClick={() => downloadFileFromURl(url)}
  //     >
  //       Download
  //     </Button>
  //   );
  // }
}

function G5NoiseStrip(props, context) {
  const { item, graphData } = props;

  const { bandsData } = item;

  const classes = useStyles();

  const barsData = {
    ...bandsData,
    text: bandsData.y.map(String),
    textposition: 'auto',
    type: 'bar',
    hoverinfo: 'none',
    marker: {
      color: 'rgb(158,202,225)',
      opacity: 0.6,
      line: {
        color: 'rgb(8,48,107)',
        width: 1.5,
      },
    },
  };

  const plotData = [barsData];
  const plotLayout = {
    autosize: false,
    width: 360,
    height: 250,
    margin: { l: 30, r: 10, b: 30, t: 30 },
    xaxis: {
      tickmode: 'array',
      tickvals: barsData.x,
      title: {
        text: context.t('power'),
        font: {
          color: '#8d8d8d',
        },
      },
    },
    yaxis: {
      title: {
        text: props.yTitle,
        font: {
          color: '#8d8d8d',
        },
      },
    },
  };

  // const formLabelSmValue = 6;
  // const formControlSmValue = 6;

  const closePanel = () => {
    props.handleClosePannel('g5noise');
  };

  const renderField = (key, label) => {
    const value = item[key] || '';
    return (
      <TextField
        className={classes.margin}
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.label }}
        variant='outlined'
        type={'text'}
        name={key}
        value={value}
        label={label}
        disabled
      />
    );
  };

  return (
    <div className='sample-info g5-bit-msg'>
      <i className='icon-close' onClick={closePanel} />

      <div className={classes.container}>
        <Box>
          <h4>{context.t('noise_intensity_min_band_power')}</h4>
          <Plot data={plotData} layout={plotLayout} />
        </Box>
        <Box>
          <table>
            <tbody>
              <tr
                className={`audio-tr${
                  item.audioOrig == null ? ' disabled' : ''
                }`}
              >
                <th>{context.t('original')}</th>
                <td>{getUiElement(item.AudioFS, item.audioOrig)}</td>
              </tr>
              <tr
                className={`audio-tr${
                  item.audioOrigLast == null ? ' disabled' : ''
                }`}
              >
                <th>{context.t('orig_2')}</th>
                <td>{getUiElement(item.AudioFS, item.audioOrigLast)}</td>
              </tr>
            </tbody>
          </table>
          <table className={classes.attrTable}>
            <tbody>
              <tr className={classes.attrTableRow}>
                <th className={classes.attrTableCell}></th>
                <th className={classes.attrTableCell}>
                  {context.t('audio_fs')}
                </th>
                <th className={classes.attrTableCell}>
                  {context.t('audio_gain')}
                </th>
              </tr>
              <tr className={classes.attrTableRow}>
                <th className={classes.attrTableCell}>
                  {context.t('original')}
                </th>
                <td className={classes.attrTableCell}>
                  {item['NoiseAudioFS']}
                </td>
                <td className={classes.attrTableCell}>
                  {item['NoiseAudioGain']}
                </td>
              </tr>
              <tr className={classes.attrTableRow}>
                <th className={classes.attrTableCell}>{context.t('orig_2')}</th>
                <td className={classes.attrTableCell}>
                  {item['NoiseAudioFS_Last']}
                </td>
                <td className={classes.attrTableCell}>
                  {item['NoiseAudioGain_Last']}
                </td>
              </tr>
            </tbody>
          </table>
          {/* {renderField('NoiseAudioFS', context.t('Noise Audio FS'))}
          {renderField('NoiseAudioGain', context.t('Noise Audio Gain'))} */}
        </Box>
        <Box>
          <IntensityNoiseGraph
            data={graphData}
            sampleTime={item.TimeStamp}
            timeZone={props.timeZone}
          />
        </Box>
      </div>

      {/* <div style={{ float: 'left', display: 'inline-block', textAlign: 'center' }}>
      </div>

      <div style={{ float: 'left', display: 'inline-block', textAlign: 'center' }}>

      </div>
      <div style={{ float: 'left', display: 'inline-block', textAlign: 'center' }}>
        <Box className={classes.fields}>
        </Box>
      </div>
      <div style={{ float: 'left', display: 'inline-block', textAlign: 'center' }}>
      </div> */}
    </div>
  );
}

G5NoiseStrip.contextTypes = {
  t: PropTypes.func.isRequired,
};

G5NoiseStrip.propTypes = {
  downloadFile: PropTypes.func,
  handleClosePannel: PropTypes.func,
  item: PropTypes.object,
  yTitle: PropTypes.string,
};

export default G5NoiseStrip;
