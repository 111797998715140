import React from 'react';
import { zIndexs, featuresTypes } from './Options';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');


const style = new Style({
  image: new Icon({
    src: require('../../../../images/map/sensors/couple1.png'), anchor: [0.5, 1]
  })
});

const source = new VectorSource({
  features: [],
});

const layer = new VectorLayer({
  name: 'layerCoupleSensors',
  style: style,
  source: source,
  zIndex: zIndexs.couplesSensors,
});

export const setSource = (features, props) => {
  const iconFeatures = [];

  source.clear();
  Object.keys(features).forEach((key) => {
    const itrFeature = features[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          itrFeature.position,
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      coupleId: key,
      type: featuresTypes.SENSOR_ON_COUPLE,
      id: itrFeature.sensorID
    });

    const style = getStyle(itrFeature);
    iconFeature.setStyle(style);
    iconFeatures.push(iconFeature);
  });
  source.addFeatures(iconFeatures);
}

export const getStyle = (feature) => {
  const style = new Style({
    image: new Icon({
      src: require('../../../../images/map/sensors/couple' + feature.sensorIndex + '.png'),
      anchor: [0.5, 1]
    }),
  });

  return (style);
}

export const getLayers = () => {
  return [layer];
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  return null;
};

export const tooltipRender = (itemInfo, context) => {
  return null;
};
