import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';
import { MAPS_END_POINT } from '../../../constants/EndPoints';

import { Style, Stroke } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import XYZ from 'ol/source/XYZ.js';
import TileLayer from 'ol/layer/Tile.js';
import LineString from 'ol/geom/LineString.js';
const proj = require('ol/proj');


const WIDTH_PIPE_FILL = 5;
const WIDTH_PIPE_FILL_OPERATIONAL = 7;
const WIDTH_PIPE_FILL_NON_OPERATIONAL = 3;
const WIDTH_PIPE_NO_CORRELATION = 3;
const WIDTH_GENERATED_COUPLE = 6;

const ePipeAssessmentsSeverity = {
  'HIGH_SEVERITY_THRESHOLD': 4,
  'MED_SEVERITY_THRESHOLD': 2,
  'LOW_SEVERITY_THRESHOLD': 1,
};

const WIDTH_PIPE_HIGH_SEVERITY = 9;
const WIDTH_PIPE_MED_SEVERITY = 7;
const WIDTH_PIPE_LOW_SEVERITY = 5;
const WIDTH_PIPE_SLIM = 2;

const source = new VectorSource({
  features: [],
});

const vectorLayer = new VectorLayer({
  name: 'layerCoupleCoverageVector',
  style: new Style({
    stroke: new Stroke({
      color: [0, 255, 0, 1], // Green
      width: WIDTH_PIPE_FILL_OPERATIONAL,
    }),
  }),
  source: source,
  zIndex: zIndexs.couplesCovarage,
});

const tileLayer = new TileLayer({
  name: 'layerCoupleCoverageTiles',
  source: new XYZ(),
  zIndex: zIndexs.pipes,
});


export const setSource = (paths, props) => {
  source.clear();

  switch (props.coverageSource) {
    case 'pipeAssessment': {
      vectorLayer.setVisible(false);
      tileLayer.setVisible(true);
      tileLayer.setSource(new XYZ({
        url: `${MAPS_END_POINT}${props.selectedProject * 5 + 4}/{z}/{x}/{y}.png`,
      }));
      break;
    }

    default: {
      vectorLayer.setVisible(true);
      tileLayer.setVisible(false);
      if (paths && paths.length > 0) {
        for (let i = 0; i < paths.length; i++) {
          const segment = paths[i];

          if (segment.pathPoints != null) {
            const coordinates = segment.pathPoints.map((point) => proj.transform(point, 'EPSG:4326', 'EPSG:3857'));
            //const coordinates = [
            //  proj.transform([segment.Longitude1, segment.Latitude1], 'EPSG:4326', 'EPSG:3857'),
            //  proj.transform([segment.Longitude2, segment.Latitude2], 'EPSG:4326', 'EPSG:3857')
            //];
            const lineFeature = new Feature({
              geometry: new LineString(coordinates, 'XY'),
              name: 'Line',
              id: segment.CoupleID,
              type: featuresTypes.COUPLES_COVERAGE,
              lastSample: segment.LastSampleTime,
              length: segment.SectionLength,
              coupleLength: segment.coupleLength,
              material: segment.material,
              diameter: segment.diameter,
            });

            const style = getStyle(segment, false, props);

            lineFeature.setStyle(style);
            source.addFeature(lineFeature);
          }
        }
      }
      break;
    }
  }
};

export const getLayers = () => {
  return [vectorLayer, tileLayer];
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

//export const getStyle = (segment, coverageSource = 'couplesCoverage', bAdvancedIndications) => {
export const getStyle = (segment, selected, props) => {
  let color = [0, 255, 0, 1];
  let width = WIDTH_PIPE_SLIM;

  if (segment != null) {
    switch (props.coverageSource) {
      case 'couplesCoverage':
        if (!segment.isConnectionActive) {
          color = [255, 0, 0, 1]; // Red
          width = WIDTH_PIPE_FILL_NON_OPERATIONAL;
        } else if (!segment.isCorrelationActive) {
          color = 'blueviolet';//[255, 140, 0, 1]; // Pink
          width = WIDTH_PIPE_NO_CORRELATION;
          // } else if (segment.isNoiseOK) {
          //   color = [100, 10, 175, 1]; // Purple
          //   width = WIDTH_PIPE_FILL;
        } else if (props.user.advancedMapIndications && !segment.isRegularNoise) {
          color = [255, 255, 0, 1]; // Yellow
          width = WIDTH_PIPE_FILL_NON_OPERATIONAL;
        } else {
          color = [0, 255, 0, 1]; // Green
          width = WIDTH_PIPE_FILL_OPERATIONAL;
        }
        break;

      case 'pipeAssessment':
        if (segment.LeaksCount >= ePipeAssessmentsSeverity.HIGH_SEVERITY_THRESHOLD) {
          color = [255, 0, 0, 1]; // red
          width = WIDTH_PIPE_HIGH_SEVERITY;
        } else if (segment.LeaksCount >= ePipeAssessmentsSeverity.MED_SEVERITY_THRESHOLD) {
          color = [255, 20, 147, 1]; // deep pink
          width = WIDTH_PIPE_MED_SEVERITY;
        } else if (segment.LeaksCount >= ePipeAssessmentsSeverity.LOW_SEVERITY_THRESHOLD) {
          if (segment.Burst) {
            color = [255, 150, 0, 1]; // Orange
            width = WIDTH_PIPE_LOW_SEVERITY;
          } else {
            color = [255, 255, 0, 1]; // Yellow
            width = WIDTH_PIPE_LOW_SEVERITY;
          }
        } else {
          color = [0, 255, 0, 1]; // Green
          width = WIDTH_PIPE_SLIM;
        }
        break;

      default:
        break;
    }
  }

  const style = [new Style({
    stroke: new Stroke({
      color: color,
      width: width,
    }),
  })];

  return (style);
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();

  const infoTooltip = {
    type: attr.type,
    id: attr.id,
    lastSample: attr.lastSample,
    length: attr.length,
    coupleLength: attr.coupleLength,
    material: attr.material,
    diameter: attr.diameter,

  };

  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context, props) => {
  const values = {
    id: itemInfo.id,
    length: (itemInfo.length * props.distanceFactor).toFixed(0),
    units: props.distanceUnits,
    diameter: itemInfo.diameter,
    material: itemInfo.material,
    coupleLength: (itemInfo.coupleLength * props.distanceFactor).toFixed(0),
  };
  return (
    <span className="tooltip">
      <div className="tooltip-row">{context.t('couple_id_param', values)}</div>

      {/*{itemInfo.material != null &&*/}
      {/*  <div className="tooltip-row">{context.t('Material: {material}', values)}</div>*/}
      {/*}*/}
      {itemInfo.coupleLength &&
        <div className="tooltip-row">{context.t('couple_length_units', values)}</div>
      }
      {/* {itemInfo.length != null && itemInfo.length != NaN &&
          <div className="tooltip-row">{context.t('length: {length}[{units}]', values)}</div>
        } */}
      {itemInfo.lastSample &&
        <div className="tooltip-row">{context.t('last_sample')} {UIDateFormater(itemInfo.lastSample, DateType.DATE_AND_TIME, props.timeZone)}</div>
      }
    </span>
  );
};
