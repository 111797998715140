import fetch from 'isomorphic-fetch';
import { GOOGLE_API_KEY } from '../constants/Misc';

const useGeocoder = (language) => {
  const fetchAddress = async (lat, lng) => {
    let address = await findAddressByPositionGoogle(lat, lng, language);
    if (! address) {
      address = await findAddressByPositionOSM(lat, lng, language);
    }
    return address;
  }

  return { fetchAddress };
}

const findAddressByPositionGoogle = async (lat, lng, language) => {
  const latLngStr = [lat, lng].join(',');
  const request = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latLngStr + '&language=' + language + '&key=' + GOOGLE_API_KEY;
  const response = await fetch(request);

  if (!response.ok) {
    // console.log('can not find address [Google]', response.statusText);
    return undefined;
  }

  const json = await response.json();

  if (json.status !== 'OK') {
    // console.log('Geocode was not successful for the following reason: ' + json.data);
    return undefined;
  }

  const { results } = json;
  const address = {
    FormattedAddress: results[0].formatted_address
  };

  results.forEach((result) => {
    const addressComponent = result.address_components;
    if (address.City == null) {
      const city = addressComponent.find((item) => { return item.types.indexOf('locality') != -1 });
      address.City = (city == null) ? null : city.long_name;
    }
    if (address.CityDistrict == null) {
      const cityDistrict = addressComponent.find((item) => { return item.types.indexOf('sublocality') != -1 });
      address.cityDistrict = (cityDistrict == null) ? null : cityDistrict.long_name;
    }
    if (address.Country == null || address.CountryCode == null) {
      const country = addressComponent.find((item) => { return item.types.indexOf('country') != -1 });
      address.Country = (country == null) ? null : country.long_name;
      address.CountryCode = (country == null) ? null : country.short_name;
    }
    if (address.HouseNumber == null) {
      const houseNumber = addressComponent.find((item) => { return item.types.indexOf('street_number') != -1 });
      address.HouseNumber = (houseNumber == null) ? null : houseNumber.long_name;
    }
    if (address.Street == null) {
      const street = addressComponent.find((item) => { return item.types.indexOf('route') != -1 });
      address.Street = (street == null) ? null : street.long_name;
    }
    if (address.State == null) {
      const state = addressComponent.find((item) => { return item.types.indexOf('administrative_area_level_1') != -1 });
      address.State = (state == null) ? null : state.long_name;
    }
    if (address.PostalCode == null) {
      const postalCode = addressComponent.find((item) => { return item.types.indexOf('postal_code') != -1 });
      address.PostalCode = (postalCode == null) ? null : postalCode.long_name;
    }
  });

  return address;
}

const createParam = (key, value) => ({ key, value });
const findAddressByPositionOSM = async (lat, lng, language) => {
  const params = [
    createParam('format', 'json'),
    createParam('lat', lat),
    createParam('lon', lng),
    createParam('accept-language', language),
    createParam('zoom', 18),
    createParam('addressdetails', 1)
  ];

  const request = 'https://nominatim.openstreetmap.org/reverse?' + params.join('&');
  const response = await fetch(request);
  if (! response.ok) {
    throw new Error(response.statusText);
  }

  const json = await response.json();
  const addressDetails = json.address;

  if (!addressDetails) {
    throw new Error('can not find address [OSM]')
  }

  const address = {
    City: addressDetails.city,
    CityDistrict: addressDetails.city_district || addressDetails.suburb,
    Country: addressDetails.country,
    CountryCode: addressDetails.country_code,
    HouseNumber: addressDetails.house_number,
    Street: addressDetails.road,
    PostalCode: addressDetails.postcode,
    State: addressDetails.state,
    FormattedAddress: json.display_name,
  };

  return (address);
}

export default useGeocoder;
