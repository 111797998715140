import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../Input/Input';
import AddressField from '../Custom/AddressField';
export default class PrsDetails extends React.Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = () => {

  }

  render() {
    const { user, timeZone } = this.props;
    const labelsClass = 'col-xs-4 control-label';
    const fieldsClass = 'col-xs-8 control-input';
    const divStyle = { float: 'left', width: '100%' };

    const isDisabled = true; // !user.editMode;

    return (
        <div>
          <form className="form-horizontal sticky-actions" onSubmit={this.handleSubmit}>

            <AddressField
              className="form-group"
              user={this.props.user}
              fieldName="StreetAddress"
              onGetAddress={this.props.getAddressByLatLong}
              disabled={isDisabled}
            />

            <div style={{ display: 'flex' }}>

              <div style={divStyle}>
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('create_date')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="CreateDate"
                      component={Input}
                      type="date"
                      disabled={isDisabled}
                      className="form-control" />
                  </div>
                </div>
                {/* <div className="form-group">
                  <label className={labelsClass}>{this.context.t('End Date')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="EndDate"
                      component={Input}
                      type="date"
                      className="form-control"
                      disabled={isDisabled} />
                  </div>
                </div>
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('AutoCloseDate')}:</label>
                  <div className={fieldsClass}>
                    <Field name="AutoCloseDate" component={Input} type="date" className="form-control" disabled={isDisabled} />
                  </div>
                </div> */}
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('last_event_time')}:</label>
                  <div className={fieldsClass}>
                    <Field name="LastEventTime" component={Input} type="date" className="form-control" disabled={isDisabled} />
                  </div>
                </div>
              </div>

              <div style={divStyle}>
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('event_min_value')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="LastEventMinValue"
                      component={Input}
                      className="form-control"
                      type="number"
                      timeZone={timeZone}
                      disabled={isDisabled} />
                  </div>
                </div>
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('event_max_value')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="LastEventMaxValue"
                      component={Input}
                      className="form-control"
                      type="number"
                      timeZone={timeZone}
                      disabled={isDisabled} />
                  </div>
                </div>
                {/* <div className="form-group">
                  <label className={labelsClass}>{this.context.t('LastEventFrequency')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="LastEventFrequency"
                      component={Input}
                      className="form-control"
                      type="number"
                      timeZone={timeZone}
                      disabled={isDisabled} />
                  </div>
                </div> */}
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('session_num_events')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="SessionNumEvents"
                      component={Input}
                      className="form-control"
                      type="number"
                      disabled={isDisabled} />
                  </div>
                </div>
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('session_min_value')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="SessionMinValue"
                      component={Input}
                      className="form-control"
                      type="number"
                      disabled={isDisabled} />
                  </div>
                </div>
                <div className="form-group">
                  <label className={labelsClass}>{this.context.t('session_max_value')}:</label>
                  <div className={fieldsClass}>
                    <Field
                      name="SessionMaxValue"
                      component={Input}
                      className="form-control"
                      type="number"
                      disabled={isDisabled} />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
    );
  }
}

PrsDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

PrsDetails = reduxForm({
  form: 'prsAlerts', // a unique name for this form
  enableReinitialize: true
})(PrsDetails);
