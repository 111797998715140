/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import Plot from 'react-plotly.js';
import {
  graphsBackgroundColor,
  graphsIncludedBackgroundColor,
} from '../../constants/Misc';

import './spectrogram.scss';

const SHOW_LINES = false;

const SpectrogramGraph = (props) => {
  const [smooth, setSmooth] = React.useState(false);
  if (props.data == null) {
    return null;
  }

  const config = {
    displaylogo: false,
    responsive: true,
    displayModeBar: true,
    modeBarButtonsToRemove: [
      'drawrect',
      'zoomIn2d',
      'zoomOut2d',
      'resetScale2d',
      'toggleSpikelines',
      'resetViewMapbox',
    ],
  };

  let columnsHeigh = props.data ? props.data.length : 0;
  if (props.yAxisLabels && props.yAxisLabels.length < columnsHeigh) {
    columnsHeigh = props.yAxisLabels.length;
  }

  const columnsSize = props.data && props.data[0] ? props.data[0].length : 0;
  const maxValueY = columnsHeigh;

  const yValues = new Array(columnsHeigh)
    .fill(0)
    .map((_, index) => (index + 1) * (maxValueY / columnsHeigh));
  let xLabels = [];
  for (let i = 0; i < columnsSize; i++) {
    xLabels.push(
      props.xValues.start + i * props.xValues.step + props.xValues.step / 2
    );
  }

  const data = [
    {
      type: 'heatmap',
      showscale: false,
      z: props.data,
      x: xLabels,
      y: yValues,
      zsmooth: smooth ? 'best' : false,
      // zsmooth: 'best',
      // zsmooth: (ENV === ENVIRONMENTS.PROD || ENV === ENVIRONMENTS.TRIMBLE) ? 'best' : false,
    },
    // {
    //   type: 'scatter',
    //   mode: 'markers',
    //   marker: {
    //     symbol: 'circle-open',
    //     size: 15,
    //     color: 'red',
    //     line: {
    //       width: 2,
    //     }
    //   },
    //   x: [300,500,800],
    //   y: [1000, 1500, 2000]
    // }
  ];

  let yAxisConfig = {
    title: {
      text: props.yTitle,
      font: {
        color: '#8d8d8d',
      },
    },
    tickfont: {
      size: 10,
    },
  };

  if (props.yAxisLabels) {
    yAxisConfig = {
      ...yAxisConfig,
      tickmode: 'array',
      tickvals: yValues,
      ticktext: props.yAxisLabels ? props.yAxisLabels : yValues,
    };
  }

  if (props.leaksMarkers && props.leaksMarkers.length > 0) {
    const max = Math.max(...props.leaksMarkers);
    const min = Math.min(...props.leaksMarkers);
    yAxisConfig = {
      ...yAxisConfig,
      // autorange: false,
      // range: [min - 5, max + 5], // set start focus
    };
  }

  const layout = {
    paper_bgcolor: props.included
      ? graphsIncludedBackgroundColor
      : graphsBackgroundColor,
    border: 'steelblue 1px solid',
    padding: '2px',
    autosize: false,
    hovermode: false,
    width: props.small ? 270 : 350,
    height: props.small ? 200 : 240,
    title: {
      text: props.graphTitle,
      font: { size: props.titleSize || 17 },
      x: 0.02,
      y: 0.97,
    },
    margin: {
      l: 50,
      r: 10,
      b: 40,
      t: 30,
    },
    xaxis: {
      tickmode: 'auto',
      nticks: 10,
      tickvals: xLabels,
      title: {
        text: props.xTitle,
        font: {
          color: '#8d8d8d',
        },
      },
      tickfont: {
        size: 10,
      },
    },
    yaxis: yAxisConfig,
  };

  const plotConfig = props.small
    ? {
        ...config,
        modeBarButtonsToRemove: [
          ...config.modeBarButtonsToRemove,
          'hoverClosestCartesian',
          'hoverCompareCartesian',
          'pan2d',
          'zoom2d',
          'toImage',
        ],
      }
    : config;

  const layoutShapes = [];

  if (SHOW_LINES && props.sensorsPositions) {
    const shapes = props.sensorsPositions.map((sensorPos) => ({
      type: 'line',
      xref: 'paper',
      x0: 0,
      y0: sensorPos,
      x1: 1,
      y1: sensorPos,
      line: {
        color: 'rgba(0, 255, 0, 0.45)',
        width: 2,
      },
    }));
    layoutShapes.push(...shapes);
  }

  if (SHOW_LINES && props.leaksMarkers && props.leaksMarkers.length > 0) {
    const shapes = props.leaksMarkers.map((prob) => ({
      type: 'line',
      xref: 'paper',
      x0: 0,
      y0: prob,
      x1: 1,
      y1: prob,
      line: {
        color: 'rgba(255, 0, 0, 0.3)',
        width: 2,
      },
    }));
    layoutShapes.push(...shapes);
  }

  if (layoutShapes.length > 0) {
    layout.shapes = layoutShapes;
  }

  return (
    // <div className='spectrogram-contaier'>
    // <div className={props.included ? 'included-border' : ''}>
    <Plot layout={layout} config={plotConfig} data={data} />
    // </div>
    //   <label
    //     style={{
    //       position: 'absolute',
    //       bottom: 0,
    //       left: 0
    //     }}
    //   >smooth<input type='checkbox' onChange={(e) => setSmooth(e.target.checked)}/></label>
    // </div>
  );
};

SpectrogramGraph.propTypes = {
  data: PropTypes.array.isRequired,
  graphTitle: PropTypes.string,
  small: PropTypes.bool,
  titleSize: PropTypes.number,
  xTitle: PropTypes.string,
  xValues: PropTypes.object.isRequired,
  yAxisLabels: PropTypes.array,
  yTitle: PropTypes.string,
};

export default SpectrogramGraph;
