import PropTypes from 'prop-types';
import React from 'react';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

require('../SensorInfo/SensorInfo.scss');

export default class SensorLogInfo extends React.Component {

  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    const { selectSensorLog } = this.props;
    selectSensorLog({});
  }

  render() {
    const { selectedSensorLog, timeZone, downloadFile } = this.props;
    return (
      <div className='sample-info'>
        <i className="icon-close" onClick={this.closePanel} />
        <h2 style={{ marginLeft: '20px' }}>{this.context.t('log_information_device_deviceid', { deviceId: selectedSensorLog.DeviceID })}</h2>
        <div className="sample-graphs">
          <table>
            <tbody>
              <tr><th>{this.context.t('battery_voltage_pre')}</th><td>{selectedSensorLog.BatteryVoltagePreRec}</td></tr>
              <tr><th>{this.context.t('battery_voltage_post')}</th><td>{selectedSensorLog.BatteryVoltage}</td></tr>
              <tr><th>{this.context.t('sim_number')}</th><td>{selectedSensorLog.SimNumber}</td></tr>
              <tr><th>{this.context.t('bit_type')}</th><td>{selectedSensorLog.Meaning}</td></tr>
              <tr><th>{this.context.t('hardware_version')}</th><td>{selectedSensorLog.HardVersion}</td></tr>
              <tr><th>{this.context.t('firmware_version')}</th><td>{selectedSensorLog.FirmwareVersion}</td></tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr><th>{this.context.t('recording_time_1')}</th><td>{UIDateFormater(selectedSensorLog.RecordingTime1, DateType.TIME, timeZone)}</td></tr>
              <tr><th>{this.context.t('recording_time_2')}</th><td>{UIDateFormater(selectedSensorLog.RecordingTime2, DateType.TIME, timeZone)}</td></tr>
              <tr><th>{this.context.t('recording_time_3')}</th><td>{UIDateFormater(selectedSensorLog.RecordingTime3, DateType.TIME, timeZone)}</td></tr>
              <tr><th>{this.context.t('end_record_time')}</th><td>{UIDateFormater(selectedSensorLog.EndRecordTime, DateType.TIME, timeZone)}</td></tr>
              <tr><th>{this.context.t('config_record_duration')}</th><td>{selectedSensorLog.ConfigRecordingTime}</td></tr>
              <tr><th>{this.context.t('file_size')}</th><td>{selectedSensorLog.FTPFileSize}</td></tr>
              <tr><th>{this.context.t('modem_usage')}</th><td>{selectedSensorLog.ModemUsage}</td></tr>

            </tbody>
          </table>

          <table>
            <tbody>
              <tr><th>{this.context.t('is_send_file_ok')}</th><td>{selectedSensorLog.IsFtpOk ? <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} /> : <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} />}</td></tr>
              <tr><th>{this.context.t('is_modem_closed_ok')}</th><td>{selectedSensorLog.IsModemClosedOk ? <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} /> : <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} />}</td></tr>
              <tr><th>{this.context.t('is_csq_ok')}</th><td>{selectedSensorLog.IsCsqOk ? <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} /> : <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} />}</td></tr>
              <tr><th>{this.context.t('modem_atenna_signal_strength')}</th><td>{selectedSensorLog.ModemAtennaSignalStrength}</td></tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

SensorLogInfo.contextTypes = {
  t: PropTypes.func.isRequired
}
