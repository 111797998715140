import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import Select from './../Select/Select';

const classNames = require('classnames');

require('./CouplesSettings.scss');

class CouplesSettings extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, uiInfo, materialsList, optionList, toggleSettingsDisplay } = this.props;
    const panelClasses = classNames(
      'couples-settings',
      { 'show': this.props.showSettings }
    );

    const panelStyle = { height: uiInfo.pageHeight, width: 0.395 * (uiInfo.pageWidth - 10) };

    if (!this.props.showSettings) {
      return null;
    }

    return (
      <div className={panelClasses} style={panelStyle}>
        <div className="couples-header">
          <div className="actions">
            <i className="icon-close" onClick={toggleSettingsDisplay} />
          </div>
          <h2>{this.context.t('couples_options')}</h2>
        </div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <fieldset>
            <legend>{this.context.t('sort_by')}</legend>



            <div className="form-group">
              <div className="col-xs-8">
                <Field name="sortBy"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={this.context.t('select')}
                    clearable={false}
                    options={[
                      { value: 'ID', label: this.context.t('id_default') },
                      { value: 'PathLengthM', label: this.context.t('length') },
                      { value: 'Material', label: this.context.t('material') },
                      { value: 'LastDaemonActivity', label: this.context.t('updated') },
                    ]}
                  />)
                  }
                />
              </div>
              <div className="col-xs-4">
                <Field name="sortByDir"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={this.context.t('select')}
                    clearable={false}
                    backspaceRemoves={false}
                    options={[
                      { value: 'asc', label: this.context.t('asc') },
                      { value: 'desc', label: this.context.t('desc') },
                    ]}
                  />)
                  }
                />
              </div>
            </div>

          </fieldset>

          <fieldset>
            <legend>{this.context.t('filters')}</legend>

            {/* <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('status')}
              </label>
              <div className="col-xs-8">
                <Field
                  name="CoupleState"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('select')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={[
                        { value: 'asc', label: this.context.t('active') },
                        { value: 'desc', label: this.context.t('inactive') },
                        { value: 'desc', label: this.context.t('not_installed') },
                      ]}
                    />
                  )}
                />
              </div>
            </div> */}


            <div className="form-group">

              <label className="col-xs-4 control-label">{this.context.t('materials')}</label>
              <div className="col-xs-8">
                <Field name="MaterialCode"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={this.context.t('select')}
                    clearable
                    multi
                    closeMenuOnSelect={false}
                    options={optionList.Materials}
                  />)
                  }
                />
              </div>
            </div>

            <div className="text-right">
              <button className="btn btn-success" type="submit" disabled={submitting} onClick={toggleSettingsDisplay} >{this.context.t('save_and_apply')}</button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }

}

CouplesSettings.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const couplesSettings = reduxForm({
  form: 'couples-settings', // a unique name for this form
  enableReinitialize: true,
})(CouplesSettings);

export default couplesSettings;
