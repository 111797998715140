import React from 'react';

import { Table, Column, Cell } from 'fixed-data-table-2';
import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TextCell from '../../components/TextCell/TextCell';
import TableMixin from '../../components/TableMixin/TableMixin';
import CTableActions from '../../containers/CTableActions/CTableActions';

require('fixed-data-table-2/dist/fixed-data-table.css');

class UsersTable extends React.Component {

  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
    this.state = {
      goToRow: 0,
    };
    // this.rowClassNameGetter = this.rowClassNameGetter.bind(this);
  }

  onRowClick(event, rowIndex) {
    this.props.editUser(this.props.users[rowIndex]);
  }

  componentDidUpdate(prevProps) {
    const { manageItem, items, showDetails, context } = this.props;
    if (manageItem != null && manageItem.item != null) {
      if (prevProps.manageItem == null || prevProps.manageItem.item == null || manageItem.item.UID !== prevProps.manageItem.item.UID || showDetails !== prevProps.showDetails || items.length !== prevProps.items.length) {
        const selectedRow = items.filter(function (item) {
          return item.UID == manageItem.item.UID;
        })[0];
        this.setState({
          goToRow: items.indexOf(selectedRow),
        });
      }
    }
  }
  render() {
    const { items, pHeight, pWidth, localSortProp, rowClickEvent } = this.props;
    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };

    return (
      <div>
        <Table
          maxHeight={pHeight - 93}
          width={pWidth}
          rowsCount={items.length}
          rowHeight={30}
          headerHeight={30}
          onRowClick={rowClickEvent}
          scrollToRow={this.state.goToRow}
          {...this.props}
        >

          <Column
            columnKey="UserName"
            width={120}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={localSortProp} />}
            cell={<TextCell data={items} field="UserName" />} />
          <Column
            columnKey="Email"
            width={120}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={localSortProp} />}
            cell={<TextCell data={items} field="Email" />} />
          <Column
            columnKey="CustomerName"
            width={120}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={localSortProp} />}
            cell={<TextCell data={items} field="CustomerName" />} />
          <Column
            columnKey="FirstName"
            width={120}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={localSortProp} />}
            cell={<TextCell data={items} field="FirstName" />} />
          <Column
            columnKey="LastName"
            width={120}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={localSortProp} />}
            cell={<TextCell data={items} field="LastName" />} />
          <Column
            columnKey="phone"
            width={120}
            flexGrow={1}
            header={<Cell>{this.props.t('phone_number')}</Cell>}
            cell={<TextCell data={items} field="PhoneNumber" />} />

        </Table>
        {false && items && items.length > 0 &&
          <CTableActions
            type="alerts"
            notify={this.props.notify}
          />
        }
        {items && items.length > 0 &&
          <span style={totalItemsStyle}>{this.props.t('total2')} {items.length}</span>
        }
      </div>
    )
  }
}

UsersTable = TableMixin(UsersTable, null);
export default UsersTable;
