import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { Table, Column, Cell } from 'fixed-data-table-2';
import SortableHeaderCell from '../../SortableHeaderCell/SortableHeaderCell';
import TextCell from '../../TextCell/TextCell';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';
import CheckBoxCell from '../../CheckBoxCell/CheckBoxCell';
import CSelectCell from '../../../containers/CSelectCell/CSelectCell';

import { useDispatch, useSelector } from 'react-redux';
import {
  convertIntensityToDb,
  updateSampleState,
} from '../../../actions/MobileSamplesActions';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import ReactExport from 'react-data-export';
import useGeocoder from '../../../hooks/useGeocoder';

import mapLimit from 'async/mapLimit';
import { getDateForExcelExports } from '../../../actions/Utilities/dateUtils';
import CAddMobileAlert from '../../../containers/CMobileForms/CAddMobileAlert';
import {setMapDrawMode} from "../../../actions/setters";
import {DRAW_TYPES} from "../../../constants/Misc";
import * as actionsAlerts from "../../../actions/AlertsActions";
import {saveDrawnFeature} from "../../../actions/MapActionsHandler";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

require('fixed-data-table-2/dist/fixed-data-table.css');
require('../../AlertsTabs/SamplesTab/SamplesTab.scss');
require('./../mobile.scss');

const xlsColumns = [
  {
    title: 'Sample Time',
    width: { wch: 22 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  }, //pixels width
  {
    title: 'User',
    width: { wch: 14 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  }, //pixels width
  {
    title: 'Device ID',
    width: { wch: 11 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  }, //char width
  {
    title: 'Sample State',
    width: { wch: 14 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  }, //char width
  {
    title: 'Intensity',
    width: { wch: 11 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  // {title: "Intensity (engine)", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {
    title: 'Quality',
    width: { wch: 9 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Clarity',
    width: { wch: 10 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Address',
    width: { wch: 16 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Comment',
    width: { wch: 16 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Point Classify',
    width: { wch: 16 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Sample Classify',
    width: { wch: 16 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Duration',
    width: { wch: 10 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Frequency',
    width: { wch: 10 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Longitude',
    width: { wch: 16 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
  {
    title: 'Latitude',
    width: { wch: 16 },
    style: { alignment: { horizontal: 'center', vertical: 'center' } },
  },
];

const getXlsRow = (item, options, accessPointsOptions, mobileAlgParams) => {
  let status = '--';
  let sopClassify = '--';
  let audioClassify = '--';
  let mobileIntensity = '--';
  let mobileQuality = '--';
  // let engineIntensity = '--';

  if (item.sample_state !== undefined) {
    const stateOption = options.SampleState.find(
      (opt) => opt.value === item.sample_state
    );
    if (stateOption) {
      status = stateOption.label;
    }
  }
  if (item.user_sop_classify !== undefined) {
    const sopClassifyOption = accessPointsOptions.find(
      (opt) => opt.value === item.user_sop_classify
    );
    if (sopClassifyOption) {
      sopClassify = sopClassifyOption.label;
    }
  }
  if (item.user_sample_classify !== undefined) {
    const audioClassifyOption = options.AudioClassificationEnum.find(
      (opt) => opt.value === item.user_sample_classify
    );
    if (audioClassifyOption) {
      audioClassify = audioClassifyOption.label;
    }
  }
  if (item.mobile_intensity) {
    mobileIntensity = convertIntensityToDb(
      item.mobile_intensity,
      mobileAlgParams.noise_ref_delta_db,
      mobileAlgParams.engine_calibration
    );
  }
  if (item.mobile_quality) {
    mobileQuality = Math.max(item.mobile_quality, 0.2) * 100;
  }
  // if (item.engine_intensity) {
  //   engineIntensity = convertIntensityToDb(item.engine_intensity, mobileAlgParams.noise_ref_delta_db, false);
  // }

  return [
    {
      value: new Date(item.sample_time).toLocaleString(),
      style: { font: { sz: '12' } },
    },
    { value: item.user_name || '--', style: { font: { sz: '12' } } },
    { value: item.device_id_hex || '', style: { font: { sz: '12' } } },
    { value: status, style: { font: { sz: '12' } } },
    { value: mobileIntensity, style: { font: { sz: '12' }, numFmt: '1' } },
    // { value: engineIntensity, style: { font: {sz: "12"}, numFmt: '1'} },
    { value: mobileQuality, style: { font: { sz: '12' }, numFmt: '1' } },
    {
      value: item.engine_quality || '',
      style: { font: { sz: '12' }, numFmt: '1' },
    },
    { value: item.address || '', style: { font: { sz: '12' } } },
    { value: item.user_comment || '', style: { font: { sz: '12' } } },
    { value: sopClassify, style: { font: { sz: '12' } } },
    { value: audioClassify, style: { font: { sz: '12' } } },
    { value: item.audio_duration, style: { font: { sz: '12' } } },
    { value: item.audio_frequency / 8, style: { font: { sz: '12' } } },
    { value: item.location.points[0].y, style: { font: { sz: '12' } } },
    { value: item.location.points[0].x, style: { font: { sz: '12' } } },
  ];
};

const MobileSamplesTable = (props, context) => {
  const language = useSelector((state) => state.i18nState.lang);
  const { fetchAddress } = useGeocoder(language);
  const {
    displayItems,
    setDisplayItems,
    setCheckedSamples,
    checkedSamples,
    samples,
    options,
    // selectedFeature: selectedTaskId,
    uiInfo: { pageHeight, pageWidth },
  } = props;

  const mobileAlgParams = useSelector(
    (state) => state.mobile.samples.algParams
  );

  const mobileContext = useSelector((state) => state.mobile.modeState.mode);

  // const imagesOfSamples = useSelector((state) => state.mobile.samples.imagesOfItems);
  const tasksObj = useSelector((state) => state.mobile.tasks);
  const selectedTask = tasksObj.selectedTask
    .map((taskId) => tasksObj.items[tasksObj.indexMap[taskId]]['task_name'])
    .join('_');

  const selectedAlertId = useSelector(
    (state) => state.mobile.alerts.selectedAlert
  );
  const dispatch = useDispatch();

  const pWidth = 0.385 * pageWidth;
  const pHeight = 0.375 * pageHeight;

  const [statusFilter, setStatusFilter] = React.useState();
  const [accessPointFilter, setAccessPointFilter] = React.useState();
  const [scrollToIndex, setScrollToIndex] = React.useState(null);
  const [sortField, setSortField] = React.useState('sample_time');
  const [sortDir, setSortDir] = React.useState('desc');

  const [accessPointsOptions, setAccessPointsOptions] = React.useState([]);

  const [xlsData, setXlsData] = React.useState({});

  // set access points options:
  React.useEffect(() => {
    if (options.AqsCode) {
      const accessPointOptions = options.AqsCode.filter(
        (opt) => opt.isMobile === 1
      );
      setAccessPointsOptions(accessPointOptions);
    }
  }, [options.AqsCode]);

  // set scroll to selected sample:
  React.useEffect(() => {
    if (props.selectedSample) {
      const selectedRow = displayItems.findIndex(
        (item) => item['sample_uid'] === props.selectedSample
      );

      if (selectedRow !== -1) {
        setScrollToIndex(selectedRow);
      }
    }
  }, [props.selectedSample, displayItems]);

  // set xls data:
  React.useEffect(() => {
    if (options && accessPointsOptions) {
      const xlsRows = samples.map((item) =>
        getXlsRow(item, options, accessPointsOptions, mobileAlgParams)
      );
      const xlsData = [
        {
          columns: xlsColumns,
          data: xlsRows,
        },
      ];

      setXlsData(xlsData);
    }
  }, [samples, options, accessPointsOptions, mobileAlgParams]);

  // handle filters and sort changes:
  React.useEffect(() => {
    // filter by status:
    const filteredStatus = !statusFilter
      ? samples
      : samples.filter((item) => item['sample_state'] === statusFilter.value);
    const filteredAccessPoint = !accessPointFilter
      ? filteredStatus
      : filteredStatus.filter(
          (item) => item['user_sop_classify'] === accessPointFilter.value
        );
    const filteredAndSorted = filteredAccessPoint.sort((a, b) => {
      let sortResult = 0;
      const fieldA = a[sortField] || '';
      const fieldB = b[sortField] || '';
      if (fieldA > fieldB) {
        sortResult = sortDir === 'asc' ? 1 : -1;
      } else if (fieldA < fieldB) {
        sortResult = sortDir === 'asc' ? -1 : 1;
      }

      return sortResult;
    });

    setDisplayItems([...filteredAndSorted]);
  }, [
    samples,
    statusFilter,
    accessPointFilter,
    sortField,
    sortDir,
    props.selectedSample,
  ]);

  const sortSamples = (field, dir) => {
    setSortField(field);
    setSortDir(dir);
  };

  const samplesSortProps = () => ({
    sort: sortSamples,
    sortBy: sortField,
    sortDir: sortDir,
  });

  const handleRowClick = (_event, rowIndex) => {
    const selectedSampleItem = displayItems[rowIndex];
    props.onSelectMobileSample(selectedSampleItem);
  };

  const handleRowClassNameGetter = (index) => {
    return displayItems[index]?.sample_uid === props.selectedSample
      ? 'active-row'
      : '';
  };
  const isChecked = (value) => checkedSamples.includes(value?.sample_uid);

  const toggleSampleChecked = (value, checked) => {
    if (!checked) {
      setCheckedSamples([...checkedSamples, value.sample_uid]);
    } else {
      setCheckedSamples(checkedSamples.filter((it) => it !== value.sample_uid));
    }
  };

  const myHandleRowClick = (event, rowIndex) => {
    if (event.target.type === undefined || event.target.type !== 'checkbox') {
      handleRowClick(event, rowIndex);
    }
  };
  const filterItemsWithEmptyAddress = (displayItems) => {
    return displayItems.filter(
      (item) => !item.address && item.latitude && item.longitude
    );
  };
  const fillEmptyAddresses = async () => {
    const filteredItems = filterItemsWithEmptyAddress(displayItems);
    if (filteredItems.length === 0) {
      props.notify('No empty addresses to fill', 'info');
      return;
    }
    const handleAddress = async (item, callback) => {
      const address = await fetchAddress(item.latitude, item.longitude);
      if (address?.FormattedAddress) {
        dispatch(
          updateSampleState(
            item.sample_uid,
            {
              address: address.FormattedAddress,
              user_comment: item.user_comment,
            },
            (isSuccess) => {
              _.delay(callback, 100, null, isSuccess);
            }
          )
        );
      }
    };
    const results = await mapLimit(filteredItems, 1, (item, callback) =>
      handleAddress(item, callback)
    );
    const successCount = results.filter((result) => result).length;
    const totalCount = filteredItems.length;
    props.notify(
      `Filled ${successCount} addresses out of ${totalCount}`,
      'info'
    );
  };

  const [showAddMobileAlertsForm, setShowAddMobileAlertsForm] =
    React.useState(false);

  const handleShowAddMobileAlertForm = (isShown) => {
    if (showAddMobileAlertsForm !== isShown) {
      setShowAddMobileAlertsForm(isShown);
      dispatch(
        setMapDrawMode(true, DRAW_TYPES.POINT)
      );
      dispatch(actionsAlerts.setAddingNewManualAlertMode(true));
    }
  };

  return (
    <div className='samples-table'>
      <CAddMobileAlert
        isOpen={showAddMobileAlertsForm}
        showAddMobileAlertsForm={handleShowAddMobileAlertForm}
      />
      <Table
        rowsCount={displayItems.length}
        width={pWidth}
        height={pHeight}
        headerHeight={30}
        onRowClick={myHandleRowClick}
        rowClassNameGetter={handleRowClassNameGetter}
        scrollToRow={scrollToIndex}
        rowHeight={30}
      >
        <Column
          columnKey='Selected'
          header={<Cell>{}</Cell>}
          cell={
            <CheckBoxCell
              data={displayItems}
              onChange={toggleSampleChecked}
              isChecked={isChecked}
            />
          }
          width={30}
          flexGrow={1}
        />
        <Column
          columnKey='sample_images'
          header={
            <SortableHeaderCell
              title={context.t('images')}
              sortTable={samplesSortProps()}
            />
          }
          cell={<TextCell data={displayItems} field='sample_images' />}
          width={50}
          flexGrow={1}
        />
        <Column
          columnKey='sample_time'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <TextCell
              data={displayItems}
              field='sample_time'
              additional={props.timeZone}
              dateType={DateType.DATE_AND_TIME}
            />
          }
          width={150}
          flexGrow={5}
        />
        <Column
          columnKey='device_id'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='device_id_hex' />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey='sample_state'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <CSelectCell
              data={displayItems}
              options={options.SampleState}
              field='sample_state'
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey='mobile_intensity'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <TextCell
              data={displayItems}
              field='mobile_intensity'
              additional={mobileAlgParams}
            />
          }
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey='mobile_quality'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='mobile_quality' />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey='user_comment'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='user_comment' />}
          width={100}
          flexGrow={1}
        />
        {/* <Column
          columnKey="engine_quality"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={<TextCell data={displayItems} field="engine_quality" />}
          width={100}
          flexGrow={1}
        /> */}
        <Column
          columnKey='user_sop_classify'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <CSelectCell
              data={displayItems}
              options={accessPointsOptions}
              field='user_sop_classify'
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey='user_sample_classify'
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={
            <CSelectCell
              data={displayItems}
              options={options.AudioClassificationEnum}
              field='user_sample_classify'
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey='user_name'
          header={<SortableHeaderCell sortTable={samplesSortProps()} />}
          cell={<TextCell data={displayItems} field='user_name' />}
          width={100}
          flexGrow={1}
        />
      </Table>

      <div className='mobile-samples-bottom'>
        {checkedSamples.length > 0 && mobileContext === 'Tasks' && !showAddMobileAlertsForm &&(
          <button
            type='button'
            className='btn btn-success btn-assessment create-item'
            onClick={() => {
              handleShowAddMobileAlertForm(true);
            }}
          >
            <i title={context.t('create_alert')} />
          </button>
        )}

      <ExcelFile
        filename={`${getDateForExcelExports()} ${selectedTask}`} // or selectedAlertId
        element={
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<SaveIcon />}
          >
            {context.t('save_as_excel')}
          </Button>
        }
      >
        <ExcelSheet dataSet={xlsData} name={'Mobile Samples'} />
      </ExcelFile>

      <div
        style={{ display: 'flex', alignItems: 'center', columnGap: '2px' }}
      >
        <label>{context.t('status')}</label>
        <Select
          isClearable
          placeholder={context.t('status')}
          options={options.SampleState}
          onChange={(newValue) => setStatusFilter(newValue)}
          menuPosition='fixed'
          menuPlacement='auto'
        />
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', columnGap: '2px' }}
      >
        <label>{context.t('access_point')}:</label>
        <Select
          isClearable
          placeholder={context.t('access_point')}
          options={accessPointsOptions}
          onChange={(newValue) => setAccessPointFilter(newValue)}
          menuPosition='fixed'
          menuPlacement='auto'
        />
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={fillEmptyAddresses}
        >
          {context.t('fill_addresses')}
        </Button>
      </div>
    </div>
</div>
)
  ;
};

MobileSamplesTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileSamplesTable;
