import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');


const style = new Style({
  image: new Icon({
    src: require('../../../../images/map/iQuariusSamples/ic_IQsample_survey.png'),
    anchor: [0.5, 1]
  })
});

const source = new VectorSource({
  features: [],
});

const layer = new VectorLayer({
  name: 'layerIQuariusSamples',
  style: style,
  source: source,
  zIndex: zIndexs.alerts,
});

export const setSource = (features, props) => {
  const iconFeatures = [];

  Object.keys(features).forEach((key) => {
    const itrFeature = features[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          itrFeature.position,
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: 'iQuariusSample',
      id: itrFeature.id,
      taskName: itrFeature.taskName,
      sampleTime: itrFeature.sampleTime,
      userName1: itrFeature.userName1,
      userName2: itrFeature.userName2,
      GPSQuality: itrFeature.GPSQuality,
      pipeType: itrFeature.pipeType,
      comments: itrFeature.comments,
      intensity: itrFeature.intensity,
      quality: itrFeature.quality,
      probability: itrFeature.probability,
      customerMobileName: itrFeature.customerMobileName,
    });
    const style = getStyle(iconFeature);
    iconFeature.setStyle(style);
    iconFeatures.push(iconFeature);
  });

  source.addFeatures(iconFeatures);
}

export const getStyle = (feature, selected) => {
  let style = false;
  let iconType = false;

  const attr = feature.getProperties();
  iconType = (attr.userName2 == null || attr.userName2 == '') ? 'ic_IQsample_survey' : 'ic_IQsample_correlation'

  selected = selected ? '-selected' : '';

  if (iconType) {
    style  = new Style({
      image: new Icon({
        src: require('../../../../images/map/iQuariusSamples/' + iconType + selected + '.png'),
        anchor: [0.5, 1]
      })
    });
  }
  return style ;
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
}

export const getLayers = () => {
  return [layer];
}

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();

  const infoTooltip = {
      type: attr.type,
      id: attr.id,
      sampleTime: attr.sampleTime,
      intensity: attr.intensity,
      quality: attr.quality,
      probability: attr.probability,
      comments: attr.comments,
  };

  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
  return (
    <span className="tooltip">
        <div className="tooltip-row">{context.t('sample_id')} { itemInfo.id }</div>
        <div className="tooltip-row">{context.t('time')}: { UIDateFormater(itemInfo.sampleTime, DateType.DATE_AND_TIME, this.props.timeZone) }</div>
        <div className="tooltip-row">{context.t('intensity')} { Math.round(itemInfo.intensity) }</div>
        <div className="tooltip-row">{context.t('quality')} { Math.round(itemInfo.quality) }</div>
        <div className="tooltip-row">{context.t('clarity')} { Math.round(itemInfo.probability) }</div>
        <div className="tooltip-row">{context.t('comment')} { itemInfo.comments }</div>
    </span>
  );
};
