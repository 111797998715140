import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import Select from './../Select/Select';

const classNames = require('classnames');

require('./MobileTasksSettings.scss');

const MobileTasksSettings = (props, context) => {

  const {
    handleSubmit,
    pristine,
    submitting,
    uiInfo,
    toggleSettingsDisplay,
    sampleStateOptions,
    tasksList,
  } = props;
  const panelClasses = classNames('sensors-settings', 'alerts-settings', {
    show: props.showSettings,
  });

  const panelStyle = {
    height: uiInfo.pageHeight,
    width: 0.395 * (uiInfo.pageWidth - 10),
  };

  return (
    <div className={panelClasses} style={panelStyle}>
      <div className="sensors-header alerts-header">
        <div className="actions">
          <i className="icon-close" onClick={toggleSettingsDisplay} />
        </div>
        <h2>{context.t('tasks_options')}</h2>
      </div>
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <fieldset>
          <legend>{context.t('sort_by')}</legend>
          <div className="form-group">
            <div className="col-xs-8">
              <Field
                name="Tasks.sortBy"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    clearable={false}
                    options={[
                      {
                        value: 'CreationDate',
                        label: context.t('creation_date_default'),
                      },
                      {
                        value: 'TaskName',
                        label: context.t('task_name'),
                      },
                      {
                        value: 'SamplesCount',
                        label: context.t('samples_count'),
                      },
                    ]}
                  />
                )}
              />
            </div>
            <div className="col-xs-4">
              <Field
                name="Tasks.sortByDir"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    clearable={false}
                    backspaceRemoves={false}
                    options={[
                      { value: 'asc', label: context.t('asc') },
                      { value: 'desc', label: context.t('desc') },
                    ]}
                  />
                )}
              />
            </div>
          </div>
          <legend>{context.t('tasks_filters')}</legend>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('creation_date')}
            </label>
            <div className="col-xs-3 control-date-form">
              <Field
                name="Tasks.CreationDate-From"
                component="input"
                type="date"
                className="form-control"
              />
            </div>
            <div className="col-xs-1 control-label"> {context.t('to')}</div>
            <div className="col-xs-3 control-date-to">
              <Field
                name="Tasks.CreationDate-To"
                component="input"
                type="date"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('task_name')}
            </label>
            <div className="col-xs-6">
              <Field
                name="Tasks.TaskName"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('select')}
                    multi
                    clearable
                    backspaceRemoves
                    options={tasksList.map((task) => ({
                      label: task.task_name,
                      value: task.task_name,
                    }))}
                  />
                )}
              />
            </div>
          </div>

          {/* <h2>Samples Options</h2>
          <legend>{context.t('Samples Filters')}</legend>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('Sample State')}
            </label>
            <div className="col-xs-6">
              <Field
                name="Samples.sample_state"
                className="form-control"
                component={(props) => (
                  <Select
                    multi
                    placeholderTitle={context.t('select')}
                    clearable={false}
                    options={sampleStateOptions}
                    {...props}
                  />
                )}
              />
            </div>
          </div> */}
          <div className="text-right">
            <button
              className="btn btn-success"
              type="submit"
              disabled={pristine || submitting}
              onClick={toggleSettingsDisplay}
            >
              {context.t('save_and_apply')}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

MobileTasksSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

// Decorate the form component
const mobileTasksSettings = reduxForm({
  form: 'mobile-tasks-settings', // a unique name for this form
  enableReinitialize: true,
})(MobileTasksSettings);

export default mobileTasksSettings;
