import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import DeleteModal from '../CouplesTabs/DetailsTab/DeleteModal';
import Input from '../Input/Input';
import Select from '../Select/Select';
import CommentField from '../CommentField/CommentField';
import AddressField from '../Custom/AddressField';

import { InstallOperationMode, UninstallMode } from '../../constants/Misc';



class SOPsDetailsTab extends React.Component {
  render() {
    const { mode, selectedSOP, user, pristine, submitting, handleSubmit, requiresAreComplete, installState } = this.props;

    if (installState == null || user == null) {
      return null;
    }

    const buttonTitle = (installState.newInstallItem == null) ? this.context.t('save') : this.context.t('create_new_sop');

    return (
      <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
        <CommentField
          fieldName="Comment"
          featureID={selectedSOP}
          placeholder={this.context.t('installation_point_comment')}
          onEdit={this.props.onEdit}
          onCopy={this.props.onCopy}
          disabled={!user.editMode}
        />
        <div className="form-group">
          <AddressField user={this.props.user} fieldName="StreetAddress" onGetAddress={this.props.getAddressByLatLong} />
        </div>
        {this.props.selectedSOP === null &&
          <div className="form-group">
            <label className="col-xs-2 control-label">{this.context.t('coordinate')}:</label>
            <div className="col-xs-10 control-input">
              <Field
                className="form-control"
                name="Coordinate"
                placeholder={this.context.t('click_on_map_to_set_coordinate')}
                component={Input}
                type="text"
                disabled={!user.editMode} />
            </div>
          </div>
        }
        <div className="form-group">
          <label className="col-xs-2 control-label">{this.context.t('asset_id')}:</label>
          <div className="col-xs-10 control-input">
            <Field name="AssetID" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('type')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="AvizarCode"
                   disabled={!user.editMode}
                   component={(props) => (
                     <Select {...props}
                             key={"AvizarCode"}
                             clearable={false}
                             placeholderTitle={this.context.t('select')}
                             options={this.props.SopTypes}
                     />
                   )}
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('state')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="State"
                   disabled={!user.editMode}
                   component={(props) => (
                     <Select {...props}
                             key={"State"}
                             clearable={false}
                             placeholderTitle={this.context.t('select')}
                             options={this.props.SopStatuses}
                     />
                   )}
            />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('lamppost_number')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="lamppost_num" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('water_type')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="water_type"
                   disabled={!user.editMode}
                   component={(props) => (
                     <Select {...props}
                             key={"water_type"}
                             clearable={false}
                             placeholderTitle={this.context.t('select')}
                             options={this.props.WaterTypes}
                     />
                   )}
            />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('pipe_diameter')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="pipe_diameter" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('pipe_material')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="pipe_material"
                   disabled={!user.editMode}
                   component={(props) => (
                     <Select {...props}
                             key={"pipe_material"}
                             clearable={false}
                             placeholderTitle={this.context.t('select')}
                             options={this.props.PipeMaterials}
                     />
                   )}
            />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('pin_location')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="google_pin_location" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('g3e_fid')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="G3E_FID" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>

        <div className="form-actions">
          <button className="btn btn-success btn-submit" disabled={pristine || submitting || !requiresAreComplete} type="submit">{buttonTitle}</button>

          {mode == InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE || mode == InstallOperationMode.SOP_AND_SENSOR ?
            (
              <DeleteModal
                className="btn btn-danger"
                data-toggle="tooltip"
                data-placement="top"
                title={this.context.t('please_uninstall_device')}
                id={this.props.selectedSOP}
                name={this.props.selectedSOP}
                type={'sop'}
                disabled={true}
              />
            ) : (
              <DeleteModal
                className="btn btn-danger"
                data-toggle="tooltip"
                data-placement="top"
                title={this.context.t('please_uninstall_device')}
                id={this.props.selectedSOP ? this.props.selectedSOP : ''}
                name={this.props.selectedSOP ? this.props.selectedSOP : ''}
                type={'sop'}
                disabled={!user.installerPlanner}
                onDelete={this.props.setDeleteMode}
              />
            )
          }

        </div>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  if (values.AvizarCode == null || values.AvizarCode.toString() == '') {
    errors.AvizarCode = 'Required!';
  }

  if (values.State == null || values.State.toString() == '') {
    errors.State = 'Required!';
  }

  return (errors);
};

SOPsDetailsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const sOPsDetailsTab = reduxForm({
  form: 'sopForm', // a unique name for this form
  validate,
  touchOnBlur: false,
  enableReinitialize: true,
})(SOPsDetailsTab);
export default sOPsDetailsTab;
