import React from 'react';
import PropTypes from 'prop-types';

import Plot from 'react-plotly.js';
import { BarLoader } from 'react-spinners';

const MAX_VALUE_MARGIN = 20;

class LeakGrowth extends React.Component {
	constructor(props) {
    super(props);

    this.renderNoData = this.renderNoData.bind(this);

		this.state = {
			layout: {
				height: 300,
				autorange: true,
				showlegend: true,
				type: 'date',
				margin: {
					t: 30,
					b: 50,
					l: 60,
					r: 30
				},
			},
			config: {
				displaylogo: false,
				responsive: true,
				displayModeBar: true,
				// showLink: true
			},
			plotData: [],
		}
	}

	componentDidMount() {
    this.setPlotData(this.props.data);
	}

	componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    this.setPlotData(data);
  }

  setPlotData(data) {
    let plotData = [];
		// let layout = this.state.layout;

    let maxValue = 0;

		let trace1 = {
			x: [],
			y: [],
			mode: 'lines+markers',
			type: 'scatter',
			name: this.context.t('intensities')
		}

		let trace2 = {
			x: [],
			y: [],
			mode: 'lines',
			type: 'scatter',
			name: this.context.t('trend')
		}

		if(data != null && data.data != null) {
			data.data.forEach((sample) => {
        const sampleIntensity = sample.IntensityA;
				trace1.x.push(new Date(sample.SampleDateAndTime));
        trace1.y.push(sampleIntensity);

        if (sampleIntensity > maxValue) {
          maxValue = sampleIntensity + MAX_VALUE_MARGIN;
        }
			});
		}

		if(data != null && data.regressionData != null) {
			data.regressionData.points.forEach((point, index) => {
				trace2.x.push(trace1.x[index]);
        trace2.y.push(point);

        if (point > maxValue) {
          maxValue = point + MAX_VALUE_MARGIN;
        }
			});

			// layout.title = `${data.regressionData.string} [R^2 = ${data.regressionData.r2}]`;
		}

		plotData.push(trace1);
		plotData.push(trace2);

		this.setState({
      plotData: plotData,
      layout: Object.assign({}, this.state.layout, {
        yaxis: {
          range: [0, Math.max(100, maxValue)] // set y axis range not below 200 scale
        }
      })
		});
  }

  renderNoData() {
    const { data } = this.props;

    return (
      <div>
        <BarLoader
          color="#4cae4c"
          width="110%"
          loading={data.isFetching}
        />

        {this.context.t('no_data')}
      </div>
    )
  }

	render() {
    const { data } = this.props;
    if (data == null) {
      return this.renderNoData();
    }

    if (data.regressionData == null) {
      return this.renderNoData();
    }

		const { layout, config, plotData } = this.state;
		let formula = {};
		if (data.regressionData != null) {
			formula.formula = data.regressionData.string;
      formula.r2 = data.regressionData.r2.toFixed(2);
      formula.slope = (Math.atan(data.regressionData.slope) * (180 / Math.PI)).toFixed();
      formula.std = data.regressionData.std.toFixed(1);
		}
		return (
		<div>

      { formula &&
        <h5 style={{ marginLeft: '10px' }}>{this.context.t('slope_std_r2', formula)}</h5>
      }

			<Plot
				data={plotData}
				layout={layout}
				config={config}
			/>
		</div>
		);
	}
}

LeakGrowth.contextTypes = {
	t: PropTypes.func.isRequired
}

export default LeakGrowth;
