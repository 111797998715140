import React from 'react';
import { featuresTypes, zIndexs } from './Options';

import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
const proj = require('ol/proj');

const itemStyle = new Style({
  image: new Icon({
    src: require('../../../../images/map/sensors/sensor-installed-accmtr-above-gps.png'), anchor: [0.5, 1]
  })
});

const source = new VectorSource({
  features: [],
});

// Sensors layer
export const layer = new VectorLayer({
  name: 'layerSensors',
  style: itemStyle,
  source: source,
  zIndex: zIndexs.sensors,
});

// Sensors source
export const setSource = (nextIconFeatures, props) => {
  let iconFeatures = [];
  Object.keys(nextIconFeatures).forEach((key) => {
    const sensor = nextIconFeatures[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          sensor.position,
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: featuresTypes.SENSOR,
      id: sensor.id,
      status: sensor.status,
      isUnderGround: sensor.isUnderGround,
      sensorType: sensor.sensorType,
      syncType: sensor.syncType,
      assetID: sensor.assetID,
      deviceGenerationValue: sensor.DeviceGenerationValue,
      deviceID: sensor.deviceID,
      address: sensor.address,
      deviceInstallationDate: sensor.deviceInstallationDate,
      radioFrequency: sensor.radioFrequency,
      regularNoise: sensor.regularNoise,
      generationType: sensor.generationType,
      isPressure: sensor.isPressure,
      isActualTransient: sensor.actualTransient,
      resetResently: sensor.resetResently,
      deviceType: sensor.deviceType,
      sampleTime1: sensor.sampleTime1,
      sampleTime2: sensor.sampleTime2,
      sampleTime3: sensor.sampleTime3,
      bitResetCount: sensor.bitResetCount,
      bitResetDate: sensor.bitResetDate,
      autoResetCount: sensor.autoResetCount,
      autoResetDate: sensor.autoResetDate,
      fragmented: sensor.fragmented,
      sopState: sensor.sopState,
      lastUninstallReason: sensor.lastUninstallReason,
      isMini: sensor.isMini,
      isValveMonitor: sensor.isValveMonitor,
    });
    iconFeature.setStyle(getStyle(iconFeature, false, props));
    iconFeatures.push(iconFeature);
  });

  layer.getSource().addFeatures(iconFeatures);
};

// get sensors style
export const getStyle = (feature, selected, props) => {

  let sensorStyle = false;
  let iconStatus = false;

  const attr = feature.getProperties();

  selected = selected ? '-selected' : '';

  const statusMap = {
    'Non Active': 'inactive',
    'Active': 'installed',
    'Ready': 'ready',
    'Not Installed': 'uninstalled'
  };

  if (statusMap[attr.status] == 'uninstalled') {
    iconStatus = 'uninstalled_' + attr.isUnderGround;
  } else if (statusMap[attr.status] && attr.sensorType && attr.isUnderGround && attr.syncType) {
    let statusKey;
    if (attr.status != 'Active' ||
      attr.regularNoise == null ||
      attr.regularNoise ||
      // attr.generationType === 1 ||
      !props.user.advancedMapIndications) {
      statusKey = statusMap[attr.status];
    } else {
      statusKey = 'malfunction';
    }
    iconStatus = statusKey + '-' + attr.sensorType + '-' + attr.isUnderGround + '-' + attr.syncType;
  }

  if (iconStatus) {

    sensorStyle = [
      new Style({
        image: new Icon({
          src: require('../../../../images/map/sensors/sensor-' + iconStatus + selected + '.png'),
          anchor: [0.5, 1]
        })
      })
    ];

    if (attr.isPressure) {
      // set main Sensor icon:
      const isActualTransientSufix = (attr.isActualTransient == true) ? '-transient' : '';
      sensorStyle.push(new Style({
        image: new Icon({
          src: require(`../../../../images/map/sensors/indications/indication${isActualTransientSufix}-pressure.png`),
          scale: 0.3,
          anchor: [0, 1],
        })
      }));
    }

    let starSrc;
    let scale;
    let anchor;
    if (attr.isMini) {
      starSrc = require('../../../../images/map/sensors/indications/star-orange.png');
      scale = 0.1;
      anchor = [0.5, 2.7];
    } else if (attr.isValveMonitor) {
      starSrc = require('../../../../images/map/sensors/indications/star-paleblue.png');
      scale = 0.1;
      anchor = [0.5, 2.7];
    } else {
      starSrc = require('../../../../images/map/sensors/indications/star.png')
      scale = 0.075;
      anchor = [0.5, 3.3];
    }

    // add G5 star icon
    if (attr.generationType === 1) {
      sensorStyle.push(new Style({
        image: new Icon({
          src: starSrc,
          scale: scale,
          anchor: anchor,
        })
      }));
    }

    // add 'X' for install on SOP with Stolen state:
    if (attr.status == 'Not Installed') {
      switch (attr.lastUninstallReason) {
        case 3: // 'Stolen'
          sensorStyle.push(new Style({
            image: new Icon({
              src: require('../../../../images/map/sensors/errors/error-stolen.png'),
              scale: 0.4,
              anchor: [0, 1],
              opacity: 0.75,
            })
          }));
          break;
        case 10: // 'Vandalized'
          sensorStyle.push(new Style({
            image: new Icon({
              src: require('../../../../images/map/sensors/errors/error-damaged.png'),
              scale: 0.4,
              anchor: [0, 1],
              opacity: 0.75,
            })
          }));
          break;
        case 14: // 'archive'
          sensorStyle.push(new Style({
            image: new Icon({
              src: require('../../../../images/map/sensors/errors/error-archived.png'),
              scale: 0.4,
              anchor: [0, 1],
              opacity: 0.75,
            })
          }));
          break;
      }
    }

    // if (props.user.advancedMapIndications) {
    //   // Indication icons: iregular noise:
    //   // NOISE:
    //   if (!attr.regularNoise && attr.status == 'Active') {
    //     sensorStyle.push(new Style({
    //       image: new Icon({
    //           src: require('../../../../images/map/sensors/indications/indication-noise.png'),
    //           scale: 0.3,
    //           anchor: [1, 1],
    //       })
    //     }));
    //   }
    // }

    if (props.user.technicianAdvanced) {
      // Indication icons: reset resently / fragmented file:
      // RESETS:
      if (attr.resetResently) {
        sensorStyle.push(new Style({
          image: new Icon({
            src: require('../../../../images/map/sensors/indications/indication-resets.png'),
            scale: 0.3,
            anchor: [0, 2.5],
          })
        }));
      }

      // FRAGMENTED:
      if (attr.fragmented) {
        sensorStyle.push(new Style({
          image: new Icon({
            src: require('../../../../images/map/sensors/indications/indication-fragmented.png'),
            scale: 0.3,
            anchor: [1, 2.5],
          })
        }));
      }
    }
  }

  return sensorStyle;
};

export const getLayers = () => {
  return [layer];
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();
  let infoTooltip = {
    type: attr.type,
    id: attr.id,
    deviceGenerationValue: attr.deviceGenerationValue,
    deviceID: attr.deviceID,
    assetID: attr.assetID || false,
    sensorType: attr.sensorType,
    address: attr.address,
    radioFrequency: attr.radioFrequency,
    intensity: false,
    priority: false,
    resetResently: attr.resetResently,
    bitResetCount: attr.bitResetCount,
    deviceType: attr.deviceType,
    sampleTime1: attr.sampleTime1,
    sampleTime2: attr.sampleTime2,
    sampleTime3: attr.sampleTime3,
    bitResetDate: attr.bitResetDate,
    autoResetCount: attr.autoResetCount,
    autoResetDate: attr.autoResetDate,
    deviceInstallationDate: attr.deviceInstallationDate,
    fragmented: attr.fragmented
  };
  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context, props) => {
  const { id, deviceType, assetID, address, radioFrequency, resetResently, deviceID, deviceGenerationValue,
    deviceInstallationDate, bitResetDate, sampleTime1, sampleTime2, sampleTime3, autoResetDate } = itemInfo;
  return (
    <span className="tooltip">
      <div className="tooltip-row">{context.t('sensor_id_param', { id: id })}</div>

      {deviceType &&
        <div className="tooltip-row">{context.t('device_type', { deviceType: deviceType })}</div>
      }
      {address &&
        <div className="tooltip-row">{context.t('address_param', { address: address })}</div>
      }

      {deviceInstallationDate &&
        <div className="tooltip-row">{context.t('device_installation_date', { deviceInstallationDate: UIDateFormater(deviceInstallationDate, DateType.DATE, props.timeZone) })}</div>
      }

      {assetID && assetID !== false &&
        <div className="tooltip-row">{context.t('asset_id_param', { asset: assetID })}</div>}
      {props.user.advancedMapIndications && resetResently && bitResetDate &&
        <div>
          <div className="tooltip-row">{context.t('manual_reset_at', { lastManualResetDate: UIDateFormater(bitResetDate, DateType.DATE_AND_TIME, props.timeZone) })}</div>
          <div className="tooltip-row">{context.t('manual_reset_count', { manualResetCount: itemInfo.bitResetCount })}</div>
        </div>
      }

      {props.user.advancedMapIndications && resetResently && autoResetDate &&
        <div>
          <div className="tooltip-row">{context.t('auto_reset_at', { lastAutoResetDate: UIDateFormater(autoResetDate, DateType.DATE_AND_TIME, props.timeZone) })}</div>
          <div className="tooltip-row">{context.t('auto_reset_count', { autoResetCount: itemInfo.autoResetCount })}</div>
        </div>
      }
    </span>
  );
};
