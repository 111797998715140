import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/ReportsActions';
import CAlertsSettings from '../../containers/CAlertsSettings/CAlertsSettings';

import { getUIinfo } from '../UIhelper/UIhelper';

import '../Page/Page.scss';

import ReportsTable from '../../components/Reports/ReportsTable';
import ReportsHeader from '../../components/Reports/ReportsHeader';
import Header from '../../components/Header/Header';

const classNames = require('classnames');

class Reports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            uiInfo: getUIinfo(),
            showSettings: false,
        }

        this.toggleSettingsDisplay = this.toggleSettingsDisplay.bind(this);
        this.updateUIinfo = this.updateUIinfo.bind(this);
        //this.handlesubmitreport = this.handlesubmitreport.bind(this);

    }

    toggleSettingsDisplay() {
        this.setState({ showSettings: !this.state.showSettings });
    }

    componentDidMount() {
        var self = this;

        self.notificationSystem = self.refs.notificationSystem;
        const { dispatch } = this.props;

        window.addEventListener("resize", this.updateUIinfo);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateUIinfo);
    }

    updateUIinfo() {
        this.setState({
            uiInfo: getUIinfo()
        });
    }

    //handleSubmitReport(props) {
    //    actions.fetchReport(props);
    //    console.log("values");
    //}

    render() {
        const { onSubmit, isFetching, user, projects, reportsHeaderValues, columns, report } = this.props;
        const { uiInfo } = this.state;
        const mainClasses = classNames(
            'main',
            'reports-main',
            { 'fetching': isFetching }
        );

        //document.title = 'AQS • Reports';

        return (
            <section className="page reports-page">

                <Header userName={user.name} />

                <div className={mainClasses}>

                    <div className="reports" style={{ minHeight: uiInfo.pageHeight }} >
                        <div className="reports-wrp">

                            <ReportsHeader
                                toggleSettingsDisplay={this.toggleSettingsDisplay}
                                showSettings={this.state.showSettings}
                                projects={projects}
                                formValues={reportsHeaderValues}
                                onSubmit={onSubmit}
                            />

                            <CAlertsSettings
                                showSettings={this.state.showSettings}
                                toggleSettingsDisplay={this.toggleSettingsDisplay}
                                uiInfo={uiInfo}
                                {...uiInfo}
                            />

                            <ReportsTable
                                report={report}
                                data={report.data}
                                columns={columns}
                                {...uiInfo}
                            />

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Reports.propTypes = {
    isFetching: PropTypes.bool.isRequired
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: (values, dispatch, props) => {
            const type = values.reportType;
            const projects = values.projects;
            dispatch(actions.fetchReport(type, projects));
        }
    };
};

function mapStateToProps(state, ownProps) {
    const { projectsList, optionList, user, reports } = state.leaksList;
    // console.log(state);
    const { reportsHeader } = state.form && state.form.reportsHeader ? state.form : { reportsHeader: { values: {} } };
    const lang = state.i18nState.lang;
    const projects = projectsList.items;
    const report = state.leaksList.leaksByProject.reports.report || [];//{ data: [], columns: [] };

    let columns = [];

    if (report && report.length > 0) {
        columns = Object.keys(report[0]).map(key => {
            return {
                'key': key,
            }
        });
    }

    const {
    isFetching,
  } = reports || {
            // Hard coded values (dev only)
            isFetching: false,
        };

    return {
        lang,
        isFetching,
        user,
        projects,
        optionList,
        reportsHeaderValues: reportsHeader.values,
        columns,
        report,
        ownProps,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps)(Reports);
