/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';


import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

require('./Menu.scss');

const classNames = require('classnames');



class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.menuItemRender = this.menuItemRender.bind(this);
  }

  menuItemRender(item, key) {
    if (item.external) {
      return (
        <li key={key}>
          <a className={item.className} href={item.href} target="_blank">
            <i/>
            <div>{item.title}</div>
          </a>
        </li>
      );
    }
    else {
      const itemCls = classNames(
        item.className,
        // react-router activeClassName didn't work well when
        // navigating between alets/ sensors
        {'active': this.props.path == item.href}
      );
      return (
        <li key={key}>
          <a
            className={itemCls}
            onClick={() => {
              this.onChange({
                pathname: item.href,
                query: item.query
              });
            }}
          >
            <i></i>
            <div>{item.title}</div>
          </a>
        </li>
      );
    }
  }

  onChange(data) {
    this.props.onPathChange(data.pathname);
    browserHistory.push(data);
  }

  render() {

    const { selectedProject, editMode, user } = this.props;

    const menuItems =
    [
      {
        title: this.context.t('alerts'),
        className: 'leaks',
        href: 'alerts',
        query: {project: selectedProject}
      },
      {
        title: this.context.t('sensors'),
        className: 'sensors',
        href: 'sensors',
        query: {project: selectedProject}
      }
    ];

    if (editMode) {
      menuItems.push({ title: this.context.t('couples'), className: 'couples', href: 'couples', query: { project: selectedProject } });
    }

    if (JSON.parse(process.env.REACT_APP_MOBILE_MENU) && (user.isAQS || user.mobile)) {
      menuItems.push({ title: this.context.t('mobile'), className: 'leak-locator', href: 'mobile' });
    }

    if (editMode) {
      menuItems.push({ title: this.context.t('management'), className: 'manage', href: 'manage', query: {project: selectedProject}});
    }

    if (editMode) {
      menuItems.push({ title: this.context.t('install'), className: 'install', href: 'install', query: { project: selectedProject } });
    }

    if (user.g5Operator) {
      menuItems.push({ title: this.context.t('g5_devices'), className: 'g5Devices', href: 'g5Devices', query: { project: selectedProject } });
    }
    if (JSON.parse(process.env.REACT_APP_PCA_MODULE) && user.operatePcaModule) {
      menuItems.push({ title: this.context.t('pca'), className: 'inspect', href: 'inspect', query: { project: selectedProject } });
    }

    return (
      <nav className="menu">
        <ul>
        {
          menuItems.map(this.menuItemRender)
        }
        </ul>
      </nav>
    );
  }
}

Menu.contextTypes = {
  t: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    editMode: state.leaksList.user.editMode,
    path: state.routing.locationBeforeTransitions.pathname.replace("/", "")
  };
}

export default connect(mapStateToProps)(Menu);
