
import React from 'react';
import ChooseProjectModal from '../../components/Modals/ChooseProjectModal';

import PropTypes from 'prop-types';
import AdminSensorsReportsModal from '../Modals/AdminSensorsReportsModal';

require('./Picker.scss');
const Picker = (props) => {
  const { value, onChange, options, isAqsAdmin } = props;


  return (
    <>

      <div className="project-picker">
        <ChooseProjectModal />

        <div className="admin-report-modal">
          {isAqsAdmin && JSON.parse(process.env.REACT_APP_DASHBOARD) && <AdminSensorsReportsModal />}
        </div>


        <select onChange={e => onChange(e.target.value)}
          value={value}>
          {options.map(option =>
            <option key={option.ID} value={option.ID}>
              {option.ShortName}
            </option>)
          }
        </select>
      </div>

    </>

  );
}

Picker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.object.isRequired
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Picker;
