import React from 'react';
import {translations} from "../../translations/tr";
import I18n from "redux-i18n";
import Loading from "./Loading";


export default class I18nLoading extends React.Component {

    render() {
        return (
            <I18n translations={translations}>
               <Loading/>
            </I18n>
        );
    }

}
