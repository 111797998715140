import React from 'react';
import { featuresTypes, zIndexs } from './Options';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

const proj = require('ol/proj');

// Alerts layer style
const source = new VectorSource({
  features: [],
});

// Alerts layer
export const layer = new VectorLayer({
  name: featuresTypes.NOISE_ALERT,
  source: source,
  zIndex: zIndexs.alerts,
});

export const setSource = (nextIconFeatures, props) => {
  let iconFeatures = [];
  nextIconFeatures.forEach((itrFeature) => {

    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          [itrFeature.Longitude, itrFeature.Latitude],
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: featuresTypes.NOISE_ALERT,
      id: itrFeature.ID,
      status: itrFeature.AlertStateName,
      //   severity: itrFeature.Severity,
      priority: itrFeature.NoisePriority,
      alertType: itrFeature.AlertType,
      eventDate: itrFeature.CreateDate,
    });
    iconFeature.setStyle(getStyle(iconFeature));
    iconFeatures.push(iconFeature);
  });
  source.addFeatures(iconFeatures);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (feature, bSelected) => {
  //   const severity = feature.get('severity');
  const attr = feature.getProperties();
  const priority = attr.priority;
  let iconName = 'noiseA.png';

  if (priority) {
    if (priority == null) {
      return [];
    } else {
      iconName = 'noise' + priority + '.png';
    }
  }

  const iconStyle = {
    opacity: 0.8,
    scale: 0.04,
    anchor: [0.5, 0.5],
    src: require(`../../../../images/map/alerts/noiseAlerts/${iconName}`),
  };

  const alertStyle = new Style({
    image: new Icon(iconStyle),
  });

  if (bSelected) {
    alertStyle.setImage(
      new Icon({
        src: require('../../../../images/map/sensors/indications/selected-background.png'),
        opacity: 0.75,
        scale: 0.5,
        anchor: [0.5, 0.5],
      })
    );
  }

  return [alertStyle];
};

export const getInfoTooltip = (feature) => {

  const attr = feature.getProperties();

  let infoTooltip = {
    type: attr.type,
    id: attr.id,
    status: attr.status,
    deviceId: attr.deviceId,
    severity: attr.severity,
    alertType: attr.alertType,
    eventDate: attr.eventDate
  };
  return infoTooltip;
};

export const tooltipRender = (itemInfo, context, props) => {

  return (
    <span className={'tooltip'}>
      <div className='tooltip-row'>
        {context.t('alert_id_param', { id: itemInfo.id })}
      </div>
      <div className='tooltip-row'>
        {context.t('status_param', { status: itemInfo.status })}
      </div>
      {itemInfo.alertType && (
        <div className='tooltip-row'>
          {context.t('alert_type_param', { alertType: itemInfo.alertType })}
        </div>
      )}
      <div className='tooltip-row'>
        {context.t('event_date_param', { date: itemInfo.eventDate ? UIDateFormater(itemInfo.eventDate, DateType.DATE, props.timeZone) : '--' })}
      </div>

    </span>
  );
};
