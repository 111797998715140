import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { GridLoader } from 'react-spinners';
import Select from 'react-select';

import './plotModal.scss';

const unitsOptions = [
  { value: 0, label: 'Bar', convertFactor: 0.09804139432 },
  { value: 1, label: 'PSI', convertFactor: 1.4219702063247 },
  { value: 2, label: 'Meters', convertFactor: 1 },
];

export default class SensorPressureGraph extends React.Component {

  static get propTypes() {
    return ({
      title: PropTypes.string,
      selectedFeature: PropTypes.number,
      plotData: PropTypes.array,
      timeZone: PropTypes.string,
      uiInfo: PropTypes.object.isRequired,
      onPlotValueClick: PropTypes.func,
      isFetching: PropTypes.bool,
      allowChangeUnits: PropTypes.bool,
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedFeature: -1,
      activePage: 1,
      layout: {
        showlegend: this.props.legend !== null ? this.props.legend : true,
        type: 'date',
        margin: {
          t: 80,
          b: 50,
          l: 60,
          r: 30
        },
        yaxis: {
          rangemode: 'tozero',
          autorange: true
        }
      },
      config: {
        displaylogo: false,
        responsive: true,
        displayModeBar: true,
        modeBarButtonsToRemove: [
          'drawrect',
          'zoomIn2d',
          'zoomOut2d',
          'resetScale2d',
          'toggleSpikelines',
          'resetViewMapbox',
        ],
      },
      units: unitsOptions[0]
    };
  }

  componentDidMount() {
    const { uiInfo, title } = this.props;

    if (uiInfo) {
      const pWidth = 0.38 * (uiInfo.pageWidth - 10);
      const pHeight = 0.3 * uiInfo.pageHeight;

      const newLayout = Object.assign({}, this.state.layout);
      newLayout.width = pWidth;
      newLayout.height = pHeight;
      newLayout.title = title;

      this.setState({
        layout: newLayout
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.title != this.props.title) {
      const newLayout = Object.assign({}, this.state.layout);
      newLayout.title = nextProps.title;

      this.setState({
        layout: newLayout
      });
    }
  }

  onUnitsChange = (value) => {
    this.setState({
      units: value
    });
  }

  render() {
    const { plotData, isFetching, onPlotValueClick, allowChangeUnits } = this.props;
    const { layout, config } = this.state;

    let data = [];
    if (plotData) {
      data = [
        ...plotData.map((data) => ({
          ...data,
          y: (!allowChangeUnits) ? data.y : data.y.map((y) => (y * this.state.units.convertFactor).toFixed(1))
        }))
      ];
    }
    return (
      <div>
        { isFetching &&
          <div style={{ paddingLeft: '35%', paddingTop: '5%' }}>
            <GridLoader
              sizeUnit={"px"}
              size={60}
              margin='10px'
              color={'#123abc'}
              loading={isFetching}
            />
          </div>
        }

        { !isFetching &&
          <div>
            { allowChangeUnits &&
              <Select
                className='pressure-units-select'
                value={this.state.units}
                onChange={this.onUnitsChange}
                options={unitsOptions}
                clearable={false}
              />
            }
            <Plot
              data={data}
              layout={layout}
              config={config}
              onClick={onPlotValueClick}
            />
          </div>
        }
      </div>
    );
  }
}

SensorPressureGraph.contextTypes = {
  t: PropTypes.func.isRequired
};
